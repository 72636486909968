import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { IFriendData } from '../../../../game-server/src/repositories/UserFriendList.repository';
import ChatMessageTooltip from './Chat/ChatMessageTooltip';

interface IFriendsTabFriendProps {
	friendData: IFriendData;
}

export function FriendsTabFriend(props: IFriendsTabFriendProps) {
	const [isTooltipEnabled, setIsTooltipEnabled] = useState(false);

	return (
		<Popover
			isOpen={isTooltipEnabled}
			padding={10}
			onClickOutside={() => setIsTooltipEnabled(false)}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={'white'}
					arrowSize={8}
					arrowStyle={{ zIndex: 1000, opacity: 1 }}
				>
					<ChatMessageTooltip
						username={props.friendData.username}
						setIsTooltipEnabled={setIsTooltipEnabled}
					/>
				</ArrowContainer>
			)}
		>
			<tr onClick={() => setIsTooltipEnabled(!isTooltipEnabled)}>
				<Box as='td' width='75%'>
					{props.friendData.username}
				</Box>
				<Box as='td' float='right' color={props.friendData.status === 'online' ? 'green' : 'red'}>
					{props.friendData.status}
				</Box>
			</tr>
		</Popover>
	);
}
