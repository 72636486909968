import {
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeNumberInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { TTrueInventoryType } from '../../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { mobileTooltipViewAtom } from '../../../../atoms/mobileTooltipViewAtom';
import useIsMobile from '../../../../hooks/useIsMobile';
import { toggleAmmoInventory } from '../../Combat/CombatAmmoStockpile';
import { toggleAugmentingQueue } from '../../SkillPanels/Enchanting/Augmenting/Augmenting';
import { toggleScrapQueue } from '../../SkillPanels/Enchanting/Scrapping/Scrapping';
import ItemTooltip from '../../Tooltips/ItemTooltip';

interface IToggleQueueDialogProps {
	item: IItem;
	onClose: () => void;
	inventoryName: TTrueInventoryType;
}

export function ToggleAugmentingQueueDialog(props: IToggleQueueDialogProps) {
	return <ToggleQueueDialog {...props} toggleQueue={toggleAugmentingQueue} queueName='Augmenting' />;
}

export function ToggleScrappingQueueDialog(props: IToggleQueueDialogProps) {
	return <ToggleQueueDialog {...props} toggleQueue={toggleScrapQueue} queueName='Research' />;
}

export function ToggleAmmoDialog(props: IToggleQueueDialogProps) {
	return <ToggleQueueDialog {...props} toggleQueue={toggleAmmoInventory} queueName='Ammo Pouch' />;
}

export function ToggleQueueDialog(
	props: {
		toggleQueue: (itemInventoryId: number, inventoryName: TTrueInventoryType, itemAmount: number) => void;
		queueName: string;
	} & IToggleQueueDialogProps
) {
	const [sliderValue, setSliderValue] = useState(1);
	const [mobileView, setMobileView] = useRecoilState(mobileTooltipViewAtom);
	const isMobile = useIsMobile();

	function handleConfirm() {
		props.toggleQueue(props.item.id, props.inventoryName, sliderValue);
		props.onClose();
	}

	function setMax() {
		setSliderValue(props.item.stackSize);
	}

	return (
		<Modal isOpen={true} onClose={props.onClose} size='xl'>
			<ModalOverlay />
			<ModalContent>
				{isMobile && (
					<Flex justifyContent='center' width='100%' top='0.5rem' margin='-13px 0'>
						<IdlescapeButton variant='blue' onClick={() => setMobileView(false)}>
							Details
						</IdlescapeButton>
						<IdlescapeButton variant='green' onClick={() => setMobileView(true)}>
							Action
						</IdlescapeButton>
					</Flex>
				)}
				<ModalHeader>
					{props.inventoryName === 'stockpile'
						? `Move ${props.item.name} to ${props.queueName}${
								props.queueName !== 'Ammo Pouch' ? ' Queue' : ''
						  }?`
						: `Take ${props.item.name} from ${props.queueName}${
								props.queueName !== 'Ammo Pouch' ? ' Queue' : ''
						  }?`}
				</ModalHeader>
				{isMobile && !mobileView ? (
					<Flex justifyContent='center'>
						<ItemTooltip item={props.item} embed={true} />
					</Flex>
				) : (
					<>
						<ModalBody as={Flex} gap='10px' alignItems='center'>
							<Slider
								value={sliderValue}
								onChange={setSliderValue}
								min={1}
								max={props.item.stackSize}
								focusThumbOnChange={false}
							>
								<SliderTrack>
									<SliderFilledTrack />
								</SliderTrack>
								<SliderThumb />
							</Slider>
							<IdlescapeNumberInput
								value={sliderValue}
								setValue={setSliderValue}
								min={1}
								max={props.item.stackSize}
								aria-labelledby='input-slider'
								width='200px'
								paddingX='4px'
								textAlign='center'
							/>
							<IdlescapeButton width='50px' onClick={setMax} variant='green'>
								Max
							</IdlescapeButton>
						</ModalBody>
						<ModalFooter>
							<IdlescapeButton onClick={props.onClose} variant='red'>
								Cancel
							</IdlescapeButton>
							<IdlescapeButton onClick={handleConfirm} variant='green'>
								Confirm
							</IdlescapeButton>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	);
}
