import { Box, Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import React from 'react';
import { useAppSelector, usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { GroupInvitePlayerInput } from './GroupInvitePlayerInput';

export default function GroupInvites() {
	const groupInvites = usePlayerField('groupInvites');
	const playerId = usePlayerField('id');
	const { groupData } = useAppSelector((state) => state.group);

	function handleAcceptInvite(inviter: string) {
		socket.emit('group:acceptInvite', {
			username: inviter,
		});
	}

	function handleDeclineInvite(inviter: string) {
		socket.emit('group:declineInvite', {
			username: inviter,
		});
	}

	function handleRefresh() {
		socket.emit('group:refresh');
	}

	return (
		<>
			{(groupData.leaderId === undefined || groupData.leaderId === playerId) && <GroupInvitePlayerInput />}
			<IdlescapeContainer margin='0 auto' maxWidth='500px' width='100%'>
				<table className='friendlist-table header-fixed'>
					<thead>
						<tr>
							<Box as='th' width='70%'>
								Open Invites
							</Box>
							<th />
							<th />
						</tr>
					</thead>
					<tbody>
						{groupInvites.map((playerName) => (
							<tr key={'groupInvite-' + playerName}>
								<td className='group-panel-invitation-name'>{playerName}</td>
								<Box
									as='td'
									color='green'
									textDecoration='underline'
									_hover={{ fontWeight: 'bold' }}
									cursor='pointer'
									onClick={() => handleAcceptInvite(playerName)}
								>
									Accept
								</Box>
								<Box
									as='td'
									color='red'
									textDecoration='underline'
									_hover={{ fontWeight: 'bold' }}
									cursor='pointer'
									onClick={() => handleDeclineInvite(playerName)}
								>
									Decline
								</Box>
							</tr>
						))}
						{groupInvites.length === 0 && (
							<tr>
								<td>No open invites</td>
								<td></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</IdlescapeContainer>
			<Flex justifyContent='center'>
				<IdlescapeButton variant='red' size='large' onClick={handleRefresh}>
					Force Group Data Refresh
				</IdlescapeButton>
			</Flex>
		</>
	);
}
