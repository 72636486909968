import { IconButton } from '@chakra-ui/react';
import { useLocalStorage } from 'usehooks-ts';
import { BsFillBarChartFill } from 'react-icons/bs';

export const NavDrawerShowLevels = () => {
	const [showLevels, setShowLevels] = useLocalStorage('navbar-showLevels', true);

	return (
		<IconButton
			variant={'outline'}
			icon={<BsFillBarChartFill />}
			color={'blue.300'}
			colorScheme={'blue'}
			aria-label={'Pin Drawer'}
			isActive={showLevels}
			size={'sm'}
			fontSize={'20px'}
			onClick={() => setShowLevels(!showLevels)}
		></IconButton>
	);
};
