import React, { useEffect, useRef } from 'react';
import { getSocket } from './socket.service';
import { setGroupData } from '../actions/groupActions';
import { useAppDispatch, usePlayerField } from '../hooks/hooks';
import { IGroupClientData } from '../../../game-server/src/modules/group/Group';
import { changeFaviconToDefault, changeFaviconToNotify, playNotificationAudio } from '../helper/helperFunctions';

let group: IGroupClientData | null = null;

export const GroupManager = ({ loaded, setLoaded }: { loaded?: boolean; setLoaded: (value: boolean) => void }) => {
	// const [party, setParty] = useRecoilState(monsterAtom);
	const socket = getSocket();

	const dispatch = useAppDispatch();

	const settings = usePlayerField('settings');
	const groupInvites = usePlayerField('groupInvites');
	const previousGroupInvites = useRef(groupInvites);

	useEffect(() => {
		/** Subscribe on mount */
		console.log('Party Manager started');
		socket.on('update:group', (data) => {
			if (data.portion === 'all') {
				group = data.value as IGroupClientData;
			}
			if (!group) return;
			if (data.portion.length === 1) {
				Object.assign(group, { [data.portion[0]]: data.value });
			}

			/* console.log('group update', data); */

			dispatch(setGroupData(group));
		});

		socket.on('update:group-member', (data) => {
			const userIndex = group?.groupMemberData.findIndex((user) => user.id === data.userId);
			if (userIndex === -1 || !group || userIndex === undefined) {
				console.log('ERROR: NOT IN GROUP, PLEASE REPORT TO ADMINS', userIndex, group, data);
				return;
			}
			if (group && group.groupMemberData && userIndex !== -1) {
				Object.assign(group.groupMemberData[userIndex], { [data.key]: data.value });
				dispatch(setGroupData(group));
			}
		});

		setLoaded(true);

		/** Unsubscribe on unmount */
		return () => {
			console.log('Party Manager stopped working');
			socket.off('update:group');
			socket.off('update:group-member');
			setLoaded(false);
		};
	}, []);

	useEffect(() => {
		if (previousGroupInvites.current.length < groupInvites.length) {
			changeFaviconToNotify();
			if (settings.sounds.playSoundOnInvite) {
				playNotificationAudio(settings.sounds.volume);
			}
		} else if (previousGroupInvites.current.length > groupInvites.length) {
			changeFaviconToDefault();
		}
		previousGroupInvites.current = groupInvites;
	}, [settings, groupInvites]);

	return null;
};
