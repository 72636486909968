import { atom, selector } from 'recoil';

export const smithingIntensityAtom = atom({ key: 'smithingIntensityAtom', default: 1 as number });

export const smithingIntensitySelector = selector({
	key: 'smithingIntensitySelector',
	get: ({ get }) => get(smithingIntensityAtom),
	set: ({ set, get }, newValue) => {
		const value = Math.max(Number(newValue), 0);
		const currentValue = get(smithingIntensityAtom);

		if (value === currentValue) {
			return;
		}

		set(smithingIntensityAtom, value);
	},
});
