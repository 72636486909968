import { Text } from '@chakra-ui/react';
import { Dialog } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import './MonetizationPurchasePopup.css';

export const MonetizationPurchasePopup = () => {
	const [open, setOpen] = useState(false);

	const [data, setData] = useState<{ title: string; image: string; message?: string }>({
		title: '1000 Platinum',
		image: '/images/premium/platinum_pack_4.png',
	});

	useEffect(() => {
		socket.on('monetization:purchase:successful', (data) => {
			setData(data);
			setOpen(true);
		});

		return () => {
			socket.off('monetization:purchase:successful');
		};
	}, []);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog monetization-purchase-popup'
		>
			<div className='monetization-purchase-popup-title'>{data.title}</div>
			<Text textAlign={'center'} fontWeight={'bold'} zIndex={1}>
				{data.message}
			</Text>
			<div className='monetization-purchase-popup-image-container'>
				<img
					className='monetization-purchase-popup-image idlescape-frame-box-dark'
					src={data.image}
					alt={data.message ?? 'Successful Purchase'}
				/>
				<div className='sun'>
					<div className='ray_box'>
						<div className='ray ray1'></div>
						<div className='ray ray2'></div>
						<div className='ray ray3'></div>
						<div className='ray ray4'></div>
						<div className='ray ray5'></div>
						<div className='ray ray6'></div>
						<div className='ray ray7'></div>
						<div className='ray ray8'></div>
						<div className='ray ray9'></div>
						<div className='ray ray10'></div>
					</div>
				</div>
				<div className='sun2'>
					<div className='ray_box'>
						<div className='ray ray1'></div>
						<div className='ray ray2'></div>
						<div className='ray ray3'></div>
						<div className='ray ray4'></div>
						<div className='ray ray5'></div>
						<div className='ray ray6'></div>
						<div className='ray ray7'></div>
						<div className='ray ray8'></div>
						<div className='ray ray9'></div>
						<div className='ray ray10'></div>
					</div>
				</div>
			</div>

			<div
				className='idlescape-button idlescape-button-red'
				style={{ width: '250px', zIndex: 1 }}
				onClick={() => setOpen(false)}
			>
				Close
			</div>
		</Dialog>
	);
};
