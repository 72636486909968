// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TEquipments } from '../../../game-server/src/modules/player/PlayerTypes';
import { TIngredientTag } from '../../../game-server/src/modules/skills/cooking/Cooking.interface';
import { cookingList } from '../utils/cookingList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import {
	ALCHEMY_BREAKPOINT,
	COOKING_BREAKPOINT,
	COOKING_TIME_EXP_MULTI,
	FOOD_INITIAL_COST,
	FOOD_QUALITY_SLIDER,
	NORMAL_POTION_IDS,
	POTION_UPGRADE_COST,
} from './constantsCollection';

export function getCookingQuality(level: number, affixBonus: number, isAlchemy = false): number {
	const foodStrength = level / (isAlchemy ? ALCHEMY_BREAKPOINT : COOKING_BREAKPOINT);
	let foodAugmentation = Math.max(0, foodStrength);

	// Do the quality bonus affixes now
	foodAugmentation += affixBonus;
	return foodAugmentation;
}

export function getPotionInfo(itemID?: number) {
	const info = {
		tier: -1,
		upgradeCost: [0, 0, 0, 0, 0],
	};
	if (!itemID) return info;
	let cost = 0;
	for (const [tier, ids] of NORMAL_POTION_IDS.entries()) {
		if (info.tier !== -1) {
			cost += POTION_UPGRADE_COST[tier];
		}
		if (ids === itemID) {
			info.tier = tier;
		}
		info.upgradeCost[tier] = cost;
	}
	return info;
}

export function getFoodInfo(itemID?: number) {
	const info = {
		tier: 0,
		initialCost: 1,
		perSlider: 1,
	};
	if (!itemID) return info;
	const recipeData = cookingList[itemID];
	if (recipeData.recipeTags) {
		info.tier = Object.keys(recipeData.recipeTags).length;
	}
	info.initialCost = FOOD_INITIAL_COST[info.tier];
	info.perSlider = FOOD_QUALITY_SLIDER[info.tier];
	return info;
}

export function getLadleHaste(equipment: TEquipments) {
	const normalLadleIDs = [itemsIds.ladle, itemsIds.lesser_ladle, itemsIds.greater_ladle];
	let ladleBonus = 0;
	if (equipment.ladle?.itemID === itemsIds.searing_ladle) {
		// searing ladle equipped
		ladleBonus = 0.12;
	} else if (normalLadleIDs.includes(equipment.ladle?.itemID ?? -1)) {
		// any other ladle type equipped
		ladleBonus = 0.08;
	} else if (equipment.ladle?.itemID === itemsIds.golden_spoon) {
		// golden spoon equipped
		ladleBonus = 0.16;
	}
	return ladleBonus;
}

export function getNonBurnFormula(difficulty: number, cookingLevel: number) {
	return 1.2 - (difficulty ** 2.5 * 3.5) / (cookingLevel + 50) / 100;
}

export function getCookingDurationFormula(size: number) {
	return 3 * (0.8 + 0.2 * size ** 0.85);
}

// Increase by 1 every 4 quality, (1,2,3,4,6,8,...)
export function qualityCostCurve(quality: number) {
	const step = 4;
	let cost = 0;
	for (let i = quality; i > 0; i -= step) {
		cost += i;
	}
	return cost;
}

const ingredientTags: { [key in TIngredientTag]?: number } = {};

// Get all ingredient tags and their corresponding prepared ingredient
export function getCookingTags() {
	if (Object.keys(ingredientTags).length === 0) {
		for (const id in cookingList) {
			const item = cookingList[id];
			if (item.ingredientTags && item.prepared) {
				ingredientTags[item.ingredientTags[0]] = Number(id);
			}
		}
	}
	return ingredientTags;
}

export function getItemIDFromTag(tag: TIngredientTag): number | undefined {
	return getCookingTags()[tag];
}

export function validPrepIngredient(itemID: number) {
	const cookingData = cookingList[itemID];
	if (cookingData) {
		if (cookingData.ingredientTags && !cookingData.prepared) {
			return true;
		}
	}
	return false;
}
// Slow down cooking with more types of ingredients
export function cookingTimeExpMulti(itemID?: number) {
	let multi = COOKING_TIME_EXP_MULTI[0];
	if (!itemID) return multi;
	const cookingData = cookingList[itemID];
	if (cookingData && cookingData.recipeTags) {
		multi = COOKING_TIME_EXP_MULTI[Object.keys(cookingData.recipeTags).length] ?? multi;
	}
	return multi;
}

export function getFragmentChance(
	size: number,
	difficulty: number,
	effectiveCookingLevel: number,
	fromPreparing = false
) {
	const fromPreparationDiv = fromPreparing ? 2 : 1;
	const sizeAndDifficultyBonus = (size + difficulty) ** 1.5;
	const fragChance = (1 / 200000000 / fromPreparationDiv) * sizeAndDifficultyBonus * effectiveCookingLevel;
	return fragChance;
}
