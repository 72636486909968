import { AnyAction } from 'redux';
import { IGlobals } from '../../../game-server/src/modules/globals/Globals';
import { PlayerData } from '../../../game-server/src/modules/player/PlayerData';
import { PLAYER_INFORMATION, SET_GLOBALS } from '../actions/types';

export interface GameReducerState {
	playerInformation: PlayerData;
	globals: IGlobals;
}

const initialState: GameReducerState = {
	playerInformation: {} as PlayerData,
	globals: {} as IGlobals,
};

export default function (state = initialState, action: AnyAction): GameReducerState {
	switch (action.type) {
		case PLAYER_INFORMATION:
			return {
				...state,
				playerInformation: action.payload,
			};
		case SET_GLOBALS:
			return {
				...state,
				globals: action.payload,
			};
		default:
			return state;
	}
}
