import { Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { usePlayerField } from '../../../../hooks/hooks';
import { locations } from '../../../../utils/locationList';
import { formatNumber } from '../../../../helper/StringFormatter';
import { IFishingData } from './FishingData';
import { animationAtom } from '../../../../atoms/animationAtom';
import { ActionTypes } from '../../../../utils/enums/ActionTypes';

export function FishingInfo(fishingInfo: IFishingData) {
	const actionQueue = usePlayerField('actionQueue');
	const nodeData = useRecoilValue(animationAtom)?.node;
	const active = actionQueue?.actionType === ActionTypes.Fishing;

	function getCurrentNodeInformation() {
		if (!active) {
			return (
				<div className='fishing-info-header'>
					<Heading className='fishing-info-title-border' size='md'>
						Waiting for Node...
					</Heading>
					<p>Find a node!</p>
					<Heading className='fishing-info-title-border' size='md'>
						Fishing Info
					</Heading>
				</div>
			);
		}

		return (
			<div className='fishing-info-header'>
				<Heading className='fishing-info-title-border' size='md'>
					Current Node
				</Heading>
				<p>
					Zone: {locations[actionQueue.location].name}
					<br />
					Node:{' '}
					<Text
						as='span'
						color='lightblue'
						style={{
							textShadow: '0.1px 0.1px 0.1px white',
							WebkitTextStroke: '0.1px white',
						}}
					>
						{nodeData?.nodeID ?? 'Searching...'}
					</Text>
					<br />
					Remaining: {nodeData?.resourcesLeft ?? 0}
				</p>
				<Heading className='fishing-info-title-border' size='md'>
					Fishing Info
				</Heading>
			</div>
		);
	}

	return (
		<div className='fishing-info idlescape-container idlescape-container-blue'>
			{getCurrentNodeInformation()}

			<div className='fishing-info-content-wrapper'>
				<div>
					<Text as='span' color={'blue.300'}>
						Effective Fishing Level: {formatNumber(fishingInfo.level)}
					</Text>
					<br />- Chance of finding and gathering from a node
					<br />- Rarity of resources
					<br />- Quantity of resources in a node
					<br />- Chance to preserve bait
					<br />
					<br />
					<Text as='span' color={'blue.300'}>
						Bait Preservation: {formatNumber(fishingInfo.preservation * 100)}%
					</Text>
					<b className='blue-text'></b>
					<br />
					Capped at 50%.
					<br />
					<br />
					<Text as='span' color={'blue.300'}>
						Bait Power: {formatNumber(fishingInfo.bait)}
					</Text>
					<br />
					Chance and speed of finding nodes.
					<br />
					<br />
					<Text as='span' color={'blue.300'}>
						Reel Power: {formatNumber(fishingInfo.reel)}
					</Text>
					<br />
					Chance and speed of gathering resources.
					<br />
					<br />
					<Text as='span' color={'blue.300'}>
						Bonus Rarity: {formatNumber(fishingInfo.bonus)}
					</Text>
					<br />
					Quality of resources and nodes.
				</div>
			</div>
		</div>
	);
}
