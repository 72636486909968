import React, { useState } from 'react';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { abilities as masterAbilityList } from '../../../../utils/abilityList';
import LoadoutItem from './LoadoutItem';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { Box, Flex } from '@chakra-ui/react';
import AbilityList from '../Abilities/AbilityList';
import { doItemsMatch } from '../../../../utils/itemFunctions';
import FakeItem from '../../Inventory/FakeItem';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { cloneDeep } from 'lodash';
import {
	AFFIX_TOOL_SLOTS,
	ALL_EQUIPMENT_SLOTS,
	COMBAT_INVENTORY_SIZE,
	TEMPLATE_LOADOUT,
} from '../../../../utils/constantsCollection';
import LoadoutSelectEquipment from './LoadoutSelectEquipment';
import { TEquipmentPartials } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import LoadoutSelectFood from './LoadoutSelectFood';
import AbilityEditor from '../Abilities/AbilityEditor';
import { IdlescapeContainer } from '@idlescape/ui';
import LoadoutFoodStockpile from './LoadoutFoodStockpile';

const RED = 'rgba(255, 0, 0, 0.5)';
const GREEN = 'rgba(0, 255, 0, 0.5)';
const BLUE = 'rgba(0, 0, 255, 0.5)';

const LoadoutView = ({
	loadoutId,
	viewMode = false,
	editMode = false,
	ignoresTools = undefined,
}: {
	loadoutId: number;
	viewMode?: boolean;
	editMode?: boolean;
	ignoresTools?: boolean;
}) => {
	const loadouts = usePlayerField('loadouts');
	const stockpile = usePlayerField('stockpile');
	const vault = usePlayerField('vault');
	const currentlyEquipped = usePlayerField('equipment');
	const combatInventory = usePlayerField('combatInventory');
	const [selectedContainerTab, setSelectedContainerTab] = useState('equipment');
	const selectedLoadoutData = loadouts.find((loadout) => loadout.id === loadoutId) || TEMPLATE_LOADOUT;

	// Use passed in ignoreTools as selectedLoadoutData has pre-edited data
	const hideTools = (ignoresTools !== undefined ? ignoresTools : selectedLoadoutData.options.ignoresTools) ?? false;

	function getEquipmentBorder(equippedItem: TPartialItemWithItemID<IItem> | undefined) {
		// Check if it's equipped already, then if it's in the stockpile, then if it's in vault, and if not in vault then it's not found
		const currentlyEquippedItem = Object.values(currentlyEquipped).find((e) => doItemsMatch(e, equippedItem));
		if (currentlyEquippedItem) {
			return BLUE;
		}
		const stockpileItem = stockpile.find((e) => doItemsMatch(e, equippedItem));
		if (!stockpileItem) {
			// Find it in the vault
			const vaultItem = vault.find((e) => doItemsMatch(e, equippedItem));
			if (!vaultItem) {
				// Couldn't be found
				return RED;
			}
		}
		return GREEN;
	}

	function getFoodBorders(inventory: ILoadout['foodInventory']) {
		const borderColors: string[] = [];
		const stockpileCopy = cloneDeep(stockpile);
		const combatInventoryCopy = cloneDeep(combatInventory);
		for (const item of inventory.filter(Boolean)) {
			const combatInventoryItemindex = combatInventoryCopy.findIndex((e) => doItemsMatch(e, item));
			if (combatInventoryItemindex !== -1) {
				borderColors.push(BLUE);
				combatInventoryCopy.splice(combatInventoryItemindex, 1);
				continue;
			}
			const stockpileItem = stockpileCopy.find((e) => doItemsMatch(e, item));
			if (stockpileItem && stockpileItem.stackSize > 0) {
				borderColors.push(GREEN);
				stockpileItem.stackSize--;
			} else {
				borderColors.push(RED);
			}
		}
		return borderColors;
	}

	function renderEquipment(equipment: TEquipmentPartials) {
		return (
			<IdlescapeContainer className='loadout-inventory-container-wrapper' variant={editMode ? 'red' : undefined}>
				<div className={`loadout-equipment-container ${hideTools ? 'loadout-hide-tools' : ''}`}>
					<img src='/images/combat/combat_stick_figure.png' alt='Combat stick figure' />
					{ALL_EQUIPMENT_SLOTS.map((slot) => {
						if (hideTools && AFFIX_TOOL_SLOTS.includes(slot)) {
							return;
						}
						if (!editMode) {
							const equippedItem = equipment[slot];
							const borderColor = getEquipmentBorder(equippedItem);
							if (selectedLoadoutData.options.unequipEmptySlots && !equippedItem) {
								return (
									<Box
										key={slot}
										id={`gear-${slot}`}
										className='combat-gear-item'
										boxShadow={`0 0 0 2px ${RED} inset`}
										borderRadius='8px'
									/>
								);
							}
							return (
								<LoadoutItem
									key={slot}
									class={`gear-${slot}`}
									item={equippedItem}
									borderColor={borderColor}
									onClick={() => changeIconItemId(equippedItem?.itemID)}
								/>
							);
						}
						return <LoadoutSelectEquipment key={slot} slot={slot} />;
					})}
				</div>
			</IdlescapeContainer>
		);
	}

	function changeIconItemId(itemIconId: number | undefined) {
		if (!viewMode && !editMode && itemIconId && itemIconId !== selectedLoadoutData.iconItemId) {
			socket.emit('loadout:save', {
				loadoutID: loadoutId,
				loadoutIconItemId: itemIconId,
			});
		}
	}

	function renderInventory(inventory: ILoadout['foodInventory']) {
		if (editMode) {
			return (
				<Flex flexWrap='wrap'>
					<IdlescapeContainer
						className='loadout-inventory-container-wrapper'
						variant={editMode ? 'red' : undefined}
					>
						<LoadoutSelectFood />
					</IdlescapeContainer>
					<LoadoutFoodStockpile />
				</Flex>
			);
		}
		const items: React.ReactElement[] = [];

		const borderColors = getFoodBorders(inventory);
		for (const [index, item] of inventory.filter(Boolean).entries()) {
			if (!item) continue;
			items.push(
				<div key={index} className='combat-consumable'>
					<FakeItem
						item={item}
						height='45px'
						width='45px'
						margin='0 auto'
						float='none'
						borderWidth='2px'
						borderStyle='solid'
						borderColor={borderColors[index]}
					/>
				</div>
			);
		}

		for (let i = items.length; i < COMBAT_INVENTORY_SIZE; i++) {
			items.push(
				<div key={i} className='combat-consumable'>
					<div className='combat-empty-slot' />
				</div>
			);
		}
		return (
			<IdlescapeContainer className='loadout-inventory-container-wrapper' variant={editMode ? 'red' : undefined}>
				<div className='loadout-inventory-container'>{items}</div>
			</IdlescapeContainer>
		);
	}

	function renderAbilities(abilityIds: ILoadout['abilityRotation']) {
		const abilityList = abilityIds.map((abilityId) => masterAbilityList[abilityId]);
		return (
			<Box
				width='100%'
				className='anchor-loadout-combat-abilities idlescape-container thin idlescape-container-light'
			>
				{editMode ? (
					<AbilityEditor isLoadout={true} />
				) : (
					<AbilityList abilities={abilityList} justifyContent='center' columnGap='15px' padding='5px' />
				)}
			</Box>
		);
	}

	return (
		<>
			<div className='loadout-equipment-inventory-buttons'>
				<div
					className='loadout-button idlescape-button idlescape-button-green'
					onClick={() => setSelectedContainerTab('equipment')}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Equipment' />
				</div>
				<div
					className='loadout-button idlescape-button idlescape-button-blue'
					onClick={() => setSelectedContainerTab('inventory')}
				>
					<img
						className='nav-tab-icon icon-border'
						src='/images/ui/inventory_icon.png'
						alt='Combat inventory'
					/>
				</div>
			</div>
			{selectedContainerTab === 'equipment' && renderEquipment(selectedLoadoutData.equipment)}
			{selectedContainerTab === 'inventory' && renderInventory(selectedLoadoutData.foodInventory)}

			{renderAbilities(selectedLoadoutData.abilityRotation)}
		</>
	);
};

export default LoadoutView;
