import { Box, Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';
import { socket } from '../../services/socket.service';

export default function BlockedTab() {
	const blockList = usePlayerField('blockList');

	const [value, setValue] = useState('');

	function handleBlock(event: React.MouseEvent | React.KeyboardEvent) {
		if (event.type !== 'click' && 'key' in event && event.key !== 'Enter') return;
		if (value === '') return;
		socket.emit('social:block', {
			username: value,
		});
		setValue('');
	}

	function handleUnblock(targetId: number) {
		socket.emit('social:unblock', {
			targetId: targetId,
		});
	}

	return (
		<div className='friendlist-container'>
			<IdlescapeContainer margin='auto' maxWidth='500px'>
				<table className='friendlist-table header-fixed'>
					<thead>
						<tr>
							<Box as='th' width='70%'>
								Name
							</Box>
							<th />
						</tr>
					</thead>
					<tbody>
						{blockList.map((blockedUser, index) => (
							<tr key={index}>
								<Box as='td' width='70%' userSelect='text'>
									{blockedUser.username}
								</Box>
								<Box
									as='td'
									onClick={() => handleUnblock(blockedUser.userId)}
									color='red'
									textDecoration='underline'
									float='right'
									cursor='pointer'
									_hover={{ fontWeight: 'bold' }}
								>
									Remove
								</Box>
							</tr>
						))}
						{blockList.length === 0 && (
							<tr>
								<td>No blocked users</td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</IdlescapeContainer>
			<Flex gap='15px' alignItems='center' maxWidth='450px' margin='auto'>
				<IdlescapeInput
					value={value}
					placeholder='Type a username...'
					variant='simple'
					setValue={setValue}
					onKeyUp={handleBlock}
					type='text'
					flex='2'
				/>
				<IdlescapeButton variant='red' onClick={handleBlock} flex='1'>
					Block User
				</IdlescapeButton>
			</Flex>
		</div>
	);
}
