import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { QUEST_TO_TALENT_POINTS } from '../../../utils/constantsCollection';

export default function QuestGuide() {
	return (
		<Box>
			Located here are the main quests of Idlescape, which will guide you on the basics of the game and its
			functions. As well as some fun lore!
			<br />
			In order to start a quest, you need to click on the desired one and click accept. Once this is done, the
			quest should turn green to signify that you have started it. Once you have started a quest, you will have to
			complete the steps within the quest by either collecting and turning in items or doing a certain amount of
			actions. Once this has happen, the quest will either proceed to the next step or be ready to turn in by
			turning yellow. When you turn in a completed quest, you will acquire quest points as well as a handful of
			experience and items depending on the quest rewards, which can be checked in the quest info. You will also
			unlock other quests or even talents sometimes, when you complete a quest.
			<br />
			However, if a quest is red, that means you are unable to do that quest at this time. You can check what the
			requirements of the quest are by clicking it and reading the requirements section under the difficulty.
			<br />
			You can also pin quests for easier tracking by clicking the mini thumb tack icon located to the right of the
			quest name. Doing this will pin the quest progress in the top right of your screen.
			<br />
			On the subject of quests point, once you get a certain amount of them, you&apos;ll gain a talent point.
			Neat! (Basically every {QUEST_TO_TALENT_POINTS} quest points is 1 talent point.)
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</Box>
	);
}
