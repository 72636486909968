import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import setAuthToken from '../../helper/setAuthToken';
import { setCurrentUser } from '../../actions/authActions';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ICharacterJWTData } from '../../../../game-server/src/modules/session/SessionStarter';
import { openGameUrl } from '../../GameUrls';

/* export const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			element={
				((props) =>
					auth.isAuthenticated === true ? (
						<Component {...props} />
					) : (
						redirect('/login')
					)) as unknown as ReactNode
			}
		/>
	);
}; */

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const auth = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!auth.isAuthenticated) {
			checkForActiveCookie();
		}
		function checkForActiveCookie() {
			console.log('Checking for an active cookie from the server.');
			axios
				.get('/api/auth/get-token', { withCredentials: true })
				.then((res) => {
					if (res.data.token) {
						console.log('Active cookie found! Setting auth token and user data.');
						setAuthToken(res.data.token);
						// Decode token to get user data
						const decoded: ICharacterJWTData = jwtDecode(res.data.token);
						// Set current user
						dispatch(setCurrentUser(decoded));
					} else {
						console.log('No active cookie, redirecting to login');
						openGameUrl('login', { navigate });
					}
				})
				.catch((err) => {
					console.error('Error checking for active cookie', err);
					openGameUrl('login', { navigate });
				});
		}
	}, []);

	if (auth.isAuthenticated) {
		return <>{children}</>;
	} else {
		return null;
	}
};
