import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { IQuestDB } from '../../../../../../game-server/src/repositories/Quest.repository';
import { activeTabAtom } from '../../../../atoms/activeTabAtom';
import { questAtom } from '../../../../atoms/questAtom';
import useIsMobile from '../../../../hooks/useIsMobile';
import { questList } from '../../../../utils/questList';
import { ProgressBar } from '../../../layout/UI/ProgressBar';

export default function ChatQuest(props: { quest: IQuestDB }) {
	const setCurrentQuest = useSetRecoilState(questAtom);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const isMobile = useIsMobile();

	const questData = questList[props.quest.questId];
	const questStep = props.quest.stepID
		? questData.questSteps.find((step) => step.stepID === props.quest.stepID)
		: null;

	const stepNumber = questData.questSteps.findIndex((step) => step.stepID === questStep?.stepID);
	const totalSteps = questData.questSteps.length;
	const totalProgress = props.quest.completed
		? 1
		: (stepNumber + props.quest.progress / (questStep?.targetAmount ?? 1)) / totalSteps;

	function handleClick() {
		if (questData.achievement) setActiveTab('achievements');
		else {
			setActiveTab('quests');
			setCurrentQuest({ quest: props.quest.questId, step: props.quest.stepID ?? 0 });
		}
	}

	return (
		<IdlescapeWrappingTooltip
			content={
				<Grid
					width='265px'
					position='relative'
					gridTemplateColumns='50px 1fr'
					columnGap='10px'
					alignItems='center'
				>
					<Image src={questData.questIcon} alt='Quest icon' width='50px' height='50px' objectFit='contain' />
					<Box textAlign='center'>
						<Text fontWeight='bold'>{questData.name}</Text>
						{props.quest.stepID !== null ? (
							questStep && (
								<>
									<Text>
										Step {questStep.stepID}: {questStep.name}
									</Text>
									<Flex
										position='relative'
										justifyContent='center'
										alignItems='center'
										height='25px'
										margin='10px'
									>
										<ProgressBar
											theme='quest'
											value={props.quest.progress}
											max={questStep.targetAmount}
											height='100%'
										/>
										<Box position='absolute' bottom='3px'>
											Progress: {props.quest.progress.toLocaleString('en-us')}/
											{questStep.targetAmount.toLocaleString('en-us')}
										</Box>
									</Flex>
								</>
							)
						) : (
							<Flex justifyContent='space-around'>
								<Text marginTop='0'>Completed</Text>
								{questData.questRewards?.questPoints && (
									<Box>
										<Image
											src='/images/tutorial/quest_point.png'
											alt='Quest Points'
											width='20px'
											height='20px'
										/>
										{questData.questRewards.questPoints}
									</Box>
								)}
								{questData.questRewards?.achievementPoints && (
									<Box>
										<Image
											src='/images/tutorial/achievement_point.png'
											alt='Achievement Points'
											width='20px'
											height='20px'
										/>{' '}
										{questData.questRewards.achievementPoints}
									</Box>
								)}
							</Flex>
						)}
					</Box>
				</Grid>
			}
		>
			<Box
				className='chat-item gold-medal'
				onClick={isMobile ? undefined : handleClick}
				padding='1px' // padding to stop tooltip flicker on top edge
				cursor='pointer'
			>
				<Box
					className='chat-item'
					margin='0'
					background={`linear-gradient(90deg, #0000, ${totalProgress * 100}%, #0068 ${totalProgress * 100}%)`}
				>
					<Image
						src={questData.questIcon}
						alt={questData.achievement ? 'Quest' : 'Achievement'}
						className='chat-skill-icon'
					/>{' '}
					{questData.name}
				</Box>
			</Box>
		</IdlescapeWrappingTooltip>
	);
}
