import { useEffect } from 'react';
import { socket } from './socket.service';

export const PaymentListener = () => {
	useEffect(() => {
		const openInNewTab = (url: string) => {
			window.open(url, '_blank');
		};
		socket.on('monetization:checkout-url', openInNewTab);

		return () => {
			socket.off('monetization:checkout-url', openInNewTab);
		};
	}, []);

	return null;
};
