import { Box } from '@chakra-ui/react';
import React from 'react';

export default function TalentGuide() {
	return (
		<Box>
			The talent system can be used to enhance skills or specialize heavily into one with a wide and ever growing
			list of talents. Each talent can be sorted into the different types of skills by clicking on the filter at
			the top. The total amount of talent points you have will also be shown here at the very far right in the
			blue circle. Most of these talents will be locked behind either a level or mastery level requirement. (White
			for normal levels, blue for mastery levels.)
			<br />
			One can earn talent points by various methods, such as quests, achievements, and mastery levels. You can see
			the breakdown of where your talent points are coming from on the summary screen which can be access by
			clicking the &quot;level&quot; icon on the far left. You can also see the full list of talents you currently
			have, if any, on this screen as well.
			<br />
			Please note that once you select and confirm a talent, you are stuck with that talent till you use a
			&quot;Talent Respec Token&quot; to fully reset all of your talents at once. This token can be found in the
			General Shop and can be obtained three times a month for a hefty price.
		</Box>
	);
}
