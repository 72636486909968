import { Box, Image, Text, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { IGameShopListData } from '../../../../../game-server/src/modules/game-shop/GameShop.interface';
import { IGeneralShopPurchase } from '../../../../../game-server/src/repositories/Stats.repository';
import { getTimeString } from '../../../utils/helperFunctions';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import Timer from '../../layout/drawer/timer';

export default function GeneralShopItem(props: { gameShopItem: IGameShopListData; gold: number }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [amountToBuy, setAmountToBuy] = useState(1);
	const [disabled, setDisabled] = useState(false);
	const vendorPurchases = usePlayerField('vendorPurchases');
	const [totalBought, setTotalBought] = useState(0);
	const [oldestPurchase, setOldestPurchase] = useState<IGeneralShopPurchase | null>(null);

	const itemData = itemList[props.gameShopItem.itemID];
	const name = props.gameShopItem.customName || itemData.name;
	const maxAmount = Math.floor(
		Math.min(
			props.gameShopItem.purchaseLimits?.maxAmountPerReset || Infinity,
			props.gold / props.gameShopItem.price
		)
	);

	useEffect(() => {
		if (!props.gameShopItem.purchaseLimits) return;
		const { hoursBetweenReset } = props.gameShopItem.purchaseLimits;
		const dateToCheck = new Date(Date.now() - hoursBetweenReset * 60 * 60 * 1000);
		const filteredPurchases = vendorPurchases.filter(
			(v) => v.itemID === props.gameShopItem.itemID && dateToCheck < new Date(v.date)
		);
		setTotalBought(filteredPurchases.reduce((acc, v) => v.amount + acc, 0));
		setOldestPurchase(
			filteredPurchases.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0] || null
		);
	}, [vendorPurchases]);

	function buyUpgrade() {
		if (props.gameShopItem.price <= props.gold) {
			onOpen();
		}
	}

	function buyItems() {
		onClose();
		setDisabled(true);
		if (amountToBuy === 0) return;
		socket.emit('game-shop:buy:item', { id: props.gameShopItem.id, amount: amountToBuy });
	}

	function nextReset() {
		if (!props.gameShopItem.purchaseLimits) return '';
		const { maxAmountPerReset, hoursBetweenReset } = props.gameShopItem.purchaseLimits;
		const nextReset = new Date(
			oldestPurchase ? new Date(oldestPurchase.date).getTime() + hoursBetweenReset * 60 * 60 * 1000 : 0
		);
		return (
			<Text as='span'>
				Limited to {maxAmountPerReset} every {getTimeString(hoursBetweenReset * 60 * 60)}. You bought{' '}
				{totalBought.toLocaleString('en-us')}.
				{oldestPurchase && (
					<>
						{' '}
						Your next reset in <Timer propTime={nextReset} countdown /> will unlock{' '}
						{oldestPurchase.amount.toLocaleString('en-us')} new purchase
						{oldestPurchase.amount > 1 ? 's' : ''}.
					</>
				)}
			</Text>
		);
	}

	return (
		<>
			<IdlescapeWrappingTooltip
				content={
					<Text textAlign='center'>
						{props.gameShopItem.description} {nextReset()}
					</Text>
				}
			>
				<div className='game-shop-item' onClick={buyUpgrade} data-itemid={props.gameShopItem.itemID}>
					{name}
					<img className='game-shop-image' src={props.gameShopItem.image} alt={name}></img>
					<div className='game-shop-item-price'>
						<Text as='span' color={props.gameShopItem.price > props.gold ? 'red' : ''}>
							{props.gameShopItem.price.toLocaleString('en-us')}
							<img src='/images/gold_coin.png' alt='Price' className='icon16' />
						</Text>
					</div>
					{props.gameShopItem.purchaseLimits && (
						<Box textAlign='center'>
							<Text margin='0'>
								{(props.gameShopItem.purchaseLimits.maxAmountPerReset - totalBought).toLocaleString(
									'en-us'
								)}
								/{props.gameShopItem.purchaseLimits.maxAmountPerReset.toLocaleString('en-us')}
							</Text>
							<Text margin='0'>
								{getTimeString(props.gameShopItem.purchaseLimits.hoursBetweenReset * 60 * 60)}
								<Image
									src='/images/pontification/skill_waiting_icon.png'
									alt='Time until reset'
									width='14px'
								/>
							</Text>
						</Box>
					)}
				</div>
			</IdlescapeWrappingTooltip>
			<Dialog
				open={isOpen}
				onClose={onClose}
				className='sell-item-dialog'
				TransitionProps={{ onExit: () => setDisabled(false) }}
			>
				<DialogTitle>Shop</DialogTitle>
				{props.gameShopItem.allowBulkPurchase ? (
					<DialogContent>
						<Box minWidth='300px' textAlign='center'>
							<Text>How many {itemData.name} do you want to buy?</Text>
							<IdlescapeNumberInput
								value={amountToBuy}
								setValue={setAmountToBuy}
								min={1}
								max={maxAmount}
								minWidth='100px'
								width='fit-content'
								margin='0 auto'
								onKeyUp={(e) => {
									if (e.key === 'Enter') {
										buyItems();
									}
								}}
							/>
						</Box>
						<Text textAlign={'center'}>
							This will cost you: {(amountToBuy * props.gameShopItem.price).toLocaleString('en-us')}{' '}
							<img src='/images/gold_coin.png' alt='Gold' className='icon16' />
						</Text>
					</DialogContent>
				) : (
					<DialogContentText>
						You are about to buy {name} for {props.gameShopItem.price.toLocaleString('en-us')}{' '}
						<img src='/images/gold_coin.png' alt='Gold' className='icon16' />
					</DialogContentText>
				)}
				<DialogActions>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
					<IdlescapeButton variant={disabled ? 'disabled' : 'green'} onClick={buyItems}>
						Buy
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
