// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const talentsIds = {
	gathering_master: 1,
	gathering_grandmaster: 2,
	dwarven_student: 3,
	elven_student: 4,
	shrimp_student: 5,
	hamster_hunter: 6,
	melter_smelter: 7,
	fresh_forager: 8,
	mining_prodigy: 9,
	foraging_prodigy: 10,
	fishing_prodigy: 11,
	mining_grandmaster: 12,
	foraging_grandmaster: 13,
	fishing_grandmaster: 14,
	forest_cleaner: 15,
	snowman: 16,
	tea_break: 17,
	fleet_fungi: 18,
	it_belongs_in_a_museum: 19,
	rough_seas_fishing: 20,
	oil_rig: 21,
	mining_shard_mastery_i: 22,
	mining_shard_mastery_ii: 23,
	mining_shard_mastery_iii: 24,
	fishing_shard_mastery_i: 25,
	fishing_shard_mastery_ii: 26,
	fishing_shard_mastery_iii: 27,
	foraging_shard_mastery_i: 28,
	foraging_shard_mastery_ii: 29,
	foraging_shard_mastery_iii: 30,
	precision: 1000,
	bound_anger: 1001,
	infernal_knowledge: 1002,
	mercenary_guild_trainer: 1003,
	mercenary_guild_paymaster: 1004,
	lucky_looter: 1005,
	critical_weakpoint: 1006,
	careful_criticals: 1007,
	unbound_rage: 1008,
	unadorned_agility: 1009,
	arsonist: 1010,
	giants_blood: 1011,
	featherweight_fighter: 1012,
	ninelives: 1013,
	coldblooded: 1014,
	goblin_hunter: 1015,
	demon_hunter: 1016,
	undead_hunter: 1017,
	goblin_slayer: 1018,
	demon_slayer: 1019,
	undead_slayer: 1020,
	featherweight_champion: 1021,
	weredruid: 1022,
	wereelf: 1023,
	loaded_dice: 1024,
	human_hunter: 1025,
	human_slayer: 1026,
	elf_hunter: 1027,
	elf_slayer: 1028,
	dwarf_hunter: 1029,
	dwarf_slayer: 1030,
	giant_hunter: 1031,
	giant_slayer: 1032,
	golem_hunter: 1033,
	golem_slayer: 1034,
	beast_hunter: 1035,
	beast_slayer: 1036,
	shrimp_hunter: 1037,
	shrimp_slayer: 1038,
	chaotic_hunter: 1039,
	chaotic_slayer: 1040,
	corrupted_hunter: 1041,
	corrupted_slayer: 1042,
	woodlike_hunter: 1043,
	woodlike_slayer: 1044,
	silent_fury: 1045,
	arrow_expenditure: 1046,
	elven_exaltation: 1047,
	piercing_ammo_specialization: 1048,
	serrated_ammo_specialization: 1049,
	heavy_ammo_specialization: 1050,
	mark_ii: 1051,
	ammunition_overload: 1052,
	ammunition_overoverload: 1053,
	poisoner: 1054,
	druidism: 1055,
	novice_treasure_hunter: 1056,
	master_treasure_hunter: 1057,
	critical_striker: 1058,
	thick_skinned: 1059,
	lightfooted: 1060,
	fortitude_i: 1061,
	fortitude_ii: 1062,
	reckless_attacker: 1063,
	elemental_stability: 1064,
	force_i: 1065,
	force_ii: 1066,
	force_iii: 1067,
	convergence_i: 1068,
	convergence_ii: 1069,
	convergence_iii: 1070,
	finesse_i: 1071,
	finesse_ii: 1072,
	finesse_iii: 1073,
	messy_eater: 1074,
	wild_magic_i: 1075,
	wild_magic_ii: 1076,
	wild_magic_iii: 1077,
	wild_magic_master: 1078,
	deflection: 1079,
	giant_kings_vitality: 1080,
	vault_hunter: 1081,
	shrimps_blessing: 1082,
	deviant_shrimp_studier: 1083,
	goblin_tactics: 1084,
	alloyed_joints: 1085,
	elder_error: 1086,
	castle_hunting: 1087,
	grave_digger: 1088,
	sunscreen: 1089,
	eye_of_the_shrimpholder: 1090,
	reap_the_storm: 1091,
	thunderstruck: 1092,
	ranger_repertoire_i: 1093,
	critical_strikes_i: 1094,
	ranger_repertoire_ii: 1095,
	melee_master_i: 1096,
	mystic_spells_i: 1097,
	mystic_spells_ii: 1098,
	mystic_spells_iii: 1099,
	runecrafting_master: 2000,
	augmentation_afficionado_ii: 2001,
	novice_crafter: 2002,
	apprentice_crafter: 2003,
	journeyman_crafter: 2004,
	master_crafter: 2005,
	grandmaster_crafter: 2006,
	runecrafting_grandmaster: 2007,
	slag_refinement: 2008,
	seed_sifter: 2009,
	pungent_runes: 2010,
	smithing_master: 2011,
	cooking_master: 2012,
	transformation_talent: 2013,
	augmentation_afficionado_i: 2014,
	bulk_cooking: 2015,
	overheating: 2016,
	obsidian_forgery: 2017,
	dwarven_refinement: 2018,
	molten_metal: 2019,
	pyromania: 2020,
	greenest_thumb: 2021,
	totemic_wisdom: 2022,
	mysterious_harvest: 2023,
	trapper: 2024,
	farm_blitz: 2025,
	sympathetic_growth: 2026,
	crop_rotation: 2027,
	tree_hugger: 2028,
	heart_of_the_forest: 2029,
	farming_master: 2030,
	mysterious_nutrients: 2031,
	research_ready: 2032,
	mad_scientist: 2033,
	lingering_enlightenment: 2034,
	spiraling_out_of_control: 2035,
	affix_affinity: 2036,
	fantastic_fiber_finder: 2037,
	talisman_talent: 2038,
	essence_concentration_expert: 2039,
	essence_concentration_master: 2040,
	consistent_chef: 2041,
	mass_crafter_i: 2042,
	mass_crafter_ii: 2043,
	mass_crafter_iii: 2044,
	smithing_grandmaster: 2045,
	smithing_legend: 2046,
	cooking_grandmaster: 2047,
	cooking_legend: 2048,
	runic_racing: 2049,
	gold_leaf_cook_i: 2050,
	gold_leaf_cook_ii: 2051,
	prismatic_power: 2052,
	shrimp_aberrancy: 2053,
	goblin_recipe_book: 2054,
	dark_alloying: 2055,
	seeds_of_doubt: 2056,
	experienced_hunter: 2057,
	demonic_architecture: 2058,
	do_ya_membah: 2059,
	flowing_breath_i: 2060,
	flowing_breath_ii: 2061,
	pungent_runes_ii: 2062,
	flame_fixation_i: 2063,
	flame_fixation_ii: 2064,
	flame_fixation_iii: 2065,
	cooking_shard_mastery_i: 2066,
	cooking_shard_mastery_ii: 2067,
	cooking_shard_mastery_iii: 2068,
	runecrafting_shard_mastery_i: 2069,
	runecrafting_shard_mastery_ii: 2070,
	runecrafting_shard_mastery_iii: 2071,
	smithing_shard_mastery_i: 2072,
	smithing_shard_mastery_ii: 2073,
	smithing_shard_mastery_iii: 2074,
	farming_shard_mastery_i: 2075,
	farming_shard_mastery_ii: 2076,
	farming_shard_mastery_iii: 2077,
};
