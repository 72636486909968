import { IGlobals } from '../../../game-server/src/modules/globals/Globals';
import { PlayerData } from '../../../game-server/src/modules/player/PlayerData';
import { PLAYER_INFORMATION, SET_GLOBALS } from './types';

export const setPlayerInformation = (userData: PlayerData) => {
	return {
		type: PLAYER_INFORMATION,
		payload: userData,
	};
};

export const setGlobals = (userData: IGlobals) => {
	return {
		type: SET_GLOBALS,
		payload: userData,
	};
};
