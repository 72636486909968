import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IdlescapeButton } from '@idlescape/ui';

export default class Disconnect extends Component {
	constructor() {
		super();

		this.state = {
			showModal: false,
			popupMessage: '',
			popupTitle: 'Disconnected',
		};
	}

	componentDidMount() {
		setInterval(() => {
			if (this.props.socket.disconnected === true) {
				this.setState({
					popupMessage: 'You have been disconnected. Refresh the page to connect again.',
					showModal: true,
				});
			}
		}, 5000);
	}

	handleOpenModal = () => {
		this.setState({
			showModal: true,
		});
	};

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		return (
			<Dialog
				open={this.state.showModal}
				onClose={this.handleCloseModal}
				className='donate-dialog feedback-dialog sell-item-dialog'
			>
				<DialogTitle>Notification</DialogTitle>
				<DialogContentText>{this.state.popupMessage}</DialogContentText>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={this.handleCloseModal} size='medium'>
						Close
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		);
	}
}
