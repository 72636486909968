import { getImage } from '@idlescape/ui';
import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);
const accordionTheme = defineMultiStyleConfig({
	variants: {
		largePrimary: {
			container: {
				borderImageSource: `url(${getImage('BorderImage')})`,
				borderImageSlice: '100 fill',
				borderImageWidth: '50px',
				borderImageOutset: 0,
				borderImageRepeat: 'round',
				padding: '15px',
			},
			button: {
				backgroundColor: 'transparent',
				borderWidth: '0',
				fontSize: '4xl',
				justifyContent: 'center',
				margin: '0',
				_hover: {
					backgroundColor: 'transparent',
				},
			},
		},
		blank: {
			container: { border: 0 },
			button: { padding: 0, outline: 0 },
		},
	},
	defaultProps: {
		variant: 'largePrimary',
	},
});

export default accordionTheme;
