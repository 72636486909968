import { Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { IEventShopListData } from '../../../../../game-server/src/modules/event-shop/EventShop.interface';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { EnchantmentData } from '../EnchantmentData';

export default function EventShopItem(props: { item: IEventShopListData; tokens: number }) {
	const [showModal, setShowModal] = useState(false);
	const [amountToBuy, setAmountToBuy] = useState(1);
	const [disabled, setDisabled] = useState(false);

	function buyUpgrade() {
		if (props.item.price <= props.tokens) {
			setShowModal(true);
		}
	}

	function buyItems() {
		setShowModal(false);
		setDisabled(true);
		if (amountToBuy === 0) return;
		socket.emit('event-shop:buy:item', { id: props.item.id, amount: amountToBuy });
	}

	function handleCloseModal() {
		setShowModal(false);
		setDisabled(false);
	}

	let image = '';
	let description = '';
	let numberOverlay: React.ReactElement | null = null;
	let enchantStackText = '';
	let name = '';

	if (props.item.enchantmentID) {
		const data = EnchantmentData.findEnchantmentByID(props.item.enchantmentID);
		image = data.buffIcon;
		description = data.getTooltip(2, data.strengthPerLevel);
		numberOverlay = <div className='number-overlay-event-shop'>3</div>;
		enchantStackText = `and give you ${(amountToBuy * 3).toLocaleString('en-us')} stacks of ${data.name}`;
		name = data.name + ' 2';
	} else if (props.item.itemID) {
		const data = itemList[props.item.itemID];
		image = data.itemIcon ?? data.itemImage ?? '';
		description = data.extraTooltipInfo ?? '';
		name = data.name;
	}
	if (props.item.description) description = props.item.description;

	return (
		<>
			<IdlescapeWrappingTooltip content={description}>
				<div className='game-shop-item' onClick={buyUpgrade} data-itemid={props.item.itemID}>
					{name}
					<img className='game-shop-image' src={image} alt={name} />
					{numberOverlay}
					<div className='game-shop-item-price'>
						{props.item.price !== 0 && (
							<Text as='span' color={props.item.price > props.tokens ? 'red' : ''}>
								{props.item.price.toLocaleString('en-us')}
								<img src='/images/misc/event_token.png' alt='Event Tokens' className='icon16' />
							</Text>
						)}
					</div>
				</div>
			</IdlescapeWrappingTooltip>
			<Dialog
				open={showModal}
				onClose={handleCloseModal}
				className='sell-item-dialog'
				TransitionProps={{ onExit: () => setDisabled(false) }}
			>
				<DialogTitle>Event Shop</DialogTitle>
				<DialogContent>
					<Text>How many do you want to buy?</Text>
					<IdlescapeInput type='number' value={amountToBuy} setValue={setAmountToBuy} min='1' />
					<Text>
						This will cost you: {(amountToBuy * props.item.price).toLocaleString('en-us')}{' '}
						<img src='/images/misc/event_token.png' alt='Event Tokens' className='icon16' />
						{enchantStackText}
					</Text>
				</DialogContent>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={handleCloseModal}>
						Close
					</IdlescapeButton>
					<IdlescapeButton variant={disabled ? 'disabled' : 'green'} onClick={buyItems}>
						Buy
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
