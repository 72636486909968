import { BaseTooltip } from './BaseTooltip';
import { TTooltipStats } from '../Data/TooltipData';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';

export class FishingBaitTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Fishing Bait';
	}

	protected getStats(
		item: IItem,
		itemResource: IItemData
	): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		const baitStats: Record<string, number> = {};

		if (itemResource.fishingBait?.bait) baitStats['fishing.bait'] = itemResource.fishingBait.bait;
		if (itemResource.fishingBait?.reel) baitStats['fishing.reel'] = itemResource.fishingBait.reel;
		if (itemResource.fishingBait?.bonus) baitStats['fishing.bonusRarity'] = itemResource.fishingBait.bonus;
		if (itemResource.fishingBait?.level) baitStats['fishing.fishing'] = itemResource.fishingBait.level;
		if (itemResource.fishingBait?.multBait) baitStats['fishing.multBait'] = itemResource.fishingBait.multBait;
		if (itemResource.fishingBait?.multReel) baitStats['fishing.multReel'] = itemResource.fishingBait.multReel;
		if (itemResource.fishingBait?.multBonus) baitStats['fishing.multBonus'] = itemResource.fishingBait.multBonus;
		if (itemResource.fishingBait?.multLevel) baitStats['fishing.multFishing'] = itemResource.fishingBait.multLevel;

		return { baitStats: baitStats };
	}
}
