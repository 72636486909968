import { Box, Progress } from '@chakra-ui/react';
import { TSkillLevelProps } from './SkillLevel';

export const SkillLevelBarNoIcon = (props: TSkillLevelProps) => {
	return (
		<Box position={'relative'} display={'flex'}>
			<Progress
				colorScheme={props.showMastery ? 'blue' : 'gray'}
				margin={'auto 10px'}
				value={props.levelProgress}
				width={'60%'}
				borderRadius={'2px'}
				hasStripe={props.showMastery}
			></Progress>
			<Box color={props.showMastery ? 'blue.200' : 'white'} fontWeight={'bold'} fontSize={'18px'}>
				{props.showMastery ? props.skillData.masteryLevel : props.skillData.level}
			</Box>
			{props.tooltip}
		</Box>
	);
};
