import React from 'react';
import { useRecoilState } from 'recoil';
import { enchantingTabAtom } from '../../../../atoms/enchantingTabAtom';
import SubSkillTabs from '../SubSkillTabs';
import Augmenting from './Augmenting/Augmenting';
import Enchanting from './Enchanting';
import './Enchanting.css';
import Scrapping from './Scrapping/Scrapping';
import Scrollcrafting from './Scrollcrafting';

export default function EnchantingContainer() {
	const [tab, setTab] = useRecoilState(enchantingTabAtom);
	return (
		<div className='enchanting-container'>
			<SubSkillTabs
				tabs={[
					{
						id: 'scrollcrafting',
						name: 'Scrollcrafting',
						image: '/images/enchanting/enchanted_scroll.png',
						imageAlt: 'Scrollcrafting logo',
					},
					{
						id: 'augmenting',
						name: 'Augmenting',
						image: '/images/enchanting/augmenting_logo.png',
						imageAlt: 'Augmenting logo',
					},
					{
						id: 'scrapping',
						name: 'Item Research',
						image: '/images/runecrafting/dust/gear_scrap_common.png',
						imageAlt: 'Scrapping logo',
					},
					{
						id: 'enchanting',
						name: 'Enchanting',
						image: '/images/enchanting/enchanting_logo.png',
						imageAlt: 'Enchanting logo',
					},
				]}
				setTab={setTab}
				selected={tab}
				baseStyle={{
					border: '2px solid rgba(255, 0, 255, 0.2)',
					background: 'linear-gradient(to bottom, rgb(65, 0, 68), rgb(107, 0, 110))',
				}}
				selectedStyle={{
					background: 'linear-gradient(to bottom, rgb(42, 0, 44), rgb(77, 0, 80))',
				}}
			/>
			{tab === 'scrollcrafting' && <Scrollcrafting />}
			{tab === 'augmenting' && <Augmenting />}
			{tab === 'enchanting' && <Enchanting />}
			{tab === 'scrapping' && <Scrapping />}
		</div>
	);
}
