import React from 'react';
import { itemList } from '../../../utils/itemList';
import { locations } from '../../../utils/locationList';
import { Box, Flex, Image } from '@chakra-ui/react';
import { forges } from '../../../utils/forgeList';
import { IdlescapeButton } from '@idlescape/ui';
import './ActionQueue.css';
import { IUserActionQueue } from '../../../../../game-server/src/modules/action-queue/UserActionQueue';
import { BiAddToQueue, BiDownArrow, BiTrash, BiUpArrow } from 'react-icons/bi';
import { socket } from '../../../services/socket.service';
import { usePlayerField } from '../../../hooks/hooks';
import { tabs } from '../../../helper/NavigationTabsList';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { cookingList } from '../../../utils/cookingList';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { IActionOptions } from '../../../../../game-server/src/modules/actions/Action.interface';

export default function ActionQueueItem({
	action,
	index,
	selected,
	showImage = true,
	addCurrent,
}: {
	action: IUserActionQueue;
	index: number;
	selected: boolean;
	showImage?: boolean;
	addCurrent?: () => void;
}) {
	const settings = usePlayerField('settings');
	const actionQueue = usePlayerField('actionQueue');
	const queueLength = settings.miscellaneous.userActionQueue.length;

	const location = locations[action.targetLocationID];
	const actionIndex = index + 1;
	const actionName = location.actionType.replace('Action-', '');
	const skillIcon = actionName === 'Combat' ? '/images/combat/attack_icon.png' : tabs[actionName.toLowerCase()]?.icon;
	const currentQueue = actionQueue?.info?.userQueue?.position === index;

	let actionString = 'action';
	switch (location.actionType) {
		case 'Action-Cooking':
			if (location.locID === locationsIds.the_cooking_guild) {
				if (action.additionalOptions?.cooking?.potionID) {
					actionString = 'potion';
				} else {
					actionString = 'food';
				}
			}
			break;
		case 'Action-Enchanting':
			if (action.additionalOptions?.scrollcraftingId) {
				actionString = 'scroll';
			}
			break;
		case 'Action-Combat':
			actionString = 'wave';
			break;
		case 'Action-Smithing':
			actionString = 'bar';
			break;
		case 'Action-Runecrafting':
			if (
				action.additionalOptions?.runecraftingId &&
				itemList[action.additionalOptions?.runecraftingId].name.includes('Rune')
			) {
				actionString = 'rune';
			} else {
				actionString = 'cloth';
			}
			break;
	}
	function renderAdditionalOptions(options: IActionOptions, index: number) {
		let returnObject: React.ReactNode = null;
		if (options.runecraftingId || options.scrollcraftingId) {
			const item = itemList[options.runecraftingId ?? options.scrollcraftingId ?? 1];
			returnObject = (
				<div key={`action-option-${index}-rc`}>
					{showImage && <Image className='action-queue-icon' src={item.itemImage} />} {item.name}
				</div>
			);
		} else if (options.smithing) {
			const item = itemList[options.smithing.barId];
			if (item) {
				returnObject = (
					<div key={`action-option-${index}-smithing`}>
						{forges[options.smithing.forgeId]?.forgeName} -{' '}
						{showImage && <Image className='action-queue-icon' src={item.itemImage} />}
						{item.name}, Intensity: {options.smithing.intensity}
					</div>
				);
			}
		} else if (options.cooking && options.cooking.recipeID) {
			const item = itemList[options.cooking.recipeID];
			const buffItem = options.cooking.buffItem ? cookingList[options.cooking.buffItem] : undefined;
			const buffId = buffItem?.alchemyEnchantment ?? buffItem?.cookingEnchantment;
			const buff = buffId ? enchantmentsList[buffId].name : '';
			returnObject = (
				<div key={`action-option-${index}-cooking`}>
					{showImage && <Image className='action-queue-icon' src={item.itemImage} />}
					{item.name}
					{options.cooking.quality ? ' ' + options.cooking.quality : ''}
					{buff ? ' with ' + buff : ''}
				</div>
			);
		}
		return returnObject;
	}
	function removeFromQueue(e: React.MouseEvent) {
		socket.emit('action:queue:remove', index);
		if (actionQueue?.info?.userQueue?.position && index < actionQueue.info.userQueue.position) {
			actionQueue.info.userQueue.position--;
		}
		e.stopPropagation();
	}

	function moveUpQueue(e: React.MouseEvent) {
		socket.emit('action:queue:move', { index, destination: index - 1 });
		e.stopPropagation();
	}
	function moveDownQueue(e: React.MouseEvent) {
		socket.emit('action:queue:move', { index, destination: index + 1 });
		e.stopPropagation();
	}

	return (
		<Flex
			className={`action-queue-item queue-${currentQueue ? 'current' : selected ? 'selected' : 'none'}`}
			alignItems='center'
			height='100%'
			gap='5px'
		>
			{index !== -1 ? (
				<IdlescapeButton variant='red' size='icon' onClick={removeFromQueue}>
					<BiTrash />
				</IdlescapeButton>
			) : (
				<IdlescapeButton variant={addCurrent ? 'blue' : 'disabled'} size='icon' onClick={addCurrent}>
					<BiAddToQueue />
				</IdlescapeButton>
			)}
			<Box marginRight='auto'>
				{showImage && <Image className='action-queue-icon' src={skillIcon} />}
				{actionIndex ? actionIndex + '. ' : ''}
				{actionName}:{' '}
				{showImage && location.locationImage ? (
					<Image className='action-queue-icon' src={location.locationImage} />
				) : (
					''
				)}{' '}
				{location.name} for
				{action.timeToSpendHere + action.actionCount === 0 ? 'ever' : ''}
				{action.timeToSpendHere ? ` ${action.timeToSpendHere / 60 / 1000} minutes` : ''}
				{action.timeToSpendHere && action.actionCount ? ' or ' : ''}
				{action.actionCount ? ` ${action.actionCount} ${actionString}${action.actionCount > 1 ? 's' : ''}` : ''}
				{action.additionalOptions ? renderAdditionalOptions(action.additionalOptions, index) : ''}
			</Box>
			{index !== -1 && (
				<>
					{index > 0 && (
						<IdlescapeButton variant='green' size='icon' display='block' onClick={moveUpQueue}>
							<BiUpArrow />
						</IdlescapeButton>
					)}
					{queueLength > index + 1 && (
						<IdlescapeButton variant='orange' size='icon' display='block' onClick={moveDownQueue}>
							<BiDownArrow />
						</IdlescapeButton>
					)}
				</>
			)}
		</Flex>
	);
}
