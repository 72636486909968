import React from 'react';
import { eventShopList } from '../../../utils/eventShopList';
import EventShopItem from './EventShopItem';
import { usePlayerResourceStockpileAmount } from '../../../hooks/hooks';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';

export const EventShop = () => {
	const eventTokenAmount = usePlayerResourceStockpileAmount(itemsIds.event_token);

	return (
		<div className='event-shop-items'>
			{Object.values(eventShopList).map((item) => {
				return <EventShopItem key={item.id} item={item} tokens={eventTokenAmount} />;
			})}
		</div>
	);
};
