import { Flex } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useEffect, useRef, useState } from 'react';
import { IoTimerOutline } from 'react-icons/io5';
import { itemImage } from '../../../helper/itemHelperFunctions';
import { useGlobalField, usePlayerField, usePlayerSetting } from '../../../hooks/hooks';
import { itemList } from '../../../utils/itemList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { IProgressBarRef, ProgressBar } from '../../layout/UI/ProgressBar';

export default function ChatEventCooldown() {
	const throwEventCooldown = usePlayerField('throwEventCooldown');
	const throwEvent = useGlobalField('throwEvent');
	const theme = throwEvent?.theme;
	const state = throwEvent?.state;
	const time = throwEvent?.time;
	const progressBarRef = useRef<IProgressBarRef>(null);
	const [active, setActive] = useState(0);
	const highPerformance = usePlayerSetting('miscellaneous.highPerformance');

	useEffect(() => {
		if (throwEventCooldown) {
			setActive(throwEventCooldown);
			return;
		}
	}, [throwEventCooldown, progressBarRef]);

	useEffect(() => {
		if (state && time) {
			switch (state) {
				case 'join':
				case 'preparing': {
					setActive(new Date(time).getTime());
				}
			}
		}
	}, [state, time, progressBarRef]);

	useEffect(() => {
		if (active > Date.now()) {
			const duration = active - Date.now();
			progressBarRef.current?.startAnimation(duration);
		}
	}, [active, progressBarRef]);

	if (!theme || highPerformance || active < Date.now()) {
		return null;
	}

	return <ProgressBar ref={progressBarRef} theme={theme} max={100} value={0} height='2px' marginBottom='1px' />;
}

const timeFormat = new Intl.DateTimeFormat(undefined, {
	hour: '2-digit',
	minute: '2-digit',
});
export function ChatEventInactiveTimer() {
	const throwEvent = useGlobalField('throwEvent');
	const state = throwEvent?.state;
	const time = throwEvent?.time;
	const theme = throwEvent?.theme;
	if (state !== 'inactive' || !time || !theme) return null;

	const snowballData = itemList[itemsIds.extra_large_snowball];
	return (
		<IdlescapeWrappingTooltip content={`Next ${theme} event start: ${timeFormat.format(new Date(time))}`}>
			<Flex
				position='relative'
				height='30px'
				width='30px'
				fontSize='1.3rem'
				color='black'
				alignItems='center'
				justifyContent='center'
			>
				{itemImage(snowballData)}
				<IoTimerOutline style={{ zIndex: '1' }} />
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}
