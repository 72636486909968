import { BaseTooltip } from './BaseTooltip';

export class ChestTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Chest';
	}

	protected getUse(): string {
		return 'Open to get resources';
	}
}
