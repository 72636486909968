import { useEffect } from 'react';
import { socket } from './socket.service';
import { setGlobals } from '../actions/gameActions';
import { IGlobals } from '../../../game-server/src/modules/globals/Globals';
import { useAppDispatch } from '../hooks/hooks';

let globals: IGlobals | null = null;

export const GlobalsManager = ({ loaded, setLoaded }: { loaded?: boolean; setLoaded: (value: boolean) => void }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		/** Subscribe on mount */
		console.log('Globals Manager started');
		socket.on('globals:update', (data) => {
			globals = data;
			dispatch(setGlobals(data));
			setLoaded(true);
		});

		socket.on('globals:update:partial', (data) => {
			if (!globals) {
				return;
			}
			Object.assign(globals, data);
			dispatch(setGlobals(globals));
		});

		// Unsubscribe on unmount
		return () => {
			socket.off('globals:update');
			socket.off('globals:update:partial');
			console.log('Globals Manager stopped working');
			setLoaded(false);
		};
	}, []);

	return null;
};
