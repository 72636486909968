import React from 'react';
import { locations } from '../../../utils/locationList';
import { Box, Flex } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import CombatGroupSize from './CombatGroupSize';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { itemList } from '../../../utils/itemList';

const locationScroll = {
	[locationsIds.farm]: itemsIds.clustercluck,
	[locationsIds.caves]: itemsIds.stew_stained_page,
	[locationsIds.city]: itemsIds.black_knights_trial,
	[locationsIds.fallen_academy]: itemsIds.fallen_academy_scroll,
	[locationsIds.lava_maze]: itemsIds.demonic_trial,
	[locationsIds.corrupted_lands]: itemsIds.corroded_scroll,
	[locationsIds.valley_of_giants]: itemsIds.giant_scroll,
	[locationsIds.overgrown_woods]: itemsIds.overgrown_woods_scroll,
	[locationsIds.enchanted_armory]: itemsIds.enchanted_armory_scroll,
	[locationsIds.elven_encampment]: itemsIds.elven_encampment_scroll,
	[locationsIds.chaos_wastes]: itemsIds.shapeless_scroll,
};

export const CombatZone = (props: {
	zoneID: number;
	zoneName: string;
	eliteChallenge: number;
	openLocationInfo: (locationID: number) => void;
}) => {
	const settings = usePlayerField('settings');
	const quickStart = settings.combat.zoneSpecificSettings[props.zoneID]?.quickStart;

	let eliteChallenge = <></>;
	if (props.eliteChallenge) {
		let scrollImage = '/images/misc/Elite_Scroll.png';
		if (locationScroll[props.zoneID] && itemList[locationScroll[props.zoneID]]) {
			scrollImage = itemList[locationScroll[props.zoneID]].itemImage ?? scrollImage;
		}
		eliteChallenge = (
			<div className='combat-zone-elite-challenge'>
				<img src={scrollImage ?? '/images/misc/Elite_Scroll.png'} alt='Elitechallenge' /> {props.eliteChallenge}
			</div>
		);
	}

	const location = locations[props.zoneID];
	const imageStyle = location.locationImageStyle ? cloneDeep(location.locationImageStyle) : {};
	let backgroundImage = `${location.locationImage}`;
	if (imageStyle.linearGradient) {
		backgroundImage = `linear-gradient(${imageStyle.linearGradient}), url(${location.locationImage})`;
		delete imageStyle.linearGradient;
	}

	function startCombat() {
		if (location.relatedKey !== undefined) {
			// This shouldn't happen, since the option is only shown for normal combat locations
			return;
		}
		socket.emit('action:start', { action: 'combat', location: props.zoneID });
	}

	return (
		<Box className='combat-zone' backgroundImage={backgroundImage} {...imageStyle}>
			<Flex>
				<Box
					className='combat-container-button idlescape-button idlescape-button-green'
					onClick={() => props.openLocationInfo(props.zoneID)}
					flex='2'
				>
					Go to Location
				</Box>
				{quickStart && (
					<Box
						className='combat-container-button idlescape-button idlescape-button-blue'
						onClick={() => startCombat()}
						flex='1'
					>
						Quick Start
					</Box>
				)}
			</Flex>
			<div className='combat-zone-text'>{props.zoneName}</div>
			{eliteChallenge}
			<CombatGroupSize
				locationId={props.zoneID}
				showPopup={false}
				style={{
					marginTop: '5px',
					marginLeft: '10px',
					background: 'linear-gradient(to right, rgba(0, 0, 0, 0.75) 90%, rgba(0, 0, 0, 0) 100%)',
				}}
			/>
		</Box>
	);
};
