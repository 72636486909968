import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { socket } from '../../../../services/socket.service';
import { IDialogProps } from './MainItemDialog';

function DisenchantingDialog(props: IDialogProps) {
	function handleDisenchant() {
		socket.emit('enchanting:disenchant', {
			id: props.item.id,
			amount: props.sliderValue,
		});
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Disenchanting {props.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to disenchant {props.sliderValue} {props.name}
					{props.sliderValue > 1 && 's'}? This cannot be undone!
					<p>
						Enchanted items have a low chance to salvage the materials used to create the scroll that this
						enchant is related to as well as give a portion of the experience that would be given when
						enchanting.
					</p>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleDisenchant}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default DisenchantingDialog;
