import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { socket } from '../../../../services/socket.service';
import { IDialogProps } from './MainItemDialog';

function ResetNameDialog(props: IDialogProps) {
	function handleResetName() {
		socket.emit('inventory:resetName', {
			inventoryItemID: props.item.id,
			amount: props.sliderValue,
		});
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>{`Name Reset ${props.name} (${props.itemData?.name})`}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to reset the name of {props.sliderValue} {props.name}
					{props.sliderValue > 1 && 's'} back to {props.itemData?.name}? This cannot be undone!
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleResetName}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default ResetNameDialog;
