import { Flex, InputGroup, SlideFade } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { PlayerData } from '../../../../../../game-server/src/modules/player/PlayerData';
import { IMarketTransactionDeanonymized } from '../../../../../../game-server/src/repositories/MarketTransaction.repository';
import { socket } from '../../../../services/socket.service';
import HistoryTable from '../../../game/Marketplace/HistoryTable';

function TradeHistory(props: { playerId: PlayerData['id'] }) {
	const [marketHistory, setMarketHistory] = useState<IMarketTransactionDeanonymized[]>([]);
	const [totalListings, setTotalListings] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [showSettings, setShowSettings] = useState<boolean>(false);
	const [lowerThreshold, setLowerThreshold] = useState<number>(0.9);
	const [upperThreshold, setUpperThreshold] = useState<number>(4);

	useEffect(() => {
		setPage(1);
		socket.on('marketplace:transaction:player:count:deanonymized', (data) => {
			setTotalListings(data);
		});
		socket.on('marketplace:transaction:player:history:deanonymized', (data) => {
			setMarketHistory(data);
		});

		socket.emit('marketplace:transaction:player:count:deanonymized', props.playerId);
		socket.emit('marketplace:transaction:player:history:deanonymized', {
			page: 0,
			playerId: props.playerId,
		});

		return () => {
			socket.off('marketplace:transaction:player:count:deanonymized');
			socket.off('marketplace:transaction:player:history:deanonymized');
		};
	}, [props.playerId]);

	function handleSetPage(pageNumber: number) {
		socket.emit('marketplace:transaction:player:history:deanonymized', {
			page: pageNumber - 1,
			playerId: props.playerId,
		});
		setPage(pageNumber);
	}

	return (
		<>
			<Flex alignItems='center' justifyContent='end'>
				<SlideFade in={showSettings}>
					<Flex justifyContent='end' gap='4px'>
						<InputGroup alignItems='center' gap='4px' width='fit-content'>
							<label htmlFor='lowerThreshold'>Lower Threshold</label>
							<IdlescapeInput
								type='number'
								id='lowerThreshold'
								defaultValue={lowerThreshold}
								min={0}
								step={0.1}
								max={1}
								setValue={setLowerThreshold}
								width='80px'
							/>
						</InputGroup>
						<InputGroup alignItems='center' gap='4px' width='fit-content'>
							<label htmlFor='upperThreshold'>Upper Threshold</label>
							<IdlescapeInput
								type='number'
								id='upperThreshold'
								defaultValue={upperThreshold}
								min={1}
								setValue={setUpperThreshold}
								width='80px'
							/>
						</InputGroup>
					</Flex>
				</SlideFade>
				<IdlescapeButton onClick={() => setShowSettings(!showSettings)} width='40px' variant='gray'>
					<IoSettingsSharp />
				</IdlescapeButton>
			</Flex>
			<HistoryTable
				page={page}
				totalListings={totalListings}
				history={marketHistory}
				setPage={handleSetPage}
				modMode={true}
				lowerThreshold={lowerThreshold}
				upperThreshold={upperThreshold}
			/>
		</>
	);
}

export default TradeHistory;
