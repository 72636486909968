import React from 'react';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';

export default function ConfirmationModal({
	onClose,
	onConfirm,
	isOpen = true,
	header,
	children,
	size,
	className,
}: {
	onClose: () => void;
	onConfirm: () => void;
	isOpen?: boolean;
	header?: React.ReactNode;
	children?: React.ReactNode;
	size?: string;
	className?: string;
}) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<ModalOverlay />
			<ModalContent className={className}>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{children}</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={onConfirm}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
