import { useState } from 'react';
import { Flex, Switch } from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { IdlescapeButton, IdlescapeNumberInput } from '@idlescape/ui';
import { getSocket } from '../../../services/socket.service';
import { Box } from '@chakra-ui/react';
import './ActionQueue.css';
import Tooltip from '../../layout/UI/Tooltip';
import ActionQueueItem from './ActionQueueItem';
import { IUserActionQueue } from '../../../../../game-server/src/modules/action-queue/UserActionQueue';
import { useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { FaForward, FaRegPlusSquare } from 'react-icons/fa';
import { platinumTabAtom } from '../../../atoms/platinumTabAtom';
import { getMaxQueueCount } from '../../../helper/helperFunctions';

export default function ActionQueueTooltip() {
	const socket = getSocket();
	const subscription = usePlayerField('subscription');
	const isSubscribed = subscription?.active ?? false;
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');
	const userActionQueueStart = usePlayerField('userActionQueueStart');
	const settings = usePlayerField('settings');
	const actionQueue = usePlayerField('actionQueue');
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setPlatinumTab = useSetRecoilState(platinumTabAtom);

	const [selectedQueueIndex, setSelectedQueueIndex] = useState(0);

	const [selectedLength, setSelectedLength] = useState(0); // in minutes, it's converted to ms later by backend
	const [selectedCount, setSelectedCount] = useState(0);

	const queueList = settings.miscellaneous.userActionQueue;
	const queueCount = getMaxQueueCount(isSubscribed, bonusActionQueueCount);
	const usedCount = queueList.length;

	function addCurrentToQueue() {
		socket.emit('action:queue:addCurrent', { length: selectedLength, count: selectedCount });
	}
	function editQueue() {
		// If selectedQueueIndex is not in the queue range, return
		if (selectedQueueIndex < 0 || selectedQueueIndex >= queueList.length) {
			return;
		}
		socket.emit('action:queue:edit', { index: selectedQueueIndex, length: selectedLength, count: selectedCount });
	}
	function toggleQueue() {
		socket.emit('action:queue:toggle');
	}
	function advanceQueue() {
		const nextPosition = (actionQueue?.info?.userQueue?.position ?? 0) + 1;
		socket.emit('action:queue:toggle', nextPosition);
	}

	function toggleQueueRepeat() {
		socket.emit('settings:update', {
			value: !settings.miscellaneous.queueRepeat,
			path: 'miscellaneous.queueRepeat',
		});
	}

	function renderInfo() {
		const repeat = settings.miscellaneous.queueRepeat;
		return (
			<div>
				Repeating: {repeat ? 'Yes ' : 'No '}
				<Switch isChecked={settings.miscellaneous.queueRepeat} onChange={toggleQueueRepeat} />
				<br />
				Queue Count: {usedCount} / {queueCount}{' '}
				<IdlescapeButton variant='blue' size='icon' onClick={openPlatinumShop}>
					<FaRegPlusSquare />
				</IdlescapeButton>
				<hr />
			</div>
		);
	}

	function renderQueueList() {
		return queueList.map((action, index) => {
			return (
				<Box key={index} cursor='pointer' onClick={() => setSelectedQueueIndex(index)}>
					<ActionQueueItem
						action={action}
						index={index}
						selected={selectedQueueIndex === index}
						showImage={false}
					/>
				</Box>
			);
		});
	}

	function renderCurrentQueue() {
		if (!actionQueue) return null;
		const action: IUserActionQueue = {
			targetLocationID: actionQueue.location,
			timeToSpendHere: selectedLength * 60000,
			actionCount: selectedCount,
			additionalOptions: actionQueue.options,
		};

		return (
			<ActionQueueItem
				action={action}
				index={-1}
				selected={false}
				showImage={false}
				addCurrent={usedCount < queueCount ? addCurrentToQueue : undefined}
			/>
		);
	}

	function openPlatinumShop() {
		setActiveTab('platinum-shop');
		setPlatinumTab('Shop');
	}

	return (
		<Tooltip onClick={true}>
			<Box className='queue-tooltip' maxHeight='80vh' overflowY='auto' position='relative'>
				{renderInfo()}
				<Flex fontSize='1rem' alignItems='center' gap='10px' justifyContent='center'>
					<IdlescapeNumberInput
						setValue={setSelectedLength}
						value={selectedLength}
						placeholder='∞'
						width='100px'
						min={0}
						maxLength={10}
					/>
					minutes
					<IdlescapeNumberInput
						setValue={setSelectedCount}
						value={selectedCount}
						placeholder='∞'
						display='inline-block'
						width='100px'
						min={0}
						maxLength={10}
					/>
					amount
				</Flex>
				<Flex justifyContent='center'>
					<IdlescapeButton
						variant={selectedQueueIndex < usedCount ? 'orange' : 'disabled'}
						fontSize='12px'
						lineHeight='14px'
						onClick={editQueue}
					>
						Change
						<br />
						Duration
					</IdlescapeButton>
					<IdlescapeButton
						variant={userActionQueueStart ? 'red' : 'green'}
						fontSize='12px'
						onClick={toggleQueue}
					>
						{userActionQueueStart ? 'Stop' : 'Start'}
					</IdlescapeButton>
					{userActionQueueStart && (
						<IdlescapeButton variant='blue' onClick={advanceQueue} size='icon'>
							<FaForward />
						</IdlescapeButton>
					)}
				</Flex>
				{renderCurrentQueue()}
				<hr />
				{renderQueueList()}
			</Box>
		</Tooltip>
	);
}
