import { atom } from 'recoil';
import { ILoadout } from '../../../game-server/src/modules/loadout/loadout.interface';
import { TEMPLATE_LOADOUT } from '../utils/constantsCollection';

export const loadoutEquipmentAtom = atom({
	key: 'loadoutEquipmentAtom',
	default: structuredClone(TEMPLATE_LOADOUT.equipment) as ILoadout['equipment'] & {
		changed?: boolean;
	},
});
export const loadoutAbilityAtom = atom({
	key: 'loadoutAbilityAtom',
	default: structuredClone(TEMPLATE_LOADOUT.abilityRotation) as ILoadout['abilityRotation'] & {
		changed?: boolean;
	},
});
export const loadoutFoodAtom = atom({
	key: 'loadoutFoodAtom',
	default: structuredClone(TEMPLATE_LOADOUT.foodInventory) as ILoadout['foodInventory'] & {
		changed?: boolean;
	},
});
