import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { IChatChannel } from '../../../../../game-server/src/repositories/ChatChannel.repository';
import { socket } from '../../../services/socket.service';

export const ChatPublicChannelsPopup = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [channels, setChannels] = useState<IChatChannel[]>([]);

	useEffect(() => {
		socket.on('chat:channels:send', (data) => {
			setIsVisible(true);
			setChannels(data);
		});
	});

	const joinChannel = (channel: IChatChannel) => {
		const command = '/channel join ' + channel.name;

		const commandToSend = {
			channelId: 1,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	};

	return (
		<Dialog open={isVisible} onClose={() => setIsVisible(false)} className='sell-item-dialog'>
			<DialogTitle>Public Chat Channels</DialogTitle>
			<DialogContent>
				<List className='chat-public-channel-list'>
					{channels.map((item) => (
						<ListItem
							button={true}
							className='chat-public-channel-list-item'
							onClick={() => joinChannel(item)}
							key={'chat-public-channel-' + item.name}
						>
							<ListItemText
								primary={
									<Typography className='chat-public-channel-list-primary'>{item.name}</Typography>
								}
								secondary={
									item.owner == null ? (
										item.owner
									) : (
										<Typography variant='caption' className='chat-public-channel-list-secondary'>
											{'Owner: ' + item.owner}
										</Typography>
									)
								}
							/>
							<ListItemText
								secondary={
									<Typography variant='caption' className='chat-public-channel-list-secondary'>
										{item.userAmount} players
									</Typography>
								}
							/>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<div
				className='idlescape-button idlescape-button-red'
				style={{ width: '60%', marginTop: '10px' }}
				onClick={() => setIsVisible(false)}
			>
				Close
			</div>
			<DialogActions disableSpacing={true}></DialogActions>
		</Dialog>
	);
};
