import { BaseTooltip } from './BaseTooltip';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { stringCapitalize } from '../../../../helper/StringFormatter';

export class DefaultTooltip extends BaseTooltip {
	protected getItemType(itemResource: IItemData): string {
		const unknown = 'Unknown Type';
		if (!itemResource) return unknown;

		if (itemResource.tags) {
			const irrelevantTags = ['misc', 'tool'];
			const tags = itemResource.tags.filter((tag) => !irrelevantTags.includes(tag));
			if (tags.length > 0) {
				return stringCapitalize(tags.shift());
			}
		}

		return stringCapitalize(itemResource?.class?.replace('-', ' ') ?? unknown);
	}
}
