import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../../hooks/useIsMobile';
import { itemList } from '../../../../utils/itemList';
import Tooltip from '../../../layout/UI/Tooltip';
import FakeItem from '../../Inventory/FakeItem';
import './ChatChest.css';

export default function ChatChest(props: {
	chest: { chestID: IItemData['id']; amount: number; loot: { amount: number; id: number }[] };
}) {
	const chestData = props.chest;
	if (!chestData) return <div>Invalid Chest</div>;
	const amount = chestData.amount;
	const genericChest = itemList[chestData.chestID];
	const itemName = genericChest.name;
	const chestIcon = <img className='chat-chest-icon' src={genericChest.itemImage} alt={genericChest.name} />;
	const isMobile = useIsMobile();
	const { isOpen, onOpen, onClose } = useDisclosure();

	function renderItems(items: { amount: number; id: number }[]) {
		return (
			<div className='chest-tooltip-loot-container'>
				{items &&
					items.map((item, index) => {
						const { id: itemID, amount: stackSize } = item;
						const fakeItem: TPartialItemWithItemID<IItem> = {
							itemID,
							stackSize,
						};
						return <FakeItem key={index} item={fakeItem} variant='small' />;
					})}
			</div>
		);
	}

	return (
		<div className='chat-item gem' onClick={onOpen}>
			{chestIcon} Opened {itemName} x{amount}
			{isMobile ? (
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent className='sell-item-dialog'>
						<ModalCloseButton />
						<ModalBody>
							<div className='chest-tooltip'>
								<div className='chest-tooltip-title-container'>
									<span>
										Opened {itemName} x{amount}
									</span>
								</div>
								{renderItems(chestData.loot)}
							</div>
						</ModalBody>
					</ModalContent>
				</Modal>
			) : (
				<Tooltip>
					<div className='chest-tooltip'>
						<div className='chest-tooltip-title-container'>
							<span>
								Opened {itemName} x{amount}
							</span>
						</div>
						{renderItems(chestData.loot)}
					</div>
				</Tooltip>
			)}
		</div>
	);
}
