/* eslint-disable react/no-unescaped-entities */
import { Box, Flex, Heading, HeadingProps, Image } from '@chakra-ui/react';
import { TActiveTab } from '../../../game-server/src/Atypes/Skills';
import { ActionQueue } from '../components/game/ActionQueue/ActionQueue';
import AllItems from '../components/game/AllItems2';
import ChatContainer from '../components/game/Chat/ChatContainer';
import { Combat } from '../components/game/Combat/Combat';
import CombatGuide from '../components/game/Combat/CombatGuide';
import { EventShop } from '../components/game/EventShop/EventShop';
import GeneralShop from '../components/game/Marketplace/GeneralShop';
import MarketplaceGuide from '../components/game/Marketplace/MarketplaceGuide';
import Shrine from '../components/game/Shrine/Shrine';
import PlatinumShopContainer from '../components/game/PlatinumShop/PlatinumShopContainer';
import PlatinumShopGuide from '../components/game/PlatinumShop/PlatinumShopGuide';
import SessionStats from '../components/game/SessionStats';
import Settings from '../components/game/Settings/Settings';
import CookingContainer from '../components/game/SkillPanels/Cooking/CookingContainer';
import CookingGuide from '../components/game/SkillPanels/Cooking/CookingGuide';
import Crafting from '../components/game/SkillPanels/Crafting/Crafting';
import CraftingGuide from '../components/game/SkillPanels/Crafting/CraftingGuide';
import EnchantingContainer from '../components/game/SkillPanels/Enchanting/EnchantingContainer';
import EnchantingGuide from '../components/game/SkillPanels/Enchanting/EnchantingGuide';
import Farming from '../components/game/SkillPanels/Farming/Farming';
import FarmingGuide from '../components/game/SkillPanels/Farming/FarmingGuide';
import Fishing from '../components/game/SkillPanels/Fishing/Fishing';
import Foraging from '../components/game/SkillPanels/Foraging/Foraging';
import GatheringGuide from '../components/game/SkillPanels/GatheringGuide';
import Runecrafting from '../components/game/SkillPanels/Runecrafting/Runecrafting';
import RunecraftingGuide from '../components/game/SkillPanels/Runecrafting/RunecraftingGuide';
import Smithing from '../components/game/SkillPanels/Smithing/Smithing';
import SmithingGuide from '../components/game/SkillPanels/Smithing/SmithingGuide';
import SocialTab from '../components/game/SocialTab';
import { abilities } from '../utils/abilityList';
import { itemList } from '../utils/itemList';
import Quests from '../components/game/Achievements/Quests';
import Talents from '../components/game/Talents/Talents';
import Mining from '../components/game/SkillPanels/Mining';
import React from 'react';
import ShrineGuide from '../components/game/Shrine/ShrineGuide';
import Achievements from '../components/game/Achievements/Achievements';
import { Marketplace } from '../components/game/Marketplace/Marketplace';
import CombatGearRightPanel from '../components/game/Combat/CombatGearRightPanel';
import AchivementGuide from '../components/game/Achievements/AchivementGuide';
import QuestGuide from '../components/game/Achievements/QuestGuide';
import TalentGuide from '../components/game/Talents/TalentGuide';

export interface ITab {
	id: TActiveTab;
	name: string;
	/** Used to override name in the Drawer component. Mainly used for Combat skills,
	 *  as we want them to render 'Combat' container, but show their skill name in the Drawer */
	overrideTabName?: string;
	icon: string;
	overrideTabIcon?: string;
	theme: string;
	guide: React.ReactElement;
	component: React.ReactElement;
	/* activeContainerPropertiesList: {
		tabName: string;
		itemClass?: string;
		theme: string;
	}; */
}

type TTabList = {
	[key: string]: ITab;
};

export const SubHeading = ({ children, ...props }: { children: string } & HeadingProps) => (
	<Heading size='md' mb={2} ml={2} {...props}>
		{children}
	</Heading>
);

export const itemIcon = (item: number) => (
	<Image
		key={item}
		src={itemList[item].itemImage}
		alt={itemList[item].name}
		width='40px'
		height='40px'
		objectFit='contain'
	/>
);

export const abilityIcon = (ability: number) => (
	<Image
		src={abilities[ability].abilityImage}
		alt={abilities[ability].abilityName}
		width='40px'
		height='40px'
		objectFit='contain'
	/>
);

export const SubHeadingWithIcons = ({ children, icons }: { children: string; icons: React.ReactElement[] }) => (
	<Flex alignItems='center' mb={2}>
		<Heading size='md' mx={2}>
			{children}
		</Heading>
		{icons}
	</Flex>
);

export const tabs: TTabList = {
	mining: {
		id: 'mining',
		name: 'Mining',
		icon: '/images/mining/iron_pickaxe.svg',
		theme: 'theme-mining',
		guide: <GatheringGuide type='mining' />,
		component: <Mining />,
	},
	foraging: {
		id: 'foraging',
		name: 'Foraging',
		icon: '/images/foraging/foraging_icon.png',
		theme: 'theme-foraging',
		guide: <GatheringGuide type='foraging' />,
		component: <Foraging />,
	},
	fishing: {
		id: 'fishing',
		name: 'Fishing',
		icon: '/images/fishing/fishing_logo.png',
		theme: 'theme-fishing',
		guide: <GatheringGuide type='fishing' />,
		component: <Fishing />,
	},
	smithing: {
		id: 'smithing',
		name: 'Smithing',
		icon: '/images/smithing/smithing_icon.png',
		theme: 'theme-smithing',
		guide: <SmithingGuide />,
		component: <Smithing />,
	},
	farming: {
		id: 'farming',
		name: 'Farming',
		icon: '/images/farming/farming_icon.png',
		theme: 'theme-farming',
		guide: <FarmingGuide />,
		component: <Farming />,
	},
	cooking: {
		id: 'cooking',
		name: 'Cooking',
		icon: '/images/cooking/cooking_icon.png',
		theme: 'theme-cooking',
		guide: <CookingGuide />,
		component: <CookingContainer />,
	},
	crafting: {
		id: 'crafting',
		name: 'Crafting',
		icon: '/images/ui/crafting_icon.png',
		theme: 'theme-crafting',
		guide: <CraftingGuide />,
		component: <Crafting />,
	},
	runecrafting: {
		id: 'runecrafting',
		name: 'Runecrafting',
		icon: '/images/runecrafting/RuneCraftingIcon.png',
		theme: 'theme-runecrafting',
		guide: <RunecraftingGuide />,
		component: <Runecrafting />,
	},
	enchanting: {
		id: 'enchanting',
		name: 'Enchanting',
		icon: '/images/enchanting/enchanting_logo.png',
		theme: 'theme-enchanting',
		guide: <EnchantingGuide />,
		component: <EnchantingContainer />,
	},
	constitution: {
		id: 'constitution',
		name: 'Constitution',
		overrideTabName: 'Combat',
		icon: '/images/combat/constitution_icon.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	attack: {
		id: 'attack',
		name: 'Attack',
		overrideTabName: 'Combat',
		icon: '/images/combat/attack_icon.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	strength: {
		id: 'strength',
		name: 'Strength',
		overrideTabName: 'Combat',
		icon: '/images/combat/strength_icon.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	defense: {
		id: 'defense',
		name: 'Defense',
		overrideTabName: 'Combat',
		icon: '/images/combat/defense_icon.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	range: {
		id: 'range',
		name: 'Range',
		overrideTabName: 'Combat',
		icon: '/images/combat/range_icon.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	magic: {
		id: 'magic',
		name: 'Magic',
		overrideTabName: 'Combat',
		icon: '/images/magic/magic_logo.png',
		overrideTabIcon: '/images/combat/combat_level.png',
		theme: 'theme-combat',
		guide: <CombatGuide />,
		component: <Combat />,
	},
	equipment: {
		id: 'equipment',
		name: 'Equipment',
		icon: '/images/ui/equipment_icon.png',
		theme: 'theme-equipment',
		guide: <Box>Insert very useful skill/container information here</Box>,
		component: <CombatGearRightPanel />,
	},
	marketplace: {
		id: 'marketplace',
		name: 'Marketplace',
		icon: '/images/ui/marketplace_icon.png',
		theme: 'theme-marketplace',
		guide: <MarketplaceGuide />,
		component: <Marketplace />,
	},
	'general-shop': {
		id: 'general-shop',
		name: 'General Shop',
		icon: '/images/ui/shop_icon.png',
		theme: 'theme-general-shop',
		guide: (
			<Box>
				In the General Shop, you can purchase Boots, Gloves, and other various useful items. Boots and Gloves
				have a total level requirement that needs to be met before they can be purchased. When you click on any
				desired item, a pop up will appear where you can input the amount you wish to purchase before clicking
				on "Buy" to confirm.
				<br />
				Certain gamemodes have more items that can be purchased in order to assist with those said gamemodes.
			</Box>
		),
		component: <GeneralShop />,
	},
	'event-shop': {
		id: 'event-shop',
		name: 'Event Shop',
		icon: '/images/misc/event_token.png',
		theme: 'theme-event-shop',
		guide: (
			<Box>
				In the Event Shop you can spend Event Tokens earned from seasonal events for various buffs as well as
				limited event items. Event Tokens can only be obtained from Events or Seasonal rewards.
			</Box>
		),
		component: <EventShop />,
	},
	shrine: {
		id: 'shrine',
		name: 'Shrine',
		icon: '/images/misc/shrine_icon.png',
		theme: 'theme-shrine',
		guide: <ShrineGuide />,
		component: <Shrine />,
	},
	'platinum-shop': {
		id: 'platinum-shop',
		name: 'Platinum Shop',
		icon: '/images/premium/platinum_icon.png',
		theme: 'theme-platinum-shop',
		guide: <PlatinumShopGuide />,
		component: <PlatinumShopContainer />,
	},
	settings: {
		id: 'settings',
		name: 'Settings',
		icon: '/images/ui/options_gear.png',
		theme: 'theme-settings',
		guide: (
			<Box>
				Here you can change your various in game settings which can affect your Activity log, Inventory sorting
				and tooltips, Chat preferences, Combat settings and rules, and other miscellaneous settings.
			</Box>
		),
		component: <Settings />,
	},
	achievements: {
		id: 'achievements',
		name: 'Achievements',
		icon: '/images/misc/Elite_Scroll.png',
		theme: 'theme-achievements',
		guide: <AchivementGuide />,
		component: <Achievements />,
	},
	quests: {
		id: 'quests',
		name: 'Quests',
		icon: '/images/misc/book.png',
		theme: 'theme-quests',
		guide: <QuestGuide />,
		component: <Quests isAchievements={false} />,
	},
	talents: {
		id: 'talents',
		name: 'Talents',
		icon: '/images/total_level_mastery_icon.png',
		theme: 'theme-talents',
		guide: <TalentGuide />,
		component: <Talents />,
	},
	social: {
		id: 'social',
		name: 'Social',
		icon: '/images/ui/social_icon.png',
		theme: 'theme-social',
		guide: (
			<Box>
				This is the Social tab, where you can see your friends and your current party, as well as any pending
				invites you might have to join a said party.
				<br />
				You can see who you blocked here as well, if you have happened to do that.
				<br />
				You can also see the leaderboard here too.
				<br />
				Anyways, how are you? Doing good? Taking care of yourself? Making sure to take plenty of breaks and
				drink water right?
			</Box>
		),
		component: <SocialTab />,
	},
	chat: {
		id: 'chat',
		name: 'Chat',
		icon: '/images/chaticons/chat-icon-global.png',
		theme: 'theme-chat',
		guide: <Box>Insert very useful skill/container information here</Box>,
		component: <ChatContainer />,
	},
	inventory: {
		id: 'inventory',
		name: 'Inventory',
		icon: '/images/ui/inventory_icon.png',
		theme: 'theme-inventory',
		guide: (
			<Box>
				Hey. This is the inventory. It holds all of your items. Yes all of them, as it is infinite. Kinda neat.
				Well anyway seeing as you are here, I just want to say you're pretty cool.
				<br />I wonder if there are other hidden things in this game. Who knows... Nick Probably.
			</Box>
		),
		component: <AllItems />,
	},
	'session-stats': {
		id: 'session-stats',
		name: 'Session Stats',
		icon: '/images/pontification/skill_waiting_icon.png',
		theme: 'theme-sessionStats',
		guide: (
			<Box>
				Here you can see the items, experience, and levels you have gained, or lost, within the current session.
				It will also show how many enchantment procs you've had within the session. as well as combat deaths.
				<br />
				You can use the /clearstats command in chat. This will clear your old session and start tracking a new
				one.
			</Box>
		),
		component: <SessionStats />,
	},
	'action-queue': {
		id: 'action-queue',
		name: 'Action Queue',
		icon: '/images/pontification/skill_waiting_icon.png',
		theme: 'theme-default',
		guide: (
			<Box>
				You can define a list of actions to be performed automatically. It will repeat the list indefinitely
				until you stop it. If one of the actions fails, for example you attempt to smith a bar you do not have
				the ore or heat for, it will skip it and move to the next action.
				<br />
				<br />
				By default you must either be an IS+ subscriber or have used action queue count tokens purchased with
				platinum to utilize this feature.
			</Box>
		),
		component: <ActionQueue />,
	},
};
