import { Box, Grid } from '@chakra-ui/react';
import React, { useState } from 'react';
import MercenaryEditor from '../Mercenaries/MercenaryEditor';
import { LoadoutEditor } from './LoadoutEditor';

export default function Loadouts() {
	const [view, setView] = useState<'loadouts' | 'mercenaries'>('loadouts');
	return (
		<Grid gridTemplateRows='35px 1fr' height='100%'>
			<Grid
				gridTemplateColumns='150px 150px'
				justifyContent='center'
				gap='5px'
				height='30px'
				marginBottom='5px'
				boxSizing='border-box'
			>
				<Box
					width='100%'
					className={`nav-tab ${view === 'loadouts' ? 'selected-tab' : ''}`}
					onClick={() => setView('loadouts')}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Loadouts' />
					Loadouts
				</Box>
				<Box
					width='100%'
					className={`nav-tab ${view === 'mercenaries' ? 'selected-tab' : ''}`}
					onClick={() => setView('mercenaries')}
				>
					<img className='nav-tab-icon icon-border' src='/images/combat/shared_icon.png' alt='Mercenaries' />
					Mercenaries
				</Box>
			</Grid>
			{view === 'loadouts' && <LoadoutEditor />}
			{view === 'mercenaries' && <MercenaryEditor />}
		</Grid>
	);
}
