import { Box, Flex, Grid, Image, ImageProps } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { IChatIcon } from '../../../../../../game-server/src/modules/chat/Chat.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { usePlayerField } from '../../../../hooks/hooks';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { chatIconList } from '../../../../utils/chatIconList';

export function IconDisplay({
	iconData,
	allowRemove,
	...props
}: { iconData: IChatIcon; allowRemove?: boolean } & ImageProps) {
	function removeIcon(iconID: number | null) {
		if (iconID === null) return;
		socket.emit('profile:icon:remove', iconID);
	}

	return (
		<IdlescapeWrappingTooltip content={iconData.name}>
			<Box position='relative'>
				<Image
					className={`inspect-achievement-icon ${iconData.css}`}
					src={iconData.source}
					alt={iconData.name}
					{...props}
				/>
				{allowRemove && (
					<Flex
						onClick={() => removeIcon(iconData.id)}
						position='absolute'
						top='0'
						right='0'
						fontSize='20px'
						color='red.500'
						cursor='pointer'
						_hover={{ transform: 'scale(1.1)' }}
					>
						<MdOutlineRemoveCircle />
					</Flex>
				)}
			</Box>
		</IdlescapeWrappingTooltip>
	);
}

export function IconEditor({ activeIcons }: { activeIcons: IInspectData['icons'] }) {
	const unlockedIcons = usePlayerField('unlockedIcons');

	const isMobile = useIsMobile();

	const icons = unlockedIcons.map((i) => i.iconID).filter((icon) => icon && !activeIcons.includes(icon));
	if (icons.length === 0) return null;

	function addIcon(iconID: number) {
		socket.emit('profile:icon:add', iconID);
	}

	function iconPicker() {
		return (
			<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' maxWidth='300px' justifyContent='center'>
				{icons.map((icon) => {
					if (!icon) return null;
					const iconData = chatIconList[icon];
					if (!iconData) return null;
					return (
						<IconDisplay
							key={icon}
							iconData={iconData}
							onClick={() => addIcon(icon)}
							height='45px'
							width='45px'
							style={{ cursor: 'pointer' }}
						/>
					);
				})}
			</Grid>
		);
	}

	return (
		<IdlescapeWrappingTooltip content={iconPicker()} activateOnClick>
			<Flex
				height={isMobile ? '50px' : '60px'}
				width={isMobile ? '50px' : '60px'}
				border='3px solid white'
				borderRadius='15px'
				borderStyle='dashed'
				justifyContent='center'
				alignItems='center'
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
			>
				<FaPlus />
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}
