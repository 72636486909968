import React from 'react';
import { useRecoilValue } from 'recoil';
import { monsterAtom } from '../../../atoms/monsterAtom';
import CombatInfoBar from './CombatInfoBar';
import CombatSplashesContainer from './CombatSplashesContainer';

export const CombatMonsterArea = () => {
	const monsters = useRecoilValue(monsterAtom);

	return (
		<div className='combat-monster-area'>
			{monsters.map((monster) => {
				if (monster.faction !== 'monster') {
					return null;
				}
				return (
					<div
						className={`${monster.monsterName} ${monster.id} ${
							monster.monsterHealth <= 0 ? 'death-animation' : ''
						}`}
						key={'combat-monster-area' + monster.id}
						onAnimationEnd={(e) => {
							if (monster.monsterHealth <= 0) {
								e.currentTarget.style.display = 'none';
							}
						}}
					>
						<CombatInfoBar
							id={monster.id}
							name={monster.monsterName}
							currentHealth={monster.monsterHealth}
							maxHealth={monster.maxHealth}
							tempHealth={monster.monsterTempHealth}
							type='monster'
						/>
						<div className={'combat-fight-image-container '}>
							<img
								className={`${monster.monsterName} base-monster-style`}
								src={monster.image}
								alt={monster.monsterName}
							/>
							<CombatSplashesContainer id={monster.id} compact={monster.smallSplashDisplay} />
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default CombatMonsterArea;
