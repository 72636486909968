import React from 'react';
import { itemList } from '../../../../utils/itemList';
import { itemAmounts, itemAugment, itemEnchant, itemImage } from '../../../../helper/itemHelperFunctions';
import {
	Box,
	Flex,
	Text,
	Slider,
	SliderTrack,
	SliderThumb,
	SliderFilledTrack,
	Accordion,
	AccordionPanel,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	Image,
	AbsoluteCenter,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { useEffect, useState } from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import ResourceCost from '../ResourceCost';
import RecipeList from './RecipeList';
import { cookingRecipeAtom } from '../../../../atoms/cookingRecipeAtom';
import { useRecoilState } from 'recoil';
import { VIAL_IDS } from '../../../../utils/constantsCollection';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import SelectionTooltipBox from '../../Tooltips/Types/SelectionTooltipBox';
import { getPotionInfo } from '../../../../utils/cookingFunctions';
import CookingInformation from './CookingInformation';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { cookingList } from '../../../../utils/cookingList';
import useIsMobile from '../../../../hooks/useIsMobile';
import { TiArrowRight } from 'react-icons/ti';
import { FaWalking } from 'react-icons/fa';
import CookingProgressBar from './CookingProgressBar';
import { getTimeString } from '../../../../utils/helperFunctions';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import ActionQueueButton from '../../ActionQueue/ActionQueueButton';

export default function Cooking({ isAlchemy = false }: { isAlchemy?: boolean }) {
	const [cookingRecipe, setCookingRecipe] = useRecoilState(cookingRecipeAtom);
	const action = usePlayerField('actionQueue');
	const stockpile = usePlayerField('stockpile');
	const [vialPotion, setVialPotion] = useState<IItem | undefined>(findPotion());
	const actionType = action?.options?.cooking?.type;
	const active = (!isAlchemy && actionType === 'cooking') || (isAlchemy && actionType === 'alchemy');
	const [accordionIndex, setAccordionIndex] = useState(active ? [-1, 1] : [0, -1]);
	const [buffIngredient, setBuffIngredient] = useState<number | undefined>(
		active ? action?.options?.cooking?.buffItem : undefined
	);
	const [quality, setQuality] = useState(
		active ? action?.options?.cooking?.quality ?? (isAlchemy ? 1 : 0) : isAlchemy ? 1 : 0
	);

	const isMobile = useIsMobile();

	const info = isAlchemy
		? CookingInformation(quality, true, vialPotion)
		: CookingInformation(quality, false, undefined, buffIngredient);
	const previewItem = info.previewItem;

	const resourceCosts = info.ingredients.map((cost) => {
		return { resource: itemList[cost.id], count: cost.amount };
	});

	const possibleOptions: IItem[] = [];

	const potionInfo = getPotionInfo(vialPotion?.itemID);
	if (!isAlchemy) {
		for (const item of stockpile) {
			const cookingData = cookingList[item.itemID];
			// Add ingredients with cooking buffs (combat)
			if (cookingData?.cookingEnchantment) {
				possibleOptions.push(item);
			}
		}
	} else {
		const recipeBuff = cookingRecipe ? cookingList[cookingRecipe]?.alchemyEnchantment : 0;
		for (const item of stockpile) {
			if (item.augmentations) continue;
			const cookingData = cookingList[item.itemID];
			const itemBuff = item.enchantmentID;
			// Match vials or potions with the same buff
			if (cookingData?.nextPotion && (!itemBuff || recipeBuff === itemBuff || (itemBuff && !recipeBuff))) {
				possibleOptions.push(item);
			}
		}
		useEffect(() => {
			// Remove potion if the recipe is a different buff
			if (
				isAlchemy &&
				cookingRecipe &&
				!VIAL_IDS.includes(vialPotion?.itemID ?? 0) &&
				vialPotion?.enchantmentID !== cookingList[cookingRecipe]?.alchemyEnchantment
			) {
				setVialPotion(undefined);
			}
		}, [cookingRecipe]);
	}

	useEffect(() => {
		if (cookingRecipe) {
			setAccordionIndex([-1, 1]);
		}
	}, [!cookingRecipe]);

	useEffect(() => {
		setCookingRecipe(active ? action?.options?.cooking?.recipeID ?? 0 : 0);
	}, []);

	function stopAction() {
		socket.emit('action:stop');
	}

	function toggle() {
		if (active) {
			stopAction();
		} else {
			const type = isAlchemy ? 'alchemy' : 'cooking';
			const potionID = isAlchemy ? vialPotion?.itemID : undefined;
			const buffItem = isAlchemy ? cookingRecipe : buffIngredient;
			let qualityHere = quality;
			if (cookingRecipe === itemsIds.prismatic_pudding) {
				setQuality(0);
				qualityHere = 0;
			}
			socket.emit('action:start', {
				action: 'cooking',
				location: locationsIds.the_cooking_guild,
				options: {
					cooking: {
						type,
						recipeID: cookingRecipe,
						quality: qualityHere,
						potionID,
						buffItem,
					},
				},
			});
		}
	}

	function queueButton() {
		const type = isAlchemy ? 'alchemy' : 'cooking';
		const potionID = isAlchemy ? vialPotion?.itemID : undefined;
		const buffItem = isAlchemy ? cookingRecipe : buffIngredient;
		const canQueue = cookingRecipe && (!isAlchemy || (isAlchemy && vialPotion));
		return (
			<ActionQueueButton
				action='cooking'
				location={locationsIds.the_cooking_guild}
				options={{
					cooking: {
						type,
						recipeID: cookingRecipe,
						quality,
						potionID,
						buffItem,
					},
				}}
				variant={canQueue ? 'orange' : 'disabled'}
			/>
		);
	}

	function findPotion() {
		const potionID = action?.options?.cooking?.potionID;
		const buffItem = action?.options?.cooking?.buffItem;
		if (potionID) {
			if (VIAL_IDS.includes(potionID)) {
				return stockpile.find((item) => item.itemID === potionID);
			}
			if (buffItem) {
				const buff = cookingList[buffItem].alchemyEnchantment;
				return stockpile.find((item) => item.itemID === potionID && item.enchantmentID === buff);
			}
		}
	}

	function emptySlot(glow = false) {
		return (
			<Box
				width='80px'
				height='80px'
				backgroundColor='rgba(0,0,0,0.5)'
				borderRadius='10px'
				border={glow ? '' : '1px solid rgba(255, 230, 0, 0.356)'}
				backgroundImage='/images/cooking/cooking_icon_transparent.png'
				backgroundSize='contain'
				marginBottom='20px'
				boxShadow={glow ? '0 0 5px 3px rgb(208, 108, 238), inset 0 0 2px 5px rgb(208, 108, 238)' : ''}
				animation={glow ? 'glow-pink 500ms ease-out' : ''}
			></Box>
		);
	}

	// For cooking
	function buffSlot() {
		if (buffIngredient) {
			const itemData = itemList[buffIngredient];
			return (
				<ResourceCost
					resourceCosts={[{ resource: itemData, count: 1 }]}
					variant='cooking'
					imageSize={80}
					showTooltip={!isMobile}
				/>
			);
		}
		return emptySlot();
	}

	// For alchemy
	function vialSlot() {
		if (vialPotion && possibleOptions.length > 0) {
			const itemData = itemList[vialPotion.itemID];
			return (
				<>
					<ResourceCost
						resourceCosts={[{ resource: itemData, count: info.previewItem.stackSize ?? 1 }]}
						variant='cooking'
						imageSize={80}
						itemStats={[{ itemID: vialPotion.itemID, enchantmentID: vialPotion.enchantmentID }]}
						showTooltip={!isMobile}
					/>
					<Box position='absolute' top='0' margin='5px' width='70px' height='70px'>
						{itemAugment(vialPotion)}
						{itemEnchant(vialPotion)}
					</Box>
				</>
			);
		}
		return emptySlot(!!cookingRecipe);
	}

	function resultSlot() {
		if (previewItem.itemID) {
			const itemData = itemList[previewItem.itemID];
			return (
				<Box
					width='80px'
					height='80px'
					backgroundColor='rgba(0,0,0,0.5)'
					borderRadius='10px'
					border='1px solid rgba(255, 230, 0, 0.356)'
					backgroundSize='contain'
					marginBottom='20px'
					data-itemid={previewItem.itemID}
				>
					<Box
						width='74px'
						height='74px'
						margin='3px'
						position='relative'
						fontSize='1.5rem'
						textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
					>
						{itemImage(itemData)}
						{itemAugment(previewItem)}
						{itemEnchant(previewItem)}
						{itemAmounts(previewItem.stackSize ?? 0, 1)}
						<ItemTooltip item={previewItem} />
					</Box>
					{
						<IdlescapeWrappingTooltip
							content={
								isAlchemy
									? 'Chance to craft an additional potion if a vial or potion is available. Multiple chances to increase amount if higher than 100%.'
									: 'Chance to increase quality.'
							}
						>
							<Box
								textAlign='center'
								fontSize='12px'
								color={info.consistentChef ? 'grey' : ''}
								textDecoration={info.consistentChef ? 'line-through' : ''}
							>
								+{(info.higherQuality * 100).toFixed(2)}%
							</Box>
						</IdlescapeWrappingTooltip>
					}
				</Box>
			);
		}
		return emptySlot(false);
	}

	function fishOilSlot() {
		if (info.fishOil) {
			const itemData = itemList[itemsIds.fish_oil];
			return (
				<Box
					marginTop='40px'
					width='40px'
					height='40px'
					backgroundColor='rgba(0,0,0,0.5)'
					borderRadius='10px'
					border='1px solid rgba(255, 230, 0, 0.356)'
					backgroundSize='contain'
					padding='3px'
					position='relative'
					fontSize='1rem'
					textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
					className='anchor-cooking-fish-oil'
				>
					{itemImage(itemData)}
					<div className='centered'>{(info.fishOil * 100).toFixed() + '%'}</div>
					<ItemTooltip item={{ itemID: itemsIds.fish_oil }} />
				</Box>
			);
		}
		return <></>;
	}

	function selectItem(item: IItem) {
		if (!isAlchemy) {
			setBuffIngredient(item.itemID);
		} else {
			setVialPotion(item);
			const potionInfo = getPotionInfo(item.itemID);
			if (quality <= potionInfo.tier) {
				setQuality(potionInfo.tier + 1);
			}
		}
		if (!cookingRecipe && item.enchantmentID) {
			let cookingID = 0;
			for (const key in cookingList) {
				const cookingData = cookingList[key];
				if (cookingData.alchemyEnchantment === item.enchantmentID) {
					cookingID = Number(key);
					break;
				}
			}
			if (cookingID) {
				setCookingRecipe(cookingID);
			}
		}
	}

	function removeItem() {
		setBuffIngredient(undefined);
	}

	function changeQuality(slider: number) {
		setQuality(slider);
	}

	const tierString = ['Vial', 'Common', 'Rare', 'Epic', 'Legendary'];
	const canStart = cookingRecipe && info.actions > 0 && (!isAlchemy || (isAlchemy && vialPotion));
	const renderMain = (
		<Flex flexDirection='column' alignItems='center' className='anchor-cooking-main'>
			<Flex gap='10px'>
				<SelectionTooltipBox
					position='relative'
					items={possibleOptions}
					clickItem={selectItem}
					clickEmpty={removeItem}
					showEmpty={!isAlchemy}
					title={isAlchemy ? 'Select Vial/Potion' : 'Select Buff'}
					currentItem={isAlchemy ? vialPotion : buffIngredient ? { itemID: buffIngredient } : undefined}
				>
					{isAlchemy ? vialSlot() : buffSlot()}
					{possibleOptions.length > 0
						? !buffIngredient &&
						  !vialPotion && (
								<AbsoluteCenter
									textShadow={'text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}
									fontSize={'24px'}
									fontWeight={'bold'}
									color='rgba(255, 230, 0)'
									paddingBottom='20px'
								>
									{possibleOptions.length}
								</AbsoluteCenter>
						  )
						: isAlchemy && (
								<AbsoluteCenter textAlign='center' paddingBottom='20px'>
									Craft some vials
								</AbsoluteCenter>
						  )}
				</SelectionTooltipBox>
				<Box marginTop='15px'>{isAlchemy && <TiArrowRight size='50' />}</Box>
				<Box position='relative' className='anchor-cooking-result'>
					{resultSlot()}
				</Box>
				{fishOilSlot()}
			</Flex>
			{(info.minSlider > 0 || info.maxSlider > 0) && (
				<Flex alignItems='center' gap='5px'>
					<Slider
						key={potionInfo.tier + 1}
						defaultValue={quality}
						min={info.minSlider}
						max={info.maxSlider}
						onChange={changeQuality}
						width='150px'
						margin='10px 0 10px 15px'
						color={quality <= 4 ? 'springgreen' : quality <= 8 ? 'orange' : 'red'}
					>
						<SliderTrack outline='1px solid black'>
							<SliderFilledTrack background='currentcolor' />
						</SliderTrack>
						<SliderThumb background='currentcolor' _focusVisible={{}} outline='1px solid black' />
					</Slider>
					<Text fontSize='xl' margin='0' width='90px' textAlign='center'>
						{isAlchemy ? tierString[quality] : '+' + quality * info.qualityPerSlider}
					</Text>
				</Flex>
			)}
			<Flex gap='10px' position='relative' flexWrap='wrap' justifyContent='center'>
				<ResourceCost resourceCosts={resourceCosts} variant='cooking' imageSize={65} />
			</Flex>
			{isMobile ? (
				<Box width='200px' height='20px'>
					<CookingProgressBar currentAction={isAlchemy ? 'alchemy' : 'cooking'} />
				</Box>
			) : (
				<Box position='absolute' width='300px' height='300px' top='75px' zIndex='-1'>
					<CookingProgressBar currentAction={isAlchemy ? 'alchemy' : 'cooking'} theme='cooking' />
				</Box>
			)}
			<div>
				<IdlescapeButton variant={active ? 'red' : canStart ? 'green' : 'disabled'} onClick={toggle}>
					{active ? 'Stop' : 'Start'}
				</IdlescapeButton>
				{queueButton()}
			</div>
			<div>Recipe changes made after starting will only take effect when re-started.</div>
			<IdlescapeFrameBox
				display='grid'
				padding='10px'
				marginTop='10px'
				gridTemplateColumns='repeat(5, minmax(60px, 1fr))'
				alignItems='center'
				justifyItems='center'
				gap='10px'
				fontSize='13px'
				position='relative'
				textAlign='center'
				_hover={{}}
			>
				<IdlescapeWrappingTooltip content='Available attempts.'>
					<Box>
						<FaWalking size='25' />
					</Box>
				</IdlescapeWrappingTooltip>
				<IdlescapeWrappingTooltip content='Time per action. (Time to complete all actions)'>
					<Image src='/images/clock.png' alt='Duration' width='25px' height='25px' />
				</IdlescapeWrappingTooltip>
				<IdlescapeWrappingTooltip content='Chance to successfully cook. (Total difficulty)'>
					<Image src='/images/fishing/chance_icon.png' alt='Chance' width='25px' height='25px' />
				</IdlescapeWrappingTooltip>
				<IdlescapeWrappingTooltip content='Gain half experience on failure unless wearing searing or golden ladle. (Total experience when completing all actions)'>
					<Image src='/images/total_level.png' alt='Experience' width='25px' height='25px' />
				</IdlescapeWrappingTooltip>
				<IdlescapeWrappingTooltip content='Chance to find Prismatic Extract while Cooking.'>
					<Image
						src='/images/misc/keys/prismatic_extract.png'
						alt='Prismatic Extract'
						width='25px'
						height='25px'
					/>
				</IdlescapeWrappingTooltip>
				<Text margin='0' className='anchor-cooking-actions'>
					{info.actions}
				</Text>
				<Text margin='0' textAlign='center' className='anchor-cooking-duration'>
					{info.duration.toFixed(1)}s
					<br />({getTimeString(info.totalTime, 2)})
				</Text>
				<Text margin='0' className='anchor-cooking-chance'>
					{(info.chance * 100).toFixed(0)}%
					<br />({info.difficulty})
				</Text>
				<Text margin='0' className='anchor-cooking-experience'>
					{info.experience}xp
					<br />({Math.floor(info.experience * info.actions).toLocaleString('en-us')}xp)
				</Text>
				<Text margin='0' className='anchor-cooking-fragment'>
					{(info.prismaticExtractChance * 100).toFixed(4)}%
				</Text>
			</IdlescapeFrameBox>
		</Flex>
	);

	if (isMobile) {
		return (
			<Box height='calc(100% - 20px)'>
				<Accordion allowToggle index={accordionIndex} flex='1'>
					<AccordionItem>
						<AccordionButton
							as='h2'
							fontSize='2xl'
							onClick={() => {
								setAccordionIndex([accordionIndex[0] === 0 ? -1 : 0, accordionIndex[1]]);
							}}
						>
							Recipes
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<RecipeList isAlchemy={isAlchemy} />
						</AccordionPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionButton
							as='h2'
							fontSize='2xl'
							onClick={() => {
								setAccordionIndex([accordionIndex[0], accordionIndex[1] === 1 ? -1 : 1]);
							}}
						>
							{isAlchemy ? 'Brewing' : 'Cooking'}
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>{renderMain}</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Box>
		);
	}
	return (
		<Box height='calc(100% - 20px)'>
			<Flex height='100%' flexWrap='wrap' className='anchor-cooking-content'>
				<IdlescapeContainer
					height='100%'
					display='flex'
					flexDirection='column'
					flex='1 0 300px'
					textAlign='center'
				>
					<Text as='h2' margin='10px 0'>
						Recipes
					</Text>
					<RecipeList isAlchemy={isAlchemy} />
				</IdlescapeContainer>
				<Box flex='1 0 300px' padding='15px' margin='15px' borderRadius='10px' background='#0008'>
					{renderMain}
				</Box>
			</Flex>
		</Box>
	);
}
