import { BaseTooltip } from './BaseTooltip';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { TEnchant } from '../Data/TooltipData';

export class EnchantmentScrollTooltip extends BaseTooltip {
	SCROLL_DEFAULT_ENCHANT_STRENGTH = 1;
	protected getItemType(): string {
		return 'Enchantment Scroll';
	}

	protected getEnchant(item: IItem, itemResource: IItemData): Record<TEnchant, string | number> | undefined {
		if (!itemResource.enchantmentID) return undefined;

		const enchantment = enchantmentsList[itemResource.enchantmentID];
		return {
			enchantId: enchantment.id,
			enchantName: enchantment.name,
			enchantStrength: this.SCROLL_DEFAULT_ENCHANT_STRENGTH,
			enchantDescription: enchantment.getTooltip(
				this.SCROLL_DEFAULT_ENCHANT_STRENGTH,
				enchantment.strengthPerLevel
			),
			enchantType: enchantment.relatedSkills[0],
		};
	}
}
