import { productionRecipeList } from '../../../utils/craftAugTable';
import { IItem, IItemData } from '../../../../../game-server/src/modules/items/items.interface';

export class CraftingAugmentingData {
	/**
	 * Retrieves the entire Crafting-Augmenting list. If you want a single item use {@link getCraftingAugmentingByID} instead!
	 *
	 * @returns The entire Crafting-Augmenting list
	 */
	static getCraftingAugmentingList() {
		return productionRecipeList;
	}

	static getCraftingByID(itemID: IItemData['id']) {
		const craftingRecipes = productionRecipeList[itemID]?.crafting;
		if (this.exists(itemID) && craftingRecipes) {
			return craftingRecipes;
		}
	}

	static getScrollcraftingByID(itemID: IItemData['id']) {
		const scrollcraftingCost = productionRecipeList[itemID]?.scrollcrafting;
		if (this.exists(itemID) && scrollcraftingCost) {
			return scrollcraftingCost;
		}
	}

	static getAugmentingByID(itemID: IItemData['id']) {
		const augmentationCost = productionRecipeList[itemID]?.augmenting;
		if (this.exists(itemID) && augmentationCost) {
			return augmentationCost;
		}
	}

	static getRunecraftingByID(itemID: IItemData['id']) {
		const runecraftingCost = productionRecipeList[itemID]?.runecrafting;
		if (this.exists(itemID) && runecraftingCost) {
			return runecraftingCost;
		}
	}

	static getSmithingByID(itemID: IItemData['id']) {
		const smithingCost = productionRecipeList[itemID]?.smithing;
		if (this.exists(itemID) && smithingCost) {
			return smithingCost;
		}
	}

	static getScrappingByID(itemID: IItemData['id']) {
		const scrappingCost = productionRecipeList[itemID]?.scrapping;
		if (this.exists(itemID) && scrappingCost) {
			return scrappingCost;
		} else {
			return this.getAugmentingByID(itemID);
		}
	}

	/**
	 * Simple helper function to check if the itemID is valid and exists
	 *
	 * @param  {IItem['itemID']} itemID
	 * @returns boolean
	 *
	 * @throws {@link Error} if there was no item with that itemID
	 */
	static exists(itemID: IItem['itemID']): boolean {
		return productionRecipeList[itemID] !== undefined;
	}
}
