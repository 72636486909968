import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { ACHIEVEMENT_TO_TALENT_POINTS } from '../../../utils/constantsCollection';

export default function AchivementGuide() {
	return (
		<Box>
			Achievements are fun little goals you can aim for within the game to complete, some of which will reward you
			with talents as well! There will be a few that you can do after you completed the tutorials, but others will
			only be unlocked once you complete the associated quest/requirement they need. Note that if an achievement
			is locked and you do something that would count towards that achievement, it won&apos;t count until its
			unlocked.
			<br />
			Completing achievements will earn you points, which after getting a certain amount, will earn you a talent
			point you can use. (Basically every {ACHIEVEMENT_TO_TALENT_POINTS} achievement points is one talent point.)
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</Box>
	);
}
