import React, { useEffect, useState } from 'react';
import { questList } from '../../../../utils/questList';
import { usePlayerField } from '../../../../hooks/hooks';
import Profile from './Profile';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { useRecoilState } from 'recoil';
import { profileEditorOpenAtom } from '../../../../atoms/profileEditorOpenAtom';
import { socket } from '../../../../services/socket.service';
import { TCosmetics, TEquipmentSlots } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { cloneDeep } from 'lodash';
import { itemList } from '../../../../utils/itemList';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';

export default function ProfileEditor() {
	const username = usePlayerField('username');
	const league = usePlayerField('league');
	const settings = usePlayerField('settings');
	const { profileIcons, profileCosmetics, itemShowcase, achievementShowcase, backgroundLocation, selectedCosmetics } =
		settings.cosmetic;
	const equipment = usePlayerField('equipment');
	const displayedEquipment = getDisplayedEquipment();
	const [hiscoreData, setHiscoreData] = useState<IInspectData['hiscores']>([]);
	const [validDungeons, setValidDungeons] = useState<ILocationData['locID'][]>([]);
	const [dungeonData, setDungeonData] = useState<IInspectData['dungeons']>([]);

	const skills = usePlayerField('skills');
	const questsCompleted = usePlayerField('questsCompleted');
	const achievementPoints = questsCompleted.reduce((acc, quest) => {
		const questData = questList[quest.id];
		if (questData.achievement && questData.questRewards?.achievementPoints) {
			acc += questData.questRewards?.achievementPoints;
		}
		return acc;
	}, 0);

	const [isOpen, setIsOpen] = useRecoilState(profileEditorOpenAtom);

	useEffect(() => {
		socket.on('profile:data:send', (data) => {
			setHiscoreData(data.hiscores);
			setValidDungeons(data.validDungeons);
			setDungeonData(data.dungeons);
		});
		return () => {
			socket.off('profile:data:send');
		};
	}, []);

	useEffect(() => {
		// get hiscore and dungeon data, since that has to be fetched live
		if (isOpen) socket.emit('profile:data:get');
	}, [isOpen]);

	function getDisplayedEquipment() {
		if (Object.keys(profileCosmetics).length > 0) return profileCosmetics;
		for (const cosmeticID of Object.values(selectedCosmetics)) {
			const itemData = itemList[cosmeticID];
			if (itemData.equipmentStats?.fullTransmog) {
				return { [itemData.equipmentStats.slot]: itemData.id };
			}
		}
		const sanitized: TCosmetics = cloneDeep(selectedCosmetics);
		for (const [slotString, item] of Object.entries(equipment)) {
			const slot = slotString as TEquipmentSlots;
			if (!selectedCosmetics[slot]) {
				sanitized[slot] = item.itemID;
			}
		}
		return sanitized;
	}

	return (
		<Profile
			username={username}
			league={league}
			levels={skills}
			cosmetics={displayedEquipment}
			icons={profileIcons}
			achievements={achievementShowcase}
			dungeons={dungeonData}
			validDungeons={validDungeons}
			hiscores={hiscoreData}
			items={itemShowcase}
			achievementPoints={achievementPoints}
			backgroundLocation={backgroundLocation}
			allowEdit={true}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
		/>
	);
}
