import { Box, VStack, Text } from '@chakra-ui/react';
import Tooltip from '../UI/Tooltip';
import { affixList } from '../../../utils/affixList';
import { IAffix } from '../../../../../game-server/src/modules/affixes/affixes.interface';
import { TooltipLiterals } from '../../game/Tooltips/Data/TooltipLiterals';
import { formatAffixStrength } from '../../game/SkillPanels/Runecrafting/AffixTooltip';

export const StatusAffix = (props: IAffix) => {
	const affixData = affixList[props.path];

	return (
		<Box
			borderRadius={'5px'}
			cursor={'pointer'}
			position={'relative'}
			padding={'2px'}
			width={'40px'}
			height={'40px'}
			border={'2px solid #573a6f'}
			backgroundColor={'#1e1829'}
		>
			<Box
				className={`affix_runes ${affixData.imageSheetCSS} ${affixData.imageCSS}`}
				width='32px'
				height='32px'
				alignSelf='center'
			/>
			<Tooltip onClickHover={true}>
				<VStack textAlign={'center'}>
					<Text color={'rgb(201, 30, 207)'} fontSize={'1.5rem'} fontWeight={'bold'} margin={0}>
						{affixData.name.replace('of ', '').replace('the ', '').trim()}{' '}
						{formatAffixStrength(affixData, props.value)}
					</Text>
					<Text>{affixData.longDescription ?? TooltipLiterals.affixesLiterals[props.path]}</Text>
				</VStack>
			</Tooltip>
		</Box>
	);
};
