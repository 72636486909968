import React, { Component } from 'react';
import { socket } from '../../services/socket.service';
import { connect } from 'react-redux';
import './GroupTab.css';

class GroupTab extends Component {
	constructor() {
		super();

		this.state = {
			inputValue: '',
			invitedPlayer: '',
			maxGroupMembers: 5,
			response: {},
			groupFinder: 'NOTHING REQUESTED FROM SERVER',
		};
	}

	componentDidMount() {
		// TODO
		socket.emit('group:requestGroups');
		socket.on('group:sendGroups', (data) => {
			this.setState({ groupFinder: data.groups });
		});
	}

	handleGroupInput = (value) => {
		this.setState({ inputValue: value });
	};

	render() {
		return this.state.groupFinder;
	}
}

const mapStateToProps = function (store) {
	return {
		groupId: store.game.playerInformation.groupId,
		auth: store.auth,
		player: store.game.playerInformation,
		username: store.game.playerInformation.username,
		knownInvites: store.game.playerInformation.groupInvites,
	};
};

export default connect(mapStateToProps)(GroupTab);
