import { atom } from 'recoil';
import { TSkillTab } from '../../../game-server/src/Atypes/Skills';

export const skillTypes = ['gathering', 'production', 'combat'] as const;
export type TSkillTypes = (typeof skillTypes)[number];

export const talentSkillAtom = atom({
	key: 'talentSkillAtom',
	default: null as TSkillTab | TSkillTypes | null,
});
