import React from 'react';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';

interface LoadoutSlotProps {
	loadout: ILoadout;
	setSelectedLoadoutId: (id: number) => void;
	icon: { icon: string; alt: string };
	isSelected: boolean;
}

export const LoadoutSlot = ({ loadout, setSelectedLoadoutId, icon, isSelected }: LoadoutSlotProps) => {
	const name = loadout.name;
	const id = loadout.id;

	return (
		<div
			className={`loadout-slot idlescape-frame-box idlescape-frame-box-interactive ${
				isSelected ? 'selected' : ''
			}`}
			onClick={() => setSelectedLoadoutId(id)}
		>
			<img src={icon.icon} alt={icon.alt} />
			<div className='loadout-slot-name'>{name}</div>
		</div>
	);
};
