import { Heading, Text } from '@chakra-ui/react';

export const PaymentSuccessful = ({ isSubscription }: { isSubscription: boolean }) => {
	const heading = isSubscription ? 'Thank you for subscribing!' : 'Thank you for your purchase!';
	const text = isSubscription
		? 'You are now subscribed to Idlescape Plus. You can now close this page.'
		: 'Platinum has been added to your character. You can now close this page.';

	return (
		<div
			className='email-registered-body page-header'
			style={{
				backgroundImage: 'url(/images/landing_page.png)',
			}}
		>
			<div className='motto text-center'>
				<img className='img-fluid logo mb-5' alt='Idlescape Logo' src={'/images/landing/logo_2.0.png'} />
				<div className='email-registered-box'>
					<Heading>{heading}</Heading>
					<br></br>
					<Text>{text}</Text>
				</div>
			</div>
		</div>
	);
};
