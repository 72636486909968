import React, { useState } from 'react';
import { socket } from '../../../services/socket.service';
import './Settings.css';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import { ChangePassword } from './ChangePassword';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import { socketEmitDebounced } from '../../../helper/helperFunctions';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import { usePlayerField } from '../../../hooks/hooks';
import _ from 'lodash';
import { settingsList } from '../../../utils/settingsList';
import { camelCaseWord } from '../../../helper/StringFormatter';
import { ISettings, TSettingsPath } from '../../../../../game-server/src/modules/player/PlayerSettings';
import useIsMobile from '../../../hooks/useIsMobile';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { Buff } from '../../layout/StatusBar/Buff';

const customCategory: { [key: string]: string } = {
	cooking: 'skilling',
	augmenting: 'skilling',
};

export default function Settings() {
	const accountStatus = usePlayerField('accountStatus');
	const verifiedEmail = usePlayerField('verifiedEmail');
	const playerEmail = usePlayerField('email');
	const claimed = usePlayerField('claimed');
	const settings = usePlayerField('settings');
	const [tempSettings, setTempSettings] = useState(structuredClone(settings));
	const [email, setEmail] = useState(playerEmail);
	const isMobile = useIsMobile();
	const [activeTab, setActiveTab] = useState<keyof ISettings | undefined>(isMobile ? undefined : 'chat');

	function handleInputChange(value: string) {
		setEmail(value);
	}

	function verifyEmail() {
		socket.emit('settings:verifyEmail', { email });
	}

	function formatDescription(description?: string) {
		if (!description) return null;
		const split = description.split('|');
		return (
			<div className='settings-description'>
				{split.map((line, index) => (
					<React.Fragment key={index}>
						{line} {index < split.length - 1 && <br />}
					</React.Fragment>
				))}
			</div>
		);
	}

	function getSlider(settingPath: TSettingsPath) {
		const value = _.get(tempSettings, settingPath) as number;
		const setting = settingsList[settingPath as TSettingsPath];
		const label = setting.name ?? settingPath;
		const description = setting.description;
		const labelFormat = setting.labelFormat ? setting.labelFormat : (curValue: string | number) => curValue;
		const slider = (
			<Slider
				value={value}
				className='settings-slider'
				min={setting.min}
				max={setting.max}
				marks={setting.marks}
				valueLabelDisplay='auto'
				valueLabelFormat={labelFormat}
				onChange={(event, newValue) => sliderCallback(newValue, settingPath)}
				aria-labelledby='continuous-slider'
			/>
		);
		return (
			<div>
				<div className='settings-row settings-row-slider'>
					<div className='settings-label'>{label + ': '}</div>
					{slider}
				</div>
				{formatDescription(description)}
			</div>
		);
	}

	function checkboxCallback(value: boolean | string, settingPath: TSettingsPath) {
		if (_.get(settings, settingPath) === value) return;
		setTempSettings((tempSettings) => {
			const temp = structuredClone(tempSettings);
			_.set(temp, settingPath, value);
			return temp;
		});
		socket.emit('settings:update', {
			value,
			path: settingPath,
		});
	}

	function dropboxCallback(value: number, settingPath: TSettingsPath) {
		if (_.get(settings, settingPath) === value) return;
		setTempSettings((tempSettings) => {
			const temp = structuredClone(tempSettings);
			_.set(temp, settingPath, value);
			return temp;
		});
		socket.emit('settings:update', {
			value,
			path: settingPath,
		});
	}

	function sliderCallback(value: number | number[], settingPath: TSettingsPath) {
		if (_.get(settings, settingPath) === value) return;
		setTempSettings((tempSettings) => {
			const temp = structuredClone(tempSettings);
			_.set(temp, settingPath, value);
			return temp;
		});
		socketEmitDebounced('settings:update', {
			value,
			path: settingPath,
		});
	}

	function getCheckbox(settingPath: TSettingsPath) {
		const value = _.get(tempSettings, settingPath) as boolean;
		const setting = settingsList[settingPath as TSettingsPath];
		const label = setting.name ?? settingPath;
		const description = setting.description;
		const checkbox = <Checkbox className='settings-checkbox' checked={value} name={'settings-' + label} />;
		return (
			<>
				<div
					className='settings-row settings-row-checkbox'
					onClick={() => checkboxCallback(!value, settingPath)}
				>
					<div className='settings-label'>{label + ': '}</div>
					{checkbox}
				</div>
				{formatDescription(description)}
			</>
		);
	}

	function getDropbox(settingPath: TSettingsPath) {
		const value = _.get(tempSettings, settingPath) as number;
		const setting = settingsList[settingPath as TSettingsPath];
		const label = setting.name ?? settingPath;
		const description = setting.description;
		const dropbox = (
			<select
				className='settings-dropbox browser-default'
				onChange={(e) => dropboxCallback(Number(e.target.value), settingPath)}
				name={settingPath}
				defaultValue={value}
			>
				{(setting.entries ?? []).map((item, index) => {
					return (
						<option key={'threat-profile-map-' + index} value={Number(index)}>
							{item}
						</option>
					);
				})}
			</select>
		);
		return (
			<>
				<div className='settings-row'>
					<div className='settings-label'>{label + ': '}</div>
					{dropbox}
				</div>
				{formatDescription(description)}
			</>
		);
	}

	function getTextbox(settingPath: TSettingsPath) {
		const value = _.get(settings, settingPath) as string;
		const setting = settingsList[settingPath as TSettingsPath];
		const label = setting.name ?? settingPath;
		if (settingPath === 'security.email') {
			let isVerified = <div className='settings-email-not-verified'>Not verified</div>;
			if (verifiedEmail) {
				isVerified = <div className='settings-email-verified'>Verified</div>;
			}
			return (
				<div className='settings-row'>
					{isVerified}
					<div className='settings-label'>{label + ': '}</div>
					<IdlescapeInput
						value={email ?? undefined}
						setValue={handleInputChange}
						className='settings-textbox'
						name={'settings-' + label}
						type='email'
					/>
					<IdlescapeButton variant='green' onClick={verifyEmail} marginRight='10px'>
						Verify
					</IdlescapeButton>
				</div>
			);
		} else {
			return (
				<div className='settings-row'>
					<div className='settings-label'>{label + ': '}</div>
					<input value={value} className='settings-textbox' name={'settings-' + label} />
				</div>
			);
		}
	}

	function renderBlacklistedBuffs() {
		return (
			<>
				<h2>Blacklisted Buffs</h2>
				<IdlescapeContainer>
					{settings.miscellaneous.blacklistedBuffs.length === 0 && (
						<div className='settings-row'>
							<div className='settings-label'>No buffs are currently blacklisted</div>
						</div>
					)}
					{settings.miscellaneous.blacklistedBuffs.map((buff) => {
						const enchantmentData = enchantmentsList[buff];
						if (!enchantmentData) {
							return null;
						}
						return (
							<Buff
								key={'status-bar-active-effects-buff-' + buff}
								source={'Placeholder Blacklisted Buff'}
								enchantmentID={enchantmentData.id}
								enchantmentStrength={1}
							/>
						);
					})}
				</IdlescapeContainer>
			</>
		);
	}

	if (settings == null) {
		return (
			<div className='settings-panel'>
				<div className='settings-header'>Loading</div>
			</div>
		);
	}

	const allSettings: { [category: string]: { [subcategory: string]: React.ReactNode[] } } = {};

	for (const [key, setting] of Object.entries(settingsList)) {
		if (!setting.inSettingsPage) continue;
		if (isMobile && setting.noMobileView) continue;
		const settingsPath = key as TSettingsPath;
		const subcategory = key.split('.')[0];
		const category = customCategory[subcategory] ?? subcategory;
		if (setting.modOnly && accountStatus === 'normal') continue;
		if (!allSettings[category]) {
			allSettings[category] = {};
		}
		if (activeTab !== category) continue;
		if (!allSettings[category][subcategory]) {
			allSettings[category][subcategory] = [];
		}
		const categorySettings = allSettings[category][subcategory];

		if (setting.entries) {
			categorySettings.push(getDropbox(settingsPath));
			continue;
		}
		switch (typeof setting.default) {
			case 'boolean':
				categorySettings.push(getCheckbox(settingsPath));
				break;
			case 'string':
				categorySettings.push(getTextbox(settingsPath));
				break;
			case 'number':
				categorySettings.push(getSlider(settingsPath));
				break;
		}
	}
	const categorySettings = activeTab ? allSettings[activeTab] : undefined;
	return (
		<div className={`settings-container settings-category-${activeTab ?? 'none'}`}>
			<div className='shop-categories'>
				{Object.keys(allSettings).map((elem) => {
					return (
						<div
							className='shop-category idlescape-frame-panel idlescape-frame-panel-dark idlescape-frame-panel-interactive'
							key={'shop-category-' + elem}
							data-active={activeTab === elem}
							onClick={() => {
								setActiveTab(elem as keyof ISettings);
							}}
						>
							{camelCaseWord(elem)}
						</div>
					);
				})}
			</div>
			{isMobile && (
				<IdlescapeButton
					className='settings-mobile-back-button'
					variant='red'
					onClick={() => setActiveTab(undefined)}
				>
					Back
				</IdlescapeButton>
			)}
			<IdlescapeContainer className='settings-panel'>
				<div className='settings-section'>
					{categorySettings &&
						Object.entries(categorySettings).map(([title, subcategory]) => (
							<React.Fragment key={title}>
								<div className='settings-header'>{camelCaseWord(title)}</div>
								{subcategory &&
									subcategory.map((settingsField, i) => {
										return <React.Fragment key={i}>{settingsField}</React.Fragment>;
									})}
								{activeTab === 'security' && claimed && (
									<>
										<ChangePassword />
										<TwoFactorAuthentication />
									</>
								)}
								{activeTab === 'miscellaneous' && renderBlacklistedBuffs()}
							</React.Fragment>
						))}
				</div>
			</IdlescapeContainer>
		</div>
	);
}
