import { useDisclosure } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useMemo, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { TTrueInventoryType } from '../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { shareItemSelector } from '../../../atoms/chat/chatHistoryAtom';
import { Item, ItemProps } from './Item';
import './Item.css';
import MainItemDialog from './ItemDialogs/MainItemDialog';

export interface IItemOverwriteDialogProps {
	item: IItem;
	onClose: () => void;
	inventoryName: TTrueInventoryType;
}

export default function InventoryItem({
	item,
	renameTokenId,
	overwriteDialog: OverwriteDialog,
	overrideOnClick,
	inventoryName,
	allowAnimation,
	updaterList = [],
	...props
}: {
	item: IItem;
	renameTokenId?: IItem['id'];
	overwriteDialog?: React.ComponentType<IItemOverwriteDialogProps>;
	overrideOnClick?: (event: React.MouseEvent) => boolean; // return true to prevent default behavior
	inventoryName?: TTrueInventoryType;
	allowAnimation?: boolean;
	updaterList?: (string | number | boolean)[];
} & ItemProps) {
	const shareItem = useSetRecoilState(shareItemSelector);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const doAction = useDisclosure();
	const counter = useRef(0);

	// Drag and Drop stuff
	const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
		id: item.id.toString(),
	});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		zIndex: active ? 1 : undefined,
		opacity: active && active.id === item.id.toString() ? 0.5 : undefined,
	} as React.CSSProperties;

	function handleOnClick(e: React.MouseEvent) {
		if (overrideOnClick !== undefined) {
			const preventDefault = overrideOnClick(e);
			if (preventDefault) return;
		}
		if (e.ctrlKey || e.metaKey) {
			shareItem(item.id.toString());
		} else if (e.shiftKey) {
			// Counter to render a new empty dialog when going too fast
			counter.current++;
			doAction.onOpen();
		} else {
			onOpen();
		}
	}

	function renderItem() {
		return (
			<Item
				item={item}
				onClick={handleOnClick}
				ref={setNodeRef}
				allowAnimation={allowAnimation}
				showTooltip={!OverwriteDialog}
				mobileTooltip={false}
				{...listeners}
				{...attributes}
				{...props}
			/>
		);
	}

	return (
		// Remove drag animation styles to make it faster, also change <div> to <></>
		<div style={style} className='item-box'>
			{useMemo(renderItem, updaterList.concat(item.stackSize))}
			{isOpen &&
				(OverwriteDialog !== undefined ? (
					<OverwriteDialog item={item} onClose={onClose} inventoryName={inventoryName ?? 'stockpile'} />
				) : (
					<MainItemDialog
						item={item}
						inventoryName={inventoryName ?? ''}
						renameTokenId={renameTokenId}
						onClose={onClose}
					/>
				))}
			{doAction.isOpen && (
				<MainItemDialog
					key={counter.current}
					item={item}
					inventoryName={inventoryName ?? ''}
					onClose={doAction.onClose}
					doAction={true}
				/>
			)}
		</div>
	);
}
