import React from 'react';
import { usePlayerField } from '../../../hooks/hooks';
import { CombatAvatar } from './CombatAvatar2';
import CombatInfoBar from './CombatInfoBar';

export default function CombatPlayerArea() {
	const currentHealth = usePlayerField('currentHealth');
	const combatStats = usePlayerField('combatStats');
	const equipment = usePlayerField('equipment');
	const settings = usePlayerField('settings');
	const username = usePlayerField('username');
	const id = usePlayerField('id');

	return (
		<div className='combat-player-area'>
			<div>
				<CombatInfoBar
					id={id}
					name={username}
					currentHealth={currentHealth}
					maxHealth={combatStats.maxHealth}
					type='player'
				/>
				<CombatAvatar
					equipment={equipment}
					cosmetics={settings.cosmetic.selectedCosmetics}
					showSplashes={true}
					id={id}
				/>
			</div>
		</div>
	);
}
