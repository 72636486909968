import './Quests.css';
import { usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import QuestList from './QuestList';
import QuestMain from './QuestMain';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react';
import { questAtom } from '../../../atoms/questAtom';
import { useRecoilState } from 'recoil';
import { IdlescapeButton } from '@idlescape/ui';
import useIsMobile from '../../../hooks/useIsMobile';
import { useEffect } from 'react';
import { questList } from '../../../utils/questList';

/**
 * This is used for both Quests AND Achievements
 */
export default function Quests({ isAchievements = false }: { isAchievements: boolean }) {
	const [currentQuest, setCurrentQuest] = useRecoilState(questAtom);
	const isMobile = useIsMobile();
	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	if (!leagueData?.rules?.quests) {
		return <>You cannot do quests in this league or season.</>;
	}

	useEffect(() => {
		const quest = questList[currentQuest.quest];
		if (quest && !!quest.achievement !== isAchievements) {
			setCurrentQuest({ quest: 0, step: 0 });
		}
	});

	function renderQuestList() {
		const questList = <QuestList league={league} isAchievements={isAchievements} />;
		return questList;
	}

	if (isMobile) {
		return (
			<Flex className='quest-container' height='100%' overflow='hidden' flexDirection='column'>
				<Box
					className='quest-list'
					position='relative'
					height='calc(100% - 20px)'
					width='calc(100% - 20px)'
					fontSize='16px'
					backgroundImage='linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.1) 90%, rgba(56, 56, 56, 0.3) 100%), url(/images/ui/stone_ui_tile_dark_gray.png)'
					filter='drop-shadow(0 0 0.45rem black)'
					border='grey 2px solid'
					borderRadius='10px'
					padding='2px'
					margin='10px auto'
					flex='0'
				>
					<Accordion allowToggle variant='default' height='100%'>
						<AccordionItem className='mobile-quest-accordion-item' height='100%'>
							{({ isExpanded }) => (
								<>
									<AccordionButton
										fontWeight='bold'
										border='none'
										backgroundColor='transparent'
										className='idlescape-button idlescape-button-blue'
										width='calc(100% - 45px)'
										marginLeft='10px'
										_hover={{}}
									>
										{isExpanded ? 'View Quest' : 'Open List'}
									</AccordionButton>
									<AccordionPanel height='calc(100% - 40px)' overflowY='auto' paddingTop='2px'>
										<Flex
											className='quest-step'
											justifyContent='center'
											alignItems='center'
											onClick={() => setCurrentQuest({ quest: 0, step: 0 })}
											cursor='pointer'
										>
											Summary
										</Flex>
										{renderQuestList()}
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					</Accordion>
				</Box>
				<Box flex='1' overflowY='auto'>
					<QuestMain isAchievements={isAchievements} />
				</Box>
			</Flex>
		);
	}

	return (
		<Flex className='quest-container' height='100%' overflow='hidden'>
			<Box
				className='quest-list'
				position='relative'
				height='calc(100% - 20px)'
				fontSize='16px'
				minWidth='160px'
				maxWidth='320px'
				backgroundImage='linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.1) 90%, rgba(56, 56, 56, 0.3) 100%), url(/images/ui/stone_ui_tile_dark_gray.png)'
				filter='drop-shadow(0 0 0.45rem black)'
				border='grey 2px solid'
				borderRadius='10px'
				padding='2px'
				margin='10px'
				flex='1'
			>
				<IdlescapeButton
					textAlign='center'
					margin='0 5px'
					onClick={() => setCurrentQuest({ quest: 0, step: 0 })}
					width='calc(100% - 40px)'
					variant='blue'
				>
					Summary
				</IdlescapeButton>
				<Box height='calc(100% - 40px)' overflowY='auto'>
					{renderQuestList()}
				</Box>
			</Box>
			<Box height='100%' flex='2' overflowY='auto'>
				<QuestMain isAchievements={isAchievements} />
			</Box>
		</Flex>
	);
}
