import {
	Box,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';

export default function Book() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [popupMessage, setPopupMessage] = useState('');
	const [popupTitle, setPopupTitle] = useState('Empty Book');

	useEffect(() => {
		socket.on('books:send', (data) => {
			setPopupMessage(data.message);
			setPopupTitle(data.title);
			onOpen();
		});

		return () => {
			socket.off('books:send');
		};
	}, []);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
			<ModalOverlay />
			<ModalContent className='feedback-dialog sell-item-dialog popup-dialog book-dialog'>
				<ModalHeader>{popupTitle}</ModalHeader>
				<ModalBody>
					<Box dangerouslySetInnerHTML={{ __html: popupMessage }}></Box>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={onClose} size='medium'>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
