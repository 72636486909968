import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { socket } from '../../../../services/socket.service';
import { IDialogProps } from './MainItemDialog';

function VendorDialog(props: IDialogProps) {
	const totalValue = (props.itemData?.value ?? 0) * props.sliderValue;

	function handleSellItem() {
		socket.emit('consumable:sell', {
			inventoryItemID: props.item.id,
			consumeCount: props.sliderValue,
		});
		props.onClose();
	}
	return (
		<Modal isOpen={true} onClose={props.onClose} size='lg'>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Vendor items</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to sell {props.sliderValue.toLocaleString('en-us')} {props.name} for{' '}
					{totalValue?.toLocaleString('en-us')}{' '}
					<img src='/images/gold_coin.png' alt='coins' className='icon16' />?
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleSellItem}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default VendorDialog;
