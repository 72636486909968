import { AtomEffect } from 'recoil';

export const localStorageEffect: <T>(key: string, validate: (parsed: string) => boolean) => AtomEffect<T> =
	(key: string, validate: ((parsed: string) => boolean) | undefined = undefined) =>
	({ setSelf, onSet }) => {
		const savedValue = localStorage.getItem(key);
		if (savedValue != null) {
			const newValue = JSON.parse(savedValue);
			if (validate !== undefined && !validate(newValue)) {
				localStorage.removeItem(key);
			} else {
				setSelf(newValue);
			}
		}

		onSet((newValue, _, isReset) => {
			if (isReset) {
				localStorage.removeItem(key);
			} else {
				try {
					localStorage.setItem(key, JSON.stringify(newValue));
				} catch (e) {
					console.error(e);
				}
			}
		});
	};
