import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.css';
import { logoutUser, setCurrentUser } from './actions/authActions';
import EmailRegistered from './components/auth/EmailRegistered';
import EmailRegisteredFail from './components/auth/EmailRegisteredFail';
import { PaymentSuccessful } from './components/auth/PaymentSuccessful';
import { Game } from './components/game/Game';
import { CharactersMenu } from './components/character-menu/CharactersMenu';
import { ProtectedRoute } from './components/private-route/PrivateRoute';
import store from './store';
import setAuthToken from './helper/setAuthToken';
import { PaymentFailed } from './components/auth/PaymentFailed';
import accordionTheme from './chakra-themes/AccordionTheme';
import modalTheme from './chakra-themes/ModalTheme';
import tabsTheme from './chakra-themes/TabsTheme';
import DOMPurify from 'dompurify';
import { ICharacterJWTData } from '../../game-server/src/modules/session/SessionStarter';
import { Login } from './components/login/Login';
import { initializeExperienceTables } from './utils/experienceFunctions';
import { gameUrls, openGameUrl } from './GameUrls';
import axios from 'axios';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTM_ID ?? '',
};
TagManager.initialize(tagManagerArgs);

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};
const theme = extendTheme({
	config,
	styles: {
		global: {
			'html, body': {
				color: 'white',
			},
			'span, div': {
				fontWeight: 'unset',
			},
		},
	},
	fonts: {
		heading: 'Exo 2',
		body: 'Exo 2',
	},
	components: {
		Accordion: accordionTheme,
		Modal: modalTheme,
		Tabs: tabsTheme,
	},
});

const App = () => {
	// Create cache
	initializeExperienceTables();
	useEffect(() => {
		const checkAuth = async () => {
			try {
				const response = await axios.get('/api/auth/get-token', { withCredentials: true });
				const decoded: ICharacterJWTData = jwtDecode(response.data.token);
				// Set user and isAuthenticated
				setAuthToken(response.data.token);
				store.dispatch(setCurrentUser(decoded));
			} catch (error) {
				console.log('Error checking auth', error);
				// Logout user
				logoutUser(store.dispatch);
				// Redirect to login
				openGameUrl('login');
			}
		};
		checkAuth();
	}, [store.dispatch]);

	useEffect(() => {
		// https://github.com/cure53/DOMPurify/issues/317#issuecomment-670816926
		DOMPurify.addHook('afterSanitizeAttributes', function (node) {
			if ('target' in node) {
				node.setAttribute('target', '_blank');
				node.setAttribute('rel', 'noopener');
			}
		});
	}, []);

	return (
		<ChakraProvider resetCSS={false} theme={theme}>
			<RecoilRoot>
				<Provider store={store}>
					<Router>
						<div className='App'>
							<Routes>
								<Route
									path={gameUrls.game}
									element={
										<ProtectedRoute>
											<Game></Game>
										</ProtectedRoute>
									}
								/>
								<Route
									path={gameUrls.characters}
									element={
										<ProtectedRoute>
											<CharactersMenu />
										</ProtectedRoute>
									}
								/>
								<Route path={gameUrls.login} element={<Login />} />
								<Route path='/game' element={<Navigate to={gameUrls.game} replace={true} />} />
								<Route
									path='/recover-password'
									element={<Navigate to={gameUrls.recoverPassword} replace={true} />}
								/>

								<Route path={gameUrls.verificationComplete} element={<EmailRegistered />} />
								<Route path={gameUrls.verificationFailed} element={<EmailRegisteredFail />} />
								<Route
									path={gameUrls.paymentSuccess}
									element={<PaymentSuccessful isSubscription={false}></PaymentSuccessful>}
								/>
								<Route
									path={gameUrls.subscriptionSuccess}
									element={<PaymentSuccessful isSubscription={true}></PaymentSuccessful>}
								/>
								<Route path={gameUrls.paymentFail} element={<PaymentFailed></PaymentFailed>} />
							</Routes>
						</div>
					</Router>
				</Provider>
			</RecoilRoot>
		</ChakraProvider>
	);
};

export default App;
