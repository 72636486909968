import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { IdlescapeInput } from '@idlescape/ui';
import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IChannelSettings } from '../../../../../game-server/src/modules/chat/Chat.interface';
import { activeChannelAtom } from '../../../atoms/activeChannelAtom';
import { chatHistoryIndexSelector, chatInputSelector } from '../../../atoms/chat/chatHistoryAtom';
import { messageAtom } from '../../../atoms/chat/messageAtom';
import { gameUrls } from '../../../GameUrls';
import { getSocket } from '../../../services/socket.service';
import ChatEventCooldown, { ChatEventInactiveTimer } from './ChatEventCooldown';
import ChatModPingConfirmation from './ChatModPingConfirmation';

const ChatInputField = () => {
	const setMessageHistoryIndex = useSetRecoilState(chatHistoryIndexSelector);
	const [message, setMessage] = useRecoilState(chatInputSelector);
	const clearInput = useResetRecoilState(chatInputSelector);

	const prevMessage = useRef(message);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const inputRef = useRef<HTMLInputElement>(null);

	const channels = useRecoilValue(messageAtom);

	const activeChannelIndex = useRecoilValue(activeChannelAtom);

	const channelKeys = Object.keys(channels);

	const currentChannelSettings: IChannelSettings | undefined =
		channelKeys.length > 0 ? channels[channelKeys[activeChannelIndex]]?.[0] : undefined;

	useEffect(() => {
		if (prevMessage.current !== message) {
			setInputActive();
			prevMessage.current = message;
		}
		// Can't share marketlistings outside of trade channel
		if (message.match(/(\|\$[\d]*\|)/g)) {
			// TODO: crashes when the user isn't in the trade channel
			// setActiveChannelIndex(channelKeys.indexOf('2'));
		}
	}, [message]);

	const handleSubmit = (event: React.BaseSyntheticEvent | null, confirmedModsPing = false) => {
		if (message === '') {
			return;
		}

		if (message.trim() === '') {
			setMessage('');
			return;
		}

		if (!currentChannelSettings) {
			return;
		}

		const socket = getSocket();

		/** Check if it is a command or not */
		if (message.trim().startsWith('/')) {
			return handleCommands();
		}
		if (!confirmedModsPing && message.trim().toLowerCase().includes('@mod')) {
			onOpen();
			event?.preventDefault();
			return;
		}

		const messageToSend = {
			channelId: currentChannelSettings.channelId,
			channelName: currentChannelSettings.channelName,
			message: message.trim(),
		};

		socket.emit('chat:message:send', messageToSend);

		clearInput();
	};

	const handleCommands = () => {
		if (!currentChannelSettings) {
			return;
		}

		const trimmedMessage = message.trim();

		if (trimmedMessage.startsWith('/wiki')) {
			const words = trimmedMessage.split(' ');
			if (words.length === 1) {
				window.open(gameUrls.wiki, '_blank');
			} else {
				words.shift();
				const keywords = words.join('+');
				window.open(`${gameUrls.wiki}index.php?search=${keywords}`, '_blank');
			}
		} else if (trimmedMessage === '/guide') {
			window.open(`${gameUrls.wiki}p/Getting_Started`, '_blank');
		} else if (trimmedMessage === '/discord') {
			window.open(gameUrls.discord, '_blank');
		} else if (trimmedMessage === '/rules') {
			window.open(gameUrls.rules, '_blank');
		} else {
			const socket = getSocket();
			const commandToSend = {
				channelId: currentChannelSettings.channelId,
				channelName: currentChannelSettings.channelName,
				commandString: trimmedMessage,
			};

			socket.emit('chat:command:send', commandToSend);
		}

		clearInput();
		return;
	};

	const handleKeyPresses = (event: KeyboardEvent) => {
		const key = event.key;
		switch (key) {
			case 'Enter':
				handleSubmit(event);
				break;
			case 'ArrowUp':
				setMessageHistoryIndex((oldIndex) => oldIndex + 1);
				event.preventDefault();
				break;
			case 'ArrowDown':
				setMessageHistoryIndex((oldIndex) => oldIndex - 1);
				event.preventDefault();
				break;
			default:
				break;
		}
	};

	const setInputActive = () => {
		inputRef.current?.focus();
	};

	if (currentChannelSettings?.channelName === 'Activity') return null;
	return (
		<>
			<Flex>
				<Box flex='1'>
					<ChatEventCooldown />
					<IdlescapeInput
						variant='simple'
						ref={inputRef}
						value={message}
						onSubmit={handleSubmit}
						contentEditable
						setValue={setMessage}
						type='text'
						placeholder='Send a message...'
						autoComplete='off'
						height='30px'
						onKeyUp={handleKeyPresses}
						disableEsc
					/>
				</Box>
				<ChatEventInactiveTimer />
			</Flex>
			<ChatModPingConfirmation isOpen={isOpen} onClose={onClose} confirm={() => handleSubmit(null, true)} />
		</>
	);
};

export default ChatInputField;
