import { useEffect } from 'react';
import _ from 'lodash';
import { socket } from './socket.service';
import { itemList } from '../utils/itemList';
import { setPlayerInformation } from '../actions/gameActions';
import { IItem, IItemData } from '../../../game-server/src/modules/items/items.interface';
import { useAppDispatch } from '../hooks/hooks';
import { IItemSource } from '../../../game-server/src/modules/stat-tracking/StatTracking.interface';
import { PlayerData } from '../../../game-server/src/modules/player/PlayerData';
import { IChangedItem } from '../../../game-server/src/modules/transaction/TransactionJobs';
import { TTrueInventoryType } from '../../../game-server/src/modules/items/Inventory.interface';
import { enchantmentsList } from '../utils/enchantmentList';
import { enchantmentOrder } from '../components/game/Tooltips/UI/LegacyTooltipPresenter';

let user: PlayerData | null = null;

export default function PlayerManager({
	loaded,
	setLoaded,
}: {
	loaded?: boolean;
	setLoaded: (value: boolean) => void;
}) {
	const dispatch = useAppDispatch();

	useEffect(() => {
		console.log('Player Manager started');
		socket.on('update:player', (data) => {
			if (!loaded && data.portion !== 'all') {
				console.log('Player Manager received ' + data.portion + ' before all data was loaded.');
				return;
			}
			handlePlayerUpdate(data);
		});

		socket.on('update:player:paths', (data) => {
			if (!loaded) {
				return;
			}
			handlePlayerPathsUpdate(data);
		});

		socket.on('update:inventory', (data) => {
			if (!loaded) {
				return;
			}
			handleInventoryUpdate(data);
		});

		socket.on('update:lootlog', (data) => {
			if (!loaded) {
				return;
			}
			handleLootlogUpdate(data);
		});

		return () => {
			console.log('Player Manager stopped working');
			socket.off('update:player');
			socket.off('update:player:paths');
			socket.off('update:inventory');
			socket.off('update:lootlog');
		};
	}, [loaded]);

	function handlePlayerUpdate(player: {
		portion: keyof PlayerData | 'all';
		value: Partial<PlayerData> | PlayerData[keyof PlayerData];
	}) {
		if (player.portion.includes('vault') || player.portion.includes('stockpile')) {
			if (player.value) (player.value as IItem[]).sort(itemOrder);
		}

		if (player.portion === 'all') {
			if (player.value) {
				// Need this for sort settings
				user = player.value as PlayerData;

				user.stockpile.sort(itemOrder);

				user.vault.sort(itemOrder);

				dispatch(setPlayerInformation(user));

				if (!loaded) {
					setLoaded(true);
					console.log('Player Manager loaded player data from server');
				}
			}
		} else {
			if (!user) return;
			// Already the same
			if (_.isEqual(user[player.portion as keyof PlayerData], player.value)) return;
			Object.assign(user, { [player.portion]: player.value });
			dispatch(setPlayerInformation(user));
		}
	}

	function handlePlayerPathsUpdate<K extends keyof PlayerData, S extends keyof PlayerData[K]>(player: {
		key: K;
		value: { [key in S]?: PlayerData[K][S] };
	}) {
		if (!user) return;
		let unchanged = true;
		for (const [path, value] of Object.entries(player.value)) {
			const fullPath = player.key + '.' + path;
			// Exception to force a re-render
			if (path === 'abilities') unchanged = false;
			if (_.isEqual(_.get(user, fullPath), value)) continue;
			_.set(user, fullPath, value);
			unchanged = false;
		}
		if (unchanged) return;
		// Need to replace the key to re-render
		const userData = structuredClone(user[player.key]);
		user[player.key] = userData;
		dispatch(setPlayerInformation(user));
	}

	function handleInventoryUpdate(data: { inventory: TTrueInventoryType; changedItems: IChangedItem[] }) {
		if (!user) return;
		const inventory = _.cloneDeep(user[data.inventory]);
		// Only sort these inventories
		const sortInventory: TTrueInventoryType[] = ['stockpile', 'vault'];
		const sortByOrder: TTrueInventoryType[] = ['augmentingItemSlot', 'tacklebox'];

		for (const change of data.changedItems) {
			switch (change.job) {
				case 'ADD':
					inventory.push(change.item as IItem);
					break;
				case 'MODIFY': {
					const index = inventory.findIndex((item) => item.id === change.item?.id);
					if (index !== -1) {
						if (change.item?.stackSize) {
							inventory[index] = change.item;
						} else {
							inventory.splice(index, 1);
						}
					} else {
						// Item doesn't exist on client, possible desync, get all data
						socket.emit('chat:command:send', { channelId: 1, commandString: '/reloadui' });
					}
					break;
				}
			}
		}
		if (sortInventory.includes(data.inventory)) {
			inventory.sort(itemOrder);
		} else if (sortByOrder.includes(data.inventory)) {
			inventory.sort((a, b) => a.order - b.order);
		}

		user[data.inventory] = inventory;

		dispatch(setPlayerInformation(user));
	}

	function handleLootlogUpdate(lootlog: IItemSource[]) {
		if (!user || lootlog.length === 0) return;
		const newLootlog = structuredClone(user.lootlog);
		for (const newLog of lootlog) {
			const idx = newLootlog.findIndex(
				(log) =>
					log.location === newLog.location &&
					log.sourceName === newLog.sourceName &&
					log.sourceType === newLog.sourceType
			);
			if (idx !== -1) {
				newLootlog[idx] = newLog;
			} else {
				newLootlog.push(newLog);
			}
		}
		if (_.isEqual(user.lootlog, newLootlog)) return;
		user.lootlog = newLootlog;
		dispatch(setPlayerInformation(user));
	}

	return null;
}

export function itemOrder(a: IItem, b: IItem): number {
	let legacySorting = true;
	let sortByValue = false;
	if (user !== null) {
		legacySorting = user.settings?.miscellaneous?.useLegacySorting;
		sortByValue = user.settings?.miscellaneous?.sortByValue;
	}
	if (legacySorting) {
		if (a.itemID == b.itemID && a.name && b.name) {
			a.name = String(a.name);
			b.name = String(b.name);
			const an = a.name.toLowerCase();
			const bn = b.name.toLowerCase();
			//return a.augmentations < b.augmentations ? -1 : (an < bn ? -1 : (an > bn ? 1 : 0));
			return an < bn ? -1 : an > bn ? 1 : 0;
		}
		return a.itemID - b.itemID;
	}
	const itemA = itemList[a.itemID];
	const itemB = itemList[b.itemID];
	if (getClass(itemA) !== getClass(itemB)) {
		return getClass(itemA) > getClass(itemB) ? 1 : -1;
	}
	if (a.itemID === b.itemID) {
		if ((a.augmentations ?? 0) !== (b.augmentations ?? 0)) {
			return (a.augmentations ?? 0) - (b.augmentations ?? 0);
		}
		if (a.enchantmentID && b.enchantmentID) {
			const aData = enchantmentsList[a.enchantmentID];
			const bData = enchantmentsList[b.enchantmentID];
			const aType = enchantmentOrder[aData.relatedSkills[0]];
			const bType = enchantmentOrder[bData.relatedSkills[0]];
			return aType - bType;
		}
		if (a.enchantmentID || b.enchantmentID) return a.enchantmentID ? 1 : -1;
		return a.id - b.id;
	}
	// scrolls
	if (itemA.enchantmentID && itemB.enchantmentID) {
		const aData = enchantmentsList[itemA.enchantmentID];
		const bData = enchantmentsList[itemB.enchantmentID];
		const aType = enchantmentOrder[aData.relatedSkills[0]];
		const bType = enchantmentOrder[bData.relatedSkills[0]];
		if (aType !== bType) return aType - bType;
	}
	if (itemA.equipmentStats?.slot && itemB.equipmentStats?.slot) {
		const slotA = getSlot(itemA);
		const slotB = getSlot(itemB);
		if (slotA !== slotB) {
			return slotA > slotB ? 1 : -1;
		}
		if (itemA.value !== itemB.value) {
			return (itemA.value ?? 0) - (itemB.value ?? 0);
		}
	}
	if (sortByValue && itemA.value !== itemB.value) {
		return (itemA.value ?? 0) - (itemB.value ?? 0);
	}
	return a.itemID - b.itemID;
}

function getClass(item: IItemData) {
	const input = item.class;
	if (input === 'bar') {
		return 'oreBar';
	}
	if (input === 'christmas-drop') {
		return 'z' + input;
	}
	if (input === 'christmas-food') {
		return 'z' + input;
	}
	if (input === 'christmas-present') {
		return 'z' + input;
	}
	return input ?? '';
}

function getSlot(item: IItemData) {
	const slot = item.equipmentStats?.slot ?? 'arrows';
	switch (slot) {
		case 'cape':
			return 'equipA';
		case 'weapon':
			if (item.equipmentStats?.oneHanded) return 'equipB';
			return 'equipBB';
		case 'shield':
			return 'equipC';
		case 'helm':
			return 'equipD';
		case 'body':
			return 'equipE';
		case 'legs':
			return 'equipF';
		case 'boots':
			return 'equipG';
		case 'gloves':
			return 'equipH';
		case 'necklace':
			return 'equipI';
		case 'ring':
			return 'equipJ';
		default:
			return slot;
	}
}
