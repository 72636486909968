import { IdlescapeFrameBox } from '@idlescape/ui';
import React from 'react';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { getAugmentingCost } from '../../../../../utils/augmentingFunctions';
import { itemList } from '../../../../../utils/itemList';
import { CraftingAugmentingData } from '../../../CraftingAugmenting/CraftingAugmentingData';
import ResourceCost from '../../ResourceCost';

export default function AugmentingCost(props: { item: IItem | undefined }) {
	let augmentingCost: { id: number; amount: number }[] | undefined = undefined;
	if (!props.item) return null;
	const itemData = itemList[props.item.itemID];
	const baseCost = CraftingAugmentingData.getScrappingByID(props.item?.itemID);
	if (!baseCost) return null;

	augmentingCost = getAugmentingCost(props.item, itemData, baseCost);

	if (!augmentingCost || !augmentingCost.length) return null;
	const resourceCosts = augmentingCost.map((cost) => {
		return { resource: itemList[cost.id], count: cost.amount };
	});

	return (
		<IdlescapeFrameBox
			variant='primary'
			padding='8px 15px'
			width='100%'
			_hover={{}} // This overrides the default hover effect to get rid of it
		>
			<ResourceCost resourceCosts={resourceCosts} />
		</IdlescapeFrameBox>
	);
}
