import GeneralShopItem from './GeneralShopItem';
import './Marketplace.css';
import { gameShopList } from '../../../utils/gameShopList';
import React from 'react';
import { usePlayerField, usePlayerResourceStockpileAmount } from '../../../hooks/hooks';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';

export default function GeneralShop() {
	const league = usePlayerField('league');
	const gold = usePlayerResourceStockpileAmount(itemsIds.gold);

	return (
		<div className='marketplace-game-shop'>
			{Object.values(gameShopList).map((item) => {
				if (item?.purchaseRequirements?.league && !item?.purchaseRequirements?.league.includes(league)) {
					return null;
				}
				return <GeneralShopItem key={item.id} gold={gold} gameShopItem={item} />;
			})}
		</div>
	);
}
