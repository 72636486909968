import React, { useEffect, useState } from 'react';
import { ICombatInstanceStats } from '../../../../../game-server/src/modules/combat/Combat.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { abilities } from '../../../utils/abilityList';
import { IdlescapeTooltip } from '@idlescape/ui';
import './Combat.css';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Image,
} from '@chakra-ui/react';
import { monsters } from '../../../utils/monsterList';

export function CombatInstanceStats() {
	const [stats, setStats] = useState<ICombatInstanceStats>({
		charactersKilled: {},
		characterDamage: {},
		instanceStartTime: 0,
	});
	const subscription = usePlayerField('subscription');

	useEffect(() => {
		socket.emit('combat:requestInstanceStats');
		socket.on('combat:instanceStats', (stats) => {
			setStats(stats);
		});
		return () => {
			socket.off('combat:instanceStats');
		};
	}, []);

	function subscribed() {
		return subscription.active;
	}

	if (!subscribed()) {
		return <div>You must be subscribed to access this.</div>;
	}

	function renderStats() {
		return (
			<div className='combat-instance-stats'>
				<Accordion allowToggle variant='default' height='100%' width='100%'>
					<AccordionItem height='100%'>
						<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
							<div className='combat-instance-header'>Kill Counts</div>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
							<>{renderKilled()}</>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
				<hr></hr>
				<Accordion allowToggle variant='default' height='100%' width='100%'>
					<AccordionItem height='100%'>
						<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
							<div className='combat-instance-header'>Damage Dealt</div>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
							<>{renderDamage()}</>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</div>
		);
	}

	function renderKilled() {
		const rows: React.ReactElement[] = [];
		for (const source in stats.charactersKilled) {
			const monsterData = Object.values(monsters).find((m) => m.name === source);
			const rowsHere: React.ReactElement[] = [];
			const kills = stats.charactersKilled[source];
			for (const target in kills) {
				const monsterData = Object.values(monsters).find((m) => m.name === target);
				const count = kills[target];
				rowsHere.push(
					<div className='combat-instance-monster-killcount'>
						<Image
							className={`combat-instance-monster-image ${source}-${target}`}
							src={monsterData?.image ?? '/images/ui/group_icon.png'}
						></Image>
						<div>
							{target} - {count.toLocaleString('en-us')}
						</div>
					</div>
				);
			}
			rows.push(
				<Accordion allowToggle variant='default' height='100%' width='100%'>
					<AccordionItem height='100%'>
						<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
							<div className='combat-instance-monster-header'>
								<Image
									className={`combat-instance-monster-image ${source}`}
									src={monsterData?.image ?? '/images/ui/group_icon.png'}
								></Image>
								{source}
							</div>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
							{rowsHere}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			);
		}
		return rows;
	}

	function renderDamage() {
		const rows: React.ReactElement[] = [];
		for (const source in stats.characterDamage) {
			let sourceTotalDamageDealt = 0;
			let sourceTotalDamageMitigated = 0;
			let sourceTotalTimeSpentCasting = 0;
			const damage = stats.characterDamage[source];
			const sourceRows: React.ReactElement[] = [];
			for (const target in damage) {
				const targetRows: React.ReactElement[] = [];
				const targetTable = [];
				const ability = damage[target];
				const monsterData = Object.values(monsters).find((m) => m.name === target);

				let totalDamageDealt = 0;
				let totalDamageMitigated = 0;
				let totalTimeSpentCasting = 0;

				const sortedAbilities = [];
				for (const abilityName in ability) {
					const abilityDamage = ability[abilityName];
					const averageDamagePerCast = abilityDamage.damageDealt / abilityDamage.casts;
					const dps = (averageDamagePerCast / abilityDamage.timeSpentCasting) * 1000;
					sortedAbilities.push({
						abilityName,
						damageDealt: abilityDamage.damageDealt,
						damageMitigated: abilityDamage.damageMitigated,
						dps,
					});
					totalDamageDealt += abilityDamage.damageDealt;
					totalDamageMitigated += abilityDamage.damageMitigated;
					totalTimeSpentCasting += abilityDamage.timeSpentCasting;
				}
				// Alphabetical order
				sortedAbilities.sort((a, b) => a.abilityName.localeCompare(b.abilityName));

				for (const sorted of sortedAbilities) {
					const dps = sorted.dps;
					const element = renderAbilityWithDamage(
						sorted.abilityName,
						sorted.damageDealt,
						sorted.damageMitigated,
						dps,
						source,
						target
					);
					if (element) {
						targetTable.push(element);
					}
				}
				targetRows.push(
					<div className='combat-instance-subheader'>
						Total Damage Dealt: {totalDamageDealt}
						<br></br>
						Total Damage Mitigated: {totalDamageMitigated}
						<br></br>
						Average DPS: {((totalDamageDealt / totalTimeSpentCasting) * 1000).toFixed(1)}
					</div>
				);
				targetRows.push(<div className='combat-instance-stats-table'>{targetTable}</div>);

				sourceRows.push(
					<Accordion allowToggle variant='default' height='100%' width='100%'>
						<AccordionItem height='100%'>
							<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
								<div className='combat-instance-monster-header'>
									<Image
										className={`combat-instance-monster-image ${source}-${target}`}
										src={monsterData?.image ?? '/images/ui/group_icon.png'}
									></Image>
									{target}
								</div>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
								{targetRows}
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				);

				sourceTotalDamageDealt += totalDamageDealt;
				sourceTotalDamageMitigated += totalDamageMitigated;
				sourceTotalTimeSpentCasting += totalTimeSpentCasting;
			}
			// Put as the first entry in sourceRows
			sourceRows.unshift(
				<Accordion allowToggle variant='default' height='100%' width='100%'>
					<AccordionItem height='100%'>
						<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
							<div className='combat-instance-monster-header'>
								<Image
									className={`combat-instance-monster-image ${source}-average`}
									src={'/images/combat/combat_level.png'}
								></Image>
								Averages
							</div>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
							<div className='combat-instance-subheader'>
								<br></br>
								Total Damage Dealt: {sourceTotalDamageDealt}
								<br></br>
								Total Damage Mitigated: {sourceTotalDamageMitigated}
								<br></br>
								Average DPS:{' '}
								{((sourceTotalDamageDealt / sourceTotalTimeSpentCasting) * 1000).toFixed(1)}
							</div>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			);
			rows.push(
				<Accordion allowToggle variant='default' height='100%' width='100%'>
					<AccordionItem height='100%'>
						<AccordionButton background='transparent' border='1px solid rgba(155, 155, 155, 0.05)'>
							<div className='combat-instance-subheader'>{source}</div>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel height='calc(100% - 40px)' overflowY='auto'>
							{sourceRows}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			);
		}
		return rows;
	}

	function renderAbilityWithDamage(
		abilityName: string,
		damage: number,
		mitigated: number,
		dps: number,
		source: string,
		target: string
	) {
		const abilityData = Object.values(abilities).find((a) => a.abilityName === abilityName);
		if (!abilityData) {
			return null;
		}

		// TODO: Move the damage and mitigation into a tooltip; it was acting weird when I tried to do it
		return (
			<Box className={`combat-instance-ability-box ${source}-${abilityName}-${target}`}>
				<Image
					className={`combat-instance-ability-image ${source}-${abilityName}-${target}`}
					src={abilityData.abilityImage}
				/>
				{abilityName}
				<br />
				DPS: {dps.toFixed(1)}
				<br />
				Damage: {damage}
				<br />
				Mitigated: {mitigated}
			</Box>
		);
	}
	return <div className='combat-instance-stats-container'>{renderStats()}</div>;
}
