import React from 'react';
import { CombatCharacterEquipment } from '../CombatCharacterEquipment';

export default function CombatBarGear() {
	return (
		<div className='combat-gear-container'>
			<CombatCharacterEquipment view={'equipment'} />
		</div>
	);
}
