import React, { Component } from 'react';
import { socket } from '../../../services/socket.service';

import './CombatDialogue.css';

export default class CombatDialogue extends Component {
	constructor() {
		super();

		this.state = { dialogue: '', visible: false };

		this.setDialogue = this.setDialogue.bind(this);
		this.removeDialogue = this.removeDialogue.bind(this);
	}

	componentDidMount() {
		socket.on('monster dialogue', this.setDialogue);
	}

	setDialogue(message) {
		this.setState({ dialogue: message, visible: true });
		setTimeout(() => {
			this.removeDialogue(message);
		}, 5000);
	}

	removeDialogue(message) {
		if (message === this.state.dialogue) {
			this.setState({ visible: false });
		}
	}

	render() {
		return (
			<div className='combat-dialogue-container'>
				<div
					className={
						'combat-dialogue ' + (this.state.visible ? 'combat-dialogue-visible' : 'combat-dialogue-hidden')
					}
				>
					{this.state.dialogue}
				</div>
			</div>
		);
	}
}
