import React from 'react';
import Tooltip from './UI/Tooltip';
import { leagueList } from '../../utils/leagueList';
import { useGlobalField } from '../../hooks/hooks';

export default function PlayerCount() {
	const characterOnlineData = useGlobalField('characterOnlineData');

	const sumOnline = Object.values(characterOnlineData.online).reduce((acc, online) => acc + online, 0);

	return (
		<div className='navbar1-box player-counter' id='usersOnline'>
			<img src='/images/ui/social_icon.png' className='header-league-icon' alt='Player Count' />
			{Number.isNaN(sumOnline) ? 'X' : sumOnline}
			<Tooltip className={'player-counter-tooltip'}>
				{(sumOnline <= 0 || isNaN(sumOnline)) && <span>Online Counter Loading...</span>}
				{sumOnline > 0 &&
					Object.entries(characterOnlineData.online).map(([leagueId, count]) => {
						if (count === 0) return;
						const leagueData = leagueList[Number(leagueId)];
						return (
							<div key={`leagueheader-${leagueId}`}>
								<img src={leagueData.icon} className='header-league-icon' alt={leagueData.name} />
								{count}
							</div>
						);
					})}
			</Tooltip>
		</div>
	);
}
