import { Text } from '@chakra-ui/react';
import React from 'react';
import {
	IChatMessage,
	ISystemMessage,
	IWhisperMessage,
} from '../../../../../game-server/src/modules/chat/Chat.interface';
import { usePlayerSetting } from '../../../hooks/hooks';
import useIsMobile from '../../../hooks/useIsMobile';

const newMessageDateFormat = new Intl.DateTimeFormat(undefined, {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
});
const oldMessageDateFormat = new Intl.DateTimeFormat(undefined, {
	month: '2-digit',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
});

const oneDay = 24 * 60 * 60 * 1000;

export default function ChatMessageTimestamp(props: {
	message: IChatMessage | ISystemMessage | IWhisperMessage;
	show?: boolean;
}) {
	const isMobile = useIsMobile();
	const showMobileDate = usePlayerSetting('chat.showMobileDate');

	const time = new Date(props.message.createdAt);
	if (!time || (props.message.visualClass === 'me-message' && !props.show)) return null;
	if (isMobile && !showMobileDate) return null;
	// use old format for messages older than 24 hours
	const now = new Date();
	const diff = now.getTime() - time.getTime();
	return (
		<Text
			as='span'
			color='grey'
			fontFamily="'Roboto Mono', monospace"
			fontSize='0.85em'
			position='relative'
			whiteSpace='nowrap'
		>
			[{diff > oneDay ? oldMessageDateFormat.format(time) : newMessageDateFormat.format(time)}]
		</Text>
	);
}
