import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import React, { useEffect } from 'react';
import './NotificationManager.css';

import { socket } from './socket.service';
import { NotificationType } from '../../../game-server/src/modules/notifications/Notification';
import { IdlescapeButton } from '@idlescape/ui';

export const NotificationManager = ({
	loaded,
	setLoaded,
}: {
	loaded?: boolean;
	setLoaded: (value: boolean) => void;
}) => {
	useEffect(() => {
		console.log('Notification Manager started');
		socket.on('notification:send', (data) => {
			addNotification(data.title, data.message, data.type);
		});

		setLoaded(true);

		return () => {
			socket.off('notification:send');
			console.log('Notification Manager stopped working');
			setLoaded(false);
		};
	}, []);

	return null;
};

export function addNotification(titleString: string, message: string, type: NotificationType) {
	const title = (
		<>
			{titleString}
			<IdlescapeButton
				className='clear-all-button'
				position='absolute'
				top='22px'
				right='22px'
				display='none'
				onClick={Store.removeAllNotifications}
			>
				Clear all
			</IdlescapeButton>
		</>
	);
	Store.addNotification({
		title,
		message,
		type,
		insert: 'top',
		container: 'top-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: 10000,
			onScreen: true,
			pauseOnHover: true,
		},
	});
}
