import React from 'react';
import { TCosmetics, TEquipmentPartials } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { CombatAvatar } from './CombatAvatar2';

export default function CombatAvatarMugshot(props: { equipment: TEquipmentPartials; cosmetics: TCosmetics }) {
	return (
		<div className='combat-avatar-mugshot-container'>
			<CombatAvatar equipment={props.equipment} cosmetics={props.cosmetics} />
		</div>
	);
}
