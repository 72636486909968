import { socket } from '../../services/socket.service';
import './FriendsTab.css';
import { FriendsTabFriend } from './FriendsTabFriend';
import { Box, Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';

export default function FriendsTab() {
	const friendList = usePlayerField('friendList');
	const [value, setValue] = useState('');

	function handleAddFriend(event: React.MouseEvent | React.KeyboardEvent) {
		if (event.type !== 'click' && 'key' in event && event.key !== 'Enter') return;
		if (value === '') return;
		socket.emit('social:addFriend', {
			username: value,
		});
		setValue('');
	}

	return (
		<div className='friendlist-container'>
			<IdlescapeContainer margin='auto' maxWidth='500px'>
				<table className='friendlist-table header-fixed'>
					<thead>
						<tr>
							<th>Name</th>
							<Box as='th' float='right'>
								Status
							</Box>
						</tr>
					</thead>
					<tbody>
						{friendList.map((elem, index) => {
							return <FriendsTabFriend key={index} friendData={elem} {...elem}></FriendsTabFriend>;
						})}
					</tbody>
				</table>
			</IdlescapeContainer>
			<Flex gap='15px' alignItems='center' maxWidth='450px' margin='auto'>
				<IdlescapeInput
					value={value}
					placeholder='Type a username...'
					variant='simple'
					setValue={setValue}
					onKeyUp={handleAddFriend}
					flex='2'
				/>
				<IdlescapeButton variant='green' onClick={handleAddFriend} flex='1'>
					Add Friend
				</IdlescapeButton>
			</Flex>
		</div>
	);
}
