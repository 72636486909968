import { SET_GROUP_DATA } from '../actions/types';
import { IGroupClientData } from '../../../game-server/src/modules/group/Group';
import { AnyAction } from 'redux';

const initialState = {
	groupData: {} as IGroupClientData,
};

export interface GroupReducerState {
	groupData: IGroupClientData;
}

export default function (state = initialState, action: AnyAction): GroupReducerState {
	switch (action.type) {
		case SET_GROUP_DATA:
			return {
				...state,
				groupData: action.payload,
			};
		default:
			return state;
	}
}
