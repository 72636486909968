import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { TSkillTab } from '../../../../game-server/src/Atypes/Skills';
import { IItem, TPartialItemWithItemID } from '../../../../game-server/src/modules/items/items.interface';
import { ILoot, ILootExtra, ILootWithFrequency } from '../../../../game-server/src/modules/items/Loot.interface';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { enchantingTabAtom } from '../../atoms/enchantingTabAtom';
import { scrollcraftingFilterAtom } from '../../atoms/scrollcraftingFilterAtom';
import { itemImage } from '../../helper/itemHelperFunctions';
import { itemList } from '../../utils/itemList';
import ItemTooltip from './Tooltips/ItemTooltip';

export interface IResourceLoot extends ILoot {
	minRarity?: number;
}

export interface IResourceLootWithFrequency extends ILootWithFrequency {
	minRarity?: number;
}

function ResourceList(props: { resources: IResourceLoot[]; skill?: TSkillTab }) {
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setEnchantingTab = useSetRecoilState(enchantingTabAtom);
	const setScrollcraftingCategory = useSetRecoilState(scrollcraftingFilterAtom);

	let showHint = false;

	const resourceDisplays = props.resources.map(
		({ id, frequency, chance, minAmount, maxAmount, minRarity, extra }) => {
			let bottomLabel: React.ReactNode;
			const chanceOrFrequency = chance ?? frequency;
			if (!chanceOrFrequency || chanceOrFrequency === 0) {
				if (minRarity) {
					bottomLabel = (
						<Box color='#F88'>
							{Math.abs(minRarity)}
							<br />
							Rarity
						</Box>
					);
				} else {
					bottomLabel = '???';
				}
				showHint = true;
			} else if (chanceOrFrequency < 0.001) {
				const fraction = (chanceOrFrequency * 10000).toFixed(1) + '/10k';
				bottomLabel = fraction;
			} else if (chanceOrFrequency < 0.0001) {
				const fraction = (chanceOrFrequency * 100000).toFixed(1) + '/100k';
				bottomLabel = fraction;
			} else {
				bottomLabel = chanceOrFrequency.toLocaleString('en-US', {
					maximumSignificantDigits: 3,
					style: 'percent',
				});
			}
			let topLabel: React.ReactElement = <></>;
			if (maxAmount && maxAmount !== minAmount) {
				topLabel = (
					<Box pos='absolute' top='0' right='5px' fontSize='14px'>
						{minAmount?.toLocaleString('en-US', { notation: 'compact' })}-
						{maxAmount?.toLocaleString('en-US', { notation: 'compact' })}
					</Box>
				);
			} else if (minAmount && minAmount > 1) {
				topLabel = (
					<Box pos='absolute' top='0' right='5px' fontSize='14px'>
						~{minAmount?.toLocaleString('en-US', { notation: 'compact' })}
					</Box>
				);
			}

			const itemData = itemList[id];
			const itemClass = itemData.class;
			const itemDisplay: TPartialItemWithItemID<IItem> = { itemID: id };
			if (itemData.forcedEnchant && itemData.forcedEnchantAmount) {
				itemDisplay.enchantmentStrength = itemData.forcedEnchantAmount;
				itemDisplay.enchantmentID = itemData.forcedEnchant;
			}
			let keyExtra = '';
			if (extra) {
				for (const [key, value] of Object.entries(extra)) {
					itemDisplay[key as keyof ILootExtra] = value;
					keyExtra += key + value;
				}
			}
			return (
				<div
					className={`item ${itemClass}`}
					key={'resource-' + id + '-' + chanceOrFrequency + '-' + keyExtra}
					data-itemid={id}
				>
					{itemImage(itemData)}
					{topLabel}
					<Box position='absolute' fontSize='70%' lineHeight='1.1em' bottom='2px' left='0' right='0'>
						{bottomLabel}
					</Box>
					<ItemTooltip item={itemDisplay} />
				</div>
			);
		}
	);

	function showHatchetEnchants() {
		setActiveTab('enchanting');
		setEnchantingTab('scrollcrafting');
		setScrollcraftingCategory('hatchet');
	}

	function getHint() {
		if (!showHint) return null;
		switch (props.skill) {
			case 'foraging':
				return (
					<Text gridColumn='1/-1'>
						Loot marked with ??? is only found in foraging nodes that require{' '}
						<Text
							as='span'
							onClick={showHatchetEnchants}
							cursor='pointer'
							color='blue.500'
							_hover={{ textDecoration: 'underline' }}
						>
							enchantments
						</Text>
						.
					</Text>
				);
			case 'fishing':
				return (
					<Text gridColumn='1/-1'>
						To find loot with a rarity you need more bonus rarity than the threshold.
					</Text>
				);
			default:
				return null;
		}
	}

	return (
		<>
			{resourceDisplays}
			{getHint()}
		</>
	);
}

export default ResourceList;
