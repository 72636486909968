// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { ILoot } from '../../../game-server/src/modules/items/Loot.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { leaguesIds } from '../utils/lookup-dictionaries/lookupLeagueList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';

export const locationsLootTable: Record<number, { loot?: ILoot[]; nodes?: Record<string, ILoot[]> }> = {
	50: {
		loot: [
			{
				id: 108,
				frequency: 40,
				minAmount: 1,
			},
			{
				id: 101,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 102,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 110,
				frequency: 10,
				minAmount: 1,
				maxAmount: 2,
			},
			{
				id: 109,
				frequency: 9,
				minAmount: 1,
			},
			{
				id: 11011,
				frequency: 1,
				minAmount: 1,
			},
		],
	},
	51: {
		loot: [
			{
				id: 101,
				frequency: 30,
				minAmount: 1,
			},
			{
				id: 102,
				frequency: 30,
				minAmount: 1,
			},
			{
				id: 108,
				frequency: 10,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 110,
				frequency: 10,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 103,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 112,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 109,
				frequency: 4,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 11011,
				frequency: 1,
				minAmount: 1,
			},
		],
	},
	52: {
		loot: [
			{
				id: 103,
				frequency: 50,
				minAmount: 1,
			},
			{
				id: 109,
				frequency: 20,
				minAmount: 1,
				maxAmount: 4,
			},
			{
				id: 112,
				frequency: 25,
				minAmount: 1,
				maxAmount: 2,
			},
			{
				id: 2013,
				frequency: 4,
				minAmount: 1,
			},
			{
				id: 11011,
				frequency: 1,
				minAmount: 1,
			},
		],
	},
	53: {
		loot: [
			{
				id: 105,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 111,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 110,
				frequency: 20,
				minAmount: 1,
				maxAmount: 10,
			},
			{
				id: 112,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 2013,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 103,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 104,
				frequency: 5,
				minAmount: 1,
			},
		],
	},
	54: {
		loot: [
			{
				id: 105,
				frequency: 30,
				minAmount: 1,
			},
			{
				id: 104,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 111,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 15,
				minAmount: 1,
			},
			{
				id: 2013,
				frequency: 10,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 109,
				frequency: 5,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 103,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 112,
				frequency: 5,
				minAmount: 1,
			},
		],
	},
	55: {
		loot: [
			{
				id: 106,
				frequency: 35,
				minAmount: 1,
			},
			{
				id: 111,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 109,
				frequency: 10,
				minAmount: 1,
				maxAmount: 2,
			},
			{
				id: 104,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 105,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 107,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 10,
				minAmount: 1,
			},
		],
	},
	56: {
		loot: [
			{
				id: 111,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 105,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 106,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 107,
				frequency: 20,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 10,
				minAmount: 1,
			},
			{
				id: 104,
				frequency: 10,
				minAmount: 1,
			},
		],
	},
	57: {
		loot: [
			{
				id: 113,
				frequency: 70,
				minAmount: 5,
				maxAmount: 15,
			},
			{
				id: 109,
				frequency: 15,
				minAmount: 2,
				maxAmount: 4,
			},
			{
				id: 110,
				frequency: 15,
				minAmount: 2,
				maxAmount: 4,
			},
		],
	},
	80: {
		loot: [
			{
				id: 107,
				frequency: 30,
				minAmount: 1,
			},
			{
				id: 109,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 108,
				frequency: 5,
				minAmount: 1,
			},
			{
				id: 113,
				frequency: 25,
				minAmount: 1,
				maxAmount: 3,
			},
			{
				id: 104,
				frequency: 15,
				minAmount: 1,
				maxAmount: 2,
			},
			{
				id: 114,
				frequency: 15,
				minAmount: 1,
			},
			{
				id: 115,
				frequency: 5,
				minAmount: 1,
			},
		],
	},
	101: {
		nodes: {
			'The Grasslands': [
				{
					id: 151,
					frequency: 3,
					minAmount: 1,
				},
				{
					id: 2016,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 2021,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 2023,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 2027,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 158,
					frequency: 0.3333333333333333,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'A Tree': [
				{
					id: 2016,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'A Large Tree': [
				{
					id: 2016,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 10,
					minAmount: 2,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'Grassy Vegetables': [
				{
					id: 151,
					frequency: 2,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 2021,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 2023,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
			],
			'Wildberry Bushes': [
				{
					id: 2027,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 158,
					frequency: 0.3333333333333333,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.5,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'Grassy Seeds': [
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 158,
					frequency: 0.3333333333333333,
					minAmount: 1,
					maxAmount: 2,
				},
			],
		},
	},
	102: {
		nodes: {
			'The Verdant Valley': [
				{
					id: 2016,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 2021,
					frequency: 2,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 2025,
					frequency: 2,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 2029,
					frequency: 2,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 170,
					frequency: 0.16666666666666666,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3004,
					frequency: 1.5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3019,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3017,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 3006,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3007,
					frequency: 1,
					minAmount: 1,
				},
			],
			'A Tree': [
				{
					id: 2016,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 170,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'Oak Tree': [
				{
					id: 2016,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 8,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 170,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'Copse of Verdant Trees': [
				{
					id: 2016,
					frequency: 1,
					minAmount: 2,
				},
				{
					id: 301,
					frequency: 8,
					minAmount: 2,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 2,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
				},
			],
			'Verdant Vegetables': [
				{
					id: 2021,
					frequency: 8,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 2025,
					frequency: 8,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.5,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3004,
					frequency: 1.5,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Wild Wheat': [
				{
					id: 2029,
					frequency: 8,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.5,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'Verdant Seeds': [
				{
					id: 170,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 0.5,
					minAmount: 1,
				},
			],
			'Verdant Reagents': [
				{
					id: 3004,
					frequency: 1.5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3019,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3017,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 3006,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3007,
					frequency: 1,
					minAmount: 1,
				},
			],
		},
	},
	103: {
		nodes: {
			'The Fungal Grotto': [
				{
					id: 2020,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 161,
					frequency: 0.3,
					minAmount: 1,
				},
				{
					id: 2023,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 2021,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 5,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 170,
					frequency: 0.16666666666666666,
					minAmount: 1,
				},
				{
					id: 171,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3018,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3020,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Lots of Fungi': [
				{
					id: 2020,
					frequency: 4,
					minAmount: 1,
				},
				{
					id: 161,
					frequency: 0.3,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.125,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3018,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3020,
					frequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			"Carrots and 'Taters": [
				{
					id: 2023,
					frequency: 8,
					minAmount: 1,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 2021,
					frequency: 8,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.125,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'A Seedy Place': [
				{
					id: 161,
					frequency: 0.3,
					minAmount: 1,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 151,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 170,
					frequency: 0.16666666666666666,
					minAmount: 1,
				},
				{
					id: 171,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
			],
			'Lone Swamp Tree': [
				{
					id: 301,
					frequency: 5,
					minAmount: 2,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
				},
			],
		},
	},
	104: {
		nodes: {
			'The Tangle': [
				{
					id: 2022,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 159,
					frequency: 0.1125,
					minAmount: 1,
				},
				{
					id: 2026,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 2028,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 153,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 301,
					frequency: 5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3010,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3008,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3009,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3021,
					frequency: 2,
					minAmount: 1,
				},
			],
			'Sweet Tooth': [
				{
					id: 2026,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 2028,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 153,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.125,
					minAmount: 2,
					maxAmount: 4,
				},
			],
			'All Tangled Up': [
				{
					id: 301,
					frequency: 5,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 302,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 10,
					minAmount: 1,
				},
			],
			'A Little Chili': [
				{
					id: 3021,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 2022,
					frequency: 4,
					minAmount: 1,
				},
				{
					id: 159,
					frequency: 0.1875,
					minAmount: 1,
				},
			],
			'Tangle of Seeds': [
				{
					id: 159,
					frequency: 0.1125,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 153,
					frequency: 0.1,
					minAmount: 1,
				},
			],
			'Tangled Reagents': [
				{
					id: 3010,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3008,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3009,
					frequency: 4,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3021,
					frequency: 2,
					minAmount: 1,
					maxAmount: 5,
				},
			],
		},
	},
	105: {
		nodes: {
			'The Misty Marsh': [
				{
					id: 2023,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 2024,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 160,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 2030,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 156,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 153,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 171,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 11012,
					frequency: 0.25,
					minAmount: 2,
					maxAmount: 4,
				},
				{
					id: 3006,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Misty Trees': [
				{
					id: 303,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
			],
			'Misty Plants': [
				{
					id: 2023,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 2024,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 2030,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3006,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Misty Seeds': [
				{
					id: 155,
					frequency: 0.375,
					minAmount: 1,
				},
				{
					id: 160,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 156,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 153,
					frequency: 0.16666666666666666,
					minAmount: 1,
				},
				{
					id: 171,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
			],
		},
	},
	106: {
		nodes: {
			'The Frozen Tundra': [
				{
					id: 2022,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 159,
					frequency: 0.1125,
					minAmount: 1,
				},
				{
					id: 2027,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 158,
					frequency: 0.3333333333333333,
					minAmount: 1,
				},
				{
					id: 2029,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.15,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 305,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 7000,
					frequency: 0.001,
					minAmount: 100,
					maxAmount: 200,
				},
			],
			'Thawed Oasis': [
				{
					id: 2022,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 3021,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 159,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 158,
					frequency: 0.6666666666666666,
					minAmount: 1,
				},
				{
					id: 2029,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.16666666666666666,
					minAmount: 1,
				},
				{
					id: 305,
					frequency: 2,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Squirrel Cache': [
				{
					id: 159,
					frequency: 0.6,
					minAmount: 1,
				},
				{
					id: 158,
					frequency: 1.3333333333333333,
					minAmount: 1,
				},
				{
					id: 154,
					frequency: 2,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.3333333333333333,
					minAmount: 1,
				},
			],
			'Frozen Reagents': [
				{
					id: 3013,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3021,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3014,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	107: {
		nodes: {
			'The Haunted Woods': [
				{
					id: 2020,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 161,
					frequency: 0.12,
					minAmount: 1,
				},
				{
					id: 2024,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 160,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.06,
					minAmount: 1,
				},
				{
					id: 172,
					frequency: 0.0625,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.041666666666666664,
					minAmount: 1,
				},
				{
					id: 174,
					frequency: 0.027777777777777776,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 306,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 3007,
					frequency: 0.25,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3008,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3009,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3011,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 3,
				},
			],
			"Woods' Bounty": [
				{
					id: 2020,
					frequency: 0.5,
					minAmount: 1,
				},
				{
					id: 161,
					frequency: 0.3,
					minAmount: 1,
				},
				{
					id: 2024,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 160,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.15,
					minAmount: 1,
				},
			],
			'Gnarled Trees': [
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 174,
					frequency: 0.05555555555555555,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 306,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
			],
			'Haunted Seeds': [
				{
					id: 172,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 173,
					frequency: 0.08333333333333333,
					minAmount: 1,
				},
				{
					id: 174,
					frequency: 0.05555555555555555,
					minAmount: 1,
				},
				{
					id: 160,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 152,
					frequency: 0.15,
					minAmount: 1,
				},
				{
					id: 161,
					frequency: 0.3,
					minAmount: 1,
				},
			],
			'Haunted Reagents': [
				{
					id: 3007,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3008,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3009,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3011,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3014,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 3015,
					frequency: 1,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	108: {
		nodes: {
			'The Living Forest': [
				{
					id: 2025,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 2026,
					frequency: 5,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.1,
					minAmount: 1,
				},
				{
					id: 2028,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 174,
					frequency: 0.05555555555555555,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 10,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 306,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 3007,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 4,
				},
				{
					id: 3015,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3016,
					frequency: 1,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 3005,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 5,
				},
			],
			'Healthy Living': [
				{
					id: 2025,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 0.1875,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 0.125,
					minAmount: 1,
				},
				{
					id: 2026,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 175,
					frequency: 0.15,
					minAmount: 1,
				},
				{
					id: 2028,
					frequency: 0.25,
					minAmount: 1,
					maxAmount: 4,
				},
			],
			'The Young Trees': [
				{
					id: 174,
					frequency: 0.05555555555555555,
					minAmount: 1,
				},
				{
					id: 304,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 305,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
				{
					id: 306,
					frequency: 5,
					minAmount: 1,
					maxAmount: 2,
				},
			],
			'The Elder Tree': [
				{
					id: 306,
					frequency: 20,
					minAmount: 1,
					maxAmount: 3,
				},
				{
					id: 307,
					frequency: 10,
					minAmount: 1,
				},
				{
					id: 177,
					frequency: 0.125,
					minAmount: 1,
				},
			],
			'Living Reagents': [
				{
					id: 3015,
					frequency: 1,
					minAmount: 1,
					maxAmount: 6,
				},
				{
					id: 3016,
					frequency: 0.5,
					minAmount: 1,
					maxAmount: 4,
				},
				{
					id: 3005,
					frequency: 0.25,
					minAmount: 1,
					maxAmount: 4,
				},
			],
			'Living Seeds': [
				{
					id: 177,
					frequency: 0.25,
					minAmount: 1,
				},
				{
					id: 174,
					frequency: 0.4444444444444444,
					minAmount: 1,
				},
				{
					id: 176,
					frequency: 1,
					minAmount: 1,
				},
				{
					id: 157,
					frequency: 2.25,
					minAmount: 1,
				},
			],
		},
	},
	151: {
		nodes: {
			'The Shallow Pond': [
				{
					id: 60,
					frequency: 100,
					maxFrequency: 150,
				},
				{
					id: 61,
					frequency: 50,
					maxFrequency: 100,
				},
				{
					id: 72,
					frequency: -10,
					maxFrequency: 18,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 15,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 2,
					maxFrequency: 40,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4020,
					frequency: -100,
					maxFrequency: 40,
					minAmount: 1,
					maxAmount: 10,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 9,
				},
			],
			'Oops, All Shrimp!': [
				{
					id: 60,
					frequency: 100,
				},
			],
			'Small Schools of Fish': [
				{
					id: 61,
					frequency: 25,
					maxFrequency: 75,
				},
				{
					id: 72,
					frequency: -5,
					maxFrequency: 22,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 2,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 9,
				},
			],
			'Drifting Around': [
				{
					id: 61,
					frequency: 15,
					maxFrequency: 50,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 2,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 25,
				},
			],
			'Shallow Flora': [
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: -5,
					maxFrequency: 45,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	152: {
		nodes: {
			'The Lazy River': [
				{
					id: 62,
					frequency: 100,
					maxFrequency: 175,
				},
				{
					id: 63,
					frequency: 50,
					maxFrequency: 100,
				},
				{
					id: 73,
					frequency: -50,
					maxFrequency: 8,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: -5,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4020,
					frequency: -100,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 10,
				},
				{
					id: 4021,
					frequency: -100,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 10,
				},
				{
					id: 72,
					frequency: 1,
					maxFrequency: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 15,
				},
				{
					id: 11031,
					frequency: -5,
					maxFrequency: 5,
				},
			],
			'Migrating Schools': [
				{
					id: 62,
					frequency: 15,
					maxFrequency: 25,
				},
				{
					id: 63,
					frequency: 50,
					maxFrequency: 75,
				},
				{
					id: 73,
					frequency: -15,
					maxFrequency: 12,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: -5,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 72,
					frequency: 5,
					maxFrequency: 12,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 25,
				},
				{
					id: 11031,
					frequency: -5,
					maxFrequency: 15,
				},
			],
			'ANGY bois': [
				{
					id: 62,
					frequency: 3,
					maxFrequency: 9,
				},
				{
					id: 63,
					frequency: 6,
					maxFrequency: 16,
				},
				{
					id: 73,
					frequency: 9,
					maxFrequency: 21,
				},
				{
					id: 11030,
					frequency: -1,
					maxFrequency: 20,
				},
				{
					id: 11031,
					frequency: -1,
					maxFrequency: 30,
				},
				{
					id: 11032,
					frequency: -50,
					maxFrequency: 1,
				},
			],
			'Riverbed Flora': [
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: -5,
					maxFrequency: 30,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	153: {
		nodes: {
			'The Still Lake': [
				{
					id: 64,
					frequency: 100,
					maxFrequency: 150,
				},
				{
					id: 77,
					frequency: 10,
					maxFrequency: 50,
				},
				{
					id: 74,
					frequency: -25,
					maxFrequency: 12,
				},
				{
					id: 73,
					frequency: -25,
					maxFrequency: 12,
				},
				{
					id: 72,
					frequency: 1,
					maxFrequency: 10,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4021,
					frequency: 5,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: -10,
					maxFrequency: 10,
				},
				{
					id: 11031,
					frequency: -10,
					maxFrequency: 10,
				},
			],
			'Big Catch!': [
				{
					id: 64,
					frequency: 45,
					maxFrequency: 90,
				},
				{
					id: 68,
					frequency: -35,
					maxFrequency: 15,
				},
				{
					id: 74,
					frequency: 5,
					maxFrequency: 12,
				},
				{
					id: 73,
					frequency: -10,
					maxFrequency: 8,
				},
				{
					id: 77,
					frequency: 15,
					maxFrequency: 75,
				},
				{
					id: 11032,
					frequency: -50,
					maxFrequency: 1,
				},
			],
			"Yedyod's Cove": [
				{
					id: 68,
					frequency: 25,
					maxFrequency: 75,
				},
				{
					id: 74,
					frequency: 5,
					maxFrequency: 12,
				},
				{
					id: 77,
					frequency: 3,
					maxFrequency: 15,
				},
				{
					id: 73,
					frequency: 10,
					maxFrequency: 30,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: -5,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 10,
				},
				{
					id: 11031,
					frequency: 1,
					maxFrequency: 30,
				},
				{
					id: 11032,
					frequency: -100,
					maxFrequency: 4,
				},
			],
			'Cage Match!': [
				{
					id: 64,
					frequency: 50,
					maxFrequency: 100,
				},
				{
					id: 77,
					frequency: 25,
					maxFrequency: 100,
				},
				{
					id: 68,
					frequency: -25,
					maxFrequency: 25,
				},
			],
			'Lake Flora': [
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: 1,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: -5,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -10,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	154: {
		nodes: {
			'The Open Ocean': [
				{
					id: 65,
					frequency: 100,
					maxFrequency: 150,
				},
				{
					id: 66,
					frequency: 50,
					maxFrequency: 100,
				},
				{
					id: 76,
					frequency: 10,
					maxFrequency: 50,
				},
				{
					id: 75,
					frequency: -33,
					maxFrequency: 5,
				},
				{
					id: 70,
					frequency: -50,
					maxFrequency: 45,
				},
				{
					id: 67,
					frequency: -75,
					maxFrequency: 10,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 3,
					maxAmount: 7,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -25,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 20,
				},
				{
					id: 11031,
					frequency: 1,
					maxFrequency: 30,
				},
				{
					id: 11032,
					frequency: -50,
					maxFrequency: 0.5,
				},
			],
			'Sharks Ate All The Tuna': [
				{
					id: 65,
					frequency: -35,
					maxFrequency: 5,
				},
				{
					id: 66,
					frequency: 75,
					maxFrequency: 100,
				},
				{
					id: 76,
					frequency: 15,
					maxFrequency: 33,
				},
				{
					id: 67,
					frequency: -50,
					maxFrequency: 20,
				},
				{
					id: 75,
					frequency: 10,
					maxFrequency: 12,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -25,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 15,
				},
				{
					id: 11031,
					frequency: 1,
					maxFrequency: 25,
				},
			],
			'Almost Jurassic': [
				{
					id: 75,
					frequency: 1,
					maxFrequency: 8,
				},
				{
					id: 76,
					frequency: 1,
					maxFrequency: 15,
				},
				{
					id: 70,
					frequency: 50,
					maxFrequency: 60,
				},
				{
					id: 67,
					frequency: 5,
					maxFrequency: 35,
				},
				{
					id: 69,
					frequency: -100,
					maxFrequency: 10,
				},
				{
					id: 11032,
					frequency: -75,
					maxFrequency: 1,
				},
			],
			'Is that a tentacle?': [
				{
					id: 75,
					frequency: 25,
					maxFrequency: 40,
				},
				{
					id: 76,
					frequency: 5,
					maxFrequency: 45,
				},
				{
					id: 67,
					frequency: 15,
					maxFrequency: 25,
				},
				{
					id: 69,
					frequency: -50,
					maxFrequency: 150,
				},
				{
					id: 11032,
					frequency: -100,
					maxFrequency: 2,
				},
				{
					id: 11036,
					frequency: -15,
					maxFrequency: 5,
				},
			],
			'Carefree Day': [
				{
					id: 65,
					frequency: 100,
					maxFrequency: 200,
				},
				{
					id: 70,
					frequency: -25,
					maxFrequency: 150,
				},
				{
					id: 71,
					frequency: 3,
					maxFrequency: 8,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -25,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 3,
					maxFrequency: 25,
				},
				{
					id: 11031,
					frequency: 3,
					maxFrequency: 30,
				},
				{
					id: 11032,
					frequency: -30,
					maxFrequency: 2,
				},
				{
					id: 11036,
					frequency: -15,
					maxFrequency: 10,
				},
			],
			'Shipwreck Bay': [
				{
					id: 71,
					frequency: 3,
					maxFrequency: 8,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4022,
					frequency: -10,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -25,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 15,
					maxFrequency: 25,
				},
				{
					id: 11031,
					frequency: 5,
					maxFrequency: 40,
				},
				{
					id: 11032,
					frequency: -30,
					maxFrequency: 1.25,
				},
				{
					id: 11036,
					frequency: -1,
					maxFrequency: 15,
				},
				{
					id: 67,
					frequency: -25,
					maxFrequency: 50,
				},
				{
					id: 75,
					frequency: 3,
					maxFrequency: 40,
				},
				{
					id: 76,
					frequency: 5,
					maxFrequency: 100,
				},
				{
					id: 66,
					frequency: 25,
					maxFrequency: 125,
				},
			],
			"Playtester's Paradise": [
				{
					id: 11032,
					frequency: -30,
					maxFrequency: 1.5,
				},
				{
					id: 67,
					frequency: 5,
					maxFrequency: 50,
				},
				{
					id: 75,
					frequency: 5,
					maxFrequency: 12,
				},
				{
					id: 76,
					frequency: 5,
					maxFrequency: 10,
				},
				{
					id: 11036,
					frequency: -1,
					maxFrequency: 20,
				},
				{
					id: 4023,
					frequency: -5,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: -10,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
			],
			'Ghost Ship': [
				{
					id: 11031,
					frequency: 5,
					maxFrequency: 25,
				},
				{
					id: 11036,
					frequency: -1,
					maxFrequency: 50,
				},
				{
					id: 11032,
					frequency: -30,
					maxFrequency: 2.5,
				},
				{
					id: 67,
					frequency: -25,
					maxFrequency: 50,
				},
				{
					id: 75,
					frequency: 3,
					maxFrequency: 40,
				},
				{
					id: 76,
					frequency: 5,
					maxFrequency: 75,
				},
				{
					id: 66,
					frequency: 25,
					maxFrequency: 50,
				},
			],
			'Ocean Flora': [
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: 1,
					maxFrequency: 20,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: -5,
					maxFrequency: 20,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: -10,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
	155: {
		nodes: {
			'The Stormy Seas': [
				{
					id: 65,
					frequency: 25,
					maxFrequency: 50,
				},
				{
					id: 66,
					frequency: 50,
					maxFrequency: 75,
				},
				{
					id: 76,
					frequency: 15,
					maxFrequency: 50,
				},
				{
					id: 75,
					frequency: -10,
					maxFrequency: 5,
				},
				{
					id: 70,
					frequency: -25,
					maxFrequency: 25,
				},
				{
					id: 67,
					frequency: -50,
					maxFrequency: 5,
				},
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4023,
					frequency: 1,
					maxFrequency: 10,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: -5,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 20,
				},
				{
					id: 11031,
					frequency: 1,
					maxFrequency: 20,
				},
				{
					id: 11032,
					frequency: -45,
					maxFrequency: 3,
				},
				{
					id: 11036,
					frequency: -35,
					maxFrequency: 15,
				},
			],
			'Eye of the Storm': [
				{
					id: 71,
					frequency: 3,
					maxFrequency: 15,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4023,
					frequency: 1,
					maxFrequency: 9,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: -5,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 11030,
					frequency: 15,
					maxFrequency: 25,
				},
				{
					id: 11031,
					frequency: 5,
					maxFrequency: 40,
				},
				{
					id: 11032,
					frequency: -30,
					maxFrequency: 2,
				},
				{
					id: 67,
					frequency: -25,
					maxFrequency: 10,
				},
				{
					id: 75,
					frequency: -20,
					maxFrequency: 5,
				},
				{
					id: 76,
					frequency: -15,
					maxFrequency: 10,
				},
				{
					id: 66,
					frequency: 10,
					maxFrequency: 25,
				},
				{
					id: 65,
					frequency: 25,
					maxFrequency: 50,
				},
				{
					id: 70,
					frequency: 5,
					maxFrequency: 15,
				},
			],
			'Endless Vortex': [
				{
					id: 67,
					frequency: 15,
					maxFrequency: 100,
				},
				{
					id: 69,
					frequency: 50,
					maxFrequency: 250,
				},
				{
					id: 11032,
					frequency: -100,
					maxFrequency: 5,
				},
				{
					id: 11036,
					frequency: -1,
					maxFrequency: 25,
				},
			],
			"INFO's Stand": [
				{
					id: 69,
					frequency: 25,
					maxFrequency: 150,
				},
				{
					id: 11032,
					frequency: -150,
					maxFrequency: 100,
				},
				{
					id: 11036,
					frequency: -120,
					maxFrequency: 100,
				},
			],
			"World's End": [
				{
					id: 65,
					frequency: 1,
					maxFrequency: 33,
				},
				{
					id: 66,
					frequency: 1,
					maxFrequency: 40,
				},
				{
					id: 76,
					frequency: 15,
					maxFrequency: 65,
				},
				{
					id: 75,
					frequency: -25,
					maxFrequency: 12,
				},
				{
					id: 70,
					frequency: -33,
					maxFrequency: 35,
				},
				{
					id: 67,
					frequency: -50,
					maxFrequency: 15,
				},
				{
					id: 4023,
					frequency: 1,
					maxFrequency: 9,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: 1,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4025,
					frequency: 1,
					maxFrequency: 25,
				},
				{
					id: 11030,
					frequency: 1,
					maxFrequency: 3,
				},
				{
					id: 11031,
					frequency: 1,
					maxFrequency: 3,
				},
				{
					id: 11032,
					frequency: -45,
					maxFrequency: 4,
				},
				{
					id: 11036,
					frequency: -40,
					maxFrequency: 20,
				},
			],
			'Deep Sea Flora': [
				{
					id: 71,
					frequency: 1,
					maxFrequency: 5,
					minAmount: 5,
					maxAmount: 10,
				},
				{
					id: 4020,
					frequency: 1,
					maxFrequency: 3,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4021,
					frequency: 1,
					maxFrequency: 6,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4022,
					frequency: 1,
					maxFrequency: 9,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4023,
					frequency: 1,
					maxFrequency: 15,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4024,
					frequency: -15,
					maxFrequency: 25,
					minAmount: 1,
					maxAmount: 5,
				},
				{
					id: 4025,
					frequency: -20,
					maxFrequency: 40,
					minAmount: 1,
					maxAmount: 3,
				},
			],
		},
	},
};
