import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox } from '@idlescape/ui';
import { cloneDeep } from 'lodash';
import React from 'react';
import { AiFillLock } from 'react-icons/ai';
import { TAffixesPaths } from '../../../../../../../game-server/src/modules/affixes/affixes.interface';
import { IItem, IItemData } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../../hooks/hooks';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { socket } from '../../../../../services/socket.service';
import {
	areValidAffixesForItem,
	getAffixCost,
	getAffixesSlotsForItem,
	getCalculatedAffixStrengths,
} from '../../../../../utils/affixFunctions';
import { affixList } from '../../../../../utils/affixList';
import {
	AFFIX_COMBAT_SLOTS,
	AFFIX_EXP_PER_DUST,
	AFFIX_EXP_PER_MULT_RARITY,
	AFFIX_JEWELRY_SLOTS,
	AFFIX_RARITY_OPTIONS,
	AFFIX_TOOL_SLOTS,
} from '../../../../../utils/constantsCollection';
import { itemList } from '../../../../../utils/itemList';
import { itemsIds } from '../../../../../utils/lookup-dictionaries/lookupItemList';
import { Item } from '../../../Inventory/Item';
import { TooltipLiterals } from '../../../Tooltips/Data/TooltipLiterals';
import ResourceCost from '../../ResourceCost';
import AffixTooltip, { formatAffixStrength, formatEquipmentAffixStrength } from '../AffixTooltip';

export default function AffixSelection({
	selectedItem,
	selectedAffixes,
	setSelectedItem,
	setSelectedAffixes,
	handleAffixSelect,
}: {
	selectedItem: IItem | undefined;
	selectedAffixes: TAffixesPaths[];
	setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
	setSelectedAffixes: React.Dispatch<React.SetStateAction<TAffixesPaths[]>>;
	handleAffixSelect: (affix: TAffixesPaths) => void;
}) {
	const settings = usePlayerField('settings');
	const stockpile = usePlayerField('stockpile');

	const isMobile = useIsMobile();

	const itemData = selectedItem ? itemList[selectedItem.itemID] : undefined;
	const maxAffixSlot = itemData ? getAffixesSlotsForItem(itemData) : 0;
	const isRerolling = selectedItem?.affixes !== undefined;
	const affixCost = getAffixCost(
		itemData,
		selectedAffixes.map((affix) => affixList[affix]),
		isRerolling ? 'reroll' : 'add'
	);
	const buttonIsActive = validToAffix();

	let resourcesCosts: { resource: IItemData; count: number }[] = [];
	if (affixCost.dust.id !== -1) {
		const dustData = itemList[affixCost.dust.id];
		resourcesCosts = [
			{
				resource: dustData,
				count: affixCost.dust.amount,
			},
		];
		if (affixCost.scrap.id !== -1) {
			const scrapData = itemList[affixCost.scrap.id];
			resourcesCosts.push({
				resource: scrapData,
				count: affixCost.scrap.amount,
			});
		}
		const goldCost = affixCost.gold.amount;
		if (goldCost > 0) {
			const goldData = itemList[itemsIds.gold];
			resourcesCosts.push({
				resource: goldData,
				count: goldCost,
			});
		}
	}
	let expMult = 1;
	if (selectedItem) {
		const itemData = itemList[selectedItem.itemID];
		if (itemData) {
			expMult = AFFIX_EXP_PER_MULT_RARITY[itemList[selectedItem.itemID].rarity ?? 'common'];
		}
	}

	function applyAffix() {
		if (validToAffix() && selectedItem) {
			if (isRerolling) {
				socket.emit('runecrafting:reroll', { inventoryItemID: selectedItem.id, affixes: selectedAffixes });
			} else {
				socket.emit('runecrafting:affixes', { inventoryItemID: selectedItem.id, affixes: selectedAffixes });
			}
			setSelectedItem(-1);
			setSelectedAffixes([]);
		}
	}

	function renderSelectedAffixInfo() {
		if (!selectedItem) return;

		const affixes: React.ReactElement[] = [];
		const affixesToRender = [...selectedAffixes];
		const totalFrequencyOfValid = affixesToRender.reduce((acc, affix) => {
			const affixData = affixList[affix];
			if (!affixData) return acc;
			return acc + AFFIX_RARITY_OPTIONS[affixData.rarity ?? 'common'].frequency;
		}, 0);
		if (isRerolling) {
			// If it's not in selected affixes, add it
			for (const affix of selectedItem?.affixes ?? []) {
				if (!affixesToRender.includes(affix.path)) {
					affixesToRender.push(affix.path);
				}
			}
		}
		// Sort the affixesToRender, this is primarily to always have things in the same order, but it can probably be improved
		affixesToRender.sort((a, b) => {
			if (a > b) return -1;
			if (a < b) return 1;
			return 0;
		});
		for (const affix of affixesToRender) {
			const affixData = affixList[affix];
			if (!affixData) continue;

			const lockedForReroll = isRerolling && selectedAffixes.find((a) => a === affix);

			const name = affixData.name.replace('of ', '').replace('the ', '');
			const itemData = itemList[selectedItem.itemID];
			const { minStrength, maxStrength, reducedEffect } = getCalculatedAffixStrengths(affixData, itemData);

			const minStrengthString = formatAffixStrength(affixData, minStrength);
			const maxStrengthString = formatAffixStrength(affixData, maxStrength);
			const selectedAffix = selectedItem?.affixes?.find((a) => a.path === affix);

			affixes.push(
				<IdlescapeContainer
					as={Flex}
					position='relative'
					key={affix}
					flexDirection='column'
					alignItems='center'
					transition=' 0.3s'
					cursor='pointer'
					height={isMobile ? '200px' : '240px'}
					width={isMobile ? '130px' : '180px'}
					_hover={{
						transform: 'scale(1.01)',
						boxShadow: '0 0 10px #ff0000',
					}}
					onClick={() => handleAffixSelect(affix)}
				>
					<div className={`rarity-${affixData.rarity}`}>{name}</div>
					{affixData.imageSheetCSS !== undefined && (
						<Box
							className={`affix_runes ${affixData.imageSheetCSS} ${affixData.imageCSS}`}
							height={isMobile ? '80px' : '131px'}
							width={isMobile ? '80px' : '131px'}
							flexShrink={0}
						/>
					)}
					<Flex
						position='absolute'
						bottom='20px'
						flexDirection='column'
						alignItems='center'
						backgroundColor='#00000088'
						width={isMobile ? '100px' : '140px'}
					>
						<span>{TooltipLiterals.affixesLiterals[affix]}</span>
						{selectedAffix && (
							<span>
								Current:{' '}
								{formatEquipmentAffixStrength(affixData, selectedAffix.value, selectedItem.itemID)}
							</span>
						)}
						{lockedForReroll && (
							<Text as='span' color='red'>
								<AiFillLock />
								Locked
								<AiFillLock />
							</Text>
						)}
						{!lockedForReroll && (
							<span style={reducedEffect ? { color: 'orange' } : {}}>
								{minStrengthString} to {maxStrengthString}
							</span>
						)}
					</Flex>
					<AffixTooltip affixData={affixData} affixPath={affix} totalFrequency={-1} itemData={itemData} />
				</IdlescapeContainer>
			);
		}
		if (affixes.length < maxAffixSlot) {
			// We need to add some empty/random slots
			for (let i = affixes.length; i < maxAffixSlot; i++) {
				affixes.push(
					<IdlescapeContainer
						as={Flex}
						key={`emptyaffixslot-${i}`}
						flexDirection='column'
						alignItems='center'
						transition='0.3s'
						_hover={{
							transform: 'scale(1.01)',
						}}
						height={isMobile ? '200px' : '240px'}
						width={isMobile ? '130px' : '180px'}
					>
						<div>Empty Slot</div>
						<Image
							src='/images/magic/buffs/chances-icon.png'
							alt='chance'
							borderRadius='10px'
							width={isMobile ? '80px' : '131px'}
							height={isMobile ? '80px' : '131px'}
						/>
						<span>Randomly Selected</span>
					</IdlescapeContainer>
				);
			}
		}
		return affixes;
	}

	function renderItem() {
		if (!selectedItem) {
			return <div className={'enchanting-window-item'}>None selected</div>;
		}
		const selectedItemClone: IItem = cloneDeep(selectedItem);
		return (
			<div className={'enchanting-window-item'}>
				<Item
					item={selectedItemClone}
					onClick={() => {
						setSelectedItem(-1);
						setSelectedAffixes([]);
					}}
				/>
			</div>
		);
	}

	function validToAffix() {
		// Check the dust cost and if player has enough
		if (affixCost.dust.id !== -1) {
			const dustItem = stockpile.find((item) => item.itemID === affixCost.dust.id);
			if (!dustItem || dustItem.stackSize < affixCost.dust.amount) {
				return false;
			}
		}
		if (!selectedItem) {
			return false;
		}
		if (selectedAffixes.length > maxAffixSlot) {
			return false;
		}
		// Make sure the slots are valid
		const itemData = itemList[selectedItem.itemID];
		if (!itemData || !itemData.equipmentStats) {
			return false;
		}

		const areValid = areValidAffixesForItem(
			itemData,
			selectedAffixes.map((affix) => affixList[affix])
		);

		if (!areValid) {
			return false;
		}
		if (isRerolling && selectedAffixes.length === maxAffixSlot) return false;

		const goldCost = affixCost.gold.amount;
		if (goldCost > 0) {
			const goldItem = stockpile.find((item) => item.itemID === itemsIds.gold);
			if (!goldItem || goldItem.stackSize < goldCost) {
				return false;
			}
		}

		return true;
	}

	return (
		<Box className='enchanting-items-container' flexWrap='nowrap'>
			<div className='enchanting-window-component-container'>{renderItem()}</div>
			<Flex className='enchanting-window-component-container' flexWrap='wrap' gap='10px'>
				{renderSelectedAffixInfo()}
			</Flex>
			<div className='affix-window-apply-removed'>
				{affixCost.dust.id === -1 ? (
					<div>No item selected</div>
				) : (
					<Flex justifyContent='center' alignContent='center'>
						<IdlescapeFrameBox
							variant='primary'
							padding='10px'
							maxHeight='200px'
							maxWidth='200px'
							paddingX='15px'
							_hover={{}} // This overrides the default hover effect to get rid of it
						>
							<Box className='smithing-information-input'>
								<img
									src='/images/total_level.png'
									className='smithing-information-input-icon'
									alt='Experience'
								/>
								<div className='smithing-information-input-amount'>
									+
									{Math.floor(affixCost.dust.amount * AFFIX_EXP_PER_DUST * expMult).toLocaleString(
										'en-us'
									)}
								</div>
							</Box>
							<ResourceCost resourceCosts={resourcesCosts} />
						</IdlescapeFrameBox>
					</Flex>
				)}
				<IdlescapeButton
					size={'large'}
					variant={buttonIsActive ? 'blue' : 'disabled'}
					className={
						buttonIsActive && !settings.miscellaneous.highPerformance ? 'enchanting-glowing-button' : ''
					}
					onClick={applyAffix}
					marginY='15px'
				>
					{isRerolling ? 'REROLL' : 'APPLY'}
				</IdlescapeButton>
			</div>
		</Box>
	);
}
