import {
	TEnchant,
	TFoodEnchant,
	THealingStats,
	TStats,
	TTooltipEquipmentStats,
	TTooltipStats,
	TWeaponStats,
} from '../Data/TooltipData';
import { formatMultiplierStat, toFixedLocale, formatStat } from '../../../../helper/StringFormatter';
import { generateAffinityDictionary } from '../Data/DamageTypes';
import React from 'react';
import { TSkillName, TSkills } from '../../../../../../game-server/src/Atypes/Skills';
import { itemList } from '../../../../utils/itemList';
import { sortDictionaryByArray } from '../../../../helper/helperFunctions';
import { TooltipLiterals } from '../Data/TooltipLiterals';
import { IAffix } from '../../../../../../game-server/src/modules/affixes/affixes.interface';
import { affixList } from '../../../../utils/affixList';
import { formatEquipmentAffixStrength } from '../../SkillPanels/Runecrafting/AffixTooltip';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { getAugmentingTrialCount } from '../../../../utils/augmentingFunctions';
import { affixIsStarWorthy } from '../../../../utils/affixFunctions';

const equipmentStatsToLabelMapping = {
	...generateAffinityDictionary('offensiveDamageAffinity', ''),
	...generateAffinityDictionary('offensiveAccuracyAffinityRating', ''),
	...generateAffinityDictionary('defensiveDamageAffinity', ''),
	'weaponBonus.strength': 'Strength',
	'weaponBonus.intellect': 'Intellect',
	'weaponBonus.dexterity': 'Dexterity',
	'offensiveCritical.chance': 'Offensive Crit',
	'offensiveCritical.damageMultiplier': 'Crit Mult',
	'armorBonus.protection': 'Protection',
	'armorBonus.resistance': 'Resistance',
	'armorBonus.agility': 'Agility',
	'armorBonus.stamina': 'Stamina',
	'defensiveCritical.chance': 'Crit Avoidance',
	'defensiveCritical.damageMultiplier': 'Crit Reduction',
	'toolBoost.fishing': 'Fishing',
	'toolBoost.fishingBaitPower': 'Bait',
	'toolBoost.fishingReelPower': 'Reel',
	'toolBoost.fishingRarityPower': 'Bonus Rarity',
	'toolBoost.mining': 'Mining',
	'toolBoost.foraging': 'Foraging',
	'toolBoost.farming': 'Farming',
	'toolBoost.cooking': 'Cooking',
	'toolBoost.smithing': 'Smithing',
	'toolBoost.enchanting': 'Enchanting',
	'toolBoost.augmentLevel': 'Augment Max Level',
	'toolBoost.runecrafting': 'Runecrafting',
};

const statsToLabelMapping: Record<TStats, string> = {
	'elite.monsterDifficulty': 'Monster Difficulty',
	'elite.monsterTH': 'Monster Treasure Hunter',
	'elite.locationTH': 'Location Treasure Hunter',
	'elite.scrollWaves': 'Elite Scroll Waves',
	'elite.groupSize': 'Max Group Size',
	'fishing.bait': 'Bait',
	'fishing.reel': 'Reel',
	'fishing.bonusRarity': 'Bonus Rarity',
	'fishing.fishing': 'Fishing',
	'fishing.multBait': 'Bait Multiplier',
	'fishing.multReel': 'Reel Multiplier',
	'fishing.multBonus': 'Bonus Rarity Multiplier',
	'fishing.multFishing': 'Fishing Multiplier',
	'food.tags': 'Tags',
	'food.size': 'Size',
	'food.alchemySize': 'Alchemy Size',
	'food.difficulty': 'Difficulty',
	'food.difficultyXP': 'Difficulty for XP',
	'food.cookingEnchantment': 'Cooking Enchantment',
	'food.alchemyEnchantment': 'Alchemy Enchantment',
	'seed.experience': 'Experience',
	'seed.plotSize': 'Plot Size',
	'seed.harvestTime': 'Harvest Time',
};

const equipmentStatsTypeToLabelMapping: Record<TTooltipEquipmentStats, string> = {
	attackAffinities: 'Offensive Accuracies',
	defenseAffinities: 'Defensive Affinities',
	strengthAffinities: 'Offensive Affinities',
	defenseStats: 'Defensive Stats',
	skillStats: 'Skilling Stats',
	strengthStats: 'Offensive Stats',
};

export function formatEquipmentStats(
	allStats: Partial<Record<TTooltipEquipmentStats, Record<string, number>>> | undefined
) {
	if (!allStats) return <></>;

	const statsHtml: Partial<Record<TTooltipEquipmentStats, React.ReactElement[]>> = {};
	for (const [statTypeString, statValues] of Object.entries(allStats)) {
		const statType = statTypeString as TTooltipEquipmentStats;
		const statTypeHtml: React.ReactElement[] = (statsHtml[statType] = []);
		const sortedStatValues = sortDictionaryByArray(statValues, Object.keys(equipmentStatsToLabelMapping));
		for (const [type, bonus] of Object.entries(sortedStatValues)) {
			const bonusValue = Number(bonus);
			if (bonusValue === 0) continue;

			let htmlClass = '';
			if (type.startsWith('offensiveDamageAffinity') || type.startsWith('defensiveDamageAffinity')) {
				if (bonusValue === 1) continue;
				htmlClass = bonusValue > 1 ? 'tooltip-stat-positive' : 'tooltip-stat-negative';
			}
			const formattedBonus = formatStat(type, bonusValue);
			statTypeHtml.push(
				<span className={htmlClass}>
					{formattedBonus}{' '}
					{equipmentStatsToLabelMapping[type as keyof typeof equipmentStatsToLabelMapping] ?? type}
				</span>
			);
		}
	}

	return (
		<div className='tooltip-stats'>
			{Object.entries(statsHtml).map(([statType, statValues]) => (
				<div key={statType}>
					<div className={'tooltip-combat-stat-header'} hidden={statValues.length === 0}>
						{equipmentStatsTypeToLabelMapping[statType as TTooltipEquipmentStats] ?? statType}
					</div>
					<div className={`tooltip-stats-${statType} tooltip-columns`} key={statType}>
						{statValues.map((statValue, index) => (
							<span key={index}>{statValue}</span>
						))}
					</div>
				</div>
			))}
		</div>
	);
}

export function formatWeaponStats(weaponStats: Partial<Record<TWeaponStats, string>>) {
	if (Object.keys(weaponStats).length === 0) return <></>;

	return (
		<div className='tooltip-weapon-info tooltip-columns'>
			<span>{weaponStats.style} Damage</span>
			<span>Speed {weaponStats.speed}</span>
			<span hidden={!weaponStats.minHitMults}>Min Hit {weaponStats.minHitMults}</span>
			<span hidden={!weaponStats.maxHitMults}>Max Hit {weaponStats.maxHitMults}</span>
		</div>
	);
}

export function formatStats(allStats: Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined) {
	if (!allStats) return <></>;

	const statsHtml: Partial<Record<TTooltipStats, string[]>> = {};
	for (const [statTypeString, statValues] of Object.entries(allStats)) {
		const statType = statTypeString as TTooltipStats;
		const statTypeHtml: React.ReactElement[] = (statsHtml[statType] = []);
		for (const [type, bonus] of Object.entries(statValues)) {
			const customClassName = type.includes('Enchantment') ? 'tooltip-enchant' : undefined;
			statTypeHtml.push(
				<span>
					{statsToLabelMapping[type as TStats] ?? type}:{' '}
					{customClassName ? <span className={customClassName}>{bonus}</span> : bonus}
				</span>
			);
		}
	}

	return (
		<div className='tooltip-stats'>
			{Object.entries(statsHtml).map(([statType, statValues]) => (
				<div className={`tooltip-stats-${statType}`} key={statType}>
					{Object.entries(statValues).map(([id, statValue]) => (
						<div key={id}>{statValue}</div>
					))}
				</div>
			))}
		</div>
	);
}

const sentenceEnd = [',', '.', '!', '?'];

export function combineItemSetDescription(desc1: string | number, desc2: string | number) {
	if (typeof desc1 === 'number') desc1 = String(desc1);
	if (typeof desc2 === 'number') desc2 = String(desc2);
	const split1 = desc1.split(' ');
	const split2 = desc2.split(' ');
	let newDesc = '';
	for (let i = 0; i < split1.length; i++) {
		const word1 = split1[i];
		const word2 = split2[i];
		if (word1 === word2) {
			newDesc += word1;
		} else {
			if (word1[0] === '[') {
				const insert1 = word1.split(']');
				newDesc += insert1[0];
				newDesc += '|';
				if (sentenceEnd.includes(word2[word2.length - 1])) {
					newDesc += word2.substring(0, word2.length - 1);
				} else {
					newDesc += word2;
				}
				newDesc += ']';
				newDesc += insert1[1];
			} else if (sentenceEnd.includes(word1[word1.length - 1])) {
				newDesc += '[';
				newDesc += word1.substring(0, word1.length - 1);
				newDesc += '|';
				newDesc += word2.substring(0, word2.length - 1);
				newDesc += ']';
				newDesc += word1.substring(word1.length - 1);
			} else {
				newDesc += `[${word1}|${word2}]`;
			}
		}
		newDesc += ' ';
	}
	newDesc = newDesc.substring(0, newDesc.length - 1);
	return newDesc;
}

export function renderRequirement(equipped: number, required: string) {
	const split = required.split(' ');
	const match = equipped >= Number(split[0]) ? Number(split[0]) : 0;
	if (!match && split.length === 1) {
		return equipped + '/' + split[0];
	}
	if (split.length === 1) {
		return (
			<>
				<span className='tooltip-set-effect-active'>{match}</span>/{split[0]}
			</>
		);
	}
	if (match) {
		split.splice(0, 1);
		return (
			<>
				{'[ '}
				<span className='tooltip-set-effect-active'>{match} </span>
				{split.join(' ')}
				{' ]'}
			</>
		);
	}
	return '[ ' + required + ' ]';
}

export function renderDescription(match: boolean, description: string, defaultDescription: string) {
	const splitDesc = description.split('[');
	if (!match) {
		return description;
	}
	if (splitDesc.length === 1) {
		const split1 = description.split(' ');
		const split2 = defaultDescription.split(' ');
		return [
			split1.map((str, index) => {
				if (str === split2[index]) {
					return str + ' ';
				}
				if (sentenceEnd.includes(str[str.length - 1])) {
					return (
						<React.Fragment key={index}>
							<span className='tooltip-set-effect-active'>{str.substring(0, str.length - 1)}</span>
							{str.substring(str.length - 1)}{' '}
						</React.Fragment>
					);
				}
				return (
					<span key={index} className='tooltip-set-effect-active'>
						{str}{' '}
					</span>
				);
			}),
		];
	}
	const firstDesc = splitDesc[0];
	splitDesc.splice(0, 1);
	return [
		firstDesc,
		...splitDesc.map((str, index) => {
			const split = str.split('|');
			const first = split[0];
			split.splice(0, 1);
			const last = split[split.length - 1].split(']');
			split.splice(split.length - 1, 1);
			return (
				<React.Fragment key={index}>
					<span className='tooltip-set-effect-inactive'>{'['}</span>
					<span className='tooltip-set-effect-active'>{first}</span>
					<span className='tooltip-set-effect-inactive'>
						{'|'}
						{split.join('|')}
						{last[0]}
						{']'}
					</span>
					{last[1]}
				</React.Fragment>
			);
		}),
	];
}

export function formatItemSets(itemSets: Record<string, string | number>[], compactVersion: boolean) {
	if (compactVersion || itemSets.length === 0) return <></>;
	let previousSet: string | number = '';
	let previousRequired = '';
	let previousDescription = '';
	let match = false;
	const setsInfo: React.ReactElement[] = [];
	for (const itemSet of itemSets) {
		let defaultDescription = '';
		for (const idx in enchantmentsList) {
			if (enchantmentsList[idx].name === itemSet.name) {
				defaultDescription = enchantmentsList[idx].getTooltip(0, 0);
			}
		}
		if (previousSet !== itemSet.name) {
			previousDescription = String(itemSet.description);
			previousRequired = String(itemSet.required);
			match = itemSet.equipped >= itemSet.required;
		} else {
			previousDescription = combineItemSetDescription(previousDescription, itemSet.description);
			previousRequired += ' | ' + itemSet.required;
			setsInfo.pop();
		}

		const activeClass = match ? '' : 'tooltip-set-effect-inactive';
		const set = (
			<div key={`${itemSet.name}-${itemSet.required}`}>
				<div className='tooltip-item-set-name'>
					{itemSet.name} ({itemSet.equipped}):{' '}
					<span className='tooltip-set-effect-inactive'>
						{renderRequirement(Number(itemSet.equipped), previousRequired)}
					</span>
				</div>
				{
					<span className={activeClass}>
						{renderDescription(match, previousDescription, defaultDescription)}
					</span>
				}
			</div>
		);
		previousSet = itemSet.name;
		setsInfo.push(set);
	}

	return <div className='tooltip-item-set'>{setsInfo}</div>;
}

export function formatSeedYield(
	seedYield: { name: string; productionMin: number; productionMax: number; chance: number }[]
) {
	if (seedYield.length === 0) return <></>;

	const yields = seedYield.map((production, index) => {
		let productionText = `${production.productionMin}-${production.productionMax}`;
		if (production.productionMin === 1 && production.productionMax === 1) productionText = '1';

		return (
			<span key={index}>
				{productionText} {production.name}
				<span className='tooltip-yield-chance' hidden={Number(production.chance) === 1}>
					{' '}
					{formatMultiplierStat(production.chance)}%
				</span>
			</span>
		);
	});

	return <div className='tooltip-yield tooltip-columns'>{yields}</div>;
}

export function formatEnchant(
	enchant: Record<TEnchant, string | number> | undefined,
	enchantmentTier: number | undefined
) {
	if (!enchantmentTier && !enchant) return <></>;

	return (
		<div className='tooltip-enchant'>
			{enchant && (
				<span className={`tooltip-enchant-active enchanted-text-modern-${enchant.enchantType}`}>
					{enchant.enchantName} {enchant.enchantStrength}
					{enchantmentTier && enchantmentTier !== enchant.enchantStrength ? `/${enchantmentTier}` : ''}
				</span>
			)}
			<span
				className='tooltip-enchant-unactive'
				hidden={!enchantmentTier || (enchant && enchant.enchantStrength > 0)}
			>
				Enchant slots: {enchantmentTier}
			</span>
		</div>
	);
}

export function formatEffects(enchant: Record<TEnchant, string | number> | undefined, isCompact: boolean) {
	if (isCompact || !enchant) return <></>;

	return (
		<div className='tooltip-effects'>
			<div>
				<span className='tooltip-effects-name'>
					{enchant.enchantName} {enchant.enchantStrength}:
				</span>{' '}
				<span className='tooltip-effects-description'>{enchant.enchantDescription}</span>
			</div>
		</div>
	);
}

export function formatFoodEnchant(foodEnchant: Record<TFoodEnchant, string | number | boolean> | undefined) {
	if (!foodEnchant) return <></>;

	return (
		<div className={'tooltip-food-enchant'}>
			<div>
				Grants {foodEnchant.stacks} buff stack{foodEnchant.stacks > 1 ? 's' : ''}
			</div>
			{foodEnchant.combat_only ? <div>Buff only granted when consumed in combat.</div> : <></>}
		</div>
	);
}

export function formatHealing(healing: Record<THealingStats, number> | undefined) {
	if (!healing) return <></>;

	return (
		<div className='tooltip-food-healing'>
			<div className='tooltip-healing-use'>Use: Heals {healing.healing} hp</div>
			<div>
				Also applies a Heal over Time effect healing{' '}
				<span className='tooltip-healing-use'>{healing.healTick} hp</span> every {healing.tickDelay.toFixed(2)}s
				over {(healing.totalTicks * healing.tickDelay).toFixed(2)} seconds.
			</div>
			<div>
				Total healing:{' '}
				<span className='tooltip-healing-use'>{healing.healing + healing.healTick * healing.totalTicks}</span>{' '}
				HP
			</div>
			<div>Cooldown: {toFixedLocale(healing.cooldown)}s</div>
		</div>
	);
}

export function formatRequirements(requiredStats: Partial<Record<TSkillName, number>>, skills: TSkills) {
	if (Object.keys(requiredStats).length === 0) return <></>;

	let fulfilledClass = 'tooltip-requirement-fulfilled';
	const requirements = Object.entries(requiredStats)
		.map(([skill, requiredLevel]) => {
			if (skills[skill as TSkillName].level < requiredLevel) fulfilledClass = 'tooltip-requirement-unfulfilled';
			return `${requiredLevel} ${skill}`;
		})
		.join(', ');

	return <div className={`tooltip-requirements ${fulfilledClass}`}>Requires {requirements}</div>;
}

export function formatAffixes(affixes: IAffix[], itemId: number) {
	if (Object.keys(affixes).length === 0) return <></>;
	const itemData = itemList[itemId];

	return (
		<>
			<div className='toolip-separator'></div>
			<div className='tooltip-item-affixes'>
				<div className={'tooltip-combat-stat-header'}>Affixes</div>
				{affixes.map((affix) => {
					const affixData = affixList[affix.path];
					return (
						<div className='tooltip-item-affix' key={affix.path}>
							{TooltipLiterals.affixesLiterals[affix.path]}:{' '}
							{formatEquipmentAffixStrength(affixData, affix.value, itemId)}
							{affixIsStarWorthy(affixData, affix.value, itemData) && <span>&#10031;</span>}
						</div>
					);
				})}
			</div>
		</>
	);
}

export function formatAugmentCounter(augmentCounter: number, item: TPartialItemWithItemID<IItem>) {
	if (augmentCounter === 0) return <></>;
	const itemData = itemList[item.itemID];
	const augmentThreshold = getAugmentingTrialCount(item as IItem, itemData);

	return (
		<div className='tooltip-item-augment-counter'>
			<span className='tooltip-augment-label'>Augment Counter</span>{' '}
			<span className='tooltip-item-augment'>
				{augmentCounter} / {augmentThreshold}
			</span>
		</div>
	);
}
