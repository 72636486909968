import React from 'react';
import { socket } from '../../../../services/socket.service';
import { usePlayerField } from '../../../../hooks/hooks';
import { itemAmounts, itemImage } from '../../../../helper/itemHelperFunctions';
import { itemList } from '../../../../utils/itemList';
import { Box, Flex, Switch } from '@chakra-ui/react';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { cookingList } from '../../../../utils/cookingList';
import { useState } from 'react';
import { validPrepIngredient } from '../../../../utils/cookingFunctions';
import { useSetRecoilState } from 'recoil';
import { cookingPreparationItemAtom } from '../../../../atoms/cookingPreparationItemAtom';
import { settingsList } from '../../../../utils/settingsList';
import { tabs } from '../../../../helper/NavigationTabsList';

export default function PreparationList() {
	const stockpile = usePlayerField('stockpile');
	const prepareItems = usePlayerField('cookingPot');
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const [disableButton, setDisableButton] = useState(false);
	const difficultySort = settings.cooking.sort;
	const cookingLevel = skills.cooking.level;
	const filteredItems = stockpile.filter((item) => {
		if (!validPrepIngredient(item.itemID)) return false;
		const cookingData = cookingList[item.itemID];
		if (settings.cooking.hidebuffs && (cookingData.alchemyEnchantment || cookingData.cookingEnchantment)) {
			return false;
		}
		return true;
	});
	const allSelected =
		prepareItems.length >=
		filteredItems.filter((item) => (cookingList[item.itemID].level ?? 1) <= cookingLevel).length;
	const setSelectedItem = useSetRecoilState(cookingPreparationItemAtom);

	filteredItems.sort((a, b) => {
		const indexA = prepareItems.findIndex((i) => i === a.itemID);
		const indexB = prepareItems.findIndex((i) => i === b.itemID);
		if (indexA === -1 && indexB !== -1) return 1;
		if (indexA !== -1 && indexB === -1) return -1;
		if (difficultySort === 3) {
			const difA = cookingList[a.itemID].difficulty ?? 0;
			const difB = cookingList[b.itemID].difficulty ?? 0;
			if (difA - difB) return difB - difA;
		}
		if (difficultySort === 2) {
			const lvlA = cookingList[a.itemID].level ?? 0;
			const lvlB = cookingList[b.itemID].level ?? 0;
			if (lvlA - lvlB) return lvlA - lvlB;
		}
		return indexA - indexB;
	});

	const preparationList = filteredItems.map((item, index) => {
		const itemID = item.itemID;
		const itemData = itemList[itemID];

		const cookingData = cookingList[itemID];
		const prepared = prepareItems.includes(itemID);
		const requiredLevel = cookingData.level ?? 1;
		const preparable = skills.cooking.level >= requiredLevel;

		const boxShadow = prepared ? 'inset 0 0 2px 5px #009900' : '';
		return (
			<Box
				key={'prepare-' + itemID + '-' + index}
				onClick={() => preparable && selectItem(itemID, prepared)}
				onPointerEnter={() => (index || !prepared ? setSelectedItem(itemID) : setSelectedItem(null))}
				onPointerOut={() => setSelectedItem(null)}
				className={`item ${itemData.class}`}
				boxShadow={boxShadow}
			>
				{itemImage(itemData)}
				{!preparable && (
					<Box className='insufficient-level' fontSize='14px' position='absolute' left='2px'>
						<img className='crafting-tab-icon' src={tabs.cooking.icon} alt='cooking-level' />
						{requiredLevel}
					</Box>
				)}
				{itemAmounts(item.stackSize)}
				<ItemTooltip item={item} />
			</Box>
		);
	});

	function selectItem(itemID: number, prepared: boolean) {
		setSelectedItem(itemID);
		if (prepared) {
			socket.emit('cooking:removeFromPot', { itemID });
		} else {
			socket.emit('cooking:addToPot', { itemID });
		}
	}

	function emptyPot() {
		if (!disableButton) {
			setDisableButton(true);
			if (allSelected) {
				socket.emit('cooking:emptyPot');
			} else {
				socket.emit('cooking:fillPot');
			}
			setTimeout(() => {
				setDisableButton(false);
			}, 1000);
		}
	}

	function toggleHideBuff(checked: boolean) {
		socket.emit('cooking:hideBuffs', checked);
	}

	// null: no sort, 0: prep items evenly
	// null -> 0 -> 1 -> -1 -> repeat
	function toggleSort() {
		let sort = difficultySort;
		sort++;
		if (sort >= (settingsList['cooking.sort'].entries?.length ?? 0)) {
			sort = 0;
		}
		socket.emit('settings:update', {
			value: sort,
			path: 'cooking.sort',
		});
	}

	return (
		<IdlescapeContainer
			display='flex'
			flexDirection='column'
			height='100%'
			flex='2 0 300px'
			className='anchor-cooking-preparation-list'
		>
			<Box as='h2' textAlign='center'>
				Preparation Queue
			</Box>
			<Flex className='preparation-buttons' gap='5px' flexWrap='wrap' justifyContent='center'>
				<IdlescapeButton onClick={emptyPot} size='medium' variant={disableButton ? 'disabled' : 'orange'}>
					{allSelected ? 'Clear Selection' : 'Select All'}
				</IdlescapeButton>
				<IdlescapeButton onClick={toggleSort} size='medium' variant='blue'>
					{(settingsList['cooking.sort'].entries ?? [])[settings.cooking.sort]}
				</IdlescapeButton>
				<Flex alignItems='center' gap='5px' margin='10px 0'>
					Hide buff ingredients
					<Switch
						id='preparation-hide-buffs'
						isChecked={settings.cooking.hidebuffs}
						onChange={(e) => toggleHideBuff(e.target.checked)}
					/>
				</Flex>
			</Flex>
			<Box className='all-items' overflowY='auto'>
				{preparationList}
			</Box>
		</IdlescapeContainer>
	);
}
