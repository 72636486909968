import { Flex, Heading } from '@chakra-ui/react';
import AllItems from '../AllItems2';
import CombatAmmoStockpile from './CombatAmmoStockpile';

export default function CombatAmmoInventory({ combatView = false }: { combatView?: boolean }) {
	if (!combatView) {
		return (
			<Flex justifyContent='center' flexWrap='wrap'>
				<div className='combat-gear-inventory-wrapper idlescape-container idlescape-container-light'>
					<Heading textAlign='center' size='md'>
						Ammo Pouch
					</Heading>
					<AllItems hideCurrency hideSorting inventoryName='ammo' />
				</div>
				<CombatAmmoStockpile />
			</Flex>
		);
	}
	return (
		<div className='combat-gear-inventory-wrapper idlescape-container idlescape-container-light'>
			<AllItems hideCurrency hideSorting inventoryName='ammo' />
		</div>
	);
}
