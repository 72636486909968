import { Box, Grid, Heading, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { Carousel } from 'react-responsive-carousel';
import { itemList } from '../../../../utils/itemList';
import { chatIconList } from '../../../../utils/chatIconList';
import { platinumShopList } from '../../../../utils/platinumShopList';
import ChatItem from '../../Chat/Links/ChatItem';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { CombatAvatar } from '../../Combat/CombatAvatar2';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';

export const ItemPreview = (props: { activeItemID: number; setActiveItem: (value: number) => void }) => {
	const shopData = platinumShopList[props.activeItemID];
	const itemData = shopData.itemID && shopData.itemID.length === 1 ? itemList[shopData?.itemID[0]] : null;
	const iconData = shopData.iconID && shopData.iconID.length === 1 ? chatIconList[shopData?.iconID[0]] : null;
	const [previewActive, setPreviewActive] = useState(false);
	const [showModal, setShowModal] = useState(false);

	function handlePurchase() {
		socket.emit('platinum-shop:purchase', { shopId: shopData.id });
		setShowModal(false);
	}

	function getShopItemName() {
		if (shopData.itemID && shopData.itemID.length === 1) {
			return itemList[shopData.itemID[0]].name;
		}
		if (shopData.iconID && shopData.iconID.length === 1) {
			return chatIconList[shopData.iconID[0]].name;
		}
		// if it's longer than 1, it's a bundle
		if (shopData.itemID && shopData.itemID.length > 1) {
			return 'Item Bundle (' + shopData.itemID.map((id) => itemList[id].name).join(', ') + ')';
		}
		if (shopData.iconID && shopData.iconID.length > 1) {
			return 'Icon Bundle (' + shopData.iconID.map((id) => chatIconList[id].name).join(', ') + ')';
		}
	}

	return (
		<IdlescapeContainer variant='platinum' zIndex={1} position={'relative'}>
			{previewActive && shopData.itemID && (
				<PreviewPopup itemID={shopData.itemID} setShowModal={setPreviewActive}></PreviewPopup>
			)}
			<IdlescapeButton
				onClick={() => props.setActiveItem(0)}
				variant='gray'
				position={'absolute'}
				width={'100px'}
				left={'30px'}
				top={'30px'}
			>
				Back
			</IdlescapeButton>
			<Heading
				background={
					'linear-gradient(to right, rgba(0, 225, 255, 0) 0, rgba(0, 40, 60, 0.8) 20%, rgba(0, 0, 0, 1) 50%, rgba(0, 40, 60, 0.8) 80%, rgba(0, 225, 255, 0) 100%);'
				}
				maxWidth={'60%'}
				marginTop={'0'}
				margin={'0 auto'}
			>
				{shopData.name || itemData?.name || iconData?.name}
			</Heading>
			<Grid gridTemplateColumns={'minmax(200px, 0.5fr) 1fr'} marginTop={'20px'} marginBottom={'20px'}>
				<Box position={'relative'}>
					<Box position={'relative'}>
						<Carousel
							autoPlay={true}
							showStatus={false}
							dynamicHeight={true}
							infiniteLoop={true}
							showThumbs={false}
						>
							{shopData.images.map((image, index) => {
								return (
									<Box
										position={'relative'}
										key={image + index}
										filter={'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.9))'}
										maxWidth={'200px'}
										maxHeight={'200px'}
										overflow={'hidden'}
										margin={'auto'}
									>
										<img
											src={image}
											alt={`Preview image ${index + 1} of ${shopData.images.length}`}
											style={{ maxHeight: '200px', width: 'auto' }}
										/>
										{shopData.itemID?.length === 1 && (
											<ItemTooltip item={{ itemID: shopData.itemID[0] }} />
										)}
									</Box>
								);
							})}
						</Carousel>
					</Box>

					{itemData?.equipmentStats?.isTransmog ||
					(shopData.itemID &&
						shopData.itemID?.length > 1 &&
						itemList[shopData.itemID[0]].equipmentStats?.isTransmog) ? (
						<IdlescapeButton
							onClick={() => setPreviewActive(true)}
							variant='blue'
							maxWidth={'100px'}
							margin={'0 auto'}
						>
							Preview
						</IdlescapeButton>
					) : null}
				</Box>

				<Box fontSize={'24px'} position={'relative'}>
					<Text fontSize={'24px'}>{shopData.description || itemData?.extraTooltipInfo}</Text>
					{itemData && itemData.equipmentStats && itemData.equipmentStats.fullTransmog ? (
						<Box width={'100%'} position={'absolute'} bottom={0} margin={'0 auto'}>
							<Text>Full Cosmetic Override</Text>
						</Box>
					) : null}
				</Box>
			</Grid>
			<Box marginY='1em'>
				{shopData.price.toLocaleString('en-us')} <ChatItem item={{ itemID: 20, name: 'Platinum' }}></ChatItem>
			</Box>
			<IdlescapeButton onClick={() => setShowModal(true)} maxWidth={'200px'} margin={'0 auto'} variant='green'>
				Purchase
			</IdlescapeButton>

			<Dialog open={showModal} onClose={() => setShowModal(false)} className='sell-item-dialog'>
				<DialogTitle>Purchase Confirmation</DialogTitle>
				<DialogContent>
					<Text>Are you sure you want to purchase {getShopItemName()}?</Text>
					<Carousel
						autoPlay={true}
						showStatus={false}
						dynamicHeight={true}
						infiniteLoop={true}
						showThumbs={false}
					>
						{shopData.images.map((image, index) => {
							return (
								<Box
									position={'relative'}
									key={image + index}
									filter={'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.9))'}
									maxWidth={'200px'}
									maxHeight={'200px'}
									overflow={'hidden'}
									margin={'auto'}
								>
									<img
										src={image}
										alt={`Preview image ${index + 1} of ${shopData.images.length}`}
										style={{ maxHeight: '200px', width: 'auto' }}
									/>
									{shopData.itemID?.length === 1 && (
										<ItemTooltip item={{ itemID: shopData.itemID[0] }} />
									)}
								</Box>
							);
						})}
					</Carousel>
				</DialogContent>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={() => setShowModal(false)}>
						Close
					</IdlescapeButton>
					<IdlescapeButton
						variant={'green'}
						onClick={() => {
							handlePurchase();
						}}
					>
						Confirm
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</IdlescapeContainer>
	);
};

const PreviewPopup = (props: { itemID: number[]; setShowModal: (value: boolean) => void }) => {
	const equipment = usePlayerField('equipment');
	const settings = usePlayerField('settings');
	const selectedCosmetics = settings.cosmetic.selectedCosmetics;

	const cosmetics = { ...selectedCosmetics };

	// If any of the cosmetics are full transmogs, remove it
	for (const [slot, itemID] of Object.entries(cosmetics)) {
		const item = itemList[itemID];
		if (item.equipmentStats?.fullTransmog) {
			delete cosmetics[slot as keyof typeof cosmetics];
		}
	}
	for (const id of props.itemID) {
		const itemData = itemList[id];
		if (!itemData.equipmentStats) continue;
		cosmetics[itemData.equipmentStats?.slot] = itemData.id;
	}

	return (
		<Dialog
			open={true}
			onClose={() => props.setShowModal(false)}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			<Box marginTop={'50px'} width={'200px'}>
				<CombatAvatar cosmetics={cosmetics} equipment={equipment} />
			</Box>
			<IdlescapeButton
				variant='red'
				width={'150px'}
				textAlign={'center'}
				marginTop={'20px'}
				onClick={() => props.setShowModal(false)}
				zIndex={10}
			>
				Close
			</IdlescapeButton>
		</Dialog>
	);
};
