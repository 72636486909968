import { atom } from 'recoil';
import {
	IChannelSettings,
	IChatMessage,
	ISystemMessage,
	IWhisperMessage,
} from '../../../../game-server/src/modules/chat/Chat.interface';

export type TClientMessage = (IChatMessage | ISystemMessage | IWhisperMessage) & {
	read?: boolean;
	hasMention?: boolean;
};

export const messageAtom = atom({
	key: 'messageAtom',
	default: {} as {
		[channelId: number | string]: [settings: IChannelSettings & { read?: boolean }, ...indexes: TClientMessage[]];
	},
	// log the value on change
	// effects_UNSTABLE: [
	// 	({ onSet }) => {
	// 		onSet((newValue) => {
	// 			console.log('messageAtom', newValue);
	// 		});
	// 	},
	// ],
});
