import { Box, Grid, Input, InputGroup, InputRightAddon, Link, TabPanel } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import Linkify from 'linkify-react';
import React, { useState } from 'react';
import { AiFillCloseCircle, AiFillEdit, AiFillSave } from 'react-icons/ai';
import { IAccountNote } from '../../../../../../game-server/src/repositories/AccountNote.repository';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';

function Notes(props: {
	notes?: IAccountNote[];
	accountId?: number;
	accountName?: string;
	editNote: (note: IAccountNote) => void;
	removeNote: (note: IAccountNote) => void;
}) {
	const [editNote, setEditNote] = useState<IAccountNote | null>(null);

	const createdBy = usePlayerField('username');
	const accountStatus = usePlayerField('accountStatus');
	const isChatMod = accountStatus === 'chat-mod';

	function handleSaveNote() {
		if (editNote) {
			let command: string;
			if (editNote.id !== -1) {
				if (editNote.note.length === 0) {
					command = '/removenote ' + editNote.id;
					props.removeNote(editNote);
				} else {
					command = '/editnote ' + editNote.id + ' ' + editNote.note;
					props.editNote(editNote);
				}
			} else {
				if (editNote.note.length === 0) return;
				command = '/addnote ' + props.accountName + ' ' + editNote.note;
			}

			const commandToSend = {
				channelId: 1,
				commandString: command,
			};

			socket.emit('chat:command:send', commandToSend);
		}
		setEditNote(null);
	}

	function renderNote(note: IAccountNote, index?: number) {
		return (
			<React.Fragment key={index}>
				<Box>{note.createdBy}</Box>
				<Box>{new Date(note.created).toLocaleString()}</Box>
				{editNote && editNote.id === note.id ? (
					<InputGroup gridColumn='span 2'>
						<Input
							value={editNote.note}
							onChange={(e) => setEditNote({ ...editNote, note: e.target.value })}
							placeholder='Enter note'
						/>
						<InputRightAddon gap='3px'>
							<AiFillSave onClick={handleSaveNote} cursor='pointer' />
							<AiFillCloseCircle onClick={() => setEditNote(null)} cursor='pointer' />
						</InputRightAddon>
					</InputGroup>
				) : (
					<>
						<Box wordBreak='break-word'>
							<Linkify options={{ attributes: { rel: 'noopener noreferrer' }, render: renderLink }}>
								{note.note}
							</Linkify>
						</Box>
						{!isChatMod ? <AiFillEdit onClick={() => setEditNote(note)} cursor='pointer' /> : <div />}
					</>
				)}
			</React.Fragment>
		);
	}

	if (!props.notes) return <TabPanel>No notes</TabPanel>;
	// if (!props.accountId) return <TabPanel>No account id</TabPanel>;
	return (
		<TabPanel>
			<Grid gridTemplateColumns='120px 150px 1fr 20px' gridTemplateRows='25px' alignItems='center' rowGap='2px'>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)'>ISSUED BY</Box>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)'>CREATED</Box>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)' gridColumn='span 2'>
					NOTE
				</Box>
				{editNote && editNote.id === -1 && renderNote(editNote)}
				{props.notes.map(renderNote)}
			</Grid>
			{props.accountId && (!editNote || editNote.id !== -1) && (
				<IdlescapeButton
					variant='blue'
					onClick={() =>
						setEditNote({
							id: -1,
							created: new Date(),
							note: '',
							createdBy,
							accountId: props.accountId ?? -1,
						})
					}
				>
					Add note
				</IdlescapeButton>
			)}
		</TabPanel>
	);
}

const renderLink = ({ attributes, content }: { attributes: { [attr: string]: string }; content: string }) => {
	const { href, ...props } = attributes;
	return (
		<Link color={'teal.500'} href={href} {...props} target={'_blank'}>
			{content}
		</Link>
	);
};

export default Notes;
