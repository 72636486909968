import React from 'react';
import './Cooking.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Cooking from './Cooking';
import Preparation from './Preparation';
import { cookingRecipeAtom } from '../../../../atoms/cookingRecipeAtom';
import { cookingTabAtom } from '../../../../atoms/cookingTabAtom';
import { Box, Flex } from '@chakra-ui/react';
import PreparationList from './PreparationList';
import SubSkillTabs from '../SubSkillTabs';

export default function CookingContainer() {
	const [tab, setTab] = useRecoilState(cookingTabAtom);

	return (
		<div className='cooking-container'>
			<SubSkillTabs
				tabs={[
					{
						id: 'preparation',
						name: 'Preparation',
						image: '/images/cooking/raw_chicken.png',
						imageAlt: 'Preparation logo',
					},
					{
						id: 'cooking',
						name: 'Cooking',
						image: '/images/cooking/cooking_icon.png',
						imageAlt: 'Cooking logo',
					},
					{
						id: 'alchemy',
						name: 'Brewing (Alchemy)',
						image: '/images/cooking/alchemy/rainbow_pot.png',
						imageAlt: 'Alchemy logo',
					},
				]}
				setTab={setTab}
				selected={tab}
				baseStyle={{
					border: '2px solid rgba(255, 180, 0, 0.2)',
					background: 'linear-gradient(to bottom, rgb(68, 50, 0), rgb(110, 70, 0))',
				}}
				selectedStyle={{
					background: 'linear-gradient(to bottom, rgb(44, 30, 0), rgb(80, 60, 0))',
				}}
			/>
			{tab === 'preparation' && (
				<Box height='calc(100% - 20px)'>
					<Flex height='100%' flexWrap='wrap'>
						<Preparation />
						<PreparationList />
					</Flex>
				</Box>
			)}
			{tab === 'cooking' && <Cooking />}
			{tab === 'alchemy' && <Cooking isAlchemy={true} />}
		</div>
	);
}
