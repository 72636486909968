import React, { useEffect, useState } from 'react';
import { leagueList } from '../../../../utils/leagueList';

import { socket } from '../../../../services/socket.service';
import {
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { IAccountMute } from '../../../../../../game-server/src/repositories/AccountMute.repository';
import { IAccountBan } from '../../../../../../game-server/src/repositories/AccountBan.repository';
import { IAccountTradeban } from '../../../../../../game-server/src/repositories/AccountTradeban.repository';
import TradeHistory from './TradeHistory';
import ChatHistory from './ChatHistory';
import Notes from './Notes';
import { IUsernameChange } from '../../../../../../game-server/src/repositories/UsernameChange.repository';
import { IAccount } from '../../../../../../game-server/src/modules/account/account.interface';
import { IAccountNote } from '../../../../../../game-server/src/repositories/AccountNote.repository';
import { IUser } from '../../../../../../game-server/src/repositories/User.repository';
import { IChatChannel } from '../../../../../../game-server/src/repositories/ChatChannel.repository';
import Punishments from './Punishments';
import { usePlayerField } from '../../../../hooks/hooks';
import { TAccountPunishment, TPunishmentType } from '../../../../../../game-server/src/modules/moderator/Moderator';
import Inventories from './Inventories';
import MarketListings from './MarketListings';
import GeneralShopHistory from './GeneralShopHistory';
import { IAccountShadowMute } from '../../../../../../game-server/src/repositories/AccountShadowMute.repository';

function Details() {
	const moderatorName = usePlayerField('username');
	const accountStatus = usePlayerField('accountStatus');
	const isChatMod = accountStatus === 'chat-mod';
	const [muteHistory, setMuteHistory] = useState<IAccountMute[] | undefined>(undefined);
	const [shadowMuteHistory, setShadowMuteHistory] = useState<IAccountShadowMute[] | undefined>(undefined);
	const [tradebanHistory, setTradebanHistory] = useState<IAccountTradeban[] | undefined>(undefined);
	const [banHistory, setBanHistory] = useState<IAccountBan[] | undefined>(undefined);
	const [usernameHistory, setUsernameHistory] = useState<IUsernameChange[] | undefined>(undefined);
	const [notes, setNotes] = useState<IAccountNote[] | undefined>(undefined);
	const [characterData, setCharacterData] = useState<IUser[] | undefined>(undefined);
	const [currentCharacter, setCurrentCharacter] = useState<number>(-1);
	const [accountData, setAccountData] = useState<IAccount | undefined>(undefined);
	const [chatChannels, setChatChannels] = useState<IChatChannel[]>([]);
	const categories = [
		'Mutes',
		'Chat History',
		'Notes',
		'Shadow Mutes',
		'Bans',
		'Tradebans',
		'Name History',
		'Trade History',
		'General Shop History',
		'Market Listings',
		'Inventories',
	] as const;
	const chatModCategories = ['Mutes', 'Chat History', 'Notes'] as const;
	const selectedCharacter = characterData?.find((el) => el.id === currentCharacter);

	const { isOpen, onOpen, onClose } = useDisclosure();
	useEffect(() => {
		socket.on('moderator:details:view', (data) => {
			setMuteHistory(data.muteHistory);
			setTradebanHistory(data.tradebanHistory);
			setBanHistory(data.banHistory);
			setShadowMuteHistory(data.shadowMuteHistory);
			setUsernameHistory(data.usernameHistory);
			setNotes(data.notes);
			setCharacterData(data.characterData);
			setCurrentCharacter(data.currentCharacter);
			setAccountData(data.accountData);
			setChatChannels(data.chatChannels);
			onOpen();
		});
		socket.on('moderator:note:add', (note: IAccountNote) => {
			addNote(note);
		});
		socket.on('moderator:punishment:edit', (data) => {
			editPunishment(data.type, data.punishment);
		});

		return () => {
			socket.off('moderator:details:view');
			socket.off('moderator:note:add');
			socket.off('moderator:punishment:edit');
		};
	}, []);

	function editPunishment(type: TPunishmentType, punishment: TAccountPunishment) {
		const editCallback = (prev: TAccountPunishment[] | undefined) => {
			return prev?.map((el) => {
				if (el.id === punishment.id) {
					return punishment;
				}
				return el;
			});
		};
		punishment.issuedBy = moderatorName;
		switch (type) {
			case 'mute':
				setMuteHistory(editCallback);
				break;
			case 'ban':
				setBanHistory(editCallback);
				break;
			case 'tradeban':
				setTradebanHistory(editCallback);
				break;
			case 'shadowmute':
				setShadowMuteHistory(editCallback);
				break;
		}
	}

	function addNote(note: IAccountNote) {
		setNotes((prev) => {
			return [note, ...(prev ?? [])];
		});
	}

	function editNote(note: IAccountNote) {
		if (!notes) {
			return;
		}
		setNotes((prev) => {
			return prev?.map((el) => {
				if (el.id === note.id) {
					return note;
				}
				return el;
			});
		});
	}

	function removeNote(note: IAccountNote) {
		if (!notes) {
			return;
		}
		setNotes((prev) => {
			return prev?.filter((el) => el.id !== note.id);
		});
	}

	function renderCharacterSelection() {
		if (!characterData) return null;

		return (
			<Grid gridTemplateColumns='repeat(auto-fill, minmax(200px, 1fr))' justifyContent='center'>
				{characterData.map((character) => {
					return (
						<IdlescapeContainer
							key={character.id}
							variant={currentCharacter === character.id ? 'platinum' : 'primary'}
							onClick={() => setCurrentCharacter(character.id)}
						>
							{character.username}
							<img
								className='header-league-icon'
								src={leagueList[character.league].icon}
								alt={leagueList[character.league].name}
							/>
						</IdlescapeContainer>
					);
				})}
			</Grid>
		);
	}

	function renderCategorySelection() {
		return (
			<TabList flexWrap='wrap'>
				{isChatMod
					? chatModCategories.map((category) => <Tab key={category}>{category}</Tab>)
					: categories.map((category) => <Tab key={category}>{category}</Tab>)}
			</TabList>
		);
	}

	function renderUsernameHistory() {
		if (!usernameHistory) return null;
		return (
			<TabPanel>
				<TableContainer>
					<Table size='small'>
						<Thead>
							<Tr>
								<Th>OLD USERNAME</Th>
								<Th>NEW USERNAME</Th>
								<Th>DATE CHANGED</Th>
							</Tr>
						</Thead>
						<Tbody>
							{usernameHistory.map((name) => (
								<Tr key={name.id}>
									<Td>{name.oldUsername}</Td>
									<Td>{name.newUsername}</Td>
									<Td>{new Date(name.date).toLocaleString()}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</TabPanel>
		);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} variant='dark'>
			<ModalOverlay />
			<ModalContent userSelect='text' width='clamp(80%,1100px,100%)' maxWidth='unset'>
				<ModalHeader>
					Details
					<br />
					{`Char ID: ${selectedCharacter?.id} | Char Name: ${selectedCharacter?.username} | Acc ID: ${accountData?.id} | Acc Name: ${accountData?.username}`}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Tabs isLazy lazyBehavior={'keepMounted'}>
						{renderCharacterSelection()}
						{renderCategorySelection()}
						<TabPanels>
							<Punishments
								type='mute'
								punishments={muteHistory}
								editPunishment={editPunishment}
								accountName={accountData?.username}
							/>
							<TabPanel>
								{selectedCharacter ? (
									<ChatHistory
										playerId={selectedCharacter.id}
										chatChannels={chatChannels}
										reverse={true}
									/>
								) : (
									'Please select a character above first'
								)}
							</TabPanel>
							<Notes
								notes={notes}
								accountId={accountData?.id}
								accountName={accountData?.username}
								editNote={editNote}
								removeNote={removeNote}
							/>
							<Punishments
								type='shadowmute'
								punishments={shadowMuteHistory}
								editPunishment={editPunishment}
								accountName={accountData?.username}
							/>
							<Punishments
								type='ban'
								punishments={banHistory}
								editPunishment={editPunishment}
								accountName={accountData?.username}
							/>
							<Punishments
								type='tradeban'
								punishments={tradebanHistory}
								editPunishment={editPunishment}
								accountName={accountData?.username}
							/>
							{renderUsernameHistory()}
							<TabPanel>
								{selectedCharacter ? (
									<TradeHistory playerId={selectedCharacter.id} />
								) : (
									'Please select a character above first'
								)}
							</TabPanel>
							<TabPanel>
								{selectedCharacter ? (
									<GeneralShopHistory playerId={selectedCharacter.id} />
								) : (
									'Please select a character above first'
								)}
							</TabPanel>
							<TabPanel>
								{selectedCharacter ? (
									<MarketListings playerId={selectedCharacter.id} key={selectedCharacter.id} />
								) : (
									'Please select a character above first'
								)}
							</TabPanel>
							<TabPanel paddingX='0'>
								{selectedCharacter ? (
									<Inventories
										playerId={selectedCharacter.id}
										username={selectedCharacter.username}
									/>
								) : (
									'Please select a character above first'
								)}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default Details;
