import { Flex, FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { socket } from '../../../../services/socket.service';
import React from 'react';
import { IFarmingSettings } from '../../../../../../game-server/src/modules/skills/farming/Farming.interface';
import { getPlantsOverlappingArea } from '../../../../utils/farmingFunctions';
import { itemList } from '../../../../utils/itemList';
import { usePlayerField } from '../../../../hooks/hooks';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';

function FarmingSettings(props: {
	settings: IFarmingSettings;
	setSettings: (settings: IFarmingSettings) => void;
	selectedArea: { start: { row: number; column: number }; end: { row: number; column: number } } | null;
	setSelectedArea: () => void;
}) {
	const farming = usePlayerField('farming');

	function handleChangeSetting(setting: keyof IFarmingSettings, value: boolean) {
		const newSettings = { ...props.settings, [setting]: value };
		props.setSettings({ ...props.settings, [setting]: value });

		if (props.selectedArea === null) return;
		socket.emit('farming:changeSetting', {
			newSettings,
			changedSetting: setting,
			start: props.selectedArea?.start,
			end: props.selectedArea?.end,
		});
	}

	function remove() {
		if (!props.selectedArea) return;
		socket.emit('farming:removePlants', { ...props.selectedArea });
	}

	function renderSeedName() {
		if (!props.selectedArea) return null;
		const overlappingPlants = getPlantsOverlappingArea(
			farming.plot,
			props.selectedArea?.start,
			props.selectedArea?.end
		);
		if (overlappingPlants.length === 1) {
			const seedData: IItemData = itemList[overlappingPlants[0].seedId];
			if (!seedData.farmingStats) return null;
			// bold text
			return (
				<Flex flexDirection='column' alignItems='center' fontWeight='bold'>
					{seedData.name}{' '}
					{(overlappingPlants[0].stage / seedData.farmingStats.time).toLocaleString('en-us', {
						style: 'percent',
						maximumFractionDigits: 0,
					})}
				</Flex>
			);
		} else if (overlappingPlants.length > 1) {
			let progress: string | null = null;
			for (const plant of overlappingPlants) {
				const seedData: IItemData = itemList[plant.seedId];
				if (!seedData.farmingStats) continue;
				const plantProgress = (plant.stage / seedData.farmingStats.time).toLocaleString('en-us', {
					style: 'percent',
					maximumFractionDigits: 0,
				});
				if (!progress) {
					progress = plantProgress;
				} else if (progress !== plantProgress) {
					progress = '';
					break;
				}
			}
			let seedId: number | null = overlappingPlants[0].seedId;
			for (const plant of overlappingPlants) {
				if (plant.seedId !== seedId) {
					seedId = null;
					break;
				}
			}
			let name = 'plants';
			if (seedId !== null) {
				const seedData: IItemData = itemList[seedId];
				if (seedData.farmingStats) {
					name = seedData.name + 's';
				}
			}
			return (
				<Flex flexDirection='column' alignItems='center' fontWeight='bold'>
					{overlappingPlants.length} {name} {progress}
				</Flex>
			);
		}
		return null;
	}

	return (
		<IdlescapeContainer gridArea='settings'>
			{renderSeedName()}
			<HStack justifyContent='space-between'>
				<Flex flexDirection='column' alignItems='center'>
					<FormLabel htmlFor='farming-auto-harvest' textAlign='center'>
						Auto harvest
					</FormLabel>
					<Switch
						id='farming-auto-harvest'
						isChecked={props.settings.autoHarvest}
						onChange={(e) => handleChangeSetting('autoHarvest', e.target.checked)}
					/>
				</Flex>
				<Flex flexDirection='column' alignItems='center'>
					<FormLabel htmlFor='farming-auto-plant' textAlign='center'>
						Auto plant
					</FormLabel>
					<Switch
						id='farming-auto-plant'
						isChecked={props.settings.autoPlant}
						onChange={(e) => handleChangeSetting('autoPlant', e.target.checked)}
					/>
				</Flex>
				<Flex flexDirection='column' alignItems='center'>
					<FormLabel htmlFor='farming-auto-fertilize' textAlign='center'>
						Auto fertilize
					</FormLabel>
					<Switch
						id='farming-auto-fertilize'
						isChecked={props.settings.autoFertilize}
						onChange={(e) => handleChangeSetting('autoFertilize', e.target.checked)}
					/>
				</Flex>
				{props.selectedArea !== null && (
					<VStack>
						<IdlescapeButton variant='blue' onClick={() => props.setSelectedArea()}>
							Unselect
						</IdlescapeButton>
						<IdlescapeButton variant='red' onClick={remove}>
							Remove
						</IdlescapeButton>
					</VStack>
				)}
			</HStack>
		</IdlescapeContainer>
	);
}

export default FarmingSettings;
