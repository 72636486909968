import { Box, Text } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { useRecoilState } from 'recoil';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { enchantingSelectedItemIdAtom } from '../../../../atoms/enchantingSelectedItemIdAtom';
import { enchantingSelectedScrollIdAtom } from '../../../../atoms/enchantingSelectedScrollIdAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { getSocket } from '../../../../services/socket.service';
import { itemList } from '../../../../utils/itemList';
import { ProgressBar } from '../../../layout/UI/ProgressBar';
import { Item } from '../../Inventory/Item';
import EnchantingItemList from './EnchantingItemList';
import EnchantingScrollList from './EnchantingScrollList';

export default function Enchanting() {
	const socket = getSocket();

	const [selectedItemId, setSelectedItemId] = useRecoilState(enchantingSelectedItemIdAtom);
	const [selectedScrollId, setSelectedScrollId] = useRecoilState(enchantingSelectedScrollIdAtom);
	const [selectedStrength, setSelectedStrength] = useState<number>(1);
	const stockpile = usePlayerField('stockpile');
	const settings = usePlayerField('settings');
	const selectedItem = stockpile.find((item) => item.id === selectedItemId);
	const selectedScroll = stockpile.find((item) => item.id === selectedScrollId);

	useEffect(() => {
		if (selectedItem && !stockpile.some((item) => item.id === selectedItem.id)) {
			setSelectedItemId(-1);
		}
		if (selectedScroll && !stockpile.some((item) => item.id === selectedScroll.id)) {
			setSelectedScrollId(-1);
		}
	}, [stockpile]);

	const selectedItemData = selectedItem ? itemList[selectedItem.itemID] : undefined;
	let selectedScrollData: IItemData | undefined;
	if (selectedScroll && selectedScroll.itemID) {
		selectedScrollData = itemList[selectedScroll.itemID];
	}

	let minStrength = 1;
	if (
		selectedItem?.enchantmentID &&
		selectedItem?.enchantmentStrength &&
		selectedScrollData?.enchantmentID &&
		selectedItem.enchantmentID === selectedScrollData.enchantmentID
	) {
		minStrength = selectedItem.enchantmentStrength + 1;
	}
	let maxStrength = 0;
	if (selectedScroll && selectedItem) {
		const selectedItemData = itemList[selectedItem.itemID];
		const scrollStackSize = selectedScroll.stackSize;
		if (selectedItemData.enchantmentTier) {
			maxStrength = Math.min(selectedItemData.enchantmentTier, minStrength + scrollStackSize - 1);
		}
	}
	if (minStrength > maxStrength) {
		minStrength = maxStrength;
	}

	useEffect(() => {
		// clamp
		const newStrength = Math.max(minStrength, Math.min(selectedStrength, maxStrength));
		if (newStrength !== selectedStrength) {
			setSelectedStrength(newStrength);
		}
	}, [selectedItem, selectedScroll]);

	let buttonIsActive = false;
	if (
		selectedScroll &&
		selectedItem &&
		selectedScroll.enchantmentID !== 0 &&
		selectedStrength !== 0 &&
		(selectedItem?.enchantmentID !== selectedScroll.enchantmentID ||
			selectedItem?.enchantmentStrength !== selectedStrength)
	) {
		buttonIsActive = true;
	}

	let enchantingExperience = 0;
	if (selectedScrollData && selectedScrollData.experience) {
		enchantingExperience = selectedScrollData.experience * (selectedStrength - minStrength + 1);
	}

	function handleSelectedStrength(value: number[]) {
		const newStrength = Math.max(minStrength, Math.min(value[1], maxStrength));
		if (newStrength !== selectedStrength) {
			setSelectedStrength(newStrength);
		}
	}

	function enchant() {
		if (selectedScroll && selectedScrollData?.enchantmentID && selectedItem && selectedStrength !== 0) {
			let trueTarget = selectedStrength;
			if (minStrength !== 1) {
				trueTarget = selectedStrength - minStrength + 1;
			}
			socket.emit('enchanting:enchant', {
				id: selectedItem.id,
				enchantmentID: selectedScrollData.enchantmentID,
				enchantmentStrength: trueTarget,
			});
		}
	}

	function renderItem() {
		let selectedItemClone;
		if (selectedItem) {
			selectedItemClone = cloneDeep(selectedItem);
			if (selectedScrollData) {
				selectedItemClone.enchantmentID = selectedScrollData.enchantmentID;
				selectedItemClone.enchantmentStrength = selectedStrength;
			}
		}
		return (
			<div className={'enchanting-window-item'}>
				{selectedItemClone ? (
					<Item item={selectedItemClone} onClick={() => setSelectedItemId(-1)} />
				) : (
					'None selected'
				)}
			</div>
		);
	}

	function renderScroll() {
		return (
			<div className={'enchanting-window-item'}>
				{selectedScroll ? (
					<Item item={selectedScroll} onClick={() => setSelectedScrollId(-1)} />
				) : (
					'None Selected'
				)}
			</div>
		);
	}

	return (
		<div className='enchanting-main'>
			<EnchantingItemList />
			<div className='enchanting-window'>
				<div className='enchanting-window-component-container'>{renderItem()}</div>

				<div className='enchanting-window-enchant-container'>
					<Box className={'enchanting-window-component-container'} marginBottom='10px'>
						{renderScroll()}
					</Box>
					<div>
						<ReactSlider
							className='enchanting-strength-slider'
							thumbClassName='smithing-intensity-slider-thumb'
							trackClassName='smithing-intensity-slider-track'
							min={0}
							max={selectedItemData?.enchantmentTier ?? 0}
							value={[minStrength, selectedStrength]}
							onChange={handleSelectedStrength}
							renderThumb={(props, state) => {
								return <div {...props}>{state.valueNow}</div>;
							}}
						/>
						<ProgressBar
							value={selectedStrength}
							max={selectedItemData?.enchantmentTier ?? 0}
							theme='intensity'
							width='95%'
						></ProgressBar>
					</div>
					<div>
						<Text color='red'>
							The same enchantments <b>do not</b> stack over different equipment
						</Text>
					</div>
				</div>
				<div className='enchanting-window-apply'>
					<p>Experience: {enchantingExperience}</p>
					<IdlescapeButton
						size={'large'}
						variant={buttonIsActive ? 'purple' : 'disabled'}
						className={
							buttonIsActive && !settings.miscellaneous.highPerformance ? 'enchanting-glowing-button' : ''
						}
						onClick={enchant}
						marginY='15px'
					>
						ENCHANT
					</IdlescapeButton>
				</div>
			</div>
			<EnchantingScrollList />
		</div>
	);
}
