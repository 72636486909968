import React from 'react';
import { CLOTHWEAVING_CLOTH, CLOTHWEAVING_FIBERS } from '../../../../utils/constantsCollection';
import { itemList } from '../../../../utils/itemList';
import RunecraftingRow from '../../RunecraftingRow';
import './Runecrafting.css';
import RunecraftingEssenceDisplay from './RunecraftingEssenceDisplay';

export default function RunecraftingCloth() {
	return (
		<div className='runecrafting-main'>
			<div className='crafting-grid'>
				{CLOTHWEAVING_CLOTH.map((clothId) => (
					<RunecraftingRow key={clothId} resource={itemList[clothId]} dungeon />
				))}
			</div>

			<div className='essence-list'>
				{CLOTHWEAVING_FIBERS.map((fiberId, index) => (
					<RunecraftingEssenceDisplay
						key={index}
						resourceID={fiberId}
						gatherMethod='can be gathered by fishing.'
					/>
				))}
			</div>
		</div>
	);
}
