import React, { useEffect, useState } from 'react';
import { socket } from '../../services/socket.service';
import { IdlescapeContainer } from '@idlescape/ui';
import {
	Box,
	Grid,
	Image,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
} from '@chakra-ui/react';
import { usePlayerField } from '../../hooks/hooks';
import { locations } from '../../utils/locationList';
import { IDungeonRunCompleted } from '../../../../game-server/src/repositories/Stats.repository';
import { itemList } from '../../utils/itemList';
import { leagueList } from '../../utils/leagueList';
import { socketEmitDebounced, timeMSToString } from '../../helper/helperFunctions';
import { profileEditorOpenAtom } from '../../atoms/profileEditorOpenAtom';
import { useSetRecoilState } from 'recoil';

export default function HiscoreCombat() {
	const username = usePlayerField('username');
	const validDungeons: number[] = [];
	for (const location of Object.values(locations)) {
		if (location.relatedKey) {
			validDungeons.push(location.locID);
		}
	}
	const [dungeonID, setDungeonID] = useState<number>(validDungeons[0] ?? 0);
	const [hiscoreData, setHiscoreData] = useState<IDungeonRunCompleted[]>([]);
	const [personalData, setPersonalData] = useState<{ placement: number; run: IDungeonRunCompleted } | null>(null);
	const league = usePlayerField('league');
	const [selectedLeague, setSelectedLeague] = useState<number>(league);
	const [selectedDifficulty, setSelectedDifficulty] = useState<number>(-1);
	const setProfileEditorOpen = useSetRecoilState(profileEditorOpenAtom);

	useEffect(() => {
		setHiscoreData([]);
		setPersonalData(null);

		// only page 1 is supported right now in backend
		socketEmitDebounced('hiscore:getDungeon', {
			locationID: dungeonID,
			league: selectedLeague,
			difficulty: selectedDifficulty,
			page: 1,
		});

		socket.on('hiscore:dungeon', (data) => {
			if (data.locationID !== dungeonID) return;
			if (data.runs) {
				setHiscoreData(data.runs);
			}
		});

		socket.on('hiscore:personalDungeon', (data) => {
			if (data.run.location_id !== dungeonID) return;
			if (data.run) {
				setPersonalData(data);
			}
		});

		return () => {
			socket.off('hiscore:dungeon');
			socket.off('hiscore:personalDungeon');
		};
	}, [dungeonID, selectedLeague, selectedDifficulty]);

	const medals = ['gold', 'silver', 'bronze'];
	const colors = ['gold', 'silver', 'rgb(205, 127, 50)'];

	function inspect(inspectName: string) {
		if (username === inspectName) {
			setProfileEditorOpen(true);
			return;
		}
		const command = '/whois ' + inspectName;

		const commandToSend = {
			channelId: 1,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	}

	function renderHiscoreRow(run: IDungeonRunCompleted, rank: number, styles = {}) {
		const medal = medals[rank - 1] ?? '';
		const color = colors[rank - 1] ?? 'white';
		const mercs = run.party_size - run.party_ids.length;
		const partyIncludesUser = run.party_ids.find((name) => name.username === username);
		return (
			<Tr key={rank} background={partyIncludesUser ? 'rgba(92, 92, 92, 0.5)' : ''} color={color} {...styles}>
				<Td textAlign='center' fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{rank <= 3 && (
						<Image
							src={`/images/ui/highscore_${medal}.png`}
							alt={medal + ' medal'}
							width='30px'
							height='30px'
							marginRight='6px'
							verticalAlign='middle'
							borderStyle='none'
						/>
					)}
					{rank}
				</Td>
				<Td fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{run.party_ids.map((name, index) => (
						<Text
							as='span'
							key={name.username}
							cursor='pointer'
							_hover={{ textDecoration: 'underline' }}
							onClick={() => inspect(name.username)}
						>
							{name.username}
							{index < run.party_ids.length - 1 && ', '}
						</Text>
					))}
					{mercs > 0 && ` (+${mercs} Merc${mercs > 1 ? 's' : ''})`}
				</Td>
				<Td fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{timeMSToString(run.time)}
				</Td>
				{selectedDifficulty === -1 && (
					<Td fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
						{run.difficulty}
					</Td>
				)}
			</Tr>
		);
	}

	const selectedModeLeagueStyle = {
		backgroundColor: 'rgba(195, 204, 255, 0.4)',
		boxShadow: '0 0 5px 5px rgba(195, 204, 255, 0.4)',
	};

	const settingsOptionsStyle = {
		gridTemplateColumns: 'repeat(auto-fill, 50px)',
		gridTemplateRows: '50px',
		columnGap: '20px',
		justifyItems: 'center',
		alignItems: 'center',
		marginBottom: '10px',
	};

	function renderLeagueOptions() {
		const ret = [];
		ret.push(
			Object.values(leagueList).map((l) => {
				return (
					<Box onClick={() => setSelectedLeague(l.id)} key={l.id} cursor='pointer'>
						<Image
							src={l.icon}
							{...(selectedLeague === l.id ? selectedModeLeagueStyle : null)}
							alt={l.name}
							width='100%'
							height='100%'
						/>
					</Box>
				);
			})
		);
		return ret;
	}

	function renderLocations() {
		return validDungeons.map((locID) => {
			const location = locations[locID];
			const relatedKeyItem = itemList[location.relatedKey ?? 1];
			return (
				<Box
					key={locID}
					onClick={() => setDungeonID(locID)}
					outline='2px solid rgba(102, 102, 102)'
					backgroundColor='rgba(0,0,0,0.1)'
					cursor='pointer'
				>
					<Image
						src={`${relatedKeyItem.itemIcon || relatedKeyItem.itemImage}`}
						alt={relatedKeyItem.name}
						width='100%'
						height='100%'
						{...(locID === dungeonID ? selectedModeLeagueStyle : null)}
					/>
				</Box>
			);
		});
	}

	function renderAccordions() {
		return (
			<Accordion allowMultiple>
				<AccordionItem>
					<AccordionButton>
						<Box flex='1' textAlign='left'>
							Locations
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>
						<Grid gridTemplateColumns='repeat(auto-fill, 50px)' gap='10px'>
							{renderLocations()}
						</Grid>
					</AccordionPanel>
				</AccordionItem>

				<AccordionItem>
					<AccordionButton>
						<Box flex='1' textAlign='left'>
							League Options
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>
						<Grid {...settingsOptionsStyle}>{renderLeagueOptions()}</Grid>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	}

	function renderPersonalRow() {
		if (!personalData) return null;
		// If the player is not in the top 10, render their personal row
		for (const run of hiscoreData) {
			if (run.party_ids.find((name) => name.username === username)) return null;
		}
		return renderHiscoreRow(personalData.run, personalData.placement, { backgroundColor: 'rgba(92, 92, 92, 0.5)' });
	}

	return (
		<Box height='100%' overflowY='scroll'>
			{renderAccordions()}
			<IdlescapeContainer textAlign={'center'}>
				<Box px='10%'>
					<h2>
						Difficulty Selection
						<Slider
							value={selectedDifficulty}
							min={-1}
							max={20}
							onChange={setSelectedDifficulty}
							focusThumbOnChange={false}
							aria-labelledby='input-slider'
						>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
					</h2>
				</Box>
			</IdlescapeContainer>
			<Box textAlign='center'>
				<h2>
					{locations[dungeonID].name} - Difficulty {selectedDifficulty === -1 ? 'Mixed' : selectedDifficulty}{' '}
					- {leagueList[selectedLeague].name}
				</h2>
			</Box>
			<IdlescapeContainer>
				<TableContainer>
					<Table
						color='white'
						border='2px solid rgba(102, 102, 102)'
						background='rgba(0, 0, 0, 0.5)'
						width='100%'
					>
						<Thead borderBottom='2px solid rgba(192, 192, 192, 0.2)'>
							<Tr>
								<Th width='15px' fontSize='22px' padding='8px 10px'>
									Rank
								</Th>
								<Th width='75%' fontSize='22px' padding='8px 10px'>
									Party
								</Th>
								<Th fontSize='22px' padding='8px 10px'>
									Time
								</Th>
								{selectedDifficulty === -1 && (
									<Th fontSize='22px' padding='8px 10px'>
										Difficulty
									</Th>
								)}
							</Tr>
						</Thead>
						<Tbody>
							{hiscoreData.map((player, index) => renderHiscoreRow(player, index + 1))}
							<Tr border='2px solid rgba(102, 102, 102)'></Tr>
							{renderPersonalRow()}
						</Tbody>
					</Table>
				</TableContainer>
			</IdlescapeContainer>
			<Text textAlign='center'>Click on a players name to open their profile</Text>
		</Box>
	);
}
