import { IdlescapeButton, IdlescapeContainer, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ITalentData } from '../../../../../game-server/src/modules/talent/Talent.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { getSocket } from '../../../services/socket.service';
import { questList } from '../../../utils/questList';
import { talentList } from '../../../utils/talentList';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import './Talents.css';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { abilities } from '../../../utils/abilityList';
import { Image } from '@chakra-ui/react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { tabs } from '../../../helper/NavigationTabsList';

export const TalentBox = ({ talent, selected = false }: { talent: ITalentData; selected: boolean }) => {
	const socket = getSocket();
	const settings = usePlayerField('settings');
	const selectedTalents = settings.miscellaneous.selectedTalents;
	const skills = usePlayerField('skills');
	const questsCompleted = usePlayerField('questsCompleted');
	const actionQueue = usePlayerField('actionQueue');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const [showModal, setShowModal] = useState(false);
	const [selectedTalent, setSelectedTalent] = useState<ITalentData | null>(null);

	const inCombat = actionQueue?.actionType === 'Action-Combat' || actionQueue?.actionSubType === 'Action-Combat';
	const isValid = isTalentValid(talent);
	const isSelected = selectedTalents.includes(talent.id);

	function handleOpenModal(talent: ITalentData) {
		setSelectedTalent(talent);
		setShowModal(true);
	}

	function handleCloseModal() {
		setShowModal(false);
		setSelectedTalent(null);
	}

	function addTalent(talentID: number) {
		setShowModal(false);
		setSelectedTalent(null);
		if (!selectedTalents.includes(talentID)) {
			socket.emit('talent:addTalent', { talentID });
		}
	}

	function isTalentValid(talent: ITalentData) {
		if (talent.inactive) {
			return false;
		}
		let valid = true;
		if (talent.skillRequirements) {
			for (const skillRequirement of talent.skillRequirements) {
				const skill = skills[skillRequirement.skill];
				if (skillRequirement.mastery) {
					if (!skill || skill.masteryLevel < skillRequirement.level) {
						valid = false;
						break;
					}
				} else {
					if (!skill || skill.level < skillRequirement.level) {
						valid = false;
						break;
					}
				}
			}
		}
		if (talent.questRequirements) {
			for (const questRequirement of talent.questRequirements) {
				if (!questIdsCompleted.includes(questRequirement)) {
					valid = false;
					break;
				}
			}
		}
		if (talent.incompatibleTalents) {
			for (const incompatibleTalent of talent.incompatibleTalents) {
				if (selectedTalents.includes(incompatibleTalent)) {
					valid = false;
					break;
				}
			}
		}
		if (talent.talentRequirements) {
			for (const talentRequirement of talent.talentRequirements) {
				if (!selectedTalents.includes(talentRequirement)) {
					valid = false;
					break;
				}
			}
		}
		return valid;
	}

	function renderTalentTooltip(talent: ITalentData) {
		const tooltip = (
			<>
				{talent.name}
				<hr />
				Point Cost: {talent.talentCost}
				{talent?.questRequirements ? (
					<>
						<hr />
						Required Quests:{' '}
						{talent.questRequirements.map((requiredQuest) => questList[requiredQuest]?.name).join(', ')}
					</>
				) : (
					''
				)}
				{talent?.skillRequirements ? (
					<>
						<hr />
						Required Skills:{' '}
						{talent.skillRequirements
							.map((requiredSkill) => {
								return `${requiredSkill.mastery ? 'Mastery' : ''} ${requiredSkill.level} ${
									requiredSkill.skill
								}`;
							})
							.join(', ')}
					</>
				) : (
					''
				)}
				{talent?.talentRequirements ? (
					<>
						<hr />
						Required Talents:{' '}
						{talent.talentRequirements.map((requiredTalent) => talentList[requiredTalent]?.name).join(', ')}
					</>
				) : (
					''
				)}
				{!talent?.questRequirements && !talent?.skillRequirements && !talent?.talentRequirements ? (
					<>
						<hr />
						No requirements!
					</>
				) : (
					''
				)}
				{talent.enchantments ? (
					<>
						<hr />
						Enchantments:
						<br />
						{talent.enchantments
							.map((enchantment) => {
								const enchantData = enchantmentsList[enchantment.id];
								return `${enchantData.name}: ${enchantment.strength}`;
							})
							.join(', ')}
					</>
				) : (
					''
				)}
				{talent.abilities ? (
					<>
						<hr />
						Abilities:
						<br />
						{talent.abilities
							.map((ability) => {
								const abilityData = abilities[ability];
								// TODO use the actual component
								return `${abilityData.abilityName}`;
							})
							.join(', ')}
					</>
				) : (
					''
				)}
				{talent.inactive ? (
					<>
						<hr />
						Not yet available in the game.
					</>
				) : (
					''
				)}
			</>
		);
		return (
			<IdlescapeWrappingTooltip content={tooltip} activateOnClick={true}>
				<InfoOutlineIcon id='resource-node-tooltip' />
			</IdlescapeWrappingTooltip>
		);
	}

	return (
		<>
			<IdlescapeContainer
				className='talent'
				position='relative'
				variant={isValid ? (isSelected ? 'dark' : 'primary') : 'red'}
			>
				<Box position='absolute' right='50px'>
					{renderTalentTooltip(talent)}
				</Box>
				<Flex marginLeft='20px'>
					{!selected &&
						talent.skillRequirements?.map((skillReq) => {
							const skill = skills[skillReq.skill];
							const skillReqMet = skillReq.mastery
								? skill.masteryLevel >= skillReq.level
								: skill.level >= skillReq.level;
							return (
								<Box
									position='relative'
									width='40px'
									height='40px'
									key={skillReq.skill + skillReq.level}
								>
									{!skillReqMet && (
										<Image
											height='100%'
											width='100%'
											filter='hue-rotate(330deg) saturate(3000%)'
											position='absolute'
											top='0'
											left='0'
											src='/images/augmentation_glow.png'
										/>
									)}
									<Image
										position='relative'
										width='40px'
										height='40px'
										objectFit='contain'
										src={tabs[skillReq.skill].icon}
									/>
									<Box
										className='centered'
										fontSize='20px'
										textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
										color={skillReq.mastery ? 'var(--chakra-colors-blue-200)' : ''}
									>
										{skillReq.level}
									</Box>
								</Box>
							);
						})}
				</Flex>
				<div className='talent-name'>
					<Text as='h2' margin='5px 0 10px 0'>
						{talent.name}
					</Text>
					{talent.talentIcon ? (
						<Image
							maxWidth={'60px'}
							marginLeft={'auto'}
							marginRight={'auto'}
							height={'auto'}
							src={talent.talentIcon}
						></Image>
					) : (
						''
					)}
				</div>
				<div className='talent-description'>
					{talent.description}
					{!selectedTalents.includes(talent.id) ? (
						<IdlescapeButton
							display='block'
							variant={isValid ? 'green' : 'disabled'}
							size='large'
							onClick={() => {
								handleOpenModal(talent);
							}}
						>
							Use {talent.talentCost} Point{talent.talentCost > 1 && 's'}
						</IdlescapeButton>
					) : (
						''
					)}
				</div>
			</IdlescapeContainer>
			<Dialog open={showModal} onClose={handleCloseModal} className='sell-item-dialog'>
				<DialogTitle>Talent Confirmation</DialogTitle>
				<DialogContent>
					<Text>Are you sure you want to select {selectedTalent?.name}?</Text>
					<Text>This cannot be easily reversed!</Text>
					{inCombat ? <Text color='red'>You cannot change talents while in combat!</Text> : ''}
				</DialogContent>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={handleCloseModal}>
						Close
					</IdlescapeButton>
					<IdlescapeButton
						variant={inCombat ? 'disabled' : 'green'}
						onClick={() => {
							if (selectedTalent) {
								addTalent(selectedTalent.id);
							}
						}}
					>
						{inCombat ? 'IN COMBAT' : 'Confirm'}
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
