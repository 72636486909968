import React, { useEffect, useState } from 'react';
import CombatInfoBar from './CombatInfoBar';
import CombatAvatarMugshot from './CombatAvatarMugshot';
import { IGroupPlayerClientData } from '../../../../../game-server/src/modules/group/Group';
import { IMonster } from '../../../../../game-server/src/modules/combat/Combat.interface';
import { TEquipmentPartials, TEquipmentSlots } from '../../../../../game-server/src/modules/player/PlayerTypes';

export default function CombatGroupInfoPlayer(props: {
	id: number;
	groupPlayer?: IGroupPlayerClientData;
	groupMerc?: IMonster;
}) {
	const [dead, setDead] = useState(false);
	const isPlayer = props.groupPlayer !== undefined;
	const isMerc = props.groupMerc !== undefined;
	useEffect(() => {
		if (
			(isPlayer && (props.groupPlayer?.currentHealth ?? 1) === 0) ||
			(isMerc && (props.groupMerc?.monsterHealth ?? 1) === 0)
		) {
			dead || setDead(true);
		} else {
			dead && setDead(false);
		}
	}, [(props.groupPlayer?.currentHealth ?? 1) === 0, (props.groupMerc?.monsterHealth ?? 1) === 0]);

	if (!isPlayer && !isMerc) {
		return null;
	}

	function highlightPlayer() {
		const player = document.getElementsByClassName('combat-group-avatar-' + props.id);
		if (player[0]) {
			player[0].classList.add('combat-group-highlight-player');
		}
	}

	function unHighlightPlayer() {
		const player = document.getElementsByClassName('combat-group-avatar-' + props.id);
		if (player[0]) {
			player[0].classList.remove('combat-group-highlight-player');
		}
	}

	function getEquipment() {
		if (isPlayer) {
			return props.groupPlayer?.equipment ?? {};
		} else {
			const mercEquipment = props.groupMerc?.playerEquipment ?? {};
			// Map to partial item with itemID
			return Object.entries(mercEquipment).reduce((acc, [slot, equipId]) => {
				acc[slot as TEquipmentSlots] = equipId;
				return acc;
			}, {} as TEquipmentPartials);
		}
	}

	const playerName = (isPlayer ? props.groupPlayer?.username : props.groupMerc?.monsterName) ?? 'Broken Player :(';
	const equipment = getEquipment();
	const currentHealth = (isPlayer ? props.groupPlayer?.currentHealth : props.groupMerc?.monsterHealth) ?? 1;
	const maxHealth = (isPlayer ? props.groupPlayer?.combatStats?.maxHealth : props.groupMerc?.maxHealth) ?? 1;
	const id = (isPlayer ? props.groupPlayer?.id : props.groupMerc?.id) ?? 0;
	const cosmetics = (isPlayer ? props.groupPlayer?.selectedCosmetics : {}) ?? {};
	return (
		<div
			className={`combat-group-info-player ${
				dead ? 'combat-group-info-player-dead' : ''
			} combat-group-info-player-${playerName}`}
			onMouseEnter={highlightPlayer}
			onMouseLeave={unHighlightPlayer}
		>
			<CombatAvatarMugshot equipment={equipment} cosmetics={cosmetics} />
			<CombatInfoBar
				type='player'
				name={playerName}
				currentHealth={currentHealth}
				maxHealth={maxHealth}
				id={id}
			/>
		</div>
	);
}
