import { Flex, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../hooks/hooks';
import ItemTooltip from '../../Tooltips/ItemTooltip';

function ItemTooltipDialog(props: { item: TPartialItemWithItemID<IItem>; onClose: () => void }) {
	const settings = usePlayerField('settings');
	const modern = settings.tooltips.useModernTooltips;
	return (
		<Modal isOpen={true} onClose={props.onClose} variant={modern ? 'modern_simple' : 'simple'}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalCloseButton />
				<Flex justifyContent='center'>
					<ItemTooltip item={props.item} embed={true} />
				</Flex>
			</ModalContent>
		</Modal>
	);
}

export default ItemTooltipDialog;
