import React from 'react';
import { RUNECRAFTING_ESSENCE, RUNECRAFTING_RUNES } from '../../../../utils/constantsCollection';
import { itemList } from '../../../../utils/itemList';
import RunecraftingRow from '../../RunecraftingRow';
import './Runecrafting.css';
import RunecraftingEssenceDisplay from './RunecraftingEssenceDisplay';

export default function RunecraftingRunes() {
	return (
		<div className='runecrafting-main'>
			<div className='crafting-grid'>
				{RUNECRAFTING_RUNES.map((runeId) => (
					<RunecraftingRow key={runeId} resource={itemList[runeId]} />
				))}
			</div>

			<div className='essence-list'>
				{RUNECRAFTING_ESSENCE.map((essenceId) => (
					<RunecraftingEssenceDisplay key={essenceId} resourceID={essenceId} />
				))}
			</div>
		</div>
	);
}
