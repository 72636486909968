import React from 'react';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import Tooltip from '../../layout/UI/Tooltip';
import { buffStacks, getEnchantmentStrength } from '../../../utils/itemFunctions';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { EnchantmentData } from '../EnchantmentData';
import { usePlayerField } from '../../../hooks/hooks';
import { Item } from '../Inventory/Item';
import { COMBAT_INVENTORY_SIZE } from '../../../utils/constantsCollection';
import CombatFoodStockpile from './CombatFoodStockpile';
import { IdlescapeButton } from '@idlescape/ui';

export default function CombatFoodInventory({ combatView = false }: { combatView?: boolean }) {
	const actionQueue = usePlayerField('actionQueue');
	const combatInventory = usePlayerField('combatInventory');

	function removeFromInventory(slot: number) {
		if (actionQueue && actionQueue.actionType === 'Action-Combat') {
			socket.emit('combat:eatFood', { idx: slot });
		} else {
			socket.emit('inventory:fromCombatInventory', { index: slot });
		}
	}

	const items: React.ReactElement[] = [];
	const enchantObj: {
		enchantmentID: number;
		enchantmentStrength: number;
		enchantmentStacks: number;
	}[] = [];
	let healSum = 0;
	for (let i = 0; i < COMBAT_INVENTORY_SIZE; i++) {
		if (combatInventory[i]) {
			const item = combatInventory[i];
			const itemData = itemList[item.itemID];
			if (!itemData.healing) continue; // Item doesn't heal???
			const augLevel = item.augmentations ?? 0;
			const heal = Math.floor(itemData.healing.hp * Math.max(1, 1 + augLevel));
			const healTick =
				Math.floor((itemData.healing.perTick ?? 0) * Math.max(1, (1 + augLevel) / 2)) *
				(itemData.healing.totalTicks ?? 0);
			const healing = Math.floor(heal + healTick);
			healSum += healing;
			items.push(
				<Item
					key={i}
					item={item}
					hideCount
					height='45px'
					width='45px'
					marginX='auto'
					onClick={() => removeFromInventory(i)}
				/>
			);
			if (item.enchantmentID) {
				const enchantment = EnchantmentData.findEnchantmentByID(item.enchantmentID);
				// if (!enchantment) continue;
				const strength = getEnchantmentStrength(item, itemList) ?? 0;
				const stackCount = buffStacks(item.augmentations, itemData.stackMultiplier, enchantment.stackMult);
				const existingEnchantment = enchantObj.find(
					(e) => e.enchantmentID === item.enchantmentID && e.enchantmentStrength === strength
				);
				if (existingEnchantment) {
					existingEnchantment.enchantmentStacks += stackCount;
				} else {
					enchantObj.push({
						enchantmentID: enchantment.id,
						enchantmentStrength: strength,
						enchantmentStacks: stackCount,
					});
				}
			}
		} else {
			items.push(<div className='combat-empty-slot' key={i} />);
		}
	}
	const enchants: React.ReactElement[] = [];
	if (healSum > 0) {
		// Add a fake enchantment for the heal amount
		enchants.push(
			<div key={'inventory healing'} className='combat-inventory-food-stat'>
				<div className='combat-inventory-food-stat-content'>
					<img
						className='combat-inventory-food-stat-icon nav-tab-icon icon-border'
						src={'/images/combat/constitution_icon.png'}
						alt={'healing'}
					></img>
					<div className='combat-inventory-food-stat-name'>Healing Sum</div>
					<div className='combat-inventory-food-stat-stacks'>{healSum.toLocaleString('en-us')}</div>
				</div>
			</div>
		);
	}
	if (enchantObj.length > 0) {
		enchantObj.sort(
			(a, b) => 2 * (b.enchantmentID - a.enchantmentID) + b.enchantmentStrength - a.enchantmentStrength
		);
		for (let i = 0; i < enchantObj.length; i++) {
			const enchantment = EnchantmentData.findEnchantmentByID(enchantObj[i].enchantmentID);
			enchants.push(
				<div key={i} className='combat-inventory-food-stat'>
					<div className='combat-inventory-food-stat-content'>
						<img
							className='combat-inventory-food-stat-icon nav-tab-icon icon-border'
							src={enchantment.buffIcon}
							alt={enchantment.name}
						></img>
						<div className='combat-inventory-food-stat-name enchanted-text'>
							{enchantment.name + ' ' + enchantObj[i].enchantmentStrength}
						</div>
						<div className='combat-inventory-food-stat-stacks'>
							{enchantObj[i].enchantmentStacks + ' Stacks'}
						</div>
						<Tooltip>
							{enchantment.getTooltip(enchantObj[i].enchantmentStrength, enchantment.strengthPerLevel)}
						</Tooltip>
					</div>
				</div>
			);
		}
	}

	function emptyFood() {
		socket.emit('inventory:emptyFoodInventory');
	}

	if (combatView === false) {
		return (
			<>
				<div className='idlescape-container idlescape-container-light'>
					<Grid gridTemplateColumns='repeat(auto-fill,140px)' minHeight='60px'>
						{enchants.length > 0 ? enchants : 'Nothing Here!'}
					</Grid>
				</div>
				<Flex justifyContent='center' flexWrap='wrap'>
					<Flex
						className='combat-gear-inventory-wrapper idlescape-container idlescape-container-light'
						flexDirection='column'
						alignItems='center'
					>
						<Heading size='md' textAlign='center'>
							Combat Food
						</Heading>
						{combatInventory.length > 0 && (
							<IdlescapeButton variant='green' size='medium' onClick={emptyFood}>
								Empty Food
							</IdlescapeButton>
						)}
						<Box className='combat-gear-inventory' width='100%'>
							{items}
						</Box>
					</Flex>
					<CombatFoodStockpile />
				</Flex>
			</>
		);
	}

	return (
		<>
			<div className='combat-gear-inventory-wrapper idlescape-container idlescape-container-light'>
				<div className='combat-gear-inventory'>{items}</div>
			</div>
			<div className='combat-inventory-food-stats idlescape-container idlescape-container-light'>
				<div>{enchants.length > 0 ? enchants : 'Nothing Here!'}</div>
			</div>
		</>
	);
}
