import { Box } from '@chakra-ui/react';
import React from 'react';
import { TSkillTab } from '../../../../../../game-server/src/Atypes/Skills';
import { TCombatType } from '../../../../../../game-server/src/modules/loadout/loadout';
import { tabs } from '../../../../helper/NavigationTabsList';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';

const combatTypes: Partial<Record<TSkillTab, TCombatType>> = {
	strength: 'Melee',
	magic: 'Magic',
	range: 'Range',
};

// TODO: Choose a location without needing to start an action
export default function LoadoutGenerator() {
	const actionQueue = usePlayerField('actionQueue');

	function generateLoadout(locationID: number, combatType?: TCombatType) {
		if (locationID) {
			socket.emit('loadout:generate', { locationID, combatType });
		}
	}

	function generateOptions(skill?: TSkillTab) {
		if (!actionQueue) return;
		const locationData = locations[actionQueue.location];
		const name = skill ? combatTypes[skill] : locationData.name;
		const icon = skill ? tabs[skill].icon : locationData.locationImage;

		return (
			<div
				key={name}
				className='loadout-slot idlescape-frame-box-dark idlescape-frame-box-interactive'
				onClick={() => generateLoadout(locationData.locID, skill && combatTypes[skill])}
			>
				<img src={icon} alt={name} />
				<div className='loadout-slot-name'>{name}</div>
			</div>
		);
	}

	const options: React.ReactNode[] = [];
	if (actionQueue?.actionType === 'Action-Combat') {
		for (const skill of Object.keys(combatTypes)) {
			options.push(generateOptions(skill as TSkillTab));
		}
	} else {
		options.push(generateOptions());
	}

	return (
		<Box textAlign='center'>
			<h2>Loadout Recommendations</h2>
			{actionQueue ? (
				<div className='loadout-slots'>{options}</div>
			) : (
				<h3>Start an action to get a recommendation. (Work in progress)</h3>
			)}
		</Box>
	);
}
