import React from 'react';
import { itemList } from '../../../utils/itemList';
import { usePlayerField } from '../../../hooks/hooks';
import { Heading } from '@chakra-ui/react';
import InventoryItem from '../Inventory/InventoryItem';
import { ToggleAmmoDialog } from '../Inventory/ItemDialogs/ToggleQueueDialogs';
import { TTrueInventoryType } from '../../../../../game-server/src/modules/items/Inventory.interface';
import { socket } from '../../../services/socket.service';
import { IdlescapeContainer } from '@idlescape/ui';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';

export function toggleAmmoInventory(itemInventoryId: number, inventoryName: TTrueInventoryType, itemAmount: number) {
	socket.emit('inventory:ammoPouchToggle', {
		inventoryItemID: itemInventoryId,
		itemAmount: itemAmount,
		sourceInventory: 'stockpile',
	});
}

export default function CombatAmmoStockpile() {
	const stockpile = usePlayerField('stockpile');

	const possibleAmmo = stockpile.filter((item) => {
		const info = itemList[item.itemID];
		return info.tags?.includes('ammunition');
	});

	function handleOnClick(event: React.MouseEvent, item: IItem) {
		if (event.shiftKey) {
			toggleAmmoInventory(item.id, 'stockpile', item.stackSize);
			return true;
		}
		return false;
	}

	return (
		<IdlescapeContainer flex='1'>
			<Heading size='md' textAlign='center'>
				Inventory
			</Heading>
			<div className='all-items'>
				{possibleAmmo.map((item) => (
					<InventoryItem
						key={item.id}
						item={item}
						overwriteDialog={ToggleAmmoDialog}
						overrideOnClick={(e) => handleOnClick(e, item)}
					/>
				))}
			</div>
		</IdlescapeContainer>
	);
}
