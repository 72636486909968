import React from 'react';
import { tabs } from '../../../helper/NavigationTabsList';
import { TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { TalentsSummary } from './TalentsSummary';
import { usePlayerField, usePlayerQuestCompleted, usePlayerQuestProgress } from '../../../hooks/hooks';
import { questsIds } from '../../../utils/lookup-dictionaries/lookupQuestList';
import { IdlescapeContainer, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { Box, Flex } from '@chakra-ui/react';
import { TalentsSkill } from './TalentsSkill';
import { useRecoilState } from 'recoil';
import { talentSkillAtom, TSkillTypes } from '../../../atoms/talentSkillAtom';
import { leagueList } from '../../../utils/leagueList';
import { questList } from '../../../utils/questList';
import { talentList } from '../../../utils/talentList';
import { talentFilterAtom } from '../../../atoms/talentFilterAtom';
import { TiTick } from 'react-icons/ti';

const filterSkills: TSkillTab[] = [
	'mining',
	'foraging',
	'fishing',
	'farming',
	'enchanting',
	'runecrafting',
	'smithing',
	'crafting',
	'cooking',
	'constitution',
	'attack',
	'defense',
	'strength',
	'magic',
	'range',
];
const filterTypes: { [key in TSkillTypes]: string } = {
	gathering: 'images/foraging/bush.png',
	production: '/images/magic/production.png',
	combat: '/images/combat/combat_level.png',
};

export default function Talents() {
	const [skillFilter, setSkillFilter] = useRecoilState(talentSkillAtom);
	const [filter, setFilter] = useRecoilState(talentFilterAtom);
	const questsCompleted = usePlayerField('questsCompleted');
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const selectedTalents = settings.miscellaneous.selectedTalents;

	const questPoints = Object.values(questsCompleted).reduce((acc, cur) => {
		return acc + (questList[cur.id]?.questRewards?.questPoints ?? 0);
	}, 0);
	const achievementPoints = Object.values(questsCompleted).reduce((acc, cur) => {
		return acc + (questList[cur.id]?.questRewards?.achievementPoints ?? 0);
	}, 0);

	function tutorialCheck() {
		const tutorialCurrentStep = usePlayerQuestProgress(questsIds.talent_tutorial);
		const tutorialCompleted = usePlayerQuestCompleted(questsIds.talent_tutorial);
		return tutorialCompleted || tutorialCurrentStep > 0;
	}

	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	if (!leagueData?.rules?.quests) {
		return <>You do not have access to mastery talents in this league or season.</>;
	}

	function getQuestAllowance() {
		return Math.floor(questPoints / 5);
	}

	function getAchievementAllowance() {
		return Math.floor(achievementPoints / 10);
	}

	function getMasteryAllowance() {
		let sum = 0;
		for (const keyName of filterSkills) {
			const skill = skills[keyName];
			if (!skill.masteryLevel) {
				continue;
			}
			// 1 mastery = 1 worth, 5 mastery = 2 worth, 10 mastery = 3 worth, etc.
			const levelWorth = Math.floor(skill.masteryLevel / 5) + 1;
			sum += levelWorth;
		}
		return Math.floor(sum);
	}

	function getTalentAllowance() {
		const questAllowance = getQuestAllowance();
		const achievementAllowance = getAchievementAllowance();
		const masteryAllowance = getMasteryAllowance();
		return Math.floor(questAllowance + achievementAllowance + masteryAllowance);
	}
	function getTalentLeft() {
		const worth = selectedTalents.reduce((acc, cur) => {
			const talent = talentList[cur];
			if (!talent) {
				return acc;
			}
			return acc + talent.talentCost;
		}, 0);
		return getTalentAllowance() - worth;
	}

	return (
		<div className='talent-container'>
			{tutorialCheck() ? (
				<>
					<div className='talent-nav'>
						<Flex
							className='scrollcrafting-info'
							alignItems='center'
							justifyContent='center'
							flexWrap='wrap'
						>
							<span key='summary' onClick={() => setSkillFilter(null)}>
								<img
									className={`scrollcrafting-filter-image ${null === skillFilter && 'clicked'}`}
									src='/images/total_level.png'
									alt='summary'
								/>
							</span>
							{Object.entries(filterTypes).map(([type, image]) => {
								return (
									<span key={type} onClick={() => setSkillFilter(type as TSkillTypes)}>
										<img
											className={`scrollcrafting-filter-image ${
												type === skillFilter && 'clicked'
											}`}
											src={image}
											alt={type}
										/>
									</span>
								);
							})}
							{filterSkills.map((skillName) => {
								const skillTab = tabs[skillName];
								return (
									<span key={skillName} onClick={() => setSkillFilter(skillName)}>
										<img
											className={`scrollcrafting-filter-image ${
												skillName === skillFilter && 'clicked'
											}`}
											src={skillTab.icon}
											alt={skillName}
										/>
									</span>
								);
							})}
							<IdlescapeWrappingTooltip content='Filter available talents'>
								<Box
									marginLeft='5px'
									textAlign='center'
									fontSize='18px'
									fontWeight='bold'
									border='2px solid'
									borderColor={filter ? 'green' : 'red'}
									borderRadius='5px'
									height='35px'
									width='35px'
									onClick={() => setFilter((filter) => !filter)}
								>
									{filter ? <TiTick size='30px' /> : <Box lineHeight='30px'>All</Box>}
								</Box>
							</IdlescapeWrappingTooltip>
							<IdlescapeWrappingTooltip content='Remaining talent points'>
								<Box
									marginLeft='5px'
									textAlign='center'
									fontSize='18px'
									fontWeight='bold'
									border='2px solid var(--chakra-colors-blue-200)'
									borderRadius='20px'
									lineHeight='31px'
									width='35px'
								>
									{getTalentLeft()}
									{/* <br />/{getTalentAllowance()} */}
								</Box>
							</IdlescapeWrappingTooltip>
						</Flex>
					</div>
					<Box>{skillFilter === null ? <TalentsSummary /> : <TalentsSkill />}</Box>
				</>
			) : (
				<IdlescapeContainer variant='dark'>
					<h1>You must start or complete the Talent Tutorial to use this feature.</h1>
				</IdlescapeContainer>
			)}
		</div>
	);
}
