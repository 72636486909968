import React from 'react';
import { TActiveTab } from '../../../game-server/src/Atypes/Skills';
import { PlayerData } from '../../../game-server/src/modules/player/PlayerData';
import { socket } from '../services/socket.service';

const sendData: ISendData[] = [];

export type TOpenTabs = 'drawer' | TActiveTab;

export interface ISendData {
	key: keyof PlayerData;
	tabs: TOpenTabs[];
}

export function openTab(tab: TOpenTabs, keys: (keyof PlayerData)[]) {
	const startSend: { key: keyof PlayerData; send: boolean }[] = [];
	for (const key of keys) {
		const found = sendData.find((entry) => entry.key === key);
		if (found) {
			if (!found.tabs.includes(tab)) {
				found.tabs.push(tab);
			}
		} else {
			sendData.push({ key, tabs: [tab] });
			startSend.push({ key, send: true });
		}
	}
	if (startSend.length > 0) {
		socket.emit('player:sendData', startSend);
	}
}

export function closeTab(tab: TOpenTabs) {
	const stopSend: { key: keyof PlayerData; send: boolean }[] = [];
	for (let i = sendData.length - 1; i >= 0; i--) {
		const entry = sendData[i];
		const newOpen = entry.tabs.filter((t) => t !== tab);
		if (entry.tabs.length !== newOpen.length) {
			entry.tabs = newOpen;
			if (newOpen.length === 0) {
				stopSend.push({ key: entry.key, send: false });
				sendData.splice(i, 1);
			}
		}
	}
	if (stopSend.length > 0) {
		socket.emit('player:sendData', stopSend);
	}
}
