import './Runecrafting.css';
import RunecraftingRunes from './RunecraftingRunes';
import RunecraftingCloth from './RunecraftingCloth';
import RunecraftingAffix from './Affixes/RunecraftingAffix';
import React from 'react';
import { useRecoilState } from 'recoil';
import { runecraftingTabAtom } from '../../../../atoms/runecraftingTabAtom';
import SubSkillTabs from '../SubSkillTabs';

export default function Runecrafting() {
	const [tab, setTab] = useRecoilState(runecraftingTabAtom);
	return (
		<div className='runecrafting-container'>
			<SubSkillTabs
				tabs={[
					{
						id: 'rune',
						name: 'Runecrafting',
						image: '/images/runecrafting/RuneCraftingIcon.png',
						imageAlt: 'Runecrafting Logo',
					},
					{
						id: 'cloth',
						name: 'Cloth Weaving',
						image: '/images/combat/materials/imbued_cloth.png',
						imageAlt: 'Cloth Weaving Logo',
					},
					{
						id: 'affix',
						name: 'Affix Warding',
						image: '/images/runecrafting/dust/runic_dust_legendary.png',
						imageAlt: 'Affix Warding Logo',
					},
				]}
				setTab={setTab}
				selected={tab}
				baseStyle={{
					border: ' 2px solid rgba(0, 0, 255, 0.2)',
					background: 'linear-gradient(to bottom, rgb(0, 65, 85), rgb(0, 98, 128))',
				}}
				selectedStyle={{
					background: 'linear-gradient(to bottom, rgb(0, 45, 65), rgb(0, 75, 98))',
				}}
			/>
			{tab === 'rune' && <RunecraftingRunes />}
			{tab === 'cloth' && <RunecraftingCloth />}
			{tab === 'affix' && <RunecraftingAffix />}
		</div>
	);
}
