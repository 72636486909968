import { Flex, Image, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { questList } from '../../../utils/questList';
import { renderQuestRewards } from '../../game/Achievements/QuestMain';

export default function QuestPopup() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [questID, setQuestID] = useState<number | null>(null);

	useEffect(() => {
		socket.on('quest', (data) => {
			setQuestID(data.questID);
			onOpen();
		});

		return () => {
			socket.off('quest');
		};
	}, []);

	function handleCloseModal() {
		setQuestID(null);
		onClose();
	}

	function renderQuest() {
		if (!questID) {
			return (
				<React.Fragment key={questID}>
					<DialogTitle>Invalid Quest</DialogTitle>
				</React.Fragment>
			);
		}
		const questData = questList[questID];
		if (!questData) {
			return (
				<React.Fragment key={questID}>
					<DialogTitle>Invalid Quest</DialogTitle>
				</React.Fragment>
			);
		}
		const isAchievement = questData.achievement;

		return (
			<React.Fragment key={questID}>
				<DialogTitle>
					{questData.difficulty} {isAchievement ? 'Achievement' : 'Quest'} Completed!
				</DialogTitle>
				<div className='popup-content'>
					<Flex direction='column' align='center'>
						{questData.questIcon ? (
							<Image
								src={questData.questIcon}
								alt={questData.name}
								width='auto'
								height='auto'
								maxWidth='150px'
								maxHeight='150px'
							/>
						) : (
							''
						)}
						<h1>{questData.name}</h1>
						{renderQuestRewards(questData)}
					</Flex>
				</div>
			</React.Fragment>
		);
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			{renderQuest()}
			<DialogActions>
				<IdlescapeButton variant='red' onClick={handleCloseModal} size='medium'>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
}
