import { Box, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { IdlescapeContainer } from '@idlescape/ui';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import { TCraftingCategory } from '../../../../../../game-server/src/modules/skills/crafting/Crafting';
import { craftingAtom } from '../../../../atoms/craftingAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import useIsMobile from '../../../../hooks/useIsMobile';
import { itemList } from '../../../../utils/itemList';
import FakeItem from '../../Inventory/FakeItem';
import './Crafting.css';
import CraftingRecipe from './CraftingRecipe';

export default function Crafting() {
	const league = usePlayerField('league');
	const skills = usePlayerField('skills');
	const craftingLevel = skills.crafting.level;
	const categories: Record<TCraftingCategory, { icon: string; items: IItemData[] }> = {
		General: { icon: '/images/ui/crafting_icon.png', items: [] },
		Mining: { icon: '/images/mining/iron_pickaxe.png', items: [] },
		Foraging: { icon: '/images/foraging/foraging_icon.png', items: [] },
		Fishing: { icon: '/images/fishing/fishing_logo.png', items: [] },
		Farming: { icon: '/images/farming/farming_icon.png', items: [] },
		Skilling: { icon: '/images/leagues/default_league_icon.png', items: [] },
		Runecrafting: { icon: '/images/runecrafting/RuneCraftingIcon.png', items: [] },
		Melee: { icon: '/images/combat/strength_icon.png', items: [] },
		Magic: { icon: '/images/magic/magic_logo.png', items: [] },
		Range: { icon: '/images/combat/range_icon.png', items: [] },
		Jewelry: { icon: '/images/smithing/gold_bar.png', items: [] },
		Rare: { icon: '/images/misc/satchel.png', items: [] },
		Dungeon: { icon: '/images/misc/keys/Complete_Disc.png', items: [] },
		Event: { icon: '/images/christmas/snowglobe.png', items: [] },
	};
	Object.values(itemList).forEach((item) => {
		if (!item.craftingStats?.craftable) return;
		// Check if it has a league restriction
		if (item.craftingStats.leagues && !item.craftingStats.leagues.includes(league)) {
			return;
		}
		categories[item.craftingStats.category].items.push(item);
	});
	for (const category in categories) {
		categories[category as TCraftingCategory].items.sort(
			(a, b) => (a.craftingStats?.level ?? 1) - (b.craftingStats?.level ?? 1)
		);
	}
	const [selectedItemID, setSelectedItem] = useRecoilState(craftingAtom);

	const isMobile = useIsMobile();

	function getCategoryItems(category: TCraftingCategory) {
		const items = categories[category].items;

		return items.map((item) => {
			const requiredLevel = item.craftingStats?.level ?? 1;
			const fakeItem: TPartialItemWithItemID<IItem> = {
				itemID: item.id,
				stackSize: 1,
				...(item.forcedEnchant !== undefined && item.forcedEnchantAmount !== undefined
					? { enchantmentID: item.forcedEnchant, enchantmentStrength: item.forcedEnchantAmount }
					: {}),
			};
			return (
				<Box key={item.id} position='relative' onClick={() => setSelectedItem(item.id)}>
					<FakeItem
						item={fakeItem}
						filter={craftingLevel < requiredLevel ? 'grayscale(0.5)' : ''}
						backgroundImage={
							selectedItemID === item.id
								? 'linear-gradient(180deg, rgba(74, 74, 74, 0.5), rgba(197, 197, 197, 0.5)), url(/images/ui/frame_box.png)'
								: 'linear-gradient(180deg, rgba(87, 104, 253, 0.199), rgba(87, 104, 253, 0.199)), url(/images/ui/frame_box.png)'
						}
						border='none'
						_hover={{
							transform: 'unset',
							backgroundImage:
								'linear-gradient(180deg, rgba(124, 124, 124, 0.5), rgba(255, 255, 255, 0.5)), url(/images/ui/frame_box.png)',
						}}
						mobileTooltip={false}
					/>
					<Box
						className={'recipe-item-level ' + (craftingLevel < requiredLevel ? ' insufficient-level' : '')}
					>
						<img className='crafting-tab-icon' src={categories.General.icon} alt='crafting-level' />
						{requiredLevel}
					</Box>
				</Box>
			);
		});
	}

	function onCategoryChange(index: number) {
		setSelectedItem(categories[Object.keys(categories)[index] as TCraftingCategory].items[0].id);
	}

	return (
		<Tabs
			isFitted
			onChange={onCategoryChange}
			display='grid !important'
			gridTemplateColumns={isMobile ? '1fr' : '10fr 13fr'}
			gridTemplateRows={isMobile ? 'fit-content(20%) fit-content(33%) 1fr' : 'auto 1fr'}
			height='100%'
			defaultIndex={Object.keys(categories).findIndex(
				(cat) => cat === itemList[selectedItemID]?.craftingStats?.category
			)}
		>
			<TabList className='crafting-tab-container' gridColumn={isMobile ? '' : 'span 2'}>
				{Object.entries(categories).map(([category, item]) => (
					<Tab key={category} justifyContent='start'>
						<img className='crafting-tab-icon' src={item.icon} alt={category} />
						{category}
					</Tab>
				))}
			</TabList>
			<TabPanels className='crafting-container'>
				{Object.keys(categories).map((category) => (
					<TabPanel as={IdlescapeContainer} key={category} width='100%' height='100%'>
						<Grid className='crafting-categories'>{getCategoryItems(category as TCraftingCategory)}</Grid>
					</TabPanel>
				))}
			</TabPanels>
			<CraftingRecipe itemID={selectedItemID} />
		</Tabs>
	);
}
