import { useLocalStorage, useWindowSize } from 'usehooks-ts';
import useIsMobile from '../../hooks/useIsMobile';
import useNavbarPinned from '../../hooks/useNavbarPinned';
import FullWidth from './levels/FullWidth';
import SmallWidth from './levels/SmallWidth';

export const Levels = () => {
	const { navbarPinned } = useNavbarPinned();
	const [showLevels] = useLocalStorage('navbar-showLevels', false);
	const isMobile = useIsMobile();

	const { width } = useWindowSize();

	let subtract = 0;
	if (navbarPinned) {
		subtract = 300;
		if (!showLevels) subtract = 200;
	}

	function getSkillLevels() {
		if (isMobile || width - subtract <= 1000) return <SmallWidth />;
		return <FullWidth />;
	}

	return <div className='header-level-container'>{getSkillLevels()}</div>;
};
