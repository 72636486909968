import { Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IShrineHighscore } from '../../../../../game-server/src/repositories/ShrineDonations.repository';
import { socket } from '../../../services/socket.service';

export default function ShrineGlobalHighscore() {
	const [globalHighscore, setGlobalHighscore] = useState<IShrineHighscore[]>([]);

	useEffect(() => {
		socket.on('shrine:highscore', (data) => {
			setGlobalHighscore(data);
		});
		socket.emit('shrine:highscore');

		return () => {
			socket.off('shrine:highscore');
		};
	}, []);

	return (
		<>
			<Heading textAlign='center' size='lg'>
				Global highscore
			</Heading>
			<TableContainer>
				<Table
					color='white'
					border='2px solid rgba(102, 102, 102)'
					background='rgba(0, 0, 0, 0.5)'
					width='100%'
				>
					<Thead borderBottom='2px solid rgba(192, 192, 192, 0.2)'>
						<Tr>
							<Th fontSize='22px'>Rank</Th>
							<Th fontSize='22px'>Player</Th>
							<Th fontSize='22px'>Donations</Th>
						</Tr>
					</Thead>
					<Tbody>
						{globalHighscore.map((entry, index) => (
							<Tr key={entry.username}>
								<Td>{index + 1}</Td>
								<Td>{entry.username}</Td>
								<Td>{entry.amount.toLocaleString('en-us')}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
}
