import { AbsoluteCenter, Box, Grid, Image, Text } from '@chakra-ui/react';
import { IGlobalBuff } from '../../../../../game-server/src/modules/buffs/global/GlobalBuffManager';
import { IEnchantmentData } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { IUserBuff } from '../../../../../game-server/src/repositories/UserBuff.repository';
import { useGlobalField, usePlayerField } from '../../../hooks/hooks';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { globalBuffApplies } from '../../../utils/leagueFunctions';
import { leagueList } from '../../../utils/leagueList';
import Tooltip from '../UI/Tooltip';
import { Buff } from './Buff';

interface IBuffsProps {
	title: string;
	image: string;
	type: 'active' | 'inactive' | 'global' | 'league' | 'madness';
	buffs?: IUserBuff[];
}

interface ILeagueBuff {
	id: number;
	expires: Date | undefined;
	enchantmentID: IEnchantmentData['id'];
	enchantmentStrength: number;
	source: string;
}
/* border: 2px solid #573a6f;
	background-color: #1e1829; */

type TBuffList = (IGlobalBuff | ILeagueBuff | IUserBuff)[];

export const Buffs = (props: IBuffsProps) => {
	const league = usePlayerField('league');
	const globalBuffs = useGlobalField('buffs');

	function getCorrectBuffs(): TBuffList {
		const leagueData = leagueList[league];
		switch (props.type) {
			case 'global': {
				return (
					globalBuffs
						.filter((buff) => globalBuffApplies(buff, leagueData))
						.filter((buff) => buff.source !== 'Madness') ?? []
				);
			}
			case 'madness': {
				return globalBuffs.filter((buff) => buff.source === 'Madness' && buff.leagueId === league) ?? [];
			}
			case 'league': {
				const leagueData = leagueList[league];
				const leagueBuffs = leagueData?.rules?.leagueBuffs;
				if (!leagueBuffs) {
					return [];
				}
				const source = leagueData.name + ' League Buff';
				// Need to make the IUserBuffs array
				const leagueBuffsArray: ILeagueBuff[] = [];
				for (const buff of leagueBuffs) {
					let expires: Date | undefined = undefined;
					if (leagueData.rules.endDate) {
						expires = new Date(leagueData.rules.endDate);
					}
					leagueBuffsArray.push({
						id: -1,
						expires: expires,
						enchantmentID: buff.id,
						enchantmentStrength: buff.strength,
						source: source,
					});
				}
				return leagueBuffsArray;
			}
			case 'inactive':
			case 'active': {
				return props.buffs || [];
			}
			default: {
				return [];
			}
		}
	}

	const buffs = getCorrectBuffs();

	function renderBuffs() {
		if (buffs.length <= 6) {
			return buffGrid(buffs);
		}

		const gathering: TBuffList = [],
			production: TBuffList = [],
			combat: TBuffList = [],
			rest: TBuffList = [];
		for (const buff of buffs) {
			const enchantment = enchantmentsList[buff.enchantmentID];
			if (
				enchantment.relatedSkills.some((skill) =>
					['gathering', 'mining', 'foraging', 'fishing'].includes(skill)
				)
			) {
				gathering.push(buff);
			} else if (
				enchantment.relatedSkills.some((skill) =>
					['production', 'enchanting', 'runecrafting', 'smithing', 'crafting', 'cooking'].includes(skill)
				)
			) {
				production.push(buff);
			} else if (
				enchantment.relatedSkills.some((skill) =>
					['combat', 'attack', 'defense', 'strength', 'magic', 'range', 'constitution'].includes(skill)
				)
			) {
				combat.push(buff);
			} else {
				rest.push(buff);
			}
		}
		return (
			<>
				{buffSection(gathering)}
				{buffSection(production)}
				{buffSection(combat)}
				{buffSection(rest)}
			</>
		);
	}

	function buffSection(buffs: TBuffList) {
		if (buffs.length === 0) return null;

		return (
			<>
				<hr />
				{buffGrid(buffs)}
			</>
		);
	}

	function buffGrid(buffs: TBuffList) {
		return (
			<Grid
				textAlign={'center'}
				maxWidth={'300px'}
				gridTemplateColumns={'repeat(auto-fit, 40px)'}
				justifyContent={'center'}
				alignContent={'center'}
				gap={'5px'}
			>
				{buffs.map((buff) => {
					return (
						<Buff
							key={'status-bar-buff-' + buff.id}
							source={'source' in buff ? buff.source : undefined}
							expires={'expires' in buff ? buff.expires : undefined}
							enchantmentID={buff.enchantmentID}
							enchantmentStrength={buff.enchantmentStrength}
							stacks={'stacks' in buff ? buff.stacks : undefined}
							extraInfo={'extraInfo' in buff ? buff.extraInfo : undefined}
						/>
					);
				})}
			</Grid>
		);
	}

	if (buffs.length === 0) {
		return null;
	}

	if (props.type === 'global' && buffs.length === 1) {
		const buff = buffs[0] as IGlobalBuff;
		return (
			<Buff
				key={'status-bar-buff-' + buff.id}
				source={buff.source}
				expires={buff.expires}
				enchantmentID={buff.enchantmentID}
				enchantmentStrength={buff.enchantmentStrength}
				extraInfo={buff.extraInfo}
			/>
		);
	}

	return (
		<Box
			borderRadius={'5px'}
			cursor={'pointer'}
			position={'relative'}
			padding={'2px'}
			width={'40px'}
			height={'40px'}
			border={'2px solid #573a6f'}
			backgroundColor={'#1e1829'}
		>
			<AbsoluteCenter
				fontSize={'16px'}
				fontWeight={'bold'}
				bottom={'20%'}
				textShadow={'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'}
			>
				{buffs.length}
			</AbsoluteCenter>
			<Image src={props.image} height={'100%'} width={'100%'}></Image>
			<Tooltip onClick={true}>
				<Text textAlign={'center'} fontSize={'1.5rem'} margin={'5px 0'}>
					{props.title}
				</Text>
				{renderBuffs()}
			</Tooltip>
		</Box>
	);
};
