import React, { useEffect, useRef, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { IProgressBarRef, ProgressBar } from '../../layout/UI/ProgressBar';
import { abilities as abilityList } from '../../../utils/abilityList';
import CombatDialogue from './CombatDialogue';
import { Box, Text } from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import CombatBuffs from './CombatBuff';
import { useRecoilValue } from 'recoil';
import { combatBuffsAtom } from '../../../atoms/combatBuffsAtom';
import { combatOverTimeEventsAtom } from '../../../atoms/combatOverTimeEvents';
import { ICombatBuff, ICombatOverTimeEvents } from '../../../../../game-server/src/modules/combat/Combat.interface';
import CombatOvertime from './CombatOvertime';

export default function CombatInfoBar({
	id,
	name,
	currentHealth,
	maxHealth,
	tempHealth,
	type,
}: {
	id: number;
	name: string;
	currentHealth: number;
	maxHealth: number;
	tempHealth?: number;
	type: string;
}) {
	//const [enchantments, setEnchantments] = useState<ICombatStatsData['enchantments']>([]);
	const allBuffs = useRecoilValue(combatBuffsAtom);
	const allOverTimeEvents = useRecoilValue(combatOverTimeEventsAtom);
	const [ability, setAbility] = useState(0);
	const settings = usePlayerField('settings');
	const progressBarRef = useRef<IProgressBarRef>(null);
	const highPerformance = settings.miscellaneous.highPerformance;

	useEffect(() => {
		socket.on('combat:attack', startAttackAnimation);

		return () => {
			socket.off('combat:attack', startAttackAnimation);
		};
	}, []);

	const playerBuffs = allBuffs.find((currentBuffs) => currentBuffs.id === id);
	const enchantments: ICombatBuff[] = playerBuffs?.buff ?? [];
	const overTimeEvents: ICombatOverTimeEvents[] =
		allOverTimeEvents
			.filter((event) => event?.targetID === id)
			?.sort((a, b) =>
				// Sort by sourceName
				a.sourceName.localeCompare(b.sourceName)
			) ?? [];

	function startAttackAnimation(data: { id: number; length: number; ability: number }) {
		if (id !== data.id) return;
		setAbility(data.ability);
		progressBarRef.current?.startAnimation(data.length);
	}

	const abilityName = ability === -1 ? 'Respawning...' : abilityList[ability]?.abilityName;

	return (
		<>
			<div className='combat-info-bar' onAnimationEnd={(e) => e.stopPropagation()}>
				<Box
					fontSize='18px'
					fontWeight='bold'
					paddingLeft='60px'
					zIndex='1'
					position='relative'
					className='combat-info-bar-name'
				>
					{name}
				</Box>

				<Box
					className='combat-info-bar-health-progress-bar'
					marginTop='-20px'
					marginBottom='-23px'
					position='relative'
				>
					<ProgressBar height={65} value={currentHealth} max={maxHealth} theme='red' />
					{tempHealth !== undefined && tempHealth > 0 && (
						<ProgressBar
							height={65}
							value={tempHealth}
							max={maxHealth}
							theme='shield'
							position='absolute'
							top='0'
							left='0'
						/>
					)}
					<Box
						textAlign='right'
						position='absolute'
						right='60px'
						top='27px'
						textShadow='-3px 0 3px #000,0 3px 3px #000,3px 0 3px #000,0 -3px 3px #000,3px 0 10px #000'
						className='combat-info-bar-health-text'
					>
						{currentHealth}
						{tempHealth !== undefined && tempHealth > 0 && <Text as='span'> + {tempHealth}</Text>}/
						{maxHealth}
					</Box>
					{/* <progress value={currentHealth} max={maxHealth}></progress> */}
				</Box>
				{!highPerformance && (
					<ProgressBar
						ref={progressBarRef}
						value={0}
						max={100}
						theme='combat'
						height='10px'
						width='180px'
						marginBottom='6px'
					/>
				)}
				<Box
					textAlign='center'
					backgroundColor='rgba(0, 0, 0, 0.459)'
					boxShadow='0 5px 4px rgb(0 0 0 / 30%)'
					height='30px'
					lineHeight='30px'
					width='fit-content'
					paddingLeft='10px'
					paddingRight='10px'
					marginLeft='auto'
					marginRight='auto'
					style={{
						borderImageSource: 'url(/images/ui/stone-9slice.png)',
						borderImageSlice: '100 fill',
						borderImageWidth: '100px',
						borderImageOutset: '0',
						borderImageRepeat: 'round',
					}}
				>
					{abilityName}
				</Box>
				<Box position='absolute' right='0'>
					<CombatBuffs enchantments={enchantments} type={'buffs'} />
					<CombatBuffs enchantments={enchantments} type={'debuffs'} />
					<CombatOvertime events={overTimeEvents} />
				</Box>
			</div>
			{type === 'monster' && <CombatDialogue />}
		</>
	);
}
