import { Box, BoxProps, Image } from '@chakra-ui/react';
import { IdlescapeTooltip } from '@idlescape/ui';
import React from 'react';
import { useAppSelector } from '../../../hooks/hooks';
import { locations } from '../../../utils/locationList';

export default function CombatGroupSize({
	locationId,
	showPopup,
	...props
}: { locationId: number; showPopup: boolean } & BoxProps) {
	const location = locations[locationId];

	const { groupData } = useAppSelector((state) => state.group);
	const currentGroupSize = groupData?.groupMemberData?.length ?? 1;
	const maxGroupSize = location.accessRequirements?.maxGroupSize ?? 1;
	const freeSpots = maxGroupSize - currentGroupSize;
	let groupSize = '1';
	let groupTooltip = '';
	if (currentGroupSize > 1) {
		groupSize = `${currentGroupSize}/${maxGroupSize}`;
	} else if (currentGroupSize < maxGroupSize) {
		groupSize = `1-${maxGroupSize}`;
	}
	if (showPopup) {
		if (currentGroupSize > maxGroupSize) {
			groupTooltip = 'Your group is too large to enter this location.';
		} else if (currentGroupSize < maxGroupSize) {
			groupTooltip = `Up to ${freeSpots} additional ${
				freeSpots > 1 ? 'mercenaries' : 'mercenary'
			} will join you if you configured them in your loadouts.`;
		} else {
			groupTooltip = 'There are no free spots in your group. No mercenary will join.';
		}
	}

	if (maxGroupSize > 1 || currentGroupSize > maxGroupSize) {
		return (
			<Box width='60px' fontSize='medium' color={currentGroupSize > maxGroupSize ? 'red' : ''} {...props}>
				<Image
					src='/images/ui/social_icon.png'
					height='90%'
					width='45%'
					marginTop='1px'
					marginRight='3px'
					marginLeft='2px'
				/>
				{groupSize}
				{groupTooltip !== '' && <IdlescapeTooltip>{groupTooltip}</IdlescapeTooltip>}
			</Box>
		);
	} else {
		return <></>;
	}
}
