import { BaseTooltip } from './BaseTooltip';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import { TTooltipEquipmentStats } from '../Data/TooltipData';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';

export class AmmunitionTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Ammunition';
	}

	protected getEquipmentStats(
		item: IItem,
		itemResource: IItemData
	): Partial<Record<TTooltipEquipmentStats, Record<string, number>>> | undefined {
		const damageMultiplier = itemResource.ammunitionMults?.damageMult ?? 0;
		const accuracyMultiplier = itemResource.ammunitionMults?.accuracyMult ?? 0;
		const strengthStats: Record<string, number> = {};
		if (damageMultiplier) strengthStats['x Damage'] = damageMultiplier;
		if (accuracyMultiplier) strengthStats['x Accuracy'] = accuracyMultiplier;

		return { strengthStats: strengthStats };
	}

	protected getRequiredStatsLevel(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Partial<Record<TSkillName, number>> {
		const requiredStats: Partial<Record<TSkillName, number>> = {};
		if (itemResource.requiredLevel) {
			for (const [skill, level] of Object.entries(itemResource.requiredLevel)) {
				if (level > 1) requiredStats[skill as TSkillName] = level;
			}
		}

		return requiredStats;
	}
}
