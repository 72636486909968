import { Grid, Heading } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { itemOrder } from '../../../../../services/PlayerManager';
import { filterItem } from '../../../../../helper/helperFunctions';
import InventoryItem from '../../../Inventory/InventoryItem';
import { ToggleScrappingQueueDialog } from '../../../Inventory/ItemDialogs/ToggleQueueDialogs';

export default function ScrappingStockpile({
	items,
	onClick,
	isDragging,
}: {
	items: IItem[];
	onClick: (event: React.MouseEvent, item: IItem) => boolean;
	isDragging: boolean;
}) {
	const [filterString, setFilterString] = useState('');

	const isMobile = useIsMobile();
	const { setNodeRef } = useDroppable({
		id: 'scrapStockpile',
		data: { items },
	});
	return (
		<IdlescapeContainer as={Grid} gridTemplateRows={'auto auto 1fr'}>
			<Heading size={'md'} textAlign='center'>
				Available Items
			</Heading>
			<IdlescapeInput
				variant='search'
				setValue={setFilterString}
				value={filterString}
				type='search'
				className={'anchor-inventory-filter'}
			/>
			<Grid
				templateColumns={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				templateRows={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				justifyContent='center'
				gap='0.5rem'
				padding='2px'
				border={isDragging ? '2px dashed rgb(208, 108, 238)' : '2px solid transparent'}
				borderRadius='5px'
				className='anchor-scrapping-stockpile'
				ref={setNodeRef}
				style={{ touchAction: 'none' }}
			>
				{items
					.filter((item) => filterItem(item, filterString))
					.sort(itemOrder)
					.map((item) => {
						return (
							<InventoryItem
								key={item.id}
								item={item}
								overwriteDialog={ToggleScrappingQueueDialog}
								overrideOnClick={(e) => onClick(e, item)}
								// showTooltip={false}
							/>
						);
					})}
			</Grid>
		</IdlescapeContainer>
	);
}
