import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { itemList } from '../../../utils/itemList';
import ItemTooltip from '../Tooltips/ItemTooltip';
import {
	itemAffix,
	itemAugment,
	itemEnchant,
	itemAmounts,
	augmentationGlow,
	itemImage,
} from '../../../helper/itemHelperFunctions';
import useIsMobile from '../../../hooks/useIsMobile';
import ItemTooltipDialog from './ItemDialogs/ItemTooltipDialog';

type TFakeItemVariant = 'normal' | 'small' | 'disabled';

function FakeItem({
	item,
	variant = 'normal',
	minAmount = 2,
	amountString,
	mobileTooltip = true,
	...props
}: {
	item: TPartialItemWithItemID<IItem>;
	variant?: TFakeItemVariant;
	minAmount?: number;
	amountString?: string;
	mobileTooltip?: boolean;
} & BoxProps) {
	const itemData = itemList[item.itemID];
	const style =
		variant === 'disabled'
			? {
					backgroundImage: '/images/ui/frame_box.png',
					filter: 'grayscale(0.8)',
					cursor: 'not-allowed',
					_hover: {
						transform: 'unset',
					},
			  }
			: {};

	const itemAmount = item.christmasSpirit ?? item.stackSize ?? 0;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const isMobile = useIsMobile();

	return (
		<Box
			onClick={onOpen}
			className={`${variant === 'small' ? 'small-item' : 'item'} ${variant !== 'disabled' ? itemData.class : ''}`}
			data-itemid={item.itemID}
			{...style}
			{...props}
		>
			{augmentationGlow(item)}
			{itemImage(itemData)}
			{itemEnchant(item)}
			{itemAugment(item)}
			{itemAffix(item)}
			{amountString ? <div className='centered'>{amountString}</div> : itemAmounts(itemAmount, minAmount)}
			{isMobile ? (
				mobileTooltip && isOpen && <ItemTooltipDialog item={item} onClose={onClose} />
			) : (
				<ItemTooltip item={item} />
			)}
		</Box>
	);
}

export default FakeItem;
