// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TAugmentingLootListClient } from '../../../game-server/src/modules/skills/enchanting/Enchanting.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const augmentingLootList: TAugmentingLootListClient = {
	620: {
		transforms: [
			{
				newItemID: 622,
				resetAugmentations: false,
				chance: 0.0004,
			},
		],
	},
	621: {
		transforms: [
			{
				newItemID: 623,
				resetAugmentations: false,
				chance: 0.0004,
			},
		],
		scrappingSuccess: {
			itemID: 12012,
			chance: 0.1,
		},
	},
	622: {
		transforms: [
			{
				newItemID: 624,
				resetAugmentations: false,
				chance: 0.0003333333333333333,
			},
		],
	},
	623: {
		transforms: [
			{
				newItemID: 625,
				resetAugmentations: false,
				chance: 0.0003333333333333333,
			},
		],
	},
	624: {
		transforms: [
			{
				newItemID: 626,
				resetAugmentations: false,
				chance: 0.00028571428571428574,
			},
		],
	},
	625: {
		transforms: [
			{
				newItemID: 627,
				resetAugmentations: false,
				chance: 0.00028571428571428574,
			},
		],
	},
	626: {
		transforms: [
			{
				newItemID: 630,
				resetAugmentations: false,
				chance: 0.00025,
			},
		],
	},
	627: {
		transforms: [
			{
				newItemID: 631,
				resetAugmentations: false,
				chance: 0.00025,
			},
		],
	},
	630: {
		transforms: [
			{
				newItemID: 629,
				resetAugmentations: false,
				chance: 0.0002,
			},
		],
		scrappingSuccess: {
			itemID: 12012,
			chance: 0.1,
		},
	},
	631: {
		transforms: [
			{
				newItemID: 628,
				resetAugmentations: false,
				chance: 0.0002,
			},
		],
		scrappingSuccess: {
			itemID: 12012,
			chance: 0.1,
		},
	},
	672: {
		transforms: [
			{
				chance: 1,
				newItemID: 673,
				resetAugmentations: true,
				augmentationLevel: 4,
				augmentingTransform: true,
			},
		],
	},
	673: {
		transforms: [
			{
				chance: 1,
				newItemID: 674,
				resetAugmentations: true,
				augmentationLevel: 5,
				augmentingTransform: true,
			},
		],
	},
	674: {
		transforms: [
			{
				chance: 1,
				newItemID: 675,
				resetAugmentations: true,
				augmentationLevel: 6,
				augmentingTransform: true,
			},
		],
	},
	675: {
		transforms: [
			{
				chance: 1,
				newItemID: 676,
				resetAugmentations: true,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
	1008: {
		transforms: [
			{
				newItemID: 1522,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1009: {
		transforms: [
			{
				newItemID: 1810,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1010: {
		transforms: [
			{
				newItemID: 1523,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1011: {
		transforms: [
			{
				newItemID: 1524,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1020: {
		transforms: [
			{
				newItemID: 1512,
				resetAugmentations: true,
				chance: 0.002,
			},
		],
	},
	1052: {
		transforms: [
			{
				newItemID: 1513,
				resetAugmentations: true,
				chance: 0.001,
			},
			{
				newItemID: 1520,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1062: {
		transforms: [
			{
				newItemID: 1526,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1072: {
		transforms: [
			{
				newItemID: 1521,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1094: {
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
		transforms: [],
	},
	1095: {
		transforms: [
			{
				newItemID: 1517,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1112: {
		transforms: [
			{
				newItemID: 1518,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1132: {
		transforms: [
			{
				newItemID: 1519,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
	},
	1152: {
		scrappingSuccess: {
			itemID: 12014,
		},
		scrappingFail: {
			itemID: 209,
			chance: 0.25,
		},
		transforms: [],
	},
	1217: {
		transforms: [
			{
				newItemID: 1318,
				resetAugmentations: true,
				chance: 0.0003333333333333333,
			},
		],
	},
	1218: {
		transforms: [
			{
				newItemID: 1318,
				resetAugmentations: true,
				chance: 0.0004,
			},
		],
	},
	1219: {
		transforms: [
			{
				newItemID: 1318,
				resetAugmentations: true,
				chance: 0.002,
			},
		],
	},
	1318: {
		transforms: [
			{
				newItemID: 1319,
				resetAugmentations: true,
				chance: 0.0033333333333333335,
			},
		],
	},
	1503: {
		transforms: [
			{
				newItemID: 1564,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
	},
	1504: {
		transforms: [
			{
				newItemID: 1564,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
	},
	1505: {
		transforms: [
			{
				newItemID: 1564,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
	},
	1517: {
		transforms: [
			{
				newItemID: 1803,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
	},
	1518: {
		transforms: [
			{
				newItemID: 1804,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
	},
	1519: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1520: {
		transforms: [
			{
				newItemID: 1805,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
	},
	1521: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1522: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1523: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1524: {
		transforms: [
			{
				newItemID: 1525,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
		scrappingSuccess: {
			itemID: 12014,
		},
	},
	1525: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1526: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1527: {
		transforms: [
			{
				newItemID: 1806,
				resetAugmentations: true,
				forcedEnchant: true,
				chance: 1,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
	1528: {
		transforms: [
			{
				newItemID: 7031,
				resetAugmentations: false,
				chance: 0.0001,
			},
		],
	},
	1545: {
		transforms: [
			{
				newItemID: 1546,
				resetAugmentations: true,
				chance: 1,
				augmentationLevel: 1,
				augmentingTransform: true,
			},
		],
	},
	1560: {
		scrappingFail: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
		transforms: [],
	},
	1561: {
		scrappingFail: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
		transforms: [],
	},
	1562: {
		scrappingFail: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
		transforms: [],
	},
	1563: {
		scrappingFail: {
			itemID: 4011,
			minimum: 6,
			maximum: 10,
		},
		transforms: [],
	},
	1566: {
		transforms: [
			{
				newItemID: 1567,
				resetAugmentations: true,
				chance: 1,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
	1568: {
		transforms: [
			{
				newItemID: 1569,
				resetAugmentations: true,
				chance: 1,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
	1570: {
		scrappingFail: {
			itemID: 4011,
			minimum: 24,
			maximum: 40,
		},
		transforms: [],
	},
	1572: {
		transforms: [
			{
				newItemID: 1573,
				resetAugmentations: true,
				chance: 1,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
	1574: {
		scrappingFail: {
			itemID: 4011,
			minimum: 24,
			maximum: 40,
		},
		transforms: [],
	},
	1575: {
		transforms: [
			{
				newItemID: 1574,
				resetAugmentations: true,
				forcedEnchant: true,
				chance: 0.001,
			},
		],
	},
	1584: {
		scrappingFail: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		transforms: [],
	},
	1585: {
		scrappingFail: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		transforms: [],
	},
	1586: {
		scrappingFail: {
			itemID: 1558,
			minimum: 80,
			maximum: 120,
		},
		transforms: [],
	},
	1810: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1811: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1812: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1813: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	1814: {
		scrappingSuccess: {
			itemID: 12014,
		},
		transforms: [],
	},
	5000: {
		transforms: [
			{
				newItemID: 9015,
				resetAugmentations: true,
				augmentationLevel: 1,
				augmentingTransform: true,
				chance: 1,
			},
		],
		scrappingFail: {
			itemID: 9015,
		},
	},
	5001: {
		transforms: [
			{
				newItemID: 5002,
				resetAugmentations: true,
				chance: 0.002,
			},
		],
	},
	5002: {
		transforms: [
			{
				newItemID: 5000,
				resetAugmentations: true,
				chance: 0.005,
			},
			{
				newItemID: 1587,
				resetAugmentations: true,
				chance: 0.001,
			},
		],
	},
	7001: {
		transforms: [
			{
				newItemID: 7042,
				chance: 0.2,
				resetAugmentations: true,
			},
		],
		scrappingFail: {
			itemID: 7000,
			minimum: 1,
			maximum: 100,
		},
	},
	12020: {
		transforms: [
			{
				newItemID: 12021,
				resetAugmentations: true,
				forcedEnchant: true,
				chance: 1,
				augmentationLevel: 5,
				augmentingTransform: true,
			},
		],
	},
	12021: {
		transforms: [
			{
				newItemID: 12022,
				resetAugmentations: true,
				forcedEnchant: true,
				chance: 1,
				augmentationLevel: 7,
				augmentingTransform: true,
			},
		],
	},
};
