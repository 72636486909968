import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { smithingActiveBarAtom } from '../../../../atoms/smithingActiveBarAtom';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingIntensityAtom } from '../../../../atoms/smithingIntensityAtom';
import { itemList } from '../../../../utils/itemList';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { forges } from '../../../../utils/forgeList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { Box } from '@chakra-ui/react';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { getBarTier, maxIntensity } from '../../../../utils/smithingFunctions';

const bars = [
	itemsIds.bronze_bar,
	itemsIds.iron_bar,
	itemsIds.gold_bar,
	itemsIds.mithril_bar,
	itemsIds.adamantite_bar,
	itemsIds.runite_bar,
	itemsIds.stygian_bar,
	itemsIds.chaotic_crystal,
	itemsIds.core_ingot,
];

export default function SmithingBarList() {
	const [activeBar, setActiveBar] = useRecoilState(smithingActiveBarAtom);
	const [intensity, setIntensity] = useRecoilState(smithingIntensityAtom);
	const activeForge = useRecoilValue(smithingActiveForgeAtom);

	const skills = usePlayerField('skills');
	const smithingLevel = skills.smithing.level;
	const metallurgyStrength = usePlayerEnchantmentStrength(enchantmentsIds.metallurgy, 'smithing');
	const moltenMetalStrength = usePlayerEnchantmentStrength(enchantmentsIds.molten_metal, 'smithing');
	const maxForgeIntensity = maxIntensity(forges[activeForge], moltenMetalStrength, metallurgyStrength);
	return (
		<div className='smithing-bar-list idlescape-container'>
			{bars.map((bar) => {
				const itemData = itemList[bar];

				const barTier = getBarTier(itemData);

				if (!itemData.level || maxForgeIntensity < barTier) {
					return null;
				}

				const isUnlockedLevel = itemData.level <= smithingLevel;
				const isUnlockedIntensity = barTier <= intensity;
				let cover = <></>;
				if (!isUnlockedLevel) {
					cover = (
						<div className='smithing-furnace-locked-cover'>
							<img
								src='images/smithing/smithing_icon.png'
								className='icon60'
								alt='Required Smithing Level'
							/>
							<div className='smithing-furnace-locked-cover-level'>{itemData.level}</div>
						</div>
					);
				} else if (!isUnlockedIntensity) {
					cover = (
						<div className='smithing-furnace-locked-cover'>
							<img src='images/heat_icon.png' className='icon60' alt='Required Intensity' />
							<div className='smithing-furnace-locked-cover-level'>{barTier}</div>
						</div>
					);
				}
				return (
					<Box
						className={`smithing-bar-list-item ${
							activeBar === bar ? 'smithing-bar-list-item-active' : ''
						} ${isUnlockedLevel && isUnlockedIntensity ? '' : 'smithing-furnace-locked'}`}
						onClick={() => {
							if (isUnlockedLevel) {
								if (!isUnlockedIntensity) {
									setIntensity(barTier);
								}
								setActiveBar(bar);
							}
						}}
						key={bar}
						position='relative'
					>
						{cover}
						<img src={itemData.itemImage} alt={itemData.name} />
						<ItemTooltip item={{ itemID: bar }} />
					</Box>
				);
			})}
		</div>
	);
}
