import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Text,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useRef } from 'react';

function ChatModPingConfirmation(props: { isOpen: boolean; onClose: () => void; confirm: () => void }) {
	const leastDestructiveRef = useRef(null);

	return (
		<AlertDialog isOpen={props.isOpen} onClose={props.onClose} leastDestructiveRef={leastDestructiveRef}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Mods Ping
					</AlertDialogHeader>

					<AlertDialogBody>
						<Text>
							Only use the @mods tag for emergencies such as spamming, racism, things being broken etc.
							Use the feedback button or create a discord ticket if you need to get in touch with mods.
						</Text>
						<Text>
							Are you sure you want to ping the mods? <b>Unnecessary use will result in a mute!</b>
						</Text>
					</AlertDialogBody>

					<AlertDialogFooter>
						<IdlescapeButton variant='red' ref={leastDestructiveRef} onClick={props.onClose}>
							No
						</IdlescapeButton>
						<IdlescapeButton
							variant='green'
							onClick={() => {
								props.confirm();
								props.onClose();
							}}
						>
							Yes
						</IdlescapeButton>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
}

export default ChatModPingConfirmation;
