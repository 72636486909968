import { Flex, InputGroup, InputRightAddon, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { IdlescapeNumberInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { PlayerData } from '../../../../../../game-server/src/modules/player/PlayerData';
import { IChatChannel } from '../../../../../../game-server/src/repositories/ChatChannel.repository';
import ChatChannelHistory from './ChatChannelHistory';

function ChatHistory(props: { playerId: PlayerData['id']; chatChannels: IChatChannel[]; reverse?: boolean }) {
	const [maxMessages, setMaxMessages] = useState<number>(50);
	const [onlyDeleted, setOnlyDeleted] = useState<boolean>(false);

	return (
		<Tabs isLazy>
			<TabList width='calc(100% - 280px)' float='left' flexWrap='wrap'>
				{props.chatChannels.map((channel) => (
					<Tab key={channel.id}>{channel.name}</Tab>
				))}
			</TabList>
			<Flex float='right' gap='2px' width='280px'>
				<InputGroup flex='0 1 130px'>
					<IdlescapeNumberInput value={maxMessages} setValue={setMaxMessages} />
					<InputRightAddon>Max</InputRightAddon>
				</InputGroup>
				<InputGroup flex='0 1 150px'>
					<Flex
						alignItems='center'
						height='100%'
						border='1px solid'
						borderColor='inherit'
						borderLeftRadius='0.375rem'
						paddingX='4px'
					>
						<Switch isChecked={onlyDeleted} onChange={(e) => setOnlyDeleted(e.target.checked)} />
					</Flex>
					<InputRightAddon>Only Deleted</InputRightAddon>
				</InputGroup>
			</Flex>
			<TabPanels style={{ clear: 'both' }}>
				{props.chatChannels.map((channel) => (
					<TabPanel key={channel.id}>
						<ChatChannelHistory
							playerId={props.playerId}
							channelID={channel.id}
							maxMessages={maxMessages}
							onlyDeleted={onlyDeleted}
							reverse={props.reverse}
						/>
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
}

export default ChatHistory;
