import {
	IdlescapeButton,
	IdlescapeContainer,
	IdlescapeNumberInput,
	IdlescapeFrameBox,
	IdlescapeWrappingTooltip,
} from '@idlescape/ui';
import React, { useState } from 'react';
import { IQuestData } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { getSocket } from '../../../services/socket.service';
import { questList } from '../../../utils/questList';
import { itemList } from '../../../utils/itemList';
import FakeItem from '../Inventory/FakeItem';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { Image, Flex, Grid, Text, Box } from '@chakra-ui/react';
import { locations } from '../../../utils/locationList';
import { monsters } from '../../../utils/monsterList';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { ProgressBar } from '../../layout/UI/ProgressBar';
import { questPointIcon } from '../../../helper/itemHelperFunctions';
import { tabs } from '../../../helper/NavigationTabsList';
import useIsMobile from '../../../hooks/useIsMobile';
import { questAtom } from '../../../atoms/questAtom';
import { usePlayerField } from '../../../hooks/hooks';
import QuestSummary from './QuestSummary';
import { checkRequirements, getQuestPoints } from './QuestFunctions';
import { TSkillName } from '../../../../../game-server/src/Atypes/Skills';
import { useReadLocalStorage } from 'usehooks-ts';
import { IQuestFilters } from './QuestList';
import { talentList } from '../../../utils/talentList';
import { shareQuestSelector } from '../../../atoms/chat/chatHistoryAtom';
import QuestItemTurnIn from './QuestItemTurnIn';

export default function QuestMain({ isAchievements = false }: { isAchievements: boolean }) {
	const socket = getSocket();
	const questFilters = useReadLocalStorage<IQuestFilters>('questFilters');
	const [turnInInput, setTurnInInput] = useState(0);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const shareQuest = useSetRecoilState(shareQuestSelector);
	const [currentQuest, setCurrentQuest] = useRecoilState(questAtom);
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	const stockpile = usePlayerField('stockpile');
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const league = usePlayerField('league');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const questPoints = getQuestPoints(questIdsCompleted);

	const quest = questList[currentQuest.quest || 1];
	const metRequirements = checkRequirements(quest, skills, questIdsCompleted);
	const step = quest.questSteps.find((qs) =>
		quest.questSteps.length === 1 ? true : qs.stepID === currentQuest.step
	);
	const requirement = renderQuestRequirements();
	const renderBoth = quest.questSteps.length === 1;

	const isMobile = useIsMobile();

	function completeQuest(questID: number) {
		socket.emit('quest:complete', { questID });
		if (questFilters?.pinSelected) {
			pinQuest(0);
		}
	}
	function startQuest(questID: number) {
		socket.emit('quest:accept', { questID });
		if (questFilters?.pinSelected) {
			pinQuest(questID);
		}
	}

	function renderQuestRequirements() {
		const requirements: React.ReactNode[] = [];
		if (!quest.questRequirements) return null;
		if (quest.questRequirements.questPoints) {
			requirements.push(
				<Flex
					key='point-requirement'
					alignItems='center'
					color={questPoints < quest.questRequirements.questPoints ? 'rgb(255, 65, 65)' : ''}
				>
					{questPointIcon(32)}
					{quest.questRequirements.questPoints} Quest Point
					{quest.questRequirements.questPoints > 1 && 's'}
				</Flex>
			);
		}
		if (quest.questRequirements.requiredQuests) {
			requirements.push(
				<Box key='quest-requirement'>
					Quests:{' '}
					{quest.questRequirements.requiredQuests.map((requiredQuest, index) => {
						const reqQuest = questList[requiredQuest];
						const pass = questIdsCompleted.includes(reqQuest.id);
						return (
							<React.Fragment key={reqQuest.id}>
								{index > 0 && ', '}
								<Box
									as='span'
									color={pass ? '' : 'rgb(255, 65, 65)'}
									cursor='pointer'
									onClick={() => setCurrentQuest({ quest: reqQuest.id, step: 0 })}
								>
									{reqQuest?.name}
								</Box>
							</React.Fragment>
						);
					})}
				</Box>
			);
		}
		if (quest.questRequirements.skillRequirements) {
			for (const [skillName, level] of Object.entries(quest.questRequirements.skillRequirements)) {
				tabs[skillName]?.name;
				requirements.push(
					<Box
						key={skillName}
						color={skills[skillName as TSkillName].level < level ? 'rgb(255, 65, 65)' : ''}
					>
						{tabs[skillName]?.name} level: {level}
					</Box>
				);
			}
		}
		return requirements;
	}
	const nextQuests: React.ReactNode[] = [];
	const nextAchievements: React.ReactNode[] = [];
	for (const [key, curQuest] of Object.entries(questList)) {
		const restricted =
			curQuest?.questRestrictions?.inactive ||
			curQuest?.questRestrictions?.blacklistedLeagues?.includes(league) ||
			(curQuest?.questRestrictions?.questStartDate &&
				curQuest?.questRestrictions?.questEndDate &&
				(new Date() < new Date(curQuest.questRestrictions.questStartDate) ||
					new Date() > new Date(curQuest.questRestrictions.questEndDate)));
		if (restricted) continue;
		if (curQuest.questRequirements?.requiredQuests?.includes(quest.id)) {
			const pass = questIdsCompleted.includes(curQuest.id);
			const questObj = (
				<React.Fragment key={key}>
					<Box
						as='span'
						cursor={!curQuest.achievement ? 'pointer' : undefined}
						color={pass ? '' : 'rgb(255, 65, 65)'}
						onClick={
							!curQuest.achievement ? () => setCurrentQuest({ quest: curQuest.id, step: 0 }) : undefined
						}
					>
						{curQuest?.name}
					</Box>
				</React.Fragment>
			);
			if (curQuest.achievement) {
				nextAchievements.push(nextAchievements.length === 0 ? 'Achievements: ' : ', ');
				nextAchievements.push(questObj);
			} else {
				nextQuests.push(nextQuests.length === 0 ? 'Quests: ' : ', ');
				nextQuests.push(questObj);
			}
		}
	}

	function pinQuest(quest: number) {
		if (settings.quest.pin !== quest) {
			socket.emit('settings:update', {
				value: quest,
				path: 'quest.pin',
			});
		}
	}

	function selectQuest(curQuest: { quest: number; step: number }) {
		setCurrentQuest(curQuest);
		if (questFilters?.pinSelected) {
			pinQuest(curQuest.quest);
		}
	}

	function renderQuestSteps() {
		if (!step) return null;

		const currentProgress = questIdsCompleted.includes(quest.id)
			? step.targetAmount
			: questProgress.find(
					(questProgress) => questProgress.id === quest.id && step.stepID === questProgress.stepID
			  )?.current ?? 0;
		const inProgress = questProgress.find(
			(questProgress) => questProgress.id === quest.id && step.stepID === questProgress.stepID
		);
		const isCompleted = currentProgress === step.targetAmount || questIdsCompleted.includes(quest.id);
		const sortTargets = structuredClone(step.validTargets).sort((a, b) => (a.trigger > b.trigger ? 1 : -1));
		const showTurnInInput = sortTargets.some((requirement) => requirement.trigger === 'inventory');
		const stepReqs: React.ReactNode[] = sortTargets.map((requirement, index) => {
			const trigger = requirement.trigger;
			if (trigger === 'custom') return <Image key={index} width='50px' src='/images/tutorial/quest_point.png' />;
			let targetNameString = '';
			let triggerString = '';
			let targetObj: React.ReactElement | null = null;
			switch (trigger) {
				case 'location':
				case 'dungeon': {
					const location = locations[requirement.targetID];
					targetNameString = location.name;
					triggerString = location.actionType.split('-')[1];
					if (trigger === 'dungeon') triggerString = 'Complete';
					if (location.locationImage) {
						targetObj = <Image width='90px' height='90px' src={location.locationImage} />;
					}
					break;
				}
				case 'gather':
				case 'craft':
				case 'farm':
				case 'enchant-scroll': {
					const here: TPartialItemWithItemID<IItem> = {
						itemID: requirement.targetID,
					};
					targetNameString = itemList[requirement.targetID].name;
					targetObj = <FakeItem item={here} key={`req-item-${requirement.targetID}`} minAmount={1} />;
					break;
				}
				case 'inventory':
					return (
						<QuestItemTurnIn
							key={requirement.targetID}
							questID={quest.id}
							stepID={step.stepID}
							requirement={requirement}
							allowTurnIn={currentProgress !== step.targetAmount && inProgress !== undefined}
							amount={turnInInput}
							setAmount={setTurnInInput}
						/>
					);
				case 'slay': {
					targetNameString = monsters[requirement.targetID].name;
					triggerString = 'Slay';
					targetObj = <Image width='90px' height='90px' src={monsters[requirement.targetID].image} />;
				}
			}
			if (requirement.triggerString) {
				triggerString = requirement.triggerString;
			}
			switch (trigger) {
				case 'gather':
					triggerString = 'Gather';
					break;
				case 'craft':
					triggerString = 'Craft';
					break;
				case 'farm':
					triggerString = 'Harvest';
					break;
				case 'plant':
					triggerString = 'Plant';
					break;
				case 'enchant-scroll':
					triggerString = 'Enchant an item with';
					break;
			}
			return (
				<Flex
					key={`${targetNameString}-${requirement.targetID}-${trigger}`}
					margin='5px'
					flexDirection='column'
					alignItems='center'
					textAlign='center'
					maxWidth='100px'
				>
					{targetObj}
					{requirement.progressMult ? `(${requirement.progressMult}x)` : ''}
					{triggerString === 'Turn in' ? '' : triggerString}
					<br />
					{targetNameString}
				</Flex>
			);
		});
		let customButton: React.ReactElement | null = null;
		if (step.customButton) {
			const emit = step.customButton.emit;
			customButton = (
				<IdlescapeButton
					size='large'
					padding='0 20px'
					variant='green'
					onClick={() => {
						socket.emit(emit);
					}}
				>
					{step.customButton.name}
				</IdlescapeButton>
			);
		}
		let linkButton: React.ReactElement | null = null;
		if (step.link) {
			const linkPage = step.link.page;
			const tab = tabs[linkPage];
			// Create a button that sets the active tab
			linkButton = (
				<IdlescapeButton
					size='large'
					padding='0 20px'
					variant='blue'
					onClick={() => {
						setActiveTab(linkPage);
					}}
				>
					Go to {tab.overrideTabName ?? tab.name}
					<Image
						width='25px'
						height='25px'
						src={tab.overrideTabIcon ?? tab.icon}
						alt={tab.overrideTabName ?? tab.name}
					/>
				</IdlescapeButton>
			);
		}
		let progressWarning: React.ReactElement | null = null;
		if (!inProgress || isCompleted) {
			let reason = 'Accept the quest or complete previous steps first.';
			// Check all in progress steps; if the quest is in progress, but this step isn't, show the reason
			const inProgressQuest = questProgress.find((questProgress) => questProgress.id === quest.id);
			if (inProgressQuest) {
				const inProgressStep = quest.questSteps.find(
					(step) => step.stepID === inProgressQuest.stepID && inProgressQuest.current < step.targetAmount
				);
				if (inProgressStep) {
					reason = `Complete Step ${inProgressStep.stepID}: ${inProgressStep.name} first.`;
				}
			}
			// If this quest isn't in progress, show the reason
			if (!inProgressQuest) {
				reason = 'Accept the quest first.';
			}
			// If the quest is completed, show that instead
			if (questIdsCompleted.includes(quest.id)) {
				reason = 'This quest has been completed.';
			}
			// Check if the step is already complete
			if (isCompleted) {
				reason = 'This step has already been completed.';
			}
			progressWarning = (
				<div className='quest-not-in-progress'>
					<hr></hr>
					This step is not active: {reason}
					<hr></hr>
				</div>
			);
		}
		return (
			<IdlescapeContainer className='quest-listing-content' position='relative' textAlign='center'>
				{progressWarning}
				<h2>
					{!renderBoth && `Step ${step.stepID}: `}
					{step.name}
				</h2>
				<div>{step.description}</div>
				<Box padding='10px' height='auto'>
					<Flex position='relative' justifyContent='center' alignItems='center' height='25px' margin='10px'>
						<ProgressBar
							position='absolute'
							top='0'
							theme='quest'
							value={currentProgress + step.targetAmount / 100} // This is a hack to make the progress bar not be full :)
							max={step.targetAmount + step.targetAmount / 100} // Nick UI go?!?
							height='100%'
							maxWidth='300px'
						/>
						<Box position='relative'>
							Current Progress: {currentProgress.toLocaleString('en-us')}/
							{step.targetAmount.toLocaleString('en-us')}
						</Box>
					</Flex>
					{currentProgress !== step.targetAmount && showTurnInInput && (
						<Flex alignItems='center' gap='5px'>
							Turn In Amount:
							<IdlescapeNumberInput
								setValue={setTurnInInput}
								value={turnInInput}
								min={0}
								max={step.targetAmount - currentProgress}
								backgroundColor='#00000088'
								maxWidth='200px'
								placeholder='Enter amount'
							/>
							<IdlescapeButton
								variant='blue'
								width='50px'
								onClick={() => setTurnInInput(step.targetAmount - currentProgress)}
							>
								Max
							</IdlescapeButton>
						</Flex>
					)}
					<IdlescapeContainer
						position='relative'
						display='flex'
						flexDirection='row'
						alignItems='flex-start'
						justifyContent='center'
						flexWrap='wrap'
						margin='20px 0'
					>
						{stepReqs}
					</IdlescapeContainer>
					<Flex justifyContent='center'>
						{currentProgress !== step.targetAmount && customButton}
						{linkButton}
					</Flex>
				</Box>
			</IdlescapeContainer>
		);
	}

	function renderSummary() {
		const questSummary = (
			<QuestSummary
				isAchievements={isAchievements}
				questsCompleted={questsCompleted}
				questProgress={questProgress}
			/>
		);
		return questSummary;
	}

	function renderQuest(quest: IQuestData) {
		const completed = questIdsCompleted.find((completedQuest) => completedQuest === quest.id);
		const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);
		let buttonString = 'Accept';
		// Check if they can even start the quest
		if (!metRequirements) {
			buttonString = 'Locked';
		}

		// Quest complete check
		if (inProgress.length > 0 && !completed) {
			// Check if they have the requirements to complete the quest
			buttonString = 'Complete';
			for (const step of quest.questSteps) {
				// Find the progress for this step
				const progress = inProgress.find((progress) => progress.stepID === step.stepID);
				if (!progress || progress.current < step.targetAmount) {
					buttonString = 'In Progress';
					break;
				}
			}
		}
		if (quest.achievement) {
			buttonString = 'Locked';
		}

		// Disabling it if it's done
		if (completed) {
			buttonString = 'Completed';
		}
		// Check if it's outside the start and end date
		if (quest?.questRestrictions?.questStartDate && quest?.questRestrictions?.questEndDate) {
			const startDate = new Date(quest.questRestrictions.questStartDate);
			const endDate = new Date(quest.questRestrictions.questEndDate);
			const now = new Date();
			if (now < startDate || now > endDate) {
				buttonString = 'Not Available';
			}
		}

		const button = (
			<IdlescapeButton
				size='large'
				padding='0 20px'
				variant={buttonString === 'Accept' || buttonString === 'Complete' ? 'green' : 'disabled'}
				onClick={() => {
					if (buttonString === 'Accept') startQuest(quest.id);
					if (buttonString === 'Complete') completeQuest(quest.id);
				}}
			>
				{buttonString}
			</IdlescapeButton>
		);
		const currentProgress =
			questProgress.find((progress) => progress.id === quest.id && step?.stepID === progress.stepID)?.current ??
			0;
		const nextStepIndex = quest.questSteps.findIndex((curStep) => curStep.stepID === step?.stepID) + 1;
		const nextStep = (
			<IdlescapeButton
				size='large'
				padding='0 20px'
				variant={
					(!step && buttonString === 'In Progress') || currentProgress === step?.targetAmount
						? 'orange'
						: 'disabled'
				}
				onClick={() => {
					selectQuest({ quest: quest.id, step: quest.questSteps[nextStepIndex]?.stepID ?? 0 });
				}}
			>
				{quest.questSteps[nextStepIndex] ? 'Next Step' : 'Back'}
			</IdlescapeButton>
		);
		return (
			<>
				{currentQuest.step === 0 || quest.questSteps.length === 1 ? ( // When no steps are displayed or only one step exists for the quest
					<IdlescapeContainer>
						<Flex alignItems='center' maxHeight={isMobile ? '180px' : '325px'}>
							<IdlescapeFrameBox
								display='flex'
								flexDirection='column'
								alignItems='center'
								flex='2'
								position='relative'
								maxWidth={isMobile ? '125px' : '250px'}
								maxHeight={isMobile ? '150px' : '325px'}
								textAlign='center'
								padding='10px'
								margin='5px'
								_hover={{}}
							>
								<Image
									maxWidth={isMobile ? '115px' : '240px'}
									maxHeight={isMobile ? '140px' : '265px'}
									src={quest.questIcon}
									padding='5px'
								/>
								{metRequirements && buttonString !== 'Accept' && (
									<IdlescapeWrappingTooltip content='Share your quest progress!'>
										<Box
											onClick={() => shareQuest(quest.id.toString())}
											filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
											float='right'
											cursor='pointer'
											position='absolute'
											top='10px'
											right='10px'
											zIndex='11'
											_hover={{ filter: 'brightness(2)' }}
										>
											<Image
												src='/images/chaticons/chat-icon-global.png'
												width='40px'
												height='40px'
											/>
										</Box>
									</IdlescapeWrappingTooltip>
								)}
							</IdlescapeFrameBox>
							<Box flex='1' overflow='auto' maxHeight={isMobile ? '180px' : '325px'}>
								<div key={`quest-listing-${quest.id}`} className='quest-listing-content'>
									<Text fontSize='32px' margin='20px 0 10px 0'>
										{quest.name}
									</Text>
									<Text as='h3' margin='0 0 15px 0'>
										Difficulty: {quest.difficulty}
									</Text>
									{requirement && (
										<>
											<Text as='h3' margin='0 0 10px 0'>
												Requirements:
											</Text>
											<Text as='h5' margin='0 0 15px 0' lineHeight='1.5em'>
												{renderQuestRequirements()}
											</Text>
										</>
									)}
									{(nextQuests.length > 0 || nextAchievements.length > 0) && (
										<>
											<Text as='h3' margin='0 0 10px 0'>
												Required for:
											</Text>
											<Text as='h5' margin='0 0 15px 0' lineHeight='1.5em'>
												{nextQuests}
												{nextQuests.length > 0 && nextAchievements.length > 0 && <br />}
												{nextAchievements}
											</Text>
										</>
									)}
									<Text fontSize={isMobile ? '16px' : '18px'} margin='0 0 10px 0'>
										{quest.description}
									</Text>
								</div>
							</Box>
						</Flex>
					</IdlescapeContainer>
				) : (
					// List of steps, when one step is being displayed
					<IdlescapeContainer>
						<Flex alignItems='center' height='150px'>
							<IdlescapeFrameBox padding='10px' margin='5px' _hover={{}}>
								<Image width='100px' src={quest.questIcon} maxHeight='90px' />
								{metRequirements && buttonString !== 'Accept' && (
									<IdlescapeWrappingTooltip content='Share your quest progress!'>
										<Box
											onClick={() => shareQuest(quest.id.toString())}
											filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
											float='right'
											cursor='pointer'
											position='absolute'
											top='10px'
											right='10px'
											zIndex='11'
											_hover={{ filter: 'brightness(2)' }}
										>
											<Image
												src='/images/chaticons/chat-icon-global.png'
												width='30px'
												height='30px'
											/>
										</Box>
									</IdlescapeWrappingTooltip>
								)}
							</IdlescapeFrameBox>
							<Text fontSize='32px'>{quest.name}</Text>
						</Flex>
					</IdlescapeContainer>
				)}
				{renderQuestSteps()}
				{currentQuest.step === 0 && renderQuestRewards(quest)}
				<Flex marginTop='10px' justifyContent='center'>
					{button}
					{quest.questSteps.length > 1 && nextStep}
				</Flex>
			</>
		);
	}

	return currentQuest.quest ? renderQuest(quest) : renderSummary();
}

export function renderQuestRewards(quest: IQuestData) {
	let experienceObj: React.ReactElement[] = [];
	if (quest.questRewards?.experience) {
		experienceObj = Object.entries(quest.questRewards.experience).map(([skill, expReward]) => {
			const skillIcon = tabs[skill].icon;
			return (
				<Flex key={`exp-reward-${skill}`} margin='1px' padding='1px' flexDirection='column' alignItems='center'>
					<Image width='50px' height='50px' src={skillIcon} />
					{expReward.toLocaleString('en-us')}
				</Flex>
			);
		});
	}
	let items: React.ReactNode[] = [];
	if (quest.questRewards?.items) {
		items = quest.questRewards.items.map((reward) => {
			if (!quest.questRewards?.items) return null;
			const itemData = itemList[reward.id];
			const newItem: TPartialItemWithItemID<IItem> = {
				itemID: itemData.id,
				augmentations: reward.extra?.augmentations,
				enchantmentID: reward.extra?.enchantmentID,
				enchantmentStrength: reward.extra?.enchantmentStrength,
				name: reward.extra?.name,
				stackSize: reward.amount,
			};
			return <FakeItem item={newItem} key={`reward-item-${reward.id}`} />;
		});
	}
	let talents: React.ReactNode[] = [];
	if (quest.questRewards?.relatedTalent) {
		talents = quest.questRewards.relatedTalent.map((talent) => {
			return (
				<Box key={`talent-reward-${talent}`} margin='1px' padding='1px'>
					{talentList[talent].name}
				</Box>
			);
		});
	}
	if (experienceObj.length === 0 && items.length === 0 && !quest.questRewards?.questPoints && talents.length === 0) {
		return null;
	}
	return (
		<>
			<IdlescapeContainer display='flex' flexDirection='column' textAlign='center' width={'100%'}>
				<Flex justifyContent='center' margin='auto' flexDirection='column'>
					{quest.questRewards?.questPoints && (
						<Text fontSize='xl' marginBottom='1px'>
							{questPointIcon(24)}
							{quest.questRewards.questPoints} Quest Point
							{quest.questRewards.questPoints > 1 && 's'}
						</Text>
					)}

					{talents.length > 0 && (
						<>
							<Text fontSize='xl' marginBottom='1px'>
								Talents:
							</Text>
							<Grid gridTemplateColumns='repeat(auto-fill, minmax(100px, 1fr))' gap='10px' width='100%'>
								{talents.map((talent, index) => (
									<Box key={`${index}-${talent?.toString()}-talent`} padding='2px'>
										{talent}
									</Box>
								))}
							</Grid>
						</>
					)}

					{experienceObj.length > 0 && (
						<>
							<Text fontSize='xl' marginBottom='1px'>
								Experience:
							</Text>
							<Flex gap='10px' justifyContent='center' fontSize='xl' flexWrap='wrap'>
								{experienceObj.map((exp, index) => (
									<Box key={`${index}-${exp?.toString()}-exp`} margin='auto'>
										{exp}
									</Box>
								))}
							</Flex>
						</>
					)}

					{items.length > 0 && (
						<>
							<Text fontSize='xl' marginBottom='1px'>
								Items:
							</Text>
							<Flex width='100%' justifyContent='center' marginBottom='1px' flexWrap='wrap'>
								{items.map((item, index) => (
									<Box key={`${index}-${item?.toString()}-item`} padding='3px'>
										{item}
									</Box>
								))}
							</Flex>
						</>
					)}
				</Flex>
			</IdlescapeContainer>
		</>
	);
}
