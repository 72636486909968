import { Grid } from '@chakra-ui/react';
import { questList } from '../../../utils/questList';
import AchievementEntry from './AchievementEntry';

const triggerOrder = {
	inventory: 1,
	gather: 2,
	location: 3,
	craft: 4,
	plant: 5,
	farm: 6,
	enchantment: 7,
	'enchant-scroll': 8,
	slay: 9,
	dungeon: 10,
	transform: 11,
	custom: 12,
};

const difficultyOrder = { Tutorial: 1, Easy: 2, Medium: 3, Hard: 4, Elite: 5, Master: 6 };

export default function AchievementList({ league }: { league: number }) {
	const filterAchievements = Object.values(questList).filter((quest) => quest.achievement);
	const sortedQuestList = filterAchievements.sort((a, b) => {
		const aTrigger = a.questSteps[0].validTargets[0].trigger;
		const bTrigger = b.questSteps[0].validTargets[0].trigger;
		if (aTrigger !== bTrigger) {
			return triggerOrder[aTrigger] - triggerOrder[bTrigger];
		}
		if (a.difficulty !== b.difficulty) {
			return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
		}
		const aPoint = a.questRewards?.achievementPoints ?? 0;
		const bPoint = b.questRewards?.achievementPoints ?? 0;
		if (aPoint !== bPoint) {
			return aPoint - bPoint;
		}
		const aAmount = a.questSteps[0].targetAmount;
		const bAmount = b.questSteps[0].targetAmount;
		if (aAmount !== bAmount) {
			return aAmount - bAmount;
		}
		return a.id - b.id;
	});

	return (
		<Grid gridTemplateColumns='repeat(auto-fill, 100px)' gridAutoRows='100px' justifyContent='center'>
			{sortedQuestList.map((quest, index) => {
				const shouldShowRestrictions = !(
					quest?.questRestrictions?.inactive ||
					quest?.questRestrictions?.blacklistedLeagues?.includes(league) ||
					(quest?.questRestrictions?.questStartDate &&
						quest?.questRestrictions?.questEndDate &&
						(new Date() < new Date(quest.questRestrictions.questStartDate) ||
							new Date() > new Date(quest.questRestrictions.questEndDate)))
				);
				if (quest.achievement && shouldShowRestrictions) {
					return <AchievementEntry key={index} quest={quest} />;
				}
			})}
		</Grid>
	);
}
