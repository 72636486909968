import { Grid } from '@chakra-ui/react';
import { IdlescapeContainer } from '@idlescape/ui';
import { useState } from 'react';
import { platinumShopList } from '../../../../utils/platinumShopList';
import { ItemPreview } from './ItemPreview';
import './Shop.css';
import { ShopItem } from './ShopItem';

const categories = ['Special', 'Armor', 'Weapons', 'Tokens', 'Icons'];

interface ShopCategoriesProps {
	activeCategory: string;
	setActiveCategory: (value: string) => void;
	setActiveItem: (value: number) => void;
}

interface ShopContentProps {
	activeCategory: string;
	setActiveCategory: (value: string) => void;
	setActiveItem: (value: number) => void;
}

/** Return an array of shop IDs */
function getItemsByCategory(category: string) {
	// Sort the platinum shop list by category then price
	const sortedShopList = Object.values(platinumShopList).sort((dataA, dataB) => {
		if (dataA.categories[0] === dataB.categories[0]) {
			return dataB.price - dataA.price;
		}
		return dataA.categories[0] > dataB.categories[0] ? -1 : 1;
	});
	if (category === 'Special') {
		return sortedShopList.reduce((acc, data) => {
			if (data.dateRemoved && data.dateRemoved < new Date()) {
				return acc;
			}
			if (data.isFeatured) {
				acc.push(data.id);
			}
			return acc;
		}, [] as number[]);
	}

	return sortedShopList.reduce((acc, data) => {
		if (data.dateRemoved && data.dateRemoved < new Date()) {
			return acc;
		}
		if (data.categories.includes(category.toLowerCase())) {
			acc.push(data.id);
		}
		return acc;
	}, [] as number[]);
}

export const ShopCategories = (props: ShopCategoriesProps) => {
	return (
		<div className='shop-categories'>
			{categories.map((elem) => {
				return (
					<div
						className='shop-category idlescape-frame-panel idlescape-frame-panel-dark idlescape-frame-panel-interactive'
						key={'shop-category-' + elem}
						data-active={props.activeCategory === elem}
						onClick={() => {
							props.setActiveCategory(elem);
							props.setActiveItem(0);
						}}
					>
						{elem}
					</div>
				);
			})}
		</div>
	);
};

export const ShopContent = (props: ShopContentProps) => {
	const items = getItemsByCategory(props.activeCategory);
	return (
		<IdlescapeContainer variant='platinum' zIndex={1}>
			<Grid gridTemplateColumns={'repeat(auto-fit, minmax(180px, 1fr))'} gap={'10px'} padding={'10px'}>
				{items.map((id) => {
					return (
						<ShopItem
							setActiveItem={props.setActiveItem}
							key={'platinum-shop-item-' + id}
							platinumShopListID={id}
						></ShopItem>
					);
				})}
			</Grid>
		</IdlescapeContainer>
	);
};

export const ShopContainer = () => {
	const [activeCategory, setActiveCategory] = useState('Special');
	const [activeItemID, setActiveItemID] = useState<number>(0);

	return (
		<Grid gridTemplateColumns={'minmax(70px, 0.1fr) 1fr'} gap={'5px'} textAlign={'center'}>
			<ShopCategories
				setActiveItem={setActiveItemID}
				activeCategory={activeCategory}
				setActiveCategory={setActiveCategory}
			></ShopCategories>
			{activeItemID ? (
				<ItemPreview activeItemID={activeItemID} setActiveItem={setActiveItemID}></ItemPreview>
			) : (
				<ShopContent
					setActiveItem={setActiveItemID}
					activeCategory={activeCategory}
					setActiveCategory={setActiveCategory}
				></ShopContent>
			)}
		</Grid>
	);
};
