/* eslint-disable react/no-unescaped-entities */
import { Image, Text } from '@chakra-ui/react';
import React from 'react';
import { itemIcon, SubHeading, SubHeadingWithIcons } from '../../../../helper/NavigationTabsList';
import { ALCHEMY_BREAKPOINT, COOKING_BREAKPOINT } from '../../../../utils/constantsCollection';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

function CookingGuide() {
	return (
		<>
			Cooking is a very useful and helpful skill as it supplies combat with food for healing and skills with buff
			potions with Alchemy. In order to get started with cooking, one must first prepare the ingredients for the
			various recipes within the game. To prepare the ingredients and essence, click on the items in the
			preparation tab to select it, then click start. Once you have obtained the ingredients/essence for said
			recipes and potions, they will automatically unlock.
			<br />
			You will also obtain one cooking shard for every 5 minutes spent cooking, which can be used towards the
			Golden Spoon, the Legendary Cooking tool and the Cooking set.
			<SubHeading>Difficulty and Size</SubHeading>
			On each ingredient, there is a difficulty, size rating, alchemy size and ingredient tags.
			<br />
			Difficulty is how hard it is to make a certain recipe/potion based upon the ingredients it has. It also
			increases the amount of experience earned multiplied by 5 or 10 when cooking food.
			<br />
			Size determines how much of the tagged ingredients you will get from preparing the various cooking items in
			the game. An example would be a tomato having a size of three, thus giving you three of each of raw veggies
			and fruits as well as one sour essence due to its one alchemy size.
			<br />
			Fish oil can be obtained by cooking fish, with each fish having a fixed percentage to give an oil based on
			size.
			<SubHeadingWithIcons
				icons={[
					<Image
						key={1}
						src='/images/cooking/cooking_icon.png'
						alt='Cooking logo'
						width='40px'
						height='40px'
						objectFit='contain'
					/>,
				]}
			>
				Cooking
			</SubHeadingWithIcons>
			Each {COOKING_BREAKPOINT} effective cooking levels will give you +1 to your base recipe level.
			<br /> Once the ingredients are prepared, a list of possible recipes will appear. If the recipe list is
			still empty, prepare other ingredients to unlock them. To get started, click on the dish you wish to create
			and it will give you a list of ingredients you need to create it on the right. You can increase the strength
			of the dish by increasing the slider, but this will require more ingredients as well as increase the dish
			difficulty. Additionally, you can add certain items to a dish to give it a combat related buff, such as
			Ichor for Demon Skin for damage reduction.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.empty_large_vial)]}>Alchemy</SubHeadingWithIcons>
			Each {ALCHEMY_BREAKPOINT} effective cooking levels will increase the amount of potions crafted by 1.
			<br />
			Alchemy can be used to create potions with various buffs to help with the skills in the game. To create a
			potion, click on the buff you wish to create, then select a vial or potion to upgrade. By upgrading potions
			you've already created, stronger buffs can be made at the cost of more ingredients and essence. The slider
			can be used to skip to a higher tier potion to save time but often comes with a higher difficulty.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.greater_ladle), itemIcon(itemsIds.chefs_hat)]}>
				Gear
			</SubHeadingWithIcons>
			Ladles can be obtained from goblins, which will increase your effective cooking level when equipped as well
			as some hidden levels of haste. Searing Ladles and Chefs Hat can be obtained from the Goblin Settlement
			dungeon, which will increase your effective levels when augmented. The Golden Spoon and cooking gear can be
			crafted with cooking shards and certain materials, which will further boost your cooking abilities.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}

export default CookingGuide;
