import { IdlescapeContainer } from '@idlescape/ui';
import { ITalentData } from '../../../../../game-server/src/modules/talent/Talent.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { talentList } from '../../../utils/talentList';
import './Talents.css';
import { skillTypes, talentSkillAtom, TSkillTypes } from '../../../atoms/talentSkillAtom';
import { useRecoilValue } from 'recoil';
import { TalentBox } from './TalentBox';
import { talentFilterAtom } from '../../../atoms/talentFilterAtom';
import { Flex } from '@chakra-ui/react';

interface TalentNode {
	talentID: number;
	children: TalentNode[];
}

interface TalentTree {
	roots: TalentNode[];
}

export const TalentsSkill = () => {
	const skillFilter = useRecoilValue(talentSkillAtom);
	const filterAvailable = useRecoilValue(talentFilterAtom);
	const settings = usePlayerField('settings');
	const selectedTalents = settings.miscellaneous.selectedTalents;
	const skills = usePlayerField('skills');
	const questsCompleted = usePlayerField('questsCompleted');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);

	const talentTree: TalentTree = {
		roots: [],
	};
	let talentCount = 0;
	function buildTree() {
		// Recursively build the tree
		for (const talent of Object.values(talentList)) {
			if (!talent.talentRequirements) {
				talentTree.roots.push({ talentID: talent.id, children: [] });
			}
		}
		for (const root of talentTree.roots) {
			const talent = talentList[root.talentID];
			if (talent) {
				recursiveBuildNode(talent, root);
			}
		}
	}
	function recursiveBuildNode(talent: ITalentData, parent: TalentNode) {
		const talentsRequiringThis = Object.values(talentList).filter((talentData) => {
			if (talentData.talentRequirements) {
				return talentData.talentRequirements.includes(talent.id);
			}
			return false;
		});
		for (const child of talentsRequiringThis) {
			const node: TalentNode = { talentID: child.id, children: [] };
			parent.children.push(node);
			recursiveBuildNode(child, node);
		}
	}
	buildTree();

	function shouldRender(talent: ITalentData) {
		if (skillFilter) {
			// Check category
			if (skillTypes.includes(skillFilter as TSkillTypes)) {
				if (!talent.category.includes(skillFilter as TSkillTypes)) {
					return false;
				}
			} else {
				// Check skills
				const skillRequirement = talent.skillRequirements?.find((req) => req.skill === skillFilter);
				if (!skillRequirement) {
					return false;
				}
			}
		}
		if (filterAvailable) {
			return isTalentValid(talent);
		}
		if (talent.hideIfUnmetRequirements) {
			return isTalentValid(talent);
		}
		return true;
	}

	function renderAllTalents() {
		return (
			<div className='talent-list'>
				<div className='talent-root-grid'>
					{talentTree.roots.map((root) => {
						const talent = talentList[root.talentID];
						if (!talent) {
							return null;
						}
						if (!shouldRender(talent)) {
							return null;
						}
						if (root.children.length > 0) {
							return (
								<div key={`talent-${talent.id}`} className='talent-tree'>
									{renderTalentAndChildren(root)}
								</div>
							);
						}
						talentCount++;
						return <TalentBox key={`talent-${talent.id}`} talent={talent} selected={false} />;
					})}
				</div>
			</div>
		);
	}

	function renderTalentAndChildren(talent: TalentNode) {
		const talentData = talentList[talent.talentID];
		if (!talentData) {
			return null;
		}
		if (!shouldRender(talentData)) {
			return null;
		}

		talentCount++;
		return (
			<div key={`talent-${talentData.id}`} className='talent-tree'>
				<TalentBox talent={talentData} selected={false} />
				<div className='talent-grid2'>
					{talent.children.map((child) => {
						return renderTalentAndChildren(child);
					})}
				</div>
			</div>
		);
	}

	function isTalentValid(talent: ITalentData) {
		if (talent.inactive) {
			return false;
		}
		let valid = true;
		if (talent.skillRequirements) {
			for (const skillRequirement of talent.skillRequirements) {
				const skill = skills[skillRequirement.skill];
				if (skillRequirement.mastery) {
					if (!skill || skill.masteryLevel < skillRequirement.level) {
						valid = false;
						break;
					}
				} else {
					if (!skill || skill.level < skillRequirement.level) {
						valid = false;
						break;
					}
				}
			}
		}
		if (talent.questRequirements) {
			for (const questRequirement of talent.questRequirements) {
				if (!questIdsCompleted.includes(questRequirement)) {
					valid = false;
					break;
				}
			}
		}
		if (talent.incompatibleTalents) {
			for (const incompatibleTalent of talent.incompatibleTalents) {
				if (selectedTalents.includes(incompatibleTalent)) {
					valid = false;
					break;
				}
			}
		}
		if (talent.talentRequirements) {
			for (const talentRequirement of talent.talentRequirements) {
				if (!selectedTalents.includes(talentRequirement)) {
					valid = false;
					break;
				}
			}
		}
		return valid;
	}
	return (
		<IdlescapeContainer variant='dark' width='100%'>
			<Flex display='flex' justifyContent='center' width='100%'>
				{renderAllTalents()}
				{talentCount > 0 ? '' : 'No talents available'}
			</Flex>
		</IdlescapeContainer>
	);
};
