import './Tooltip.css';
import React, { CSSProperties, useState } from 'react';
import { Popover, PopoverPosition } from 'react-tiny-popover';
const TOOLTIP_DELAY = 200;

export default function Tooltip(props: {
	parent?: { current: HTMLElement };
	boundaryInset?: number;
	children?: React.ReactNode;
	className?: string;
	noDefaultClassName?: boolean;
	positions?: PopoverPosition[];
	onVisibleLazyRenderLambda?: () => React.ReactNode;
	onClick?: boolean;
	onClickHover?: boolean;
	onClickOutside?: () => void;
	isOpen?: boolean;
}) {
	const divStyle: CSSProperties = {
		zIndex: '999',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		position: 'absolute',
	};
	const [tooltipEnabled, setTooltipEnabled] = useState(props.isOpen ?? false);
	const [overrideHover, setOverrideHover] = useState(false);
	const defaultPositions: PopoverPosition[] = ['top', 'bottom', 'left', 'right'];
	let hovered = false;
	let timeoutId = -1;

	function openDelayedTooltip() {
		hovered = true;
		timeoutId = Number(
			setTimeout(() => {
				setTooltipEnabled(hovered);
			}, TOOLTIP_DELAY)
		);
	}

	function closeTooltip() {
		if (timeoutId !== -1) {
			clearTimeout(timeoutId);
		}
		hovered = false;
		setTooltipEnabled(false);
		setOverrideHover(false);
		// Doubly make sure the tooltip is closed as it seems to get stuck
		setTimeout(() => {
			setTooltipEnabled(false);
		}, TOOLTIP_DELAY);
	}

	function handleMouseEnter() {
		if (overrideHover) return;
		openDelayedTooltip();
	}

	function handleMouseLeave() {
		if (overrideHover) return;
		closeTooltip();
	}

	function actions() {
		if (props.onClickHover) {
			return (
				<span
					onClick={() => {
						setTooltipEnabled(!overrideHover);
						setOverrideHover(!overrideHover);
					}}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					style={divStyle}
				/>
			);
		} else if (props.onClick) {
			return <span onClick={() => setTooltipEnabled(!tooltipEnabled)} style={divStyle} />;
		} else if (props.isOpen) {
			return <span style={divStyle} />;
		} else {
			return (
				<span
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onClick={() => setTooltipEnabled(true)}
					style={divStyle}
				/>
			);
		}
	}

	const defaultClassName = props.noDefaultClassName ? '' : 'idlescape-tooltip';
	const positions = props.positions || defaultPositions;
	const lazyRenderLambda = props.onVisibleLazyRenderLambda || (() => props.children);
	const onVisibleLazyRenderLambda = (tooltipEnabled: boolean, position?: PopoverPosition) => {
		if (!tooltipEnabled) return <></>;
		return (
			<div className={`${defaultClassName} ${props.className} tooltip-position-${position}`}>
				{lazyRenderLambda()}
			</div>
		);
	};
	return (
		<Popover
			parentElement={props.parent?.current}
			boundaryInset={props.boundaryInset}
			isOpen={props.isOpen || tooltipEnabled}
			positions={positions}
			onClickOutside={() => {
				closeTooltip();
				props.onClickOutside?.();
			}}
			padding={2}
			reposition={true}
			content={({ position }) => onVisibleLazyRenderLambda(props.isOpen || tooltipEnabled, position)}
		>
			{actions()}
		</Popover>
	);
}
