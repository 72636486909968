// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IGlobalBuff } from '../../../game-server/src/modules/buffs/global/GlobalBuffManager';
import { ILeagueData } from '../../../game-server/src/modules/leagues/league.interface';

export function globalBuffApplies(buff: IGlobalBuff, leagueData: ILeagueData) {
	if (buff.leagueId && buff.leagueId !== leagueData.id) return false;
	const source = buff.source;
	if (source === 'Platinum Global Buff') {
		return leagueData.rules.platinumBuffs;
	}
	if (source === 'shrine') {
		return leagueData.rules.shrine;
	}
	if (source === 'Madness') {
		return leagueData.rules.buffMadness;
	}
	// This is an uncategorized global buff, so it just checks the usesGlobalBuffs flag
	return leagueData.rules.globalBuffs;
}
