// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
const SECONDS_IN_AN_HOUR = 60 * 60;
const SECONDS_IN_A_DAY = 60 * 60 * 24;

export const getTimeString = (seconds: number, figures = 99) => {
	const days = Math.floor(seconds / SECONDS_IN_A_DAY);
	seconds -= days * SECONDS_IN_A_DAY;
	const hours = Math.floor(seconds / SECONDS_IN_AN_HOUR);
	seconds -= hours * SECONDS_IN_AN_HOUR;
	const minutes = Math.floor(seconds / 60);
	seconds -= minutes * 60;

	let string = '';
	if (days > 0) {
		string += days + 'd ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (hours > 0) {
		string += hours + 'h ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (minutes > 0) {
		string += minutes + 'm ';
		figures--;
	}
	if (figures <= 0) return string.trim();
	if (string === '') {
		string +=
			seconds.toLocaleString('en-us', {
				maximumFractionDigits: 1,
			}) + 's';
	} else if (seconds > 0) {
		// Take the floor to prevent displaying times such as 1m 60s
		string += Math.floor(seconds) + 's';
	}

	return string;
};
