// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TSkills } from '../../../game-server/src/Atypes/Skills';
import { TLeagueExperience } from '../../../game-server/src/modules/leagues/league.interface';

/** Results are cached into this array */
const experienceArray: { [key in TLeagueExperience]: number[] } = { default: [NaN, 0], cubic: [NaN, 0] };
const maxExperience: { [key in TLeagueExperience]: number } = { default: 0, cubic: 0 };
/** Arbitrary number, not *really* used anywhere other than preventing infinite loops, which shouldn't really happen even without this */
const MAX_LEVEL = 255;

export function initializeExperienceTables() {
	for (const formula of Object.keys(experienceArray)) {
		experienceToLevel(MAX_LEVEL, formula as TLeagueExperience);
		maxNormalExperience(formula as TLeagueExperience);
	}
}

export function maxNormalLevel(formula: TLeagueExperience = 'default') {
	switch (formula) {
		case 'cubic':
			return 100;
		case 'default':
		default:
			return 99;
	}
}

export function maxNormalExperience(formula: TLeagueExperience = 'default') {
	if (!maxExperience[formula]) {
		maxExperience[formula] = experienceToLevel(maxNormalLevel(formula), formula);
	}
	return maxExperience[formula];
}

export function getLevelFromExperience(experience: number, formula?: TLeagueExperience) {
	// binary search
	let l = 1;
	let r = MAX_LEVEL - 1;
	while (l < r) {
		const m = Math.floor((l + r) / 2);
		if (experienceToLevel(m, formula) <= experience) {
			l = m + 1;
		} else {
			r = m;
		}
	}
	return l - 1;
}

/** Experience required to reach certain level from 0 */
export function experienceToLevel(level: number, formula: TLeagueExperience = 'default') {
	if (experienceArray[formula][level] !== undefined) {
		return experienceArray[formula][level];
	}
	switch (formula) {
		case 'cubic':
			for (let i = 2; i <= level; i++) {
				if (!experienceArray.cubic[i]) {
					if (i <= 100) {
						// Normal
						experienceArray.cubic[i] = Math.round(i ** 3 * 10);
					} else {
						// Mastery
						experienceArray.cubic[i] = Math.floor(1.5 ** ((i - 100) / 4) * 10_000_000);
					}
				}
			}
			return experienceArray.cubic[level];
		case 'default':
		default: {
			let totalExperience = 0;
			for (let i = 1; i < level; i++) {
				totalExperience += Math.floor(i + 300 * Math.pow(2, i / 7));
				if (!experienceArray.default[i + 1]) {
					experienceArray.default[i + 1] = Math.floor(totalExperience / 4);
				}
			}
			return experienceArray.default[level];
		}
	}
}

export function calculateTotalLevelAndExperience(skills: TSkills, isMastery: boolean, formula?: TLeagueExperience) {
	let totalExperience = 0;
	let totalLevel = 0;
	for (const [skillName, skill] of Object.entries(skills)) {
		if (skillName === 'total') {
			continue;
		}

		if (!isMastery) {
			totalExperience += skill.experience;
			totalLevel += skill.level;
		} else {
			totalExperience += Math.max(0, skill.masteryExperience - maxNormalExperience(formula));
			totalLevel += skill.masteryLevel;
		}
	}
	return { totalExperience, totalLevel };
}
