import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { HiClipboardCopy } from 'react-icons/hi';
import { NotificationType } from '../../../../../game-server/src/modules/notifications/Notification';
import { usePlayerField } from '../../../hooks/hooks';
import { addNotification } from '../../../services/NotificationManager';
import { socket } from '../../../services/socket.service';
import './ChangePassword.css';

export default function TwoFactorAuthentication() {
	const enabled = usePlayerField('2fa_enabled');

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [twoFactorCodeInput, setTwoFactorCodeInput] = useState('');
	const [qr, setQr] = useState<string | undefined>(undefined);
	const [secret, setSecret] = useState<string | undefined>(undefined);

	useEffect(() => {
		socket.on('account:2fa:send', (data) => {
			setQr(data.qr);
			setSecret(data.secret);
		});

		return () => {
			socket.off('account:2fa:send');
		};
	}, []);

	function set2FA() {
		if (twoFactorCodeInput.length === 0) return;
		socket.emit('account:2fa:enable', {
			code: twoFactorCodeInput,
		});
	}

	function disable2FA() {
		if (twoFactorCodeInput.length === 0) return;
		socket.emit('account:2fa:disable', {
			code: twoFactorCodeInput,
		});
	}

	function handleOpenModal() {
		socket.emit('account:2fa:get');

		onOpen();
		setTwoFactorCodeInput('');
	}

	function handleKeyPress(event: React.KeyboardEvent) {
		if (event.key === 'Enter') {
			set2FA();
		}
	}

	return (
		<div className='settings-row'>
			<IdlescapeButton
				variant={enabled ? 'red' : 'green'}
				width='240px'
				onClick={handleOpenModal}
				gridColumn='1 / 3'
				justifySelf='center'
			>
				{enabled ? 'Disable' : 'Enable'} 2-Factor Authentication
			</IdlescapeButton>
			<Dialog open={isOpen} onClose={onClose} className='sell-item-dialog'>
				<DialogTitle>Enable 2-Factor Authentication</DialogTitle>
				<DialogContent>
					{enabled ? (
						<p>2FA is enabled, to disable, please enter your 2 Factor Authentication Code below</p>
					) : (
						<>
							<p
								style={{
									textAlign: 'center',
									marginBottom: '15px',
								}}
							>
								{qr !== undefined ? <img src={qr} alt='QR Code for 2fa' /> : 'Loading QR Code...'}
							</p>
							<p>
								Scan the above QR code with your authentication app on your phone and enter the code you
								receive back
							</p>
							<p>
								If you cannot scan the above QR Code please use the following key in your 2 Factor App
							</p>
							<Flex justifyContent='center' cursor='pointer' margin='15px'>
								<Box
									className='referral-code'
									background='#0004'
									textAlign='center'
									padding='5px 10px'
									borderRadius='5px'
									userSelect='all'
								>
									{secret}
								</Box>
								<HiClipboardCopy
									fontSize='24px'
									onClick={() => {
										navigator.clipboard.writeText(secret ?? '');
										addNotification(
											'Referral',
											'Your 2-Factor key has been copied to the clipboard.',
											'success' as NotificationType
										);
									}}
								/>
							</Flex>
						</>
					)}

					<Flex alignItems='center'>
						<IdlescapeInput
							className='change-password-input'
							placeholder='2-Factor Code'
							name='twoFactorCodeInput'
							type='text'
							autoComplete='false'
							value={twoFactorCodeInput}
							setValue={setTwoFactorCodeInput}
							onKeyUp={handleKeyPress}
						/>
						{enabled ? (
							<IdlescapeButton variant='red' size='large' onClick={() => disable2FA()}>
								Disable 2 Factor
							</IdlescapeButton>
						) : (
							<IdlescapeButton variant='green' size='large' onClick={set2FA}>
								Enable 2 Factor
							</IdlescapeButton>
						)}
					</Flex>
				</DialogContent>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}
