import { usePlayerField } from '../../../hooks/hooks';
import { GroupContent } from './GroupContent';
import { GroupNonGroupContent } from './GroupNonGroupContent';

export const GroupPage = () => {
	const groupId = usePlayerField('groupId');

	if (groupId) {
		return <GroupContent></GroupContent>;
	} else {
		return <GroupNonGroupContent></GroupNonGroupContent>;
	}
};
