import { Box, BoxProps, Flex, Heading, IconButton, Image, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { useSetRecoilState } from 'recoil';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { IMonsterData } from '../../../../../../game-server/src/modules/monster/monster.interface';
import { activeTabAtom } from '../../../../atoms/activeTabAtom';
import { socket } from '../../../../services/socket.service';
import { itemList } from '../../../../utils/itemList';
import FakeItem from '../../Inventory/FakeItem';
import CombatStats from '../CombatStats';

function Bestiary({ locationId, league, ...props }: { locationId: ILocationData['locID']; league: number } & BoxProps) {
	const [bestiary, setBestiary] = useState<IMonsterData[]>([]);
	const [selectedMonster, setSelectedMonster] = useState<number>(0);
	const [nextUnlock, setNextUnlock] = useState<number>(1);
	const [scrollId, setScrollId] = useState<number | undefined>(undefined);
	const setActiveTab = useSetRecoilState(activeTabAtom);

	useEffect(() => {
		socket.on('bestiary:send', (data) => {
			setBestiary(data.monsters);
			setNextUnlock(data.nextUnlock);
			setSelectedMonster(0);
			setScrollId(data.scrollId);
		});

		return () => {
			socket.off('bestiary:send');
		};
	}, []);

	useEffect(() => {
		socket.emit('bestiary:check', { locationID: locationId });
	}, [locationId]);

	function previousMonster() {
		if (nextUnlock !== null) {
			setSelectedMonster((selectedMonster - 1 + bestiary.length + 1) % (bestiary.length + 1));
		} else {
			setSelectedMonster((selectedMonster - 1 + bestiary.length) % bestiary.length);
		}
	}

	function nextMonster() {
		if (nextUnlock !== null) {
			setSelectedMonster((selectedMonster + 1) % (bestiary.length + 1));
		} else {
			setSelectedMonster((selectedMonster + 1) % bestiary.length);
		}
	}

	function renderSelectedMonster() {
		const monster = bestiary[selectedMonster];
		const species = monster?.combatStatsData.species?.map(capitalize).join(', ') ?? null;
		return (
			<Flex className='anchor-bestiary' flexWrap='wrap' alignContent='start'>
				<Box flex='1 0 300px'>
					<Heading className='monster-name' textAlign='center'>
						{monster.name}
					</Heading>
					<Image height='250px' width='100%' objectFit='contain' src={monster.image} alt={monster.name} />
					<Text align='center' fontStyle='italic'>
						{species}
						<br />
						{monster.bestiary[0].description}
					</Text>
				</Box>
				<CombatStats
					stats={monster.combatStatsData}
					loot={monster.loot?.filter((item) => !item.allowedLeagues || item.allowedLeagues.includes(league))}
					showMoreLootUnlockable={monster.hasMoreLoot}
					flex='1 0 300px'
				/>
			</Flex>
		);
	}

	function renderNextUnlock() {
		if (scrollId === undefined) return null;
		const nextUnlockScroll = {
			itemID: scrollId,
			augmentations: nextUnlock,
		};
		return (
			<Flex padding='20px 40px' alignItems='center' flexDirection='column'>
				<FakeItem item={nextUnlockScroll} />
				<Text align='center'>
					Complete a {itemList[scrollId ?? 0].name} elite scroll with a minimum difficulty of +{nextUnlock} to
					unlock {nextUnlock > 1 && 'more'} monster stats and drop rates in the bestiary.
					<br />
					To increase the difficulty of a scroll you need to{' '}
					<Box
						as='span'
						onClick={() => setActiveTab('enchanting')}
						cursor='pointer'
						color='blue.500'
						_hover={{ textDecoration: 'underline' }}
					>
						augment
					</Box>{' '}
					it.
				</Text>
			</Flex>
		);
	}

	return (
		<Box {...props}>
			{bestiary.length > 0 && (
				<Flex justifyContent='space-between'>
					<IconButton aria-label='Previous' icon={<AiFillCaretLeft />} onClick={() => previousMonster()} />
					<IconButton aria-label='Next' icon={<AiFillCaretRight />} onClick={() => nextMonster()} />
				</Flex>
			)}
			{selectedMonster !== bestiary.length ? renderSelectedMonster() : renderNextUnlock()}
		</Box>
	);
}

export default Bestiary;
