/* eslint-disable react/no-unescaped-entities */
import { Image, Text } from '@chakra-ui/react';
import React from 'react';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { itemIcon, SubHeading, SubHeadingWithIcons } from '../../../helper/NavigationTabsList';

function PlatinumShopGuide() {
	return (
		<>
			<SubHeadingWithIcons
				icons={[<Image src='/images/premium/premium_icon.svg' width='40px' height='40px' key='0' />]}
			>
				Idlescape Plus
			</SubHeadingWithIcons>
			Idlescape Plus will give you quality of life improvements in the game through a monthly subscription.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.platinum)]}>Platinum</SubHeadingWithIcons>
			Platinum can be purchased with real money in order to obtain global player buffs, quality of life tokens,
			and cosmetic items.
			<SubHeading>Global Buffs</SubHeading>
			Global player buffs will apply to the entire server for you and others to enjoy, as well as a special thank
			you in general chat when purchased. You can opt out of displaying your name by clicking "Anonymous
			Purchase". 20 Platinum is equal to one minute of buff time. Buffs will start at level 2 and upgrade to Level
			3 at 1 day, Level 4 at 1 week. Once the threshold is reached, the buff stays at that level including later
			extensions by different players or yourself.
			<SubHeading>Tokens</SubHeading>
			Tokens can be purchases to do a variety of things, such as increasing Loadout slots, changing your name,
			your item names, and other helpful things. Each token will be placed into your stockpile upon purchase where
			they can be used.
			<SubHeading>Cosmetics</SubHeading>
			Cosmetic items change how your character or equipment looks within the game. This will apply upon your
			inspect screen and in combat screens. Fashionscape starts here.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}

export default PlatinumShopGuide;
