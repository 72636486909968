import React from 'react';
import { ICombatSplotch } from '../../../../../game-server/src/modules/combat/CombatEmitHelper';

export default function CombatSplash(props: { data: ICombatSplotch; index: number }) {
	const basePath = '/images/combat/splashes/';
	let splashToRender = props.data.damageType;
	if (props.data.damageType === 'Typeless') {
		splashToRender = 'Melee';
	}
	splashToRender = basePath + splashToRender + '_splash';

	let critical = '';
	if (props.data.crit) {
		critical = '-critical';
		splashToRender = splashToRender + '_critical';
	}

	splashToRender = splashToRender + '.png';
	return (
		<div className={'combat-splash combat-splash-' + props.index + ' combat-splash' + critical}>
			<img
				src={splashToRender}
				className='combat-splash-image'
				alt={`Combat Splash${props.data.crit ? ' Crit' : ''}`}
			/>
			<span>{props.data.hit}</span>
		</div>
	);
}
