import {
	Box,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { TEquipmentSlots, TEquipmentPartials } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import useIsMobile from '../../../../hooks/useIsMobile';
import { abilities } from '../../../../utils/abilityList';
import Tooltip from '../../../layout/UI/Tooltip';
import CombatAbility from '../../Combat/Abilities/CombatAbility';
import { gearSlots } from '../../Combat/CombatCharacterEquipment';
import FakeItem from '../../Inventory/FakeItem';
import './ChatItemSet.css';

export default function ChatItemSet(props: {
	itemSet: {
		abilities: IAbilityData['id'][];
		equipmentData: TEquipmentPartials;
		itemSetName: string;
	};
}) {
	const isMobile = useIsMobile();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const itemSetName = props.itemSet.itemSetName;
	const itemSetIcon = (
		<img className='chat-itemset-icon' src={'/images/ui/equipment_icon.png'} alt={'Shared Item Set'} />
	);

	function tooltipContent() {
		return (
			<Box width='266px'>
				{renderItems()}
				<hr />
				{renderAbilities()}
			</Box>
		);
	}

	function renderItems() {
		return (
			<Box className='combat-gear'>
				<img src='/images/combat/combat_stick_figure.png' alt='Combat stick figure' />
				{gearSlots.map(renderGearItem)}
			</Box>
		);
	}

	function renderGearItem(itemSlot: TEquipmentSlots) {
		const items = props.itemSet.equipmentData;
		const itemData = items[itemSlot] ?? undefined;
		return (
			<Box id={'gear-' + itemSlot} className={'combat-gear-item'} key={itemSlot}>
				{itemData && <FakeItem item={itemData} width='48px' height='48px' margin='1px' />}
			</Box>
		);
	}

	function renderAbilities() {
		if (!props.itemSet.abilities) return;
		return (
			<Grid gridTemplateColumns='repeat(auto-fit, 50px)' gap='3px' justifyContent='center'>
				{props.itemSet.abilities.map((abilityId) => {
					const abilityData = abilities[abilityId];
					return <CombatAbility key={abilityId} ability={abilityData} size='50px' hideName />;
				})}
			</Grid>
		);
	}

	return (
		<div className='chat-item equipment' onClick={onOpen}>
			{itemSetIcon} {itemSetName}
			{isMobile ? (
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent className='sell-item-dialog'>
						<ModalCloseButton />
						<ModalBody>{tooltipContent()}</ModalBody>
					</ModalContent>
				</Modal>
			) : (
				<Tooltip onClick={true}>{tooltipContent()}</Tooltip>
			)}
		</div>
	);
}
