/* eslint-disable react/no-unescaped-entities */
import { Text } from '@chakra-ui/react';
import React from 'react';
import { SubHeading } from '../../../helper/NavigationTabsList';

export default function MarketplaceGuide() {
	return (
		<>
			The Marketplace is where you'll go in order to sell and buy other items from players. You can get here by
			clicking Marketplace on the side or clicking on your gold bag above your stockpile. You can see all the
			various items within the game here that other players are selling. When you click on any item, it'll open a
			different window that will show the different listings by other players. By default it is ordered by price,
			lowest to highest. To return, you can click the "Back" button in the top left.
			<SubHeading>Selling</SubHeading>
			In order to sell items on the market, you can click the "Sell" button also in the top left on the main
			screen. Here you will see all the items you can sell, as well as items you already have listed on the market
			if any. When you click on an item, it will bring up a screen asking you to select the amount you wish to
			sell of that item, and for how much. Once you are satisfied with your listing, click "Create Listing" and
			your item will now be on the market for other players to purchase with gold should they choose to do so.
			<br />
			When inputting a price, you can use K, M, and B to quickly enter values. For example: 4k for 4,000, 7.5M for
			7,500,000 or 2.5B for 2,500,000,000
			<SubHeading>Buying</SubHeading>
			In order to buy items, click on the item you desire to purchase, then click again on the desired listing.
			This will bring up another menu asking how many of the item you wish to purchase, before showing you a price
			in gold that you will be spending to do so. If satisfied with the option, you can click "Buy" to complete
			the purchase. Items purchased will automatically be placed within your stockpile, ready to be used.
			<SubHeading>Buy Orders</SubHeading>
			When the prices of the available listings are too high for you, you can create a buy order. Choose price and
			amount and for items that allow it augmentation level, enchantment and enchantment strength. The gold for
			the offer will be immediately taken from your inventory and stored in the buy order. When someone sells the
			item with your price or lower, you will automatically buy it. If you cancel the order, you will get your
			money back.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}
