import React, { useEffect } from 'react';
import CombatGear from './CombatGear';
import { CombatZones } from './CombatZones';
import './Combat.css';
import './ItemVisuals.css';
import { CombatFight } from './CombatFight';
import { usePlayerField } from '../../../hooks/hooks';
import { combatZoneAtom } from '../../../atoms/combatZoneAtom';
import { useRecoilState } from 'recoil';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import Loadouts from './Loadout/Loadouts';
import CombatFoodInventory from './CombatFoodInventory';
import CombatAmmoInventory from './CombatAmmoInventory';

export const Combat = () => {
	const actionQueue = usePlayerField('actionQueue');
	const [combatZone, setCombatZone] = useRecoilState(combatZoneAtom);
	const inCombat = actionQueue && actionQueue.actionType === 'Action-Combat';

	useEffect(() => {
		if (inCombat) {
			setCombatZone('combat');
		} else {
			if (combatZone === 'combat') {
				setCombatZone('equipment');
			}
		}
	}, [inCombat]);

	useEffect(() => {
		// If it's the initial render, change it back if it was changed in the above effect
		if (typeof combatZone === 'number') {
			setCombatZone(combatZone);
		}
		if (!inCombat && combatZone === 'combat') {
			setCombatZone('equipment');
		}
		return () => {
			if (inCombat) {
				setCombatZone('combat');
			} else {
				setCombatZone('equipment');
			}
		};
	}, []);

	if (combatZone === 'combat') {
		return (
			<>
				<IdlescapeButton
					position='absolute'
					variant='blue'
					right='10px'
					zIndex='999'
					onClick={() => setCombatZone(0)}
				>
					Back
				</IdlescapeButton>
				<CombatFight />
			</>
		);
	}

	return (
		<div className='core-container-combat'>
			<div className='combat-container-buttons'>
				<IdlescapeButton width='100%' variant='green' onClick={() => setCombatZone('equipment')}>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Equipment' />
					Equipment
				</IdlescapeButton>
				<IdlescapeButton width='100%' variant='orange' onClick={() => setCombatZone('loadout')}>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Loadouts' />
					Loadouts
				</IdlescapeButton>
				<IdlescapeButton width='100%' variant='blue' onClick={() => setCombatZone('inventory')}>
					<img className='nav-tab-icon icon-border' src='/images/ui/inventory_icon.png' alt='Inventory' />
					Food
				</IdlescapeButton>
				<IdlescapeButton width='100%' variant='red' onClick={() => setCombatZone('ammunition')}>
					<img
						className='nav-tab-icon icon-border'
						src='/images/combat/equipment/range/shot.png'
						alt='Ammunition'
					/>
					Ammo
				</IdlescapeButton>
				<IdlescapeButton width='100%' variant='gray' onClick={() => setCombatZone('fight')}>
					<img className='nav-tab-icon icon-border' src='/images/combat/combat_level.png' alt='Fight' />
					Fight
				</IdlescapeButton>
				{inCombat && (
					<IdlescapeButton
						width='100%'
						variant='purple'
						onClick={() => {
							setCombatZone('combat');
						}}
					>
						<img className='nav-tab-icon icon-border' src='/images/combat/combat_level.png' alt='Fight' />
						Back to Combat
					</IdlescapeButton>
				)}
			</div>
			<div className='combat-container-content'>
				{combatZone === 'equipment' && (
					<IdlescapeContainer>
						<CombatGear />
					</IdlescapeContainer>
				)}
				{combatZone === 'inventory' && <CombatFoodInventory />}
				{combatZone === 'ammunition' && <CombatAmmoInventory />}
				{combatZone === 'loadout' && <Loadouts />}
				{(combatZone === 'fight' || typeof combatZone === 'number') && <CombatZones />}
			</div>
		</div>
	);
};
