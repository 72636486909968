import React, { useState } from 'react';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { usePlayerField } from '../../../hooks/hooks';
import { COMBAT_INVENTORY_SIZE } from '../../../utils/constantsCollection';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { Flex, FormLabel, Heading, Switch } from '@chakra-ui/react';
import { Item } from '../Inventory/Item';
import { IdlescapeContainer } from '@idlescape/ui';

export default function CombatFoodStockpile() {
	const combatInventory = usePlayerField('combatInventory');
	const stockpile = usePlayerField('stockpile');
	const [fillRemaining, setFillRemaining] = useState(false);

	const possibleFood = stockpile.filter((item) => {
		const info = itemList[item.itemID];
		return info.healing;
	});

	function addToInventory(e: React.MouseEvent, item: IItem) {
		if (combatInventory.length >= COMBAT_INVENTORY_SIZE) return true;
		let itemAmount = 1;
		if (fillRemaining || e.shiftKey) {
			itemAmount = item.stackSize;
		}
		socket.emit('inventory:toCombatInventory', {
			inventoryItemID: item.id,
			itemAmount,
			sourceInventory: 'stockpile',
		});
		return true;
	}

	return (
		<IdlescapeContainer flex='1'>
			<Heading size='md' textAlign='center'>
				Inventory
			</Heading>
			<Flex justifyContent='center' margin='10px'>
				<FormLabel htmlFor='fill-remaining' textAlign='center'>
					Fill remaining slots
				</FormLabel>
				<Switch
					id='fill-remaining'
					isChecked={fillRemaining}
					onChange={(e) => setFillRemaining(e.target.checked)}
				/>
			</Flex>
			<div className='all-items'>
				{possibleFood.map((item) => (
					<Item key={item.id} item={item} onClick={(e) => addToInventory(e, item)} />
				))}
			</div>
		</IdlescapeContainer>
	);
}
