// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TGameShopList } from '../../../game-server/src/modules/game-shop/GameShop.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { leaguesIds } from '../utils/lookup-dictionaries/lookupLeagueList';

export const gameShopList: TGameShopList = {
	1: {
		id: 1,
		itemID: itemsIds.earthworm_bait,
		price: 10,
		allowBulkPurchase: true,
		description: 'Used as bait when fishing.',
		image: 'images/fishing/bait/earthworm_hook.png',
	},
	2: {
		id: 2,
		itemID: itemsIds.scroll,
		price: 50000,
		allowBulkPurchase: true,
		description: 'Used in magic to store magical properties; enchants.',
		image: '/images/enchanting/scroll.png',
	},
	3: {
		id: 3,
		itemID: itemsIds.fertilizer,
		price: 1000,
		allowBulkPurchase: true,
		description: 'Fertilizer speeds up the growth of your plants.',
		image: '/images/farming/fertilizer.png',
	},
	4: {
		id: 4,
		itemID: itemsIds.empty_vial,
		price: 50,
		allowBulkPurchase: true,
		description: 'An empty vial to pour a potion into.',
		image: '/images/cooking/alchemy/empty_vial.png',
	},
	// 5: {
	// 	id: 5,
	// 	itemID: itemsIds.empty_large_vial,
	// 	price: 100,
	// 	allowBulkPurchase: true,
	// 	description: 'A large empty vial to pour a potion into.',
	// 	image: '/images/cooking/alchemy/empty_large_vial.png',
	// },
	// 6: {
	// 	id: 6,
	// 	itemID: itemsIds.empty_reinforced_vial,
	// 	price: 2000,
	// 	allowBulkPurchase: true,
	// 	description: 'A reinforced empty vial to pour a potion into.',
	// 	image: '/images/cooking/alchemy/empty_reinforced_vial.png',
	// },
	7: {
		id: 7,
		itemID: itemsIds.feather,
		price: 50,
		allowBulkPurchase: true,
		description: 'A feather.',
		image: 'images/fishing/feather.png',
	},
	8: {
		id: 8,
		itemID: itemsIds.unstable_talisman,
		price: 100000,
		allowBulkPurchase: true,
		description: 'An unstable talisman ready to be imbued with essence to become a normal talisman.',
		image: '/images/runecrafting/unstable_talisman.png',
	},
	9: {
		id: 9,
		itemID: itemsIds.inert_heat_stone,
		price: 50000,
		allowBulkPurchase: true,
		description: 'An inert rune stone that can be charged with heat to transfer it to others.',
		image: '/images/misc/inert_heat_rune.png',
	},

	100: {
		id: 100,
		itemID: itemsIds.bronze_gloves,
		price: 100,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 100 should be sufficient.',
		image: '/images/combat/equipment/bronze_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 100,
				},
			],
		},
	},
	101: {
		id: 101,
		itemID: itemsIds.iron_gloves,
		price: 3000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 200 should be sufficient.',
		image: '/images/combat/equipment/iron_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 200,
				},
			],
		},
	},
	103: {
		id: 103,
		itemID: itemsIds.mithril_gloves,
		price: 75000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 300 should be sufficient.',
		image: '/images/combat/equipment/mithril_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 300,
				},
			],
		},
	},
	104: {
		id: 104,
		itemID: itemsIds.adamantite_gloves,
		price: 400000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 400 should be sufficient.',
		image: '/images/combat/equipment/adamantite_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 400,
				},
			],
		},
	},
	105: {
		id: 105,
		itemID: itemsIds.runite_gloves,
		price: 2000000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 500 should be sufficient.',
		image: '/images/combat/equipment/rune_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 500,
				},
			],
		},
	},
	106: {
		id: 106,
		itemID: itemsIds.stygian_gloves,
		price: 6000000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 600 should be sufficient.',
		image: '/images/combat/equipment/stygian_gloves.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 600,
				},
			],
		},
	},
	107: {
		id: 107,
		itemID: itemsIds.bronze_boots,
		price: 1500,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 100 should be sufficient.',
		image: '/images/combat/equipment/bronze_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 100,
				},
			],
		},
	},
	108: {
		id: 108,
		itemID: itemsIds.iron_boots,
		price: 10000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 200 should be sufficient.',
		image: '/images/combat/equipment/iron_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 200,
				},
			],
		},
	},
	109: {
		id: 109,
		itemID: itemsIds.mithril_boots,
		price: 80000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 300 should be sufficient.',
		image: '/images/combat/equipment/mithril_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 300,
				},
			],
		},
	},
	110: {
		id: 110,
		itemID: itemsIds.adamantite_boots,
		price: 550000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 400 should be sufficient.',
		image: '/images/combat/equipment/adamantite_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 400,
				},
			],
		},
	},
	111: {
		id: 111,
		itemID: itemsIds.runite_boots,
		price: 2400000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 500 should be sufficient.',
		image: '/images/combat/equipment/rune_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 500,
				},
			],
		},
	},
	112: {
		id: 112,
		itemID: itemsIds.stygian_boots,
		price: 4000000,
		allowBulkPurchase: true,
		description: 'These require a certain amount of skill to use. A total level of 600 should be sufficient.',
		image: '/images/combat/equipment/stygian_boots_icon.png',
		purchaseRequirements: {
			levelRequirements: [
				{
					skill: 'total',
					level: 600,
				},
			],
		},
	},

	300: {
		id: 300,
		itemID: itemsIds.gem_package,
		price: 800000,
		allowBulkPurchase: true,
		description: 'A package of gems! Only available for purchase to those in the Ironman league.',
		image: '/images/misc/package.png',
		purchaseRequirements: {
			league: [
				leaguesIds.ironman,
				leaguesIds.no_gather_ironman,
				leaguesIds.group_ironman,
				leaguesIds.season_1_ironman,
				leaguesIds.magic_catastrophe_ironman,
			],
		},
	},
	301: {
		id: 301,
		itemID: itemsIds.sealed_scroll,
		price: 1500000,
		allowBulkPurchase: true,
		description: 'A sealed scroll! Only available for purchase to those in the Ironman league.',
		image: '/images/misc/sealed_scroll.png',
		purchaseRequirements: {
			league: [
				leaguesIds.ironman,
				leaguesIds.no_gather_ironman,
				leaguesIds.group_ironman,
				leaguesIds.season_1_ironman,
				leaguesIds.magic_catastrophe_ironman,
			],
		},
	},
	// 302: {
	// 	id: 302,
	// 	itemID: itemsIds.small_gathering_supply_crate,
	// 	price: 100000,
	// 	allowBulkPurchase: true,
	// 	description: 'A small crate of gathering supplies. WIP',
	// 	image: '/images/misc/supply_crate_small.png',
	// },
	// 303: {
	// 	id: 303,
	// 	itemID: itemsIds.gathering_supply_crate,
	// 	price: 500000,
	// 	allowBulkPurchase: true,
	// 	description: 'A crate of gathering supplies. WIP',
	// 	image: '/images/misc/supply_crate.png',
	// },
	// 304: {
	// 	id: 304,
	// 	itemID: itemsIds.large_gathering_supply_crate,
	// 	price: 1000000,
	// 	allowBulkPurchase: true,
	// 	description: 'A large crate of gathering supplies. WIP',
	// 	image: '/images/misc/supply_crate_large.png',
	// },
	305: {
		id: 305,
		itemID: itemsIds.bucket_of_worms,
		price: 50000,
		allowBulkPurchase: true,
		description: 'A bucket of worms.',
		image: '/images/fishing/bait/bucket.png',
	},
	// 306: {
	// 	id: 306,
	// 	itemID: itemsIds.box_of_skill_books,
	// 	price: 5000000,
	// 	allowBulkPurchase: false,
	// 	description: 'All the skill books.',
	// 	image: '/images/misc/gold_lockbox.png',
	// },
	// 307: {
	// 	id: 307,
	// 	itemID: itemsIds.lightning_edge,
	// 	price: 1000000,
	// 	allowBulkPurchase: false,
	// 	description: 'Cosmetic.',
	// 	image: '/images/premium/cosmetic/equipment/lightning_edge.gif',
	// },
	// 308: {
	// 	id: 308,
	// 	itemID: itemsIds.frost_maul,
	// 	price: 1000000,
	// 	allowBulkPurchase: false,
	// 	description: 'Cosmetic.',
	// 	image: '/images/premium/cosmetic/equipment/frost_maul.gif',
	// },
	// 309: {
	// 	id: 309,
	// 	itemID: itemsIds.box_of_jewelry,
	// 	price: 15000000,
	// 	allowBulkPurchase: true,
	// 	description: 'All the jewelry.',
	// 	image: '/images/misc/gold_lockbox.png',
	// },
	// 310: {
	// 	id: 310,
	// 	itemID: itemsIds.magic_starter_kit,
	// 	price: 15000,
	// 	allowBulkPurchase: true,
	// 	description: 'Will eventually be tied to a tutorial quest.',
	// 	image: '/images/misc/pouch.png',
	// },
	// 311: {
	// 	id: 311,
	// 	itemID: itemsIds.range_starter_kit,
	// 	price: 15000,
	// 	allowBulkPurchase: true,
	// 	description: 'Will eventually be tied to a tutorial quest.',
	// 	image: '/images/misc/pouch.png',
	// },
	312: {
		id: 312,
		itemID: itemsIds.phroglin_pheromones,
		price: 25_000_000,
		allowBulkPurchase: true,
		description:
			"A foul smelling jar filled with a substance that apparently attracts creatures called 'Phroglins.'",
		image: '/images/jewellery/phroglin_attractor.png',
	},
	313: {
		id: 313,
		itemID: itemsIds.geode,
		price: 250_000,
		allowBulkPurchase: true,
		description: 'A geode!',
		image: '/images/misc/geode.png',
		purchaseRequirements: {
			league: [leaguesIds.preseason],
		},
		purchaseLimits: {
			maxAmountPerReset: 1,
			hoursBetweenReset: 25.2,
		},
	},
	500: {
		id: 500,
		itemID: itemsIds.talent_respec_token,
		price: 100_000_000,
		allowBulkPurchase: true,
		description: 'Allows a full respec of your talent tree. Consumed on use!',
		image: '/images/misc/talent_token.png',
		purchaseLimits: {
			maxAmountPerReset: 3,
			hoursBetweenReset: 24 * 30,
		},
	},
};
