import React from 'react';
import { itemList } from '../../../../utils/itemList';
import Tooltip from '../../../layout/UI/Tooltip';
import { formatNumber } from '../../../../helper/StringFormatter';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import './AbilityTooltip.css';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { EnchantmentData } from '../../EnchantmentData';
import { getMaxHit } from '../CombatPlayerStats';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { TDamageTypes } from '../../../../../../game-server/src/modules/combat/DamageTypes';
import { Box } from '@chakra-ui/react';

const getAbilityCooldownFormatted = (ability: IAbilityData) => {
	let abilityCooldown;
	if (ability.cooldown && ability.cooldown > 0) {
		// Take cooldown in milliseconds and convert it into seconds, or minutes, or hours
		abilityCooldown = ability.cooldown / 1000;
		if (abilityCooldown > 60) {
			abilityCooldown = abilityCooldown / 60;
			if (abilityCooldown > 60) {
				abilityCooldown = abilityCooldown / 60;
				abilityCooldown = formatNumber(abilityCooldown) + 'h';
			} else {
				abilityCooldown = formatNumber(abilityCooldown) + 'm';
			}
		} else {
			abilityCooldown = formatNumber(abilityCooldown) + 's';
		}
	}

	if (!abilityCooldown) {
		abilityCooldown = 'No';
	}

	return abilityCooldown;
};

const getFormattedStat = (abilityValue: number) => {
	let verb = 'increased';

	if (abilityValue < 1) {
		verb = 'decreased';
	}

	const string = (
		<span>
			<span className={`${verb === 'increased' ? 'green-text' : 'red-text'}`}>{verb}</span> by{' '}
			<span className={`${verb === 'increased' ? 'green-text' : 'red-text'}`}>
				{((verb === 'increased' ? abilityValue - 1 : 1 - abilityValue) * 100).toFixed(0)}%
			</span>
		</span>
	);

	return string;
};

export const AbilityTooltip = ({ ability }: { ability: IAbilityData }) => {
	const playerStats = usePlayerField('combatStats');

	const damageConversionEnchantments = Object.values(enchantmentsList).filter(
		(e) => e.damageConversion !== undefined
	);
	const conversions = damageConversionEnchantments.map((e) => ({
		id: e.id,
		strength: usePlayerEnchantmentStrength(e.id, 'attack'),
	}));
	const damageEnhancementEnchantments = Object.values(enchantmentsList).filter(
		(e) => e.damageEnhancement !== undefined
	);
	const enhancements = damageEnhancementEnchantments.map((e) => ({
		id: e.id,
		strength: usePlayerEnchantmentStrength(e.id, 'attack'),
	}));

	const renderSelfBuff = () => {
		if (ability.selfBuff) {
			const buff = ability.selfBuff.enchantmentApply;
			const chance = ability.selfBuff.enchantmentChanceToApply;
			const buffData = EnchantmentData.findEnchantmentByID(buff);

			const chanceFormatted = Number((chance * 100).toFixed(0));

			const startString = chanceFormatted === 100 ? 'Gain' : `Gain a ${chanceFormatted}% chance to apply`;

			return (
				<div className='idlescape-tooltip-row idlescape-tooltip-space-top'>
					<div className='combat-ability-tooltip-stat'>
						{startString} {ability.selfBuff.enchantmentAmount} stacks of a{' '}
						<span className='enchanted-text bold'>
							{buffData.name} {ability.selfBuff.enchantmentStrength}
						</span>{' '}
						de/buff.
					</div>
				</div>
			);
		}
	};

	const renderTargetBuff = () => {
		if (ability.targetBuff) {
			const buff = ability.targetBuff.enchantmentApply;
			const chance = ability.targetBuff.enchantmentChanceToApply;
			const buffData = EnchantmentData.findEnchantmentByID(buff);

			const chanceFormatted = Number((chance * 100).toFixed(0));

			const startString = chanceFormatted === 100 ? 'Apply' : `A ${chanceFormatted}% chance to apply`;

			return (
				<div className='idlescape-tooltip-row idlescape-tooltip-space-top'>
					<div className='combat-ability-tooltip-stat'>
						{startString} {ability.targetBuff.enchantmentAmount} stacks of a{' '}
						<span className='enchanted-text bold'>
							{buffData.name} {ability.targetBuff.enchantmentStrength}
						</span>{' '}
						de/buff.
					</div>
				</div>
			);
		}
	};

	const renderCost = () => {
		const runeCost = ability.runeCost || [];
		return (
			<div className='idlescape-tooltip-row-3'>
				{ability.useRangedAmmo && <div className='combat-ability-tooltip-stat-small'>Uses Ranged Ammo</div>}
				{runeCost.map((rune, index) => {
					const runeData = itemList[rune.itemID];
					return (
						<div className='combat-ability-tooltip-stat-small' key={index}>
							{runeData.name} x {rune.amount}
						</div>
					);
				})}
			</div>
		);
	};

	const renderDamage = () => {
		if (ability.dealsNoDamage) {
			return <div className='combat-ability-tooltip-stat'>Deals no damage</div>;
		}
		const maxHits = getMaxHit(playerStats);
		// Do the actual damage split calculations for affinity vs. player stats
		let maxDamage = 0;
		let minDamage = 0;
		let damageCount = 0;
		let baseMaxDamage = maxHits.magic.max;
		let baseMinDamage = maxHits.magic.min;
		if (ability.damageType === 'Melee') {
			baseMaxDamage = maxHits.melee.max;
			baseMinDamage = maxHits.melee.min;
		} else if (ability.damageType === 'Range') {
			baseMaxDamage = maxHits.range.max;
			baseMinDamage = maxHits.range.min;
		}
		for (const affinityIdx in ability.damageScaling) {
			if (ability.damageScaling[affinityIdx]) {
				const aff = ability.damageScaling[affinityIdx];
				const maxDamageVal =
					baseMaxDamage * aff.scaling * (playerStats.offensiveDamageAffinity[aff.affinity] ?? 1);
				const minDamageVal =
					baseMinDamage * aff.scaling * (playerStats.offensiveDamageAffinity[aff.affinity] ?? 1);
				maxDamage += maxDamageVal;
				minDamage += minDamageVal;
				damageCount += 1;
			}
		}
		maxDamage *= ability.baseMaximumDamageCoeff;
		minDamage *= ability.baseMinimumDamageCoeff;
		maxDamage /= damageCount;
		minDamage /= damageCount;
		maxDamage = Math.max(Math.floor(maxDamage), 1);
		minDamage = Math.max(Math.floor(minDamage), 1);

		const singleTargetDamage = Math.max(Math.floor((minDamage + maxDamage) / 2), 1);
		const maxTargetDamage = Math.max(Math.floor(singleTargetDamage * ability.maxTargets), 1);
		return (
			<div>
				{ability.baseMinimumDamageCoeff !== 1 && (
					<div className='combat-ability-tooltip-stat'>
						Minimum damage is {getFormattedStat(ability.baseMinimumDamageCoeff)}
					</div>
				)}
				{ability.baseMaximumDamageCoeff !== 1 && (
					<div className='combat-ability-tooltip-stat'>
						Maximum damage is {getFormattedStat(ability.baseMaximumDamageCoeff)}
					</div>
				)}
				{minDamage !== maxDamage ? (
					<>
						<hr />
						<div className='combat-ability-tooltip-stat'>
							Estimated damage range: {minDamage} - {maxDamage}
						</div>
						<div className='combat-ability-tooltip-stat'>
							Estimated single target damage: {singleTargetDamage}
							{ability.maxTargets > 1 && (
								<div className='combat-ability-tooltip-stat'>
									Estimated max target damage: {maxTargetDamage}
								</div>
							)}
						</div>
					</>
				) : (
					<>
						<hr />
						<div className='combat-ability-tooltip-stat'>Estimated damage: {minDamage}</div>
					</>
				)}
			</div>
		);
	};

	const renderCritical = () => {
		if (ability.critical) {
			const objs = [];
			if (ability.critical.chanceAdditive) {
				objs.push({
					text: `Crit Chance +${ability.critical.chanceAdditive * 100}%`,
				});
			}
			if (ability.critical.chanceMult) {
				objs.push({
					text: `Crit Chance x${ability.critical.chanceMult}`,
				});
			}
			if (ability.critical.damageAdditive) {
				objs.push({
					text: `Crit Damage +${ability.critical.damageAdditive * 100}%`,
				});
			}
			if (ability.critical.damageMult) {
				objs.push({
					text: `Crit Damage x${ability.critical.damageMult}`,
				});
			}
			return (
				<div className='combat-ability-tooltip-stat'>
					{objs.map((obj, index) => (
						<div key={index}>{obj.text}</div>
					))}
				</div>
			);
		}
	};

	const headerSection = () => {
		const damages = structuredClone(ability.damageScaling).map((d) => ({
			special: false,
			...d,
		}));
		// Apply damage enhancements to matching affinities
		for (const enhancement of enhancements) {
			if (enhancement.strength === 0) continue;
			const found = damages.find((d) => d.affinity === enchantmentsList[enhancement.id].damageEnhancement);
			if (found) {
				found.scaling *= 1 + enhancement.strength;
				found.scaling = Number(found.scaling.toFixed(2));
			}
		}
		const accuracies = structuredClone(ability.accuracyScaling).map((a) => ({
			special: false,
			...a,
		}));
		// Auto attacks are affected by damage conversion enchantments
		if (ability.autoAttackStyle !== undefined) {
			for (const conversion of conversions) {
				if (conversion.strength === 0) continue;
				damages.push({
					affinity: enchantmentsList[conversion.id].damageConversion as TDamageTypes,
					scaling: 1 + conversion.strength,
					special: true,
				});
			}
		}

		return (
			<div className='combat-ability-tooltip-header'>
				<div className={`idlescape-tooltip-row-2 ${ability.damageType.toLowerCase()}-text`}>
					<div className='combat-ability-tooltip-stat'>{ability.abilityName}</div>
					<div className={'combat-ability-tooltip-stat'}>{ability.damageType}</div>
				</div>
				{renderCost()}
				<div className='idlescape-tooltip-row-2'>
					<div className='combat-ability-tooltip-stat'>
						{formatNumber(playerStats.attackSpeed * ability.baseSpeedCoeff)}s speed
					</div>
					<div className='combat-ability-tooltip-stat'>{getAbilityCooldownFormatted(ability)} cooldown</div>
					{ability.maxTargets > 1 && (
						<div className='combat-ability-tooltip-stat'>{ability.maxTargets} targets</div>
					)}
					{ability.maxTargets > 1 && ability.diminishingAOEDamageMult && (
						<div className='combat-ability-tooltip-stat'>
							AOE DMG Loss Per Subsequent Targets: {formatNumber(ability.diminishingAOEDamageMult * 100)}%
						</div>
					)}
					{ability.canTargetSelf !== undefined && !ability.canTargetSelf && (
						<div className='combat-ability-tooltip-stat'>Cannot Target Self</div>
					)}
				</div>
				<div className='idlescape-tooltip-row idlescape-tooltip-space-top'>
					<div className='combat-ability-tooltip-description'>{ability.description}</div>
				</div>
				{(ability.selfBuff ||
					ability.targetBuff ||
					ability.baseAccuracyCoeff !== 1 ||
					ability.baseMaximumDamageCoeff !== 1 ||
					ability.baseMaximumDamageCoeff !== 1) && <hr></hr>}
				{ability.selfBuff && renderSelfBuff()}
				{ability.targetBuff && renderTargetBuff()}
				<div className='idlescape-tooltip-row idlescape-tooltip-space-top'>
					{ability.baseAccuracyCoeff !== 1 &&
						ability.baseAccuracyCoeff !== 0 &&
						(ability.baseAccuracyCoeff <= 5 ? (
							<div className='combat-ability-tooltip-stat'>
								Accuracy is {getFormattedStat(ability.baseAccuracyCoeff)}
							</div>
						) : (
							<div className='combat-ability-tooltip-stat'>Will always succeed.</div>
						))}
					{renderDamage()}
					{renderCritical()}
					<hr></hr>
				</div>
				<div className='idlescape-tooltip-row-2'>
					<div className='idlescape-tooltip-row'>
						<div className='combat-ability-tooltip-stat'>Damage Stats</div>
						{damages.map((damage, index) => {
							return (
								<Box
									className='idlescape-tooltip-row-2'
									key={index}
									color={damage.special ? '#ee00ee' : undefined}
								>
									<div className='combat-ability-tooltip-stat'>
										{damage.affinity}: {damage.scaling}x
									</div>
								</Box>
							);
						})}
					</div>
					<div className='idlescape-tooltip-row'>
						<div className='combat-ability-tooltip-stat'>Accuracy Stats</div>
						{accuracies.map((accuracy, index) => {
							return (
								<Box
									className='idlescape-tooltip-row-2'
									key={index}
									color={accuracy.special ? '#ee00ee' : undefined}
								>
									<div className='combat-ability-tooltip-stat'>
										{accuracy.affinity}: {accuracy.scaling}x
									</div>
								</Box>
							);
						})}
					</div>
				</div>

				{ability.healthChangeEvent && (
					<>
						<hr></hr>
						<div className='idlescape-tooltip-row idlescape-tooltip-space-top'>
							<div className='combat-ability-tooltip-stat'>
								{ability.healthChangeEvent.targetsSelf ? 'Targets Caster' : ''}
								{ability.healthChangeEvent.healthChange > 0 ? 'Heals for' : 'Damages for'}{' '}
								{ability.healthChangeEvent.healthChange} health {ability.healthChangeEvent.dotCount}{' '}
								times with a delay of {(ability.healthChangeEvent.healthChangeDelay / 1000).toFixed(1)}{' '}
								seconds for a total of{' '}
								{ability.healthChangeEvent.healthChange * ability.healthChangeEvent.dotCount} health.
							</div>
						</div>
					</>
				)}
			</div>
		);
	};

	return <Tooltip className={'combat-ability-tooltip'}>{headerSection()}</Tooltip>;
};
