import { Box } from '@chakra-ui/react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { activeTabAtom } from '../../../../atoms/activeTabAtom';
import { marketplaceItemAtom } from '../../../../atoms/marketplaceItemAtom';
import { marketplaceTabAtom } from '../../../../atoms/marketplaceTabAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { itemList } from '../../../../utils/itemList';
import { leagueList } from '../../../../utils/leagueList';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import './ChatItem.css';

export default function ChatMarketplaceOffer(props: {
	offer: { item: IItem; price: number; higherAugments: boolean };
}) {
	const setItemToBuy = useSetRecoilState(marketplaceItemAtom);
	const setMarketTab = useSetRecoilState(marketplaceTabAtom);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const leagueId = usePlayerField('league');
	const leagueData = leagueList[leagueId];

	function openInMarketplace() {
		if (!leagueData.rules.marketplace) return;
		setItemToBuy(props.offer.item.itemID);
		setMarketTab('buy');
		setActiveTab('marketplace');
	}

	const itemData = itemList[props.offer.item.itemID];
	if (!itemData) return <div>Invalid Item</div>;
	let augs = '';
	if ((props.offer.item.augmentations && props.offer.item.augmentations >= 1) || props.offer.higherAugments) {
		augs = ' ' + (props.offer.higherAugments ? '≥' : '+') + (props.offer.item.augmentations ?? 0);
	}

	let amountRender = 'x ' + Number(props.offer.item.stackSize).toLocaleString('en-us');
	if (props.offer.item.stackSize == 1) amountRender = '';

	return (
		<Box
			className={`chat-item ${itemData.class}`}
			data-itemid={props.offer.item.itemID}
			onClick={openInMarketplace}
			cursor='pointer'
		>
			Buying:{' '}
			<img className='chat-skill-icon' src={itemData.itemIcon || itemData.itemImage} alt={itemData.name} />{' '}
			{itemData.name} {augs} {amountRender} | {Number(props.offer.price).toLocaleString('en-us')}{' '}
			<img className='chat-skill-icon' src='/images/gold_coin.png' alt='Gold' />
			<ItemTooltip item={props.offer.item} />
		</Box>
	);
}
