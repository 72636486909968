import { BaseTooltip } from './BaseTooltip';
import { TTooltipStats } from '../Data/TooltipData';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import {
	getEliteScrollWaves,
	getLocationTreasureHunter,
	getMonsterDifficulty,
	getMonsterTreasureHunter,
} from '../../../../utils/itemFunctions';

export class EliteScrollTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Elite Scroll';
	}

	protected getStats(item: IItem): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		const eliteScrollStats: Record<string, number> = {};

		eliteScrollStats['elite.monsterDifficulty'] = getMonsterDifficulty(item);
		eliteScrollStats['elite.scrollWaves'] = getEliteScrollWaves(item);

		if (item.augmentations && item.augmentations > 0) {
			eliteScrollStats['elite.monsterTH'] = getMonsterTreasureHunter(item);
			eliteScrollStats['elite.locationTH'] = getLocationTreasureHunter(item);
		}
		return { eliteScrollStats: eliteScrollStats };
	}
}
