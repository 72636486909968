import {
	Box,
	Grid,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { chatIconList } from '../../../utils/chatIconList';

export default function ChatSettingsPopup(props: { isOpen: boolean; onClose: () => void }) {
	const settings = usePlayerField('settings');
	const selectedIcon = settings.chat.selectedIcon;
	const unlockedIcons = usePlayerField('unlockedIcons');
	const tempIcons = usePlayerField('tempIcons');
	const ownedIcons = unlockedIcons.concat(tempIcons);

	// Filter out duplicate icons in ownedIcons that have the same icon.iconID
	const filteredIcons = ownedIcons.filter((icon, index, self) => {
		return index === self.findIndex((t) => t.iconID === icon.iconID);
	});

	function setActiveIcon(iconID: number | null) {
		if (selectedIcon && iconID === selectedIcon) {
			socket.emit('chat:icon:set', {
				iconID: null,
			});
			return;
		}

		socket.emit('chat:icon:set', {
			iconID,
		});
	}

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Icons</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' justifyContent='center'>
						{filteredIcons.map((icon) => {
							const iconData = chatIconList[icon.iconID];
							if (!iconData) return null;
							return (
								<IdlescapeWrappingTooltip key={iconData.id} content={iconData.name}>
									<Box
										cursor='pointer'
										onClick={() => setActiveIcon(iconData.id)}
										border={selectedIcon && selectedIcon === icon.iconID ? '1px solid #fff' : ''}
										borderRadius='5px'
										height='45px'
										width='45px'
										padding='3px'
										aria-selected={selectedIcon !== null && selectedIcon === icon.iconID}
									>
										<Image
											src={iconData.source}
											alt={iconData.name}
											className={iconData?.css}
											width='100%'
											height='100%'
											objectFit='contain'
										/>
									</Box>
								</IdlescapeWrappingTooltip>
							);
						})}
					</Grid>
					{filteredIcons.length === 0 && 'No icons unlocked yet.'}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
