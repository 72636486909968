import { Box, BoxProps, Image } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import React, { useImperativeHandle, useRef } from 'react';
import './ProgressBar.css';

interface IProgressBarProps extends BoxProps {
	value: number;
	max: number;
	theme: string;
}

export interface IProgressBarRef {
	startAnimation: (lengthInMS: number, startTime?: number) => void;
	stopAnimation: () => void;
}

/**
 * Progress bar with animation support, default height can be overwritten
 * # Usage with animation:
 * - pass a ref to the ProgressBar component
 * - call `ref.current?.startAnimation(lengthInMS)` and `ref.current?.stopAnimation()` when needed
 * - when using inside a useEffect, make sure to add the ref to the dependency array
 */
export const ProgressBar = forwardRef(({ value, max, theme, ...props }: IProgressBarProps, ref) => {
	let percentage = Number((100 - (value / max) * 100).toFixed(2));
	if (max === 0) percentage = 100;

	const id = `idlescape-progress-bar-filler-animation-theme-${theme}`;
	const progressBar = useRef<HTMLImageElement>(null);
	useImperativeHandle(
		ref,
		() => ({
			startAnimation: (lengthInMS: number, startTime?: number) => {
				if (progressBar.current) {
					progressBar.current.classList.remove(id);
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const reRender = progressBar.current.offsetHeight;
					progressBar.current.classList.add(id);
					progressBar.current.style.animationDuration = lengthInMS + 'ms';
					progressBar.current.style.animationIterationCount = '1';
					progressBar.current.style.animationPlayState = 'running';
					// If a start time is given, resume the progress bar from the middle
					if (startTime) {
						const progress = startTime - Date.now();
						// Negative progress starts the bar mid way
						progressBar.current.style.animationDelay = `${progress}ms`;
					}
				}
			},
			stopAnimation: () => {
				if (progressBar.current) {
					progressBar.current.classList.remove(id);
				}
			},
		}),
		[id, progressBar]
	);

	let inset = { clipPath: 'inset(0 ' + percentage + '% 0 0)' };

	let background: string | undefined = undefined;
	let fill: string | undefined = undefined;
	let boxFill: BoxProps | undefined = undefined;
	let boxBackground: BoxProps | undefined = undefined;
	let height = '40px';
	switch (theme) {
		case 'red':
			background = '/images/ui/ProgressBars/red_bg.png';
			fill = '/images/ui/ProgressBars/red_fill.png';
			break;
		case 'shield':
			fill = '/images/ui/ProgressBars/shield_fill.png';
			break;
		case 'intensity':
			background = '/images/ui/ProgressBars/intensity_bg.png';
			fill = '/images/ui/ProgressBars/intensity_fill_edited.png';
			break;
		case 'smithing-progress':
			background = '/images/ui/ProgressBars/smithing_progress_bg_v2.png';
			fill = '/images/ui/ProgressBars/smithing_progress_fill_v2.png';
			break;
		case 'augmenting':
			background = '/images/ui/ProgressBars/blue_bg.png';
			fill = '/images/ui/ProgressBars/purple_fill_edited.png';
			break;
		case 'cooking':
			fill = 'images/cooking/cooking_progress_bar.png';
			inset = { clipPath: 'inset(' + percentage + '% 0 0 0)' };
			height = '100%';
			break;
		case 'scrapping':
			fill = 'images/farming/banana_tree_seed.png';
			inset = { clipPath: 'inset(0 0 ' + percentage + '% 0)' };
			height = '100%';
			break;
		case 'combat':
			background = '/images/ui/ProgressBars/blue_bg.png';
			fill = '/images/ui/ProgressBars/blue_fill_edited.png';
			break;
		case 'shrine':
			background = '/images/ui/ProgressBars/shrine_bg.png';
			fill = '/images/ui/ProgressBars/shrine_fill.png';
			break;
		case 'quest':
			boxFill = {
				borderRadius: '5px',
				// backgroundImage: 'url(/images/ui/stone_ui_tile_dark_gray.png)',
				background: 'repeating-linear-gradient(45deg, #000, #0000 10px)',
			};
			boxBackground = { border: '2px solid #0008', borderRadius: '5px' };
			break;
		case 'augment-counter':
			boxFill = {
				borderRadius: '5px',
				// backgroundImage: 'url(/images/ui/stone_ui_tile_dark_gray.png)',
				background: 'repeating-linear-gradient(55deg, #808, #000 10px)',
			};
			boxBackground = { border: '2px solid #4048', borderRadius: '5px' };
			break;
		case 'quest-list':
			boxFill = {
				className: 'quest-step quest-progress-bar',
			};
			inset = { clipPath: `rect(0 0 100% ${100 - percentage}% round 0 10px 10px 0)` };
			break;
		case 'snowball':
			boxFill = {
				background: 'linear-gradient(90deg, #ff7ea7, #d9b2fb, #d1c1fb, #d5cdff, #ceeff9)',
			};
			break;
		case 'default':
		default:
			height = '10px';
	}

	return (
		<Box position='relative' width='100%' height={height} margin='auto' {...props}>
			<Box width='100%' height='100%' position='absolute' left='0'>
				{background !== undefined && <Image src={background} width='100%' height='100%' />}
				{boxBackground !== undefined && <Box {...boxBackground} width='100%' height='100%' />}
			</Box>
			<Box className='idlescape-progress-bar-filler' width='100%' height='100%' position='absolute' left='0'>
				{fill !== undefined ? (
					<Image
						ref={progressBar}
						src={fill}
						style={inset}
						id={id}
						className={id}
						width='100%'
						height='100%'
					/>
				) : boxFill ? (
					<Box ref={progressBar} style={inset} width='100%' height='100%' {...boxFill} />
				) : (
					<Box
						ref={progressBar}
						backgroundColor='rgba(135, 186, 252, 0.9)'
						borderRadius='20px'
						boxShadow='inset 0 0 2px 1px rgba(255, 255, 255, 0.356)'
						style={inset}
						id={id}
						className={id}
						width='100%'
						height='100%'
					/>
				)}
			</Box>
		</Box>
	);
});
