import { BaseTooltip } from './BaseTooltip';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { abilities } from '../../../../utils/abilityList';

export class BookTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Book';
	}

	protected getAbilities(item: IItem, itemResource: IItemData): { name: string; id: number }[] {
		if (!itemResource.relatedAbility) return [];

		const ability = abilities[itemResource.relatedAbility];
		if (!ability) return [];

		return [{ name: ability.abilityName, id: ability.id }];
	}
	protected getEffects(item: IItem, itemResource: IItemData): Record<string, string> {
		const effects: Record<string, string> = {};
		if (!itemResource.relatedAbility) return effects;

		const ability = abilities[itemResource.relatedAbility];
		effects[ability.abilityName] = ability.description ?? '';
		return effects;
	}
}
