import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import { IAffix } from '../../../../../../game-server/src/modules/affixes/affixes.interface';

export class TooltipData {
	name = '';
	customName = '';
	premiumName = false;
	image = '';
	rarity = 'common';
	augment = 0;
	quantity = 1;
	vendor = 0;
	market = 0;
	heat = 0;
	flavor = '';
	type = '';
	weaponSubtype = '';
	weaponStats: Partial<Record<TWeaponStats, string>> = {};
	stats: Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined = {};
	equipmentStats: Partial<Record<TTooltipEquipmentStats, Record<string, number>>> | undefined = {};
	enchant: Record<TEnchant, string | number> | undefined = {
		enchantId: -1,
		enchantName: '',
		enchantStrength: 0,
		enchantDescription: '',
		enchantType: '',
	};
	requiredStats: Partial<Record<TSkillName, number>> = {};
	itemAbilities: { name: string; id: number }[] = [];
	itemSet: Record<string, string | number>[] = [];
	effects: Record<string, string> = {};
	use = '';
	yield: { name: string; productionMin: number; productionMax: number; chance: number }[] = [];
	healing: Record<THealingStats, number> | undefined = {
		healing: 0,
		healTick: 0,
		tickDelay: 0,
		totalTicks: 0,
		cooldown: 0,
	};
	foodEnchant: Record<TFoodEnchant, string | number | boolean> | undefined = {
		enchantId: 0,
		enchantName: '',
		enchantDescription: '',
		enchantStrength: 0,
		enchantType: '',
		stacks: 0,
		combat_only: false,
	};
	tradeable = true;
	canBeOpened = false;
	isEquipment = false;
	enchantmentScrollCategories: string[] = [];
	affixes: IAffix[] = [];
	augmentCounter = 0;
}

export type TTooltipEquipmentStats =
	| 'strengthStats'
	| 'strengthAffinities'
	| 'attackAffinities'
	| 'defenseStats'
	| 'defenseAffinities'
	| 'skillStats';

export type TWeaponStats = 'speed' | 'style' | 'maxHitMults' | 'minHitMults';

export type TTooltipStats = 'seedStats' | 'foodStats' | 'eliteScrollStats' | 'dungeonKeyStats' | 'baitStats';

export type THealingStats = 'healing' | 'healTick' | 'tickDelay' | 'totalTicks' | 'cooldown';

export type TFoodEnchant = TEnchant | 'stacks' | 'combat_only';

export type TEnchant = 'enchantId' | 'enchantName' | 'enchantDescription' | 'enchantStrength' | 'enchantType';

export type TStats =
	| 'elite.monsterDifficulty'
	| 'elite.monsterTH'
	| 'elite.locationTH'
	| 'elite.scrollWaves'
	| 'elite.groupSize'
	| 'fishing.bait'
	| 'fishing.reel'
	| 'fishing.bonusRarity'
	| 'fishing.fishing'
	| 'fishing.multBait'
	| 'fishing.multReel'
	| 'fishing.multBonus'
	| 'fishing.multFishing'
	| 'food.tags'
	| 'food.size'
	| 'food.alchemySize'
	| 'food.difficulty'
	| 'food.difficultyXP'
	| 'food.cookingEnchantment'
	| 'food.alchemyEnchantment'
	| 'seed.experience'
	| 'seed.plotSize'
	| 'seed.harvestTime';
