import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { getSocket } from './socket.service';
import { monsterAtom } from '../atoms/monsterAtom';
import { produce } from 'immer';
import { usePlayerField } from '../hooks/hooks';

export const MonsterManager = ({ loaded, setLoaded }: { loaded?: boolean; setLoaded: (value: boolean) => void }) => {
	const setMonsters = useSetRecoilState(monsterAtom);
	const action = usePlayerField('actionQueue');
	const active = action?.actionType === 'Action-Combat';
	const socket = getSocket();
	useEffect(() => {
		/** Subscribe on mount */
		socket.on('combat:spawnMonster', async (data) => {
			setMonsters(data);
		});

		socket.on('combat:updateMonster', async (data) => {
			setMonsters(
				produce((draft) => {
					const monsterIndex = draft.findIndex((monster) => monster.id === data.id);
					if (monsterIndex !== -1) {
						draft[monsterIndex] = data;
					} else {
						draft.push(data);
					}
				})
			);
			/* const newMonsterList = monsters.map((monster) => {
				if (monster.id === data.id) {
					return data;
				}
				return monster;
			}); */
			/* console.log('NEW MONSTER LIST', newMonsterList); */
			/* setMonsters(newMonsterList); */
		});

		setLoaded(true);

		/** Unsubscribe on unmount */
		return () => {
			socket.off('combat:spawnMonster');
			socket.off('combat:updateMonster');
			setLoaded(false);
		};
	}, []);

	// Clear monsters when not in combat
	useEffect(() => {
		if (!active) {
			setMonsters([]);
		}
	}, [active]);

	return null;
};
