import { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { platinumPacks } from '../../../utils/platinumPackList';

import ChatItem from '../Chat/Links/ChatItem';
import { usePlayerField } from '../../../hooks/hooks';

import LocaleCurrency from 'locale-currency';
import { List } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';

export const PlatinumShopPurchaseReview = ({
	platinumPackId,
	isSubscription,
	setSelectedPack,
}: {
	platinumPackId?: number;
	isSubscription?: boolean;
	setSelectedPack: (pack: number) => void;
}) => {
	const platinumPack = platinumPacks[platinumPackId as number];
	const [loading, setLoading] = useState(false);
	const subscription = usePlayerField('subscription');

	const locale = navigator.language;
	const localeCurrency = LocaleCurrency.getCurrency(locale) === 'EUR' ? '€' : '$';

	function handlePurchase() {
		setLoading(true);
		if (platinumPackId) {
			socket.emit('monetization:purchase:platinum', { platinumPackId });
			return;
		}

		if (isSubscription) {
			if (subscription.active) {
				socket.emit('monetization:idlescape-plus:manage');
				return;
			}
			socket.emit('monetization:purchase:idlescape-plus');
			return;
		}

		setLoading(false);
	}

	useEffect(() => {
		const stopLoading = () => {
			setLoading(false);
		};
		socket.on('monetization:checkout-url', stopLoading);

		return () => {
			socket.off('monetization:checkout-url', stopLoading);
		};
	}, []);

	const Loader = () => {
		return (
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		);
	};

	function getData() {
		if (platinumPackId) {
			return {
				title: platinumPack.platinumAmount.toLocaleString('en-us') + ' Platinum',
				image: platinumPack.image,
				content: (
					<div>
						<ChatItem item={{ itemID: 20, name: 'Platinum' }}></ChatItem> can be used to buy server-wide
						global Buffs, and in the <span>Platinum Shop</span> to buy items such as:
						<List paddingLeft='0'>
							<li>Cosmetics</li>
							<li>Idlescape Tokens</li>
							<li>Character Tokens</li>
							<li>Item name changes</li>
						</List>
					</div>
				),
				buttonText: `Purchase ${localeCurrency}${platinumPack.price}`,
			};
		}
		//Not sure why this isn't used
		if (isSubscription) {
			return {
				title: 'Idlescape Plus' + (subscription.active ? ' Active' : ''),
				image: '/images/premium/premium_icon.png',
				content: (
					<div>
						<span>Idlescape Plus</span> will give you quality of life improvements in the game through a
						monthly subscription<br></br>
						<span>Idlescape Plus</span> includes:
						<List paddingLeft='0'>
							<li>+1 Active Character</li>
							<li>+5 Character Slots</li>
							<li>+5 Loadout Slots</li>
							<li>+3 Action Queue Count</li>
							<li>Live Combat Instance Stats</li>
							<li>
								<img
									className='chat-skill-icon'
									src='/images/premium/premium_icon.svg'
									alt='Idlescape Plus Chat Badge'
								/>{' '}
								Idlescape Plus Chat Badge
							</li>
							<li>Idlescape Plus Chat Channel</li>
							<li>Idlescape Plus Discord Channel</li>
							<li>Offline Time Increased from 12h to 24h</li>
							<li>
								One {/* <ChatItem item={{ itemID: 1, name: 'Idle Token' }}></ChatItem>  */}Idle Token
								Every Month
							</li>
						</List>
					</div>
				),
				buttonText: subscription.active ? 'Manage My Subscription' : `Subscribe ${localeCurrency}4.95/month`,
			};
		}

		return { title: 'Error, pls report', image: '', content: '', buttonText: '' };
	}

	const data = getData();

	return (
		<div className='platinum-shop-purchase-review-container idlescape-container idlescape-container-platinum'>
			<div className='platinum-shop-purchase-review-title'>{data.title}</div>
			<div className='platinum-shop-purchase-review-image-area'>
				<img src={data.image} alt={data.title} />
			</div>
			<div className='platinum-shop-purchase-review-info'>{data.content}</div>
			<div className='platinum-shop-purchase-review-actions'>
				<IdlescapeButton
					onClick={() => {
						setSelectedPack(0);
					}}
					variant='gray'
					width='100px'
				>
					Back
				</IdlescapeButton>
				<div
					onClick={handlePurchase}
					className='platinum-shop-purchase-review-actions-purchase idlescape-button idlescape-button-blue'
				>
					{loading ? <Loader></Loader> : data.buttonText}
				</div>
			</div>
			<div className='platinum-shop-purchase-review-button-label'>
				Clicking the button will open a new tab to finish the payment
			</div>
		</div>
	);
};
