import React, { useEffect, useRef } from 'react';
import { smithingActiveBarAtom } from '../../../../atoms/smithingActiveBarAtom';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingIntensityAtom } from '../../../../atoms/smithingIntensityAtom';
import { getSocket } from '../../../../services/socket.service';
import { IProgressBarRef, ProgressBar } from '../../../layout/UI/ProgressBar';
import './Smithing.css';
import SmithingBarList from './SmithingBarList';
import SmithingFurnaceList from './SmithingFurnaceList';
import SmithingInformation from './SmithingInformation';
import SmithingIntensity from './SmithingIntensity';
import { useRecoilState, useRecoilValue } from 'recoil';
import { usePlayerField } from '../../../../hooks/hooks';
import { IdlescapeButton } from '@idlescape/ui';
import ActionQueueButton from '../../ActionQueue/ActionQueueButton';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import { smithingStartAtom } from '../../../../atoms/smithingStartAtom';
import { ActionTypes } from '../../../../utils/enums/ActionTypes';
import { animationAtom } from '../../../../atoms/animationAtom';

export default function Smithing() {
	const socket = getSocket();

	const [activeForge, setActiveForge] = useRecoilState(smithingActiveForgeAtom);
	const [activeBar, setActiveBar] = useRecoilState(smithingActiveBarAtom);
	const [intensity, setIntensity] = useRecoilState(smithingIntensityAtom);
	const canStart = useRecoilValue(smithingStartAtom);

	const settings = usePlayerField('settings');
	const actionQueue = usePlayerField('actionQueue');
	const isSmithing = actionQueue?.actionType === ActionTypes.Smithing;

	useEffect(() => {
		// This is to initialize the settings properly if player reloads the page while smithing
		if (isSmithing && actionQueue?.options?.smithing) {
			setActiveForge(actionQueue.options.smithing.forgeId);
			setActiveBar(actionQueue.options.smithing.barId);
			setIntensity(actionQueue.options.smithing.intensity);
		}
	}, [actionQueue]);

	const animation = useRecoilValue(animationAtom);
	const progressBarRef = useRef<IProgressBarRef>(null);
	useEffect(() => {
		if (isSmithing && animation) {
			progressBarRef.current?.startAnimation(animation.length, animation.startTime);
		}
	}, [animation]);

	function startAction() {
		if (isSmithing) {
			socket.emit('action:stop');
			return;
		}
		socket.emit('action:start', {
			action: 'smithing',
			location: locationsIds.the_forges,
			options: { smithing: { forgeId: activeForge, barId: activeBar, intensity: intensity } },
		});
	}

	function queueButton() {
		return (
			<ActionQueueButton
				action='smithing'
				location={locationsIds.the_forges}
				options={{ smithing: { forgeId: activeForge, barId: activeBar, intensity: intensity } }}
				variant='orange'
			/>
		);
	}

	return (
		<div className='smithing-container'>
			<SmithingFurnaceList />
			<SmithingIntensity />
			<div className='smithing-list-and-progress-bar-container'>
				{!isSmithing ? (
					<SmithingBarList />
				) : !settings.miscellaneous.highPerformance ? (
					<ProgressBar theme='smithing-progress' value={0} max={100} ref={progressBarRef} />
				) : (
					<ProgressBar theme='smithing-progress' value={100} max={100} />
				)}
			</div>
			<SmithingInformation />
			<div className='smithing-buttons'>
				<IdlescapeButton
					variant={isSmithing ? 'red' : canStart ? 'green' : 'disabled'}
					onClick={() => startAction()}
					width='50%'
				>
					{isSmithing ? 'Stop' : 'Smelt'}
				</IdlescapeButton>
				{queueButton()}
			</div>
		</div>
	);
}
