import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';
import BlockedTab from './BlockedTab';
import FriendsTab from './FriendsTab';
import { GroupPage } from './Group/GroupPage';
import ReferralTab from './ReferralTab';
import { default as GroupTab } from './GroupTab';
import Hiscore from './Hiscore';
import './RightPanel.css';
import HiscoreCombat from './HiscoreCombat';

export default function SocialTab() {
	const [selectedTab, setSelectedTab] = useState(0);
	const knownInvites = usePlayerField('knownInvites');
	function getTab() {
		switch (selectedTab) {
			case 0:
				return <GroupPage />;
			case 1:
				return <FriendsTab />;
			case 2:
				return <BlockedTab />;
			case 3:
				return <Hiscore />;
			case 4:
				return <GroupTab />;
			case 5:
				return <ReferralTab />;
			case 6:
				return <HiscoreCombat />;
		}
	}

	let groupIcon = (
		<img className='nav-tab-icon icon-border' src='/images/ui/social_icon.png' alt='Group Icon, No Invites' />
	);
	if (knownInvites.length > 0)
		groupIcon = (
			<img
				className='nav-tab-icon icon-border'
				src='/images/ui/social_icon_notification.png'
				alt='Group Icon, Open Invites'
			/>
		);
	return (
		<div className='game-right-panel-social'>
			<Flex className='nav-tab-container right' flexWrap='wrap' height='unset'>
				<Box
					className={'nav-tab ' + (selectedTab == 0 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(0)}
					height='30px'
				>
					{groupIcon}
					Group
				</Box>
				<Box
					className={'nav-tab ' + (selectedTab == 1 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(1)}
					height='30px'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/friend_icon.png' alt='Friends' />
					Friends
				</Box>
				<Box
					className={'nav-tab ' + (selectedTab == 2 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(2)}
					height='30px'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/blocked_icon.png' alt='Blocked' />
					Blocked
				</Box>
				<Box
					className={'nav-tab ' + (selectedTab == 3 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(3)}
					height='30px'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/hiscore_icon.png' alt='Hiscore' />
					Hiscore
				</Box>
				<Box
					className={'nav-tab ' + (selectedTab == 6 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(6)}
					height='30px'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/hiscore_icon.png' alt='Combat Hiscore' />
					Combat Hiscore
				</Box>
				<Box
					className={'nav-tab ' + (selectedTab == 5 ? ' selected-tab' : '')}
					onClick={() => setSelectedTab(5)}
					height='30px'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/social_icon.png' alt='Referral' />
					Referral
				</Box>
				{/* <div
						className={'nav-tab ' + (selectedTab == 4 ? ' selected-tab' : '')}
						onClick={() => setSelectedTab(4)}
					>
						<img className='nav-tab-icon icon-border' src='/images/ui/hiscore_icon.png' />
						Group Finder
					</div> */}
			</Flex>

			{getTab()}
		</div>
	);
}
