import React from 'react';
import { Flex } from '@chakra-ui/react';
import { questList } from '../../../utils/questList';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { useSetRecoilState } from 'recoil';
import PinnedQuestStep from './PinnedQuestStep';
import { usePlayerField } from '../../../hooks/hooks';

export default function PinnedQuest() {
	const settings = usePlayerField('settings');
	const pinnedQuest = settings.quest.pin;
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const questProgress = usePlayerField('questProgress');

	const empty = (
		<Flex
			position='relative'
			flexDirection='column'
			className='quest-step'
			cursor='pointer'
			padding='5px 15px'
			justifyContent='center'
			alignItems='center'
			width='100%'
			height='60px'
			minWidth='0'
			minHeight='0'
			margin='-5px 0 -10px'
			onClick={() => {
				setActiveTab('quests');
			}}
		>
			Select a quest
		</Flex>
	);

	if (!pinnedQuest) return empty;

	const quest = questList[pinnedQuest];
	if (!quest) return empty;
	const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);

	let step = quest.questSteps[quest.questSteps.length - 1];

	for (const curStep of quest.questSteps) {
		const currentProgress =
			inProgress.find((questProgress) => questProgress.id === quest.id && curStep.stepID === questProgress.stepID)
				?.current ?? 0;
		if (currentProgress < curStep.targetAmount) {
			step = curStep;
			break;
		}
	}

	return <PinnedQuestStep quest={quest} step={step} />;
}
