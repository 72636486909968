import React from 'react';
import { SellMarketplaceDialogue } from '../../layout/dialogs/SellMarketplaceDialogue';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { Item } from '../Inventory/Item';
import { useDisclosure } from '@chakra-ui/react';

function MarketplaceSellItem({ item }: { item: IItem }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Item item={item} onClick={onOpen} mobileTooltip={false} />
			{isOpen && <SellMarketplaceDialogue itemProp={item} onClose={onClose} />}
		</>
	);
}

export default MarketplaceSellItem;
