import { IdlescapeButton } from '@idlescape/ui';
import { openGameUrl } from '../../../GameUrls';
import './ChangePassword.css';

export const ChangePassword = () => {
	return (
		<div className='settings-row'>
			<IdlescapeButton
				variant='blue'
				size='large'
				gridColumn='1 / 3'
				justifySelf='center'
				onClick={() => openGameUrl('recoverPassword')}
			>
				Change Password
			</IdlescapeButton>
		</div>
	);
};
