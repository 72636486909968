import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { animationAtom } from '../../../../atoms/animationAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import { IProgressBarRef, ProgressBar } from '../../../layout/UI/ProgressBar';

export default function CookingProgressBar({
	currentAction = 'cooking',
	theme,
}: {
	currentAction?: 'cooking' | 'alchemy' | 'preparation';
	theme?: 'cooking';
}) {
	const settings = usePlayerField('settings');
	const action = usePlayerField('actionQueue');
	const highPerformance = settings.miscellaneous.highPerformance;

	const active =
		action?.options?.cooking?.type === currentAction ||
		(currentAction === 'preparation' && action?.location === locationsIds.the_preparation_halls);

	const animation = useRecoilValue(animationAtom);
	const progressBarRef = useRef<IProgressBarRef>(null);
	useEffect(() => {
		if (active && animation) {
			progressBarRef.current?.startAnimation(animation.length, animation.startTime);
		}
	}, [animation]);

	useEffect(() => {
		if (!active) {
			progressBarRef.current?.stopAnimation();
		}
	}, [action]);

	return (
		<>
			{!highPerformance && (
				<Box width='100%' height='100%'>
					<ProgressBar ref={progressBarRef} value={0} max={100} theme={theme ?? 'default'} marginY='8px' />
				</Box>
			)}
		</>
	);
}
