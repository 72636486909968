import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useGlobalField, usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import ShrineBucket from './ShrineBucket';
import { IShrineBucket } from '../../../../../game-server/src/modules/shrine/Shrine.interface';
import ShrineBucketPreview from './ShrineBucketPreview';
import ShrineGlobalHighscore from './ShrineGlobalHighscore';
import ShrinePersonalProgress from './ShrinePersonalProgress';
import { IdlescapeButton, IdlescapeFrameBox } from '@idlescape/ui';
import Countdown from 'react-countdown';

export default function Shrine() {
	const shrines = useGlobalField('shrines');
	const league = usePlayerField('league');
	const shrine = shrines[league];
	const leagueData = leagueList[league];

	const [selectedBucket, setSelectedBucket] = useState<IShrineBucket['id'] | undefined>(undefined);
	const selectedBucketData = getBucket();
	const [tab, setTab] = useState<'buckets' | 'highscore'>('buckets');

	if (!leagueData.rules.shrine) {
		const now = new Date();
		const activeShrineLeagues = Object.values(leagueList).filter((leagueData) => {
			if (!leagueData) {
				return false;
			}
			if (leagueData.rules.endDate && new Date(leagueData.rules.endDate) < now) {
				return false;
			}
			return leagueData.rules.shrine && leagueData.active;
		});
		const leagueNames = activeShrineLeagues.map((leagueData) => leagueData.name);
		const leagueString = leagueNames.join(', ');
		return (
			<Box className='enchanting-main'>
				<Box width='100%'>
					<Heading>The Shrine isn&apos;t available in your league</Heading>
					<Heading>
						Active Leagues/Seasons with a shrine:{' '}
						{leagueString.length > 0 ? leagueString : 'Currently none.'}
					</Heading>
				</Box>
			</Box>
		);
	}

	function getTime() {
		// Get current date and time
		const today = new Date();

		// Get number of days to Friday
		const dayNum = today.getDay();
		const daysToFri = 5 - (dayNum <= 5 ? dayNum : dayNum - 7);

		// Set time to Friday, 15:00
		const nextShrine = new Date(today);
		nextShrine.setDate(nextShrine.getDate() + daysToFri);
		nextShrine.setHours(15, 0, 0, 0);

		// Adjust time to account for difference between local timezone and UTC
		const offset = nextShrine.getTimezoneOffset() * 60 * 1000;
		nextShrine.setTime(nextShrine.getTime() - offset);

		// Adjust time if the shrine was earlier today
		if (nextShrine < today) {
			nextShrine.setDate(nextShrine.getDate() + 7);
		}

		return nextShrine;
	}

	function getBucket() {
		if (!shrine || selectedBucket === undefined) return undefined;
		if (shrine.timeBucket.id === selectedBucket) return shrine.timeBucket;
		if (shrine.madnessStrengthBucket?.id === selectedBucket) return shrine.madnessStrengthBucket;
		if (shrine.madnessBuffBucket?.id === selectedBucket) return shrine.madnessBuffBucket;
		return shrine.buffBuckets.find((bucket) => bucket.id === selectedBucket);
	}

	function renderBuckets() {
		if (!shrine) {
			return (
				<Box className='enchanting-main'>
					<Box width='100%'>
						<Heading>The shrine hasn&apos;t started yet. Check back later.</Heading>
					</Box>
				</Box>
			);
		}

		return (
			<Box>
				<Grid gridTemplateColumns='repeat(auto-fill, 300px)' justifyContent='space-between'>
					<ShrineBucketPreview
						bucket={shrine.timeBucket}
						onSelect={() => setSelectedBucket(shrine.timeBucket.id)}
					/>
					{shrine.madnessStrengthBucket && (
						<ShrineBucketPreview
							bucket={shrine.madnessStrengthBucket}
							onSelect={() => setSelectedBucket(shrine.madnessStrengthBucket?.id)}
						/>
					)}
					{shrine.madnessBuffBucket && (
						<ShrineBucketPreview
							bucket={shrine.madnessBuffBucket}
							onSelect={() => setSelectedBucket(shrine.madnessBuffBucket?.id)}
						/>
					)}
					{shrine.buffBuckets.map((bucket) => (
						<ShrineBucketPreview
							key={bucket.id}
							bucket={bucket}
							onSelect={() => setSelectedBucket(bucket.id)}
						/>
					))}
				</Grid>
			</Box>
		);
	}

	return (
		<Box>
			<Flex justifyContent='space-around' alignItems='start' flexWrap='wrap' marginBottom='5px' gap='5px'>
				<Flex justifyContent='center' flexWrap='wrap'>
					<IdlescapeButton
						variant='green'
						size='medium'
						onClick={() => setTab('buckets')}
						leftIcon={
							<Image
								src='/images/misc/shrine_icon.png'
								alt='Highscore'
								className='nav-tab-icon icon-border'
							/>
						}
					>
						Altars
					</IdlescapeButton>
					<IdlescapeButton
						variant='orange'
						size='medium'
						onClick={() => setTab('highscore')}
						leftIcon={
							<Image
								src='/images/ui/hiscore_icon.png'
								alt='Highscore'
								className='nav-tab-icon icon-border'
							/>
						}
					>
						Highscore
					</IdlescapeButton>
				</Flex>
				{/* <Timer time={this.getTime()} countdown={true} class='shrine-timer' /> */}
				<IdlescapeFrameBox textAlign='center' padding='5px 15px' _hover={{}}>
					<Image src='/images/clock.png' alt='Time until next Shrine' height='20px' />
					<Text margin='0'>Next shrine activates in</Text>
					<Text margin='0'>
						<Countdown
							date={getTime()}
							renderer={({ days, hours, minutes, seconds }) => {
								return `${days}d ${hours}h ${minutes}min ${seconds}s`;
							}}
						/>
					</Text>
				</IdlescapeFrameBox>
				<ShrinePersonalProgress />
			</Flex>
			{tab === 'buckets' &&
				(selectedBucketData !== undefined ? (
					<ShrineBucket bucket={selectedBucketData} onBack={() => setSelectedBucket(undefined)} />
				) : (
					renderBuckets()
				))}
			{tab === 'highscore' && <ShrineGlobalHighscore />}
		</Box>
	);
}
