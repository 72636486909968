import React from 'react';
import CombatMonsterArea from './CombatMonsterArea';
import { CombatGroupAvatarArea } from './CombatGroupAvatarArea';
import CombatBar from './CombatBar';
import { CombatGroupInfo } from './CombatGroupInfo';

export const CombatFightGroup = () => {
	return (
		<div className='group-combat-main'>
			<CombatBar></CombatBar>
			<div className='combat-fight-group'>
				<CombatGroupInfo></CombatGroupInfo>
				<CombatGroupAvatarArea></CombatGroupAvatarArea>
				<CombatMonsterArea></CombatMonsterArea>
			</div>
		</div>
	);
};
