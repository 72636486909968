import React, { useEffect, useState } from 'react';
import { itemList } from '../../../utils/itemList';
import CombatSplashesContainer from './CombatSplashesContainer';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { TCosmetics, TEquipmentPartials } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { cloneDeep } from 'lodash';
import { TCosmeticSlots } from '../../../../../game-server/src/modules/player/PlayerSettings';

interface CombatAvatarProps {
	equipment: TEquipmentPartials;
	cosmetics: TCosmetics;
	showSplashes?: boolean;
	className?: string;
	id?: number; // Used to have the combat splash wheel listen to the correct socket events
	dead?: boolean;
}

export const CombatAvatar = ({ equipment, cosmetics, showSplashes, className, id, dead }: CombatAvatarProps) => {
	const [equipmentCopy, setEquipmentCopy] = useState<{ [slot in TCosmeticSlots]?: TPartialItemWithItemID<IItem> }>(
		{}
	);

	useEffect(() => {
		const clonedEquipment: { [slot in TCosmeticSlots]?: TPartialItemWithItemID<IItem> } = cloneDeep(equipment);
		for (const [slot, cosmeticId] of Object.entries(cosmetics)) {
			clonedEquipment[slot as TCosmeticSlots] = { itemID: cosmeticId };
		}
		if (cosmetics['weapon'] && !cosmetics['shield']) {
			const weaponCosmeticData = itemList[cosmetics['weapon']];
			const twoHanded = weaponCosmeticData?.equipmentStats && !weaponCosmeticData.equipmentStats.oneHanded;
			if (twoHanded) {
				delete clonedEquipment['shield'];
			}
		}
		setEquipmentCopy(clonedEquipment);
	}, [equipment, cosmetics]);

	const equipmentKeys = Object.keys(equipmentCopy) as TCosmeticSlots[];

	function getFullTransmog() {
		// Check the equipment to see if there's a fullTransmog item equipped
		const transmogSlot = equipmentKeys.find((slot) => {
			const item = equipmentCopy[slot];
			// Make sure it actually exists
			if (item && item.itemID && itemList[item.itemID]?.equipmentStats) {
				return itemList[item.itemID]?.equipmentStats?.fullTransmog;
			}
		});
		if (transmogSlot && equipmentCopy[transmogSlot]) {
			const transmog = equipmentCopy[transmogSlot];
			// Make sure it exists and has the itemImage and visual properties
			if (transmog && itemList[transmog.itemID]?.itemImage && itemList[transmog.itemID]?.visual) {
				const transmogImage = itemList[transmog.itemID].itemImage;
				const transmogVisual = itemList[transmog.itemID].visual;
				return (
					<img
						src={transmogImage}
						alt={itemList[transmog.itemID].name}
						id='character-base'
						className={transmogVisual ?? ''}
					/>
				);
			}
		}
		return undefined;
	}

	function getModel() {
		if (
			equipmentCopy['base'] &&
			itemList[equipmentCopy['base'].itemID] &&
			itemList[equipmentCopy['base'].itemID].itemImage
		) {
			const baseImage = itemList[equipmentCopy['base'].itemID].itemImage;
			const baseName = itemList[equipmentCopy['base'].itemID].name;
			if (baseImage) {
				return <img src={baseImage} alt={baseName} id='character-base' />;
			}
		}
		return (
			<>
				<img src='/images/combat/character_model_new.png' alt='Character model' id='character-model'></img>
				<img src='/images/combat/character_model_new_hands_2.png' alt='Hands' id='character-hands'></img>
			</>
		);
	}

	function getEquipment() {
		return equipmentKeys.map((slot, index) => {
			const item = equipmentCopy[slot];
			if (!item) return;
			const itemDetails = itemList[Number(item.itemID)];
			if (!itemDetails) return;
			if (!itemDetails.itemImage) return;
			if (itemDetails?.equipmentStats?.cosmeticSlot === 'base') return; // Base slots are handled elsewhere
			if (itemDetails.specialVisuals) {
				// Special visuals for custom layered items
				const imgs = itemDetails.specialVisuals.map((visual, index) => (
					<img key={index} src={visual.image} alt={visual.css} className={'visual ' + visual.css} />
				));
				return <div key={index}>{imgs}</div>;
			}
			if (itemDetails.visual) {
				return (
					<img
						key={index}
						src={itemDetails.itemImage}
						alt={itemDetails.name}
						className={'visual ' + itemDetails.visual}
					/>
				);
			}
		});
	}

	let playerCharacter;
	if (getFullTransmog()) {
		// My harebrained way to handle full transmogs and their weird sizes
		playerCharacter = <>{getFullTransmog()}</>;
	}
	if (!playerCharacter) {
		playerCharacter = (
			<>
				{getModel()}
				{getEquipment()}
			</>
		);
	}
	// If they're dead, make them grayscale and slightly transparent
	const deadStyle = { filter: 'grayscale(85%)', opacity: 0.5 };
	return (
		<div
			className={'combat-fight-image-container combat-fight-image-container-avatar ' + className}
			id={`${id}-avatar`}
			style={dead ? deadStyle : {}}
		>
			{playerCharacter}
			{showSplashes && <CombatSplashesContainer id={id ?? 0} />}
		</div>
	);
};
