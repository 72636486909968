import { Box, Flex, Grid, Input, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PlayerData } from '../../../../../../game-server/src/modules/player/PlayerData';
import { IGeneralShopPurchase } from '../../../../../../game-server/src/repositories/Stats.repository';
import { socket } from '../../../../services/socket.service';
import { itemImage } from '../../../../helper/itemHelperFunctions';
import { itemList } from '../../../../utils/itemList';

const THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30;

export default function GeneralShopHistory(props: { playerId: PlayerData['id'] }) {
	const [shopHistory, setShopHistory] = useState<IGeneralShopPurchase[]>([]);
	const [dateWithin, setDateWithin] = useState<Date>(new Date(Date.now() - THIRTY_DAYS));

	useEffect(() => {
		socket.on('game-shop:history', (data) => {
			setShopHistory(data);
		});
		socket.emit('game-shop:history', { targetId: props.playerId, dateWithin });
		return () => {
			socket.off('game-shop:history');
		};
	}, [props.playerId, dateWithin]);

	return (
		<Box>
			<Flex justifyContent='end' alignItems='center' gap='10px'>
				<Text>Show purchases since: </Text>
				<Input
					type='date'
					value={dateWithin.toISOString().split('T')[0]}
					onChange={(e) => setDateWithin(new Date(e.target.value))}
					maxWidth='150px'
				/>
			</Flex>
			<Box textAlign='center' paddingTop='10px'>
				<Grid gridTemplateColumns='1fr repeat(3, 2fr)'>
					<Box>ITEM</Box>
					<Box>AMOUNT</Box>
					<Box>GOLD SPENT</Box>
					<Box>DATE</Box>
				</Grid>
				{shopHistory.map((entry) => {
					const itemData = itemList[entry.itemID];
					return (
						<Grid
							key={entry.id}
							gridTemplateColumns='1fr repeat(3, 2fr)'
							background='rgb(59, 59, 59)'
							_odd={{ background: 'rgb(73, 73, 73)' }}
							justifyContent='center'
							alignItems='center'
						>
							<Box position='relative' float='left' width='45px' height='45px'>
								{itemImage(itemData)}
							</Box>
							<Box>{entry.amount.toLocaleString('en-us')}</Box>
							<Box>
								{entry.cost.toLocaleString('en-us')}
								<img
									className='marketplace-history-item-price-gold'
									src='images/gold_coin.png'
									alt='Price per Item'
								/>
							</Box>
							<Box>{new Date(entry.date).toLocaleDateString()}</Box>
						</Grid>
					);
				})}
				{shopHistory.length === 0 && <Text>No purchases found</Text>}
			</Box>
		</Box>
	);
}
