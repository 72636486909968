import { IconButton } from '@chakra-ui/react';
import { AiOutlinePushpin } from 'react-icons/ai';
import useIsMobile from '../../hooks/useIsMobile';
import useNavbarPinned from '../../hooks/useNavbarPinned';

export const NavDrawerPin = () => {
	const { navbarPinned, setNavbarPinned } = useNavbarPinned();

	const isMobile = useIsMobile();
	if (isMobile) return null;

	return (
		<IconButton
			variant={'outline'}
			icon={<AiOutlinePushpin />}
			color={'blue.300'}
			colorScheme={'blue'}
			aria-label={'Pin Drawer'}
			isActive={navbarPinned}
			size={'sm'}
			fontSize={'20px'}
			onClick={() => setNavbarPinned(!navbarPinned)}
		></IconButton>
	);
};
