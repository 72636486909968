// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TPlatinumShopItemsList } from '../../../game-server/src/modules/monetization/PlatinumShopData.interface';
import { chatIconsIds } from '../utils/lookup-dictionaries/lookupChatIconList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const platinumShopList: TPlatinumShopItemsList = {
	1: {
		id: 1,
		type: 'item',
		itemID: [itemsIds.frost_maul],
		images: ['/images/premium/cosmetic/equipment/frost_maul.gif'],
		price: 1200,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	2: {
		id: 2,
		type: 'item',
		itemID: [itemsIds.mr_klimp],
		images: ['/images/combat/monsters/chicken.png'],
		price: 3000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	3: {
		id: 3,
		type: 'item',
		itemID: [itemsIds.the_mine_dwelling_hamster],
		images: ['/images/combat/monsters/elite/hamster.png'],
		price: 1000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: new Date('2023-02-01'),
		unique: true,
	},
	4: {
		id: 4,
		type: 'item',
		itemID: [itemsIds.lil_crown_hamperster],
		images: ['/images/combat/monsters/elite/hamster2.png'],
		price: 1000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: new Date('2023-02-01'),
		unique: true,
	},
	5: {
		id: 5,
		type: 'item',
		itemID: [itemsIds.lightning_edge],
		images: ['/images/premium/cosmetic/equipment/lightning_edge.gif'],
		price: 1200,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2023-02-01'),
		unique: true,
		dateRemoved: null,
	},
	6: {
		id: 6,
		type: 'item',
		itemID: [itemsIds.abusive],
		images: ['/images/combat/monsters/elite/hamster3.png'],
		price: 1000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: new Date('2023-02-01'),
		unique: true,
	},
	7: {
		id: 7,
		type: 'item',
		itemID: [itemsIds.elite_titan_helm],
		images: ['/images/premium/cosmetic/equipment/elite_titan_helmet_icon.png'],
		price: 1200,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		unique: true,
		dateRemoved: null,
	},
	8: {
		id: 8,
		type: 'item',
		itemID: [itemsIds.ancient_crown],
		images: ['/images/premium/cosmetic/equipment/ancient_crown_icon.png'],
		price: 1200,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	9: {
		id: 9,
		type: 'item',
		itemID: [itemsIds.the_castled_king],
		images: ['/images/magic/prismatic_gem.png', '/images/combat/monsters/elite/true_info.png'],
		price: 5000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	11: {
		id: 11,
		type: 'item',
		itemID: [itemsIds.character_token],
		images: ['/images/premium/premium_icon.png'],
		price: 800,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	12: {
		id: 12,
		type: 'item',
		itemID: [itemsIds.active_character_token],
		images: ['/images/premium/premium_icon.png'],
		price: 1200,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	13: {
		id: 13,
		type: 'item',
		itemID: [itemsIds.item_name_token],
		images: ['/images/premium/premium_icon.png'],
		price: 300,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	14: {
		id: 14,
		type: 'item',
		itemID: [itemsIds.vacation_token],
		images: ['/images/premium/premium_icon.png'],
		price: 1000,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	15: {
		id: 15,
		type: 'item',
		itemID: [itemsIds.loadout_token],
		images: ['/images/premium/premium_icon.png'],
		price: 600,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	16: {
		id: 16,
		name: 'Holy Avenger Bundle',
		type: 'item',
		itemID: [
			itemsIds.holy_avenger_helm,
			itemsIds.holy_avenger_breastplate,
			itemsIds.holy_avenger_cape,
			itemsIds.holy_avenger_leggings,
			itemsIds.holy_avenger_boots,
			itemsIds.holy_avenger_gloves,
		],
		images: [
			'/images/premium/cosmetic/equipment/holy_avenger_set.png',
			'/images/premium/cosmetic/equipment/holy_avenger_helm.png',
			'/images/premium/cosmetic/equipment/holy_avenger_breastplate.png',
			'/images/premium/cosmetic/equipment/holy_avenger_leggings.png',
			'/images/premium/cosmetic/equipment/holy_avenger_boots_icon.png',
			'/images/premium/cosmetic/equipment/holy_avenger_gloves_icon.png',
		],
		price: 5000,
		discount: 1400,
		description:
			"The vestments of a Holy Avenger, newcomers in this land that have come to kick ass and pray to the Toucan, and they're all out of Toucan! ... Except on Fridays, usually.",
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	17: {
		id: 17,
		type: 'item',
		itemID: [itemsIds.holy_avenger_helm],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_helm.png'],
		price: 1200,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	18: {
		id: 18,
		type: 'item',
		itemID: [itemsIds.holy_avenger_breastplate],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_breastplate.png'],
		price: 1400,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	19: {
		id: 19,
		type: 'item',
		itemID: [itemsIds.holy_avenger_cape],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_cape.png'],
		price: 1000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	20: {
		id: 20,
		type: 'item',
		itemID: [itemsIds.holy_avenger_leggings],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_leggings.png'],
		price: 1200,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	21: {
		id: 21,
		type: 'item',
		itemID: [itemsIds.holy_avenger_boots],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_boots_icon.png'],
		price: 800,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	22: {
		id: 22,
		type: 'item',
		itemID: [itemsIds.holy_avenger_gloves],
		images: ['/images/premium/cosmetic/equipment/holy_avenger_gloves_icon.png'],
		price: 800,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	23: {
		id: 23,
		type: 'item',
		itemID: [itemsIds.a_mad_faiths_wrath],
		images: ['/images/premium/cosmetic/equipment/wrath.png'],
		price: 4000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	24: {
		id: 24,
		type: 'item',
		itemID: [itemsIds.username_change_token],
		images: ['/images/premium/premium_icon.png'],
		price: 1000,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	25: {
		id: 25,
		type: 'item',
		itemID: [itemsIds.forgotten_platebody],
		images: ['/images/premium/cosmetic/equipment/ghost_body.png'],
		price: 1400,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-09-05'),
		dateRemoved: null,
		unique: true,
	},
	26: {
		id: 26,
		type: 'item',
		itemID: [itemsIds.shapeless_bow],
		images: ['/images/premium/cosmetic/equipment/shapeless_bow.png'],
		price: 2000,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	27: {
		id: 27,
		type: 'item',
		itemID: [itemsIds.shapeless_staff],
		images: ['/images/premium/cosmetic/equipment/shapeless_staff.png'],
		price: 2000,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: true,
	},
	28: {
		id: 28,
		type: 'item',
		itemID: [itemsIds.gift_of_the_lightning_king],
		images: ['/images/premium/cosmetic/equipment/Gift_of_the_Lightning_King_Icon.png'],
		price: 3500,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-06-19'),
		dateRemoved: null,
		unique: true,
	},
	29: {
		id: 29,
		type: 'item',
		itemID: [itemsIds.fresh_off_the_island],
		images: ['/images/combat/character_model_beard.png'],
		price: 800,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-06-19'),
		dateRemoved: null,
		unique: true,
	},
	30: {
		id: 30,
		type: 'item',
		itemID: [itemsIds.inventory_tab_token],
		images: ['/images/premium/premium_icon.png'],
		price: 600,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-02-01'),
		dateRemoved: null,
		unique: false,
	},
	31: {
		id: 31,
		type: 'icon',
		iconID: [chatIconsIds.elite_scroll_enthusiast],
		images: ['/images/misc/Elite_Scroll.png'],
		description:
			'Show off your enthusiasm for all things scrolls: reading them, burning them, selling them, beating up their owners, etc!',
		price: 1000,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	32: {
		id: 32,
		type: 'icon',
		iconID: [chatIconsIds.combat_chad],
		images: ['/images/combat/combat_level.png'],
		description:
			'FITE. THERE IS ONLY FITE. FITE IS LIFE. FITE IS DEATH. FITE IS ALL. FITE IS EVERYTHING. FITE IS YOU. FITE IS ME. FITE IS US. FITE! FITE! FITE!',
		price: 1000,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	33: {
		id: 33,
		type: 'icon',
		iconID: [chatIconsIds.burnt_food_connoisseur],
		images: ['/images/cooking/burnt_food.png'],
		description:
			"Please tell me you aren't trying to collect burnt food, it won't be worth trillions of gold in a few years, I promise you.",
		price: 300,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	34: {
		id: 34,
		type: 'icon',
		iconID: [chatIconsIds.marine_geologist],
		images: ['/images/fishing/material/aquamarine.png'],
		description: "Why would anyone care about the biology in the sea? It's all about that marine geology, baby!",
		price: 1200,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	35: {
		id: 35,
		type: 'icon',
		iconID: [chatIconsIds.promise_of_the_prismatic],
		images: ['/images/magic/pris_dust.png'],
		description: 'One day...',
		price: 800,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	36: {
		id: 36,
		type: 'icon',
		iconID: [chatIconsIds.le_toucan_has_arrived],
		images: ['/images/misc/shrine_icon.png'],
		description: 'Pay your respects to the ancient Toucan shrine so that it may bless you weekly!',
		price: 2000,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	37: {
		id: 37,
		type: 'icon',
		iconID: [chatIconsIds.to_the_moon],
		images: ['/images/mining/stone.png'],
		description: "Castles? Who needs them?! We're going to the Moon!",
		price: 600,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-08-28'),
		dateRemoved: null,
		unique: true,
	},
	38: {
		id: 38,
		type: 'item',
		itemID: [itemsIds.user_action_queue_count_token],
		images: ['/images/premium/premium_icon.png'],
		price: 1500,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2023-09-14'),
		dateRemoved: null,
		unique: false,
	},
	39: {
		id: 39,
		type: 'item',
		itemID: [itemsIds.user_action_queue_count_token_formerly_length_token],
		images: ['/images/premium/premium_icon.png'],
		price: 1000,
		isFeatured: false,
		categories: ['tokens'],
		dateAdded: new Date('2023-09-14'),
		dateRemoved: new Date('2024-07-10'),
		unique: false,
	},
	40: {
		id: 40,
		type: 'item',
		itemID: [itemsIds.night_walker],
		images: ['/images/premium/cosmetic/equipment/Night_walker_base.png'],
		price: 1400,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-10-24'),
		dateRemoved: null,
		unique: true,
	},
	41: {
		id: 41,
		type: 'item',
		itemID: [itemsIds.celestial],
		images: ['/images/premium/cosmetic/equipment/celestial_icon.png'],
		price: 4000,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-10-24'),
		dateRemoved: null,
		unique: true,
	},
	42: {
		id: 42,
		type: 'item',
		itemID: [itemsIds.forgotten_platelegs],
		images: ['/images/premium/cosmetic/equipment/ghost_legs.png'],
		price: 1200,
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-12-05'),
		dateRemoved: null,
		unique: true,
	},
	43: {
		id: 43,
		type: 'item',
		itemID: [itemsIds.eyes_of_chaos],
		images: ['/images/premium/cosmetic/equipment/Eyes_of_chaos.png'],
		price: 1400,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2023-12-05'),
		dateRemoved: null,
		unique: true,
	},
	44: {
		id: 44,
		name: 'Wise Hats',
		type: 'item',
		itemID: [itemsIds.wise_hat, itemsIds.tiny_wise_hat, itemsIds.big_wise_hat],
		images: ['/images/chaticons/chat-icon-dev.png'],
		price: 2000,
		description: 'A bunch of wise hats! Comes in three sizes: tiny, medium, and large.',
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2023-12-27'),
		dateRemoved: null,
		unique: true,
	},
	45: {
		id: 45,
		type: 'icon',
		iconID: [chatIconsIds.court_jester],
		images: ['/images/chaticons/jester.png'],
		description: 'Why be the class clown when you can be the Court Jester?',
		price: 600,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2023-12-27'),
		dateRemoved: null,
		unique: true,
	},
	46: {
		id: 46,
		name: "Master Chef's Armory",
		type: 'item',
		itemID: [
			itemsIds.librarians_chef_hat,
			itemsIds.librarians_chef_coat,
			itemsIds.librarians_chef_pants,
			itemsIds.master_chefs_ladle,
			itemsIds.master_chefs_greater_ladle,
			itemsIds.master_chefs_lesser_ladle,
			itemsIds.master_chefs_golden_spoon,
		],
		images: [
			'/images/cooking/equipment/cooking_hat.png',
			'/images/cooking/equipment/cooking_body.png',
			'/images/cooking/equipment/cooking_legs.png',
			'/images/combat/equipment/lesser_ladle.png',
			'/images/combat/equipment/ladle.png',
			'/images/combat/equipment/greater_ladle.png',
			'/images/cooking/golden_spoon.png',
		],
		price: 3500,
		description: 'Become the true Master Chef and save the kitchen from the evil of burnt food and bad manners!',
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2024-02-29'),
		dateRemoved: null,
		unique: true,
	},
	47: {
		id: 47,
		type: 'icon',
		iconID: [chatIconsIds.ancient_fisherman],
		images: ['/images/chaticons/aquamarine_original.png'],
		description:
			'A long forgotten gem, resurfaced from the deepest depths of the ocean. A precursor of things to come and remembrance of things that have been.',
		price: 1400,
		isFeatured: true,
		categories: ['icons'],
		dateAdded: new Date('2024-04-24'),
		dateRemoved: null,
		unique: true,
	},
	48: {
		id: 48,
		name: 'Lich Bundle',
		type: 'item',
		itemID: [
			itemsIds.lichs_visage,
			itemsIds.lichs_robe_top,
			itemsIds.lichs_robe_bottoms,
			itemsIds.lichs_gloves,
			itemsIds.lichs_boots,
		],
		images: [
			'/images/premium/cosmetic/equipment/lich_set.png',
			'/images/premium/cosmetic/equipment/C_Necro_head.png',
			'/images/premium/cosmetic/equipment/C_Necro_top.png',
			'/images/premium/cosmetic/equipment/C_Necro_legs.png',
			'/images/premium/cosmetic/equipment/C_Necro_gloves_icon.png',
			'/images/premium/cosmetic/equipment/C_Necro_boots_icon.png',
		],
		price: 5000,
		discount: 1400,
		description: 'The attire of a Lich, an enemy that has kept its presence a secret for centuries, until now.',
		isFeatured: true,
		categories: ['armor'],
		dateAdded: new Date('2024-05-06'),
		dateRemoved: null,
		unique: true,
	},
	49: {
		id: 49,
		type: 'item',
		itemID: [itemsIds.talent_respec_token],
		images: ['/images/premium/premium_icon.png'],
		price: 500,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2024-05-22'),
		dateRemoved: null,
		unique: false,
	},
	50: {
		id: 50,
		type: 'item',
		itemID: [itemsIds.marshall_the_rats_gun],
		images: ['/images/premium/cosmetic/equipment/marshall_gun.png'],
		price: 400,
		isFeatured: true,
		categories: ['weapons'],
		dateAdded: new Date('2024-13-08'),
		dateRemoved: null,
		unique: true,
	},
	51: {
		id: 51,
		type: 'item',
		itemID: [itemsIds.profile_slot_token],
		images: ['/images/premium/premium_icon.png'],
		price: 600,
		isFeatured: true,
		categories: ['tokens'],
		dateAdded: new Date('2024-08-17'),
		dateRemoved: null,
		unique: false,
	},
};
