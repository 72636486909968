import { BoxProps, Grid } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { usePlayerField } from '../../../../hooks/hooks';
import { itemList } from '../../../../utils/itemList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import CombatAbility from './CombatAbility';

function AbilityList({
	abilities,
	learnedAbilities,
	onClick,
	dropAreaID,
	...props
}: {
	abilities: IAbilityData[];
	learnedAbilities?: number[];
	onClick?: (abilityID: IAbilityData['id']) => void;
	dropAreaID?: string;
} & BoxProps) {
	const { setNodeRef } = useDroppable({
		id: dropAreaID || 'ability-list',
		data: { abilities, learnedAbilities },
	});
	const ammoPouch = usePlayerField('ammo');

	return (
		<Grid gridTemplateColumns='repeat(auto-fit, 80px)' ref={dropAreaID ? setNodeRef : null} {...props}>
			{abilities.map((ability: IAbilityData) => {
				//Check if the relevant ammo is in pouch
				let needAmmo = 0;
				if (ability.runeCost) {
					for (const cost of ability.runeCost) {
						if (needAmmo) break;
						needAmmo = cost.itemID;
						for (const ammo of ammoPouch) {
							if (ammo.itemID === cost.itemID && ammo.stackSize >= cost.amount) {
								needAmmo = 0;
								break;
							}
						}
					}
				}
				if (ability.useRangedAmmo) {
					needAmmo = itemsIds.shoddy_ammo;
					for (const ammo of ammoPouch) {
						const ammoData = itemList[ammo.itemID];
						if (ammoData.ammunitionMults?.style === 'Range') {
							needAmmo = 0;
							break;
						}
					}
				}
				const learned = learnedAbilities ? learnedAbilities.includes(ability.id) : true;
				return (
					<CombatAbility
						key={ability.id}
						ability={ability}
						learned={learned}
						needAmmo={needAmmo}
						onClick={() => onClick?.(ability.id)}
					/>
				);
			})}
		</Grid>
	);
}

export default AbilityList;
