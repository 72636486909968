import { getImage } from '@idlescape/ui';
import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys);
const modalTheme = defineMultiStyleConfig({
	baseStyle: {
		dialog: {
			backgroundColor: 'transparent',
			borderImageSlice: '100 fill',
			borderImageWidth: '100px',
			borderImageOutset: '10px',
			borderImageRepeat: 'round',
			padding: '20px',
			margin: 'auto',
		},
		closeButton: {
			backgroundImage: getImage('ButtonRed'),
			backgroundSize: '100% 100%',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			border: 'none',
			height: '40px',
			width: '30px',
			cursor: 'pointer',
			_hover: { transform: 'scale(1.05)', filter: 'brightness(1.3)' },
			_active: { transform: 'scale(0.95)', filter: 'brightness(0.9)' },
		},
	},
	variants: {
		primary: {
			dialog: {
				borderImageSource: `url(${getImage('BorderImage')})`,
			},
		},
		platinum: {
			dialog: {
				borderImageSource: `url(${getImage('BorderImagePlatinum')})`,
			},
		},
		dark: {
			dialog: {
				borderImageSource: `url(${getImage('BorderImageDark')})`,
			},
		},
		simple: {
			dialog: {
				width: 'auto',
				padding: '0',
				margin: '60px 0 0 0',
			},
			closeButton: {
				zIndex: '10000',
				top: '-32px',
				right: '3px',
			},
		},
		modern_simple: {
			dialog: {
				width: 'auto',
				padding: '0',
				margin: '60px 0 0 0',
			},
			closeButton: {
				zIndex: '10000',
				backgroundImage: 'initial',
				backgroundColor: '#DD0000',
				border: '3px solid #cfcfcf;',
				top: '-33px',
				right: '0',
				height: '35px',
				width: '35px',
				fontSize: '20px',
			},
		},
	},
	defaultProps: {
		variant: 'primary',
	},
});

export default modalTheme;
