import React from 'react';
import './PlayArea.css';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tabs } from '../../helper/NavigationTabsList';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { playAreaAtom } from '../../atoms/playAreaAtom';
import { ErrorBoundary } from './ErrorBoundary';
import { PlayAreaBackground } from './PlayAreaBackground';

export const PlayArea = () => {
	const activeTab = useRecoilValue(activeTabAtom);
	const tabInfo = tabs[activeTab];
	const setPlayAreaRef = useSetRecoilState(playAreaAtom);

	return (
		<div className={`play-area-wrapper anchor-${tabInfo.theme}`}>
			<PlayAreaBackground />
			<div className='play-area' ref={(ref) => setPlayAreaRef(ref)}>
				<ErrorBoundary>{tabInfo.component}</ErrorBoundary>
			</div>
		</div>
	);
};
