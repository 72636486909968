import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { socket } from '../../../services/socket.service';
import { IdlescapeButton } from '@idlescape/ui';

export default function Popup() {
	const [popupMessage, setPopupMessage] = useState('');
	const [closeOnOverlay, setCloseOnOverlay] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		socket.on('popup', (data) => {
			setPopupMessage(DOMPurify.sanitize(data.message));
			setCloseOnOverlay(data.closeOnOverlay);
			onOpen();
		});
		return () => {
			socket.off('popup');
		};
	}, []);

	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={closeOnOverlay}>
			<ModalOverlay />
			<ModalContent textAlign='center' maxWidth='600px' padding='clamp(20px, 4%, 40px)'>
				<ModalHeader padding='1rem 0 3px'>
					<Text
						borderBottom='2px solid rgb(94, 94, 94);'
						borderRadius='10px'
						display='inline'
						padding='0 24px 0 24px'
					>
						Notification
					</Text>
				</ModalHeader>
				<ModalBody dangerouslySetInnerHTML={{ __html: popupMessage }} />
				<ModalFooter className='item-dialogue-button-div' paddingBottom='0'>
					<IdlescapeButton variant='red' onClick={onClose} size='medium'>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
