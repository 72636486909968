import {
	TMagicalDamageTypes,
	TMainDamageTypes,
	TPhysicalDamageTypes,
} from '../../../../../../game-server/src/modules/combat/DamageTypes';

const MainDamageTypes: TMainDamageTypes[] = ['Melee', 'Magic', 'Range'];
const PhysicalDamageTypes: TPhysicalDamageTypes[] = ['Piercing', 'Blunt', 'Slashing'];
const MagicalDamageTypes: TMagicalDamageTypes[] = ['Chaos', 'Nature', 'Fire', 'Ice', 'Lightning'];
const AllStandardDamageTypes = [...MainDamageTypes, ...PhysicalDamageTypes, ...MagicalDamageTypes];

const OtherDamageTypes: string[] = ['Poison', 'Typeless', 'Heal'];
const AllDamageTypes = [...AllStandardDamageTypes, ...OtherDamageTypes];

export function generateAffinityDictionary(
	prefix: 'offensiveDamageAffinity' | 'offensiveAccuracyAffinityRating' | 'defensiveDamageAffinity',
	description: string,
	uppercase = false
): Record<string, string> {
	const affinities: Record<string, string> = {};
	for (const damageType of AllDamageTypes) {
		affinities[`${prefix}.${damageType}`] = `${uppercase ? damageType.toUpperCase() : damageType} ${description}`;
	}
	return affinities;
}
