import React from 'react';
import { locations } from '../../../../utils/locationList';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import GatheringResource from '../GatheringResource';
import './Foraging.css';
import { ForagingInfo } from './ForagingInfo';

export default function Foraging() {
	const locs = [
		locations[locationsIds.grasslands],
		locations[locationsIds.verdant_valley],
		locations[locationsIds.fungal_grotto],
		locations[locationsIds.the_tangle],
		locations[locationsIds.misty_marsh],
		locations[locationsIds.frozen_tundra],
		locations[locationsIds.haunted_woods],
		locations[locationsIds.living_forest],
	];
	return (
		<div className='resource-list foraging-container'>
			<ForagingInfo></ForagingInfo>
			{locs.map((loc) => {
				return <GatheringResource location={loc} key={loc.locID} />;
			})}
		</div>
	);
}
