import { BaseTooltip } from './BaseTooltip';
import { TTooltipStats } from '../Data/TooltipData';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { locations } from '../../../../utils/locationList';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { getLocationTreasureHunter, getMonsterTreasureHunter } from '../../../../utils/itemFunctions';

export class DungeonKeyTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Dungeon Key';
	}

	protected getStats(
		item: IItem,
		itemResource: IItemData
	): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		const dungeonKeyStats: Record<string, number> = {};

		dungeonKeyStats['elite.monsterDifficulty'] = item.augmentations ?? 0;

		if (itemResource.champEncounter) {
			const location: ILocationData = locations[itemResource.champEncounter];
			dungeonKeyStats['elite.groupSize'] = location?.accessRequirements?.maxGroupSize ?? 1;
		}

		if (item.augmentations && item.augmentations > 0) {
			dungeonKeyStats['elite.monsterTH'] = getMonsterTreasureHunter(item);
			dungeonKeyStats['elite.locationTH'] = getLocationTreasureHunter(item);
		}
		return { dungeonKeyStats: dungeonKeyStats };
	}
}
