import { Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IdlescapeContainer } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { IShrineBucket } from '../../../../../game-server/src/modules/shrine/Shrine.interface';
import { IShrineHighscore } from '../../../../../game-server/src/repositories/ShrineDonations.repository';
import { socket } from '../../../services/socket.service';

export default function ShrineBucketHighscore(props: { bucketId: IShrineBucket['id'] }) {
	const [highscore, setHighscore] = useState<IShrineHighscore[]>([]);
	const [totalBucketValue, setTotalBucketValue] = useState(0);

	useEffect(() => {
		socket.on('shrine:highscore:bucket', (data) => {
			setHighscore(data.weeklyBucketHiscore);
			setTotalBucketValue(data.totalBucketValue);
		});
		socket.emit('shrine:highscore:bucket', props.bucketId);

		return () => {
			socket.off('shrine:highscore:bucket');
		};
	}, []);

	if (highscore.length === 0) {
		return null;
	}

	return (
		<IdlescapeContainer gridColumn='1 / -1'>
			<Heading textAlign='center' size='lg'>
				Weekly Altar Highscore
			</Heading>
			<TableContainer>
				<Table
					color='white'
					border='2px solid rgba(102, 102, 102)'
					background='rgba(0, 0, 0, 0.5)'
					width='100%'
				>
					<Thead borderBottom='2px solid rgba(192, 192, 192, 0.2)'>
						<Tr>
							<Th fontSize='22px'>Rank</Th>
							<Th fontSize='22px'>Player</Th>
							<Th fontSize='22px'>Donations</Th>
						</Tr>
					</Thead>
					<Tbody>
						{highscore.map((entry, index) => (
							<Tr key={entry.username}>
								<Td>{index + 1}</Td>
								<Td>{entry.username}</Td>
								<Td>{entry.amount.toLocaleString('en-us')}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
			{/* Maybe move this somewhere else */}
			{/* {totalBucketValue} */}
		</IdlescapeContainer>
	);
}
