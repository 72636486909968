// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
export enum ActionTypes {
	Broken = 'Action-Broken',
	Expedition = 'Action-Expedition',
	Mining = 'Action-Mining',
	Foraging = 'Action-Foraging',
	Fishing = 'Action-Fishing',
	Combat = 'Action-Combat',
	Smithing = 'Action-Smithing',
	Enchanting = 'Action-Enchanting',
	Cooking = 'Action-Cooking',
	Runecrafting = 'Action-Runecrafting',
}
