import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { IMarketTransaction } from '../../../../../game-server/src/repositories/MarketTransaction.repository';
import HistoryTable from './HistoryTable';
import { MARKET_HISTORY_COOLDOWN } from '../../../utils/constantsCollection';
import { useRecoilState } from 'recoil';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';

function MarketplaceHistory(props: { button: { search: (filter: string) => void } }) {
	const [page, setPage] = useState<number>(1);
	const [totalListings, setTotalListings] = useState<number>(-1);
	const [marketFilter, setMarketFilter] = useRecoilState(marketplaceFilterAtom);
	const [history, setHistory] = useState<IMarketTransaction[]>([]);
	const [disabled, setDisabled] = useState(false);
	props.button.search = handleFilter;

	useEffect(() => {
		socket.emit('marketplace:transaction:player:count', marketFilter);
		socket.on('marketplace:transaction:player:count', (count) => {
			setTotalListings(count);
		});

		socket.emit('marketplace:transaction:player:history', { page: 0, filter: marketFilter });
		socket.on('marketplace:transaction:player:history', (data) => {
			setHistory(data);
		});

		return () => {
			socket.off('marketplace:transaction:player:count');
			socket.off('marketplace:transaction:player:history');
		};
	}, []);

	function handleSetPage(pageNumber: number) {
		if (disabled) return;
		socket.emit('marketplace:transaction:player:count', marketFilter);
		socket.emit('marketplace:transaction:player:history', { page: pageNumber - 1, filter: marketFilter });
		setPage(pageNumber);
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, MARKET_HISTORY_COOLDOWN);
	}

	function handleFilter(filter: string) {
		if (disabled) return;
		socket.emit('marketplace:transaction:player:count', filter);
		socket.emit('marketplace:transaction:player:history', { page: 0, filter });
		setMarketFilter(filter);
		setPage(1);
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, MARKET_HISTORY_COOLDOWN);
	}

	return (
		<HistoryTable
			page={page}
			totalListings={totalListings}
			history={history}
			setPage={handleSetPage}
			setFilter={handleFilter}
		/>
	);
}

export default MarketplaceHistory;
