import React, { useEffect, useState } from 'react';
import { socket } from '../../services/socket.service';
import { leagueList } from '../../utils/leagueList';
import { TSkillName } from '../../../../game-server/src/Atypes/Skills';
import { IdlescapeContainer, IdlescapeSelect } from '@idlescape/ui';
import { Box, Grid, Heading, Image, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text } from '@chakra-ui/react';
import {
	IHiscoreData,
	IHiscoreDataWithRank,
} from '../../../../game-server/src/repositories/UserLevelAndExperience.repository';
import { usePlayerField } from '../../hooks/hooks';
import { useSetRecoilState } from 'recoil';
import { profileEditorOpenAtom } from '../../atoms/profileEditorOpenAtom';
import { stringCapitalize } from '../../helper/StringFormatter';

export default function Hiscore() {
	const username = usePlayerField('username');
	const defaultLeague = usePlayerField('league');
	const [skillToShow, setSkillToShow] = useState<TSkillName>('total');
	const [hiscoreData, setHiscoreData] = useState<IHiscoreData[]>([]);
	const [personalData, setPersonalData] = useState<IHiscoreDataWithRank[]>([]);
	const [hiscoreMode, setHiscoreMode] = useState<'normal' | 'mastery'>('normal');
	const [league, setLeague] = useState(defaultLeague);
	const playerIsInSeason = leagueList[league].rules.endDate ? true : false;
	const [category, setCategory] = useState<string>(playerIsInSeason ? 'Season' : 'League');
	const setProfileEditorOpen = useSetRecoilState(profileEditorOpenAtom);

	useEffect(() => {
		setHiscoreData([]);
		setPersonalData([]);
		socket.emit('hiscore:get', {
			skill: skillToShow,
			mode: hiscoreMode,
			league: league,
		});
		socket.on('hiscore:send', (data) => {
			if (data.skill !== skillToShow || data.mode !== hiscoreMode || data.league !== league) return;
			setHiscoreData(data.players);
		});

		socket.on('hiscore:local', (data) => {
			if (data.skill !== skillToShow || data.mode !== hiscoreMode || data.league !== league) return;
			setPersonalData(data.players);
		});

		return () => {
			socket.off('hiscore:send');
			socket.off('hiscore:local');
		};
	}, [skillToShow, hiscoreMode, league]);

	const skills: { name: TSkillName; icon: string }[] = [
		{ name: 'total', icon: '/images/total_level.png' },
		{ name: 'mining', icon: '/images/mining/iron_pickaxe.svg' },
		{ name: 'foraging', icon: '/images/foraging/foraging_icon.png' },
		{ name: 'fishing', icon: '/images/fishing/fishing_logo.png' },
		{ name: 'smithing', icon: '/images/smithing/smithing_icon.png' },
		{ name: 'crafting', icon: '/images/ui/crafting_icon.png' },
		{ name: 'cooking', icon: '/images/cooking/cooking_icon.png' },
		{ name: 'constitution', icon: '/images/combat/constitution_icon.png' },
		{ name: 'attack', icon: '/images/combat/attack_icon.png' },
		{ name: 'strength', icon: '/images/combat/strength_icon.png' },
		{ name: 'defense', icon: '/images/combat/defense_icon.png' },
		{ name: 'runecrafting', icon: '/images/runecrafting/RuneCraftingIcon.png' },
		{ name: 'enchanting', icon: '/images/enchanting/enchanting_logo.png' },
		{ name: 'farming', icon: '/images/farming/farming_icon.png' },
		{ name: 'magic', icon: '/images/magic/magic_logo.png' },
		{ name: 'range', icon: '/images/combat/range_icon.png' },
	];
	const medals = ['gold', 'silver', 'bronze'];
	const colors = ['gold', 'silver', 'rgb(205, 127, 50)'];

	function inspect(inspectName: string) {
		if (username === inspectName) {
			setProfileEditorOpen(true);
			return;
		}
		const command = '/whois ' + inspectName;

		const commandToSend = {
			channelId: 1,
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	}

	function renderHiscoreRow(player: IHiscoreData, rank: number, styles = {}) {
		const medal = medals[rank - 1] ?? '';
		const color = colors[rank - 1] ?? 'white';
		return (
			<Tr
				key={rank}
				background={player.username === username ? 'rgba(92, 92, 92, 0.5)' : ''}
				color={color}
				{...styles}
			>
				<Td textAlign='center' fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{rank <= 3 && (
						<Image
							src={`/images/ui/highscore_${medal}.png`}
							alt={medal + ' medal'}
							width='30px'
							height='30px'
							marginRight='6px'
							verticalAlign='middle'
							borderStyle='none'
						/>
					)}
					{rank}
				</Td>
				<Td
					fontSize='16px'
					padding='9px'
					textOverflow='ellipsis'
					overflow='hidden'
					cursor='pointer'
					_hover={{ textDecoration: 'underline' }}
					onClick={() => inspect(player.username)}
				>
					{player.username}
				</Td>
				<Td fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{player.experience.toLocaleString('en-us')}
				</Td>
				<Td fontSize='16px' padding='9px' textOverflow='ellipsis' overflow='hidden'>
					{player.level || 0}
				</Td>
			</Tr>
		);
	}

	const selectedModeLeagueStyle = {
		backgroundColor: 'rgba(195, 204, 255, 0.4)',
		boxShadow: '0 0 5px 5px rgba(195, 204, 255, 0.4)',
	};

	const settingsOptionsStyle = {
		gridTemplateColumns: '50px 50px',
		gridTemplateRows: '50px',
		columnGap: '20px',
		justifyItems: 'center',
		alignItems: 'center',
		marginBottom: '10px',
	};

	function renderButtons(seasonal: boolean) {
		const ret = [];
		ret.push(
			Object.values(leagueList).map((l) => {
				// Right now GIM and Pre-Season are not set to active, unsure if we want to hide them or not
				// if (!l.active) {
				// 	return null;
				// }
				if (seasonal && l.rules.endDate) {
					return (
						<Box onClick={() => setLeague(l.id)} key={l.id}>
							<Image
								src={l.icon}
								{...(league === l.id ? selectedModeLeagueStyle : null)}
								alt={l.name}
								width='100%'
								height='100%'
							/>
						</Box>
					);
				} else if (!seasonal && !l.rules.endDate) {
					return (
						<Box onClick={() => setLeague(l.id)} key={l.id}>
							<Image
								src={l.icon}
								{...(league === l.id ? selectedModeLeagueStyle : null)}
								alt={l.name}
								width='100%'
								height='100%'
							/>
						</Box>
					);
				} else {
					return null;
				}
			})
		);
		return ret;
	}

	const options: { [key: string]: any } = {
		League: {
			id: 'Leagues',
			label: 'Leagues',
		},
		Season: {
			id: 'Seasons',
			label: 'Seasons',
		},
	};

	return (
		<Box height='100%' overflowY='scroll'>
			<Grid gridTemplateColumns='1fr 1fr' justifyItems='center'>
				<Heading as='h3' marginY='5px'>
					<IdlescapeSelect variant='primary' options={options} selected={category} select={setCategory} />
				</Heading>
				<Heading as='h3' marginY='5px'>
					Mode
				</Heading>
				<Grid {...settingsOptionsStyle}>{renderButtons(category !== 'League')}</Grid>
				<Grid {...settingsOptionsStyle}>
					<Box onClick={() => setHiscoreMode('normal')}>
						<Image
							src='/images/total_level.png'
							{...(hiscoreMode === 'normal' ? selectedModeLeagueStyle : null)}
							alt='Total Level'
							width='100%'
							height='100%'
						/>
					</Box>
					<Box onClick={() => setHiscoreMode('mastery')}>
						<Image
							src='/images/total_level_mastery_icon.png'
							{...(hiscoreMode === 'mastery' ? selectedModeLeagueStyle : null)}
							alt='Total Mastery Level'
							width='100%'
							height='100%'
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid gridTemplateColumns='repeat(auto-fit, 50px)' justifyContent='center' gap='3px'>
				{skills.map((skill, index) => {
					return (
						<Box
							key={index}
							onClick={() => setSkillToShow(skill.name)}
							background={
								skillToShow === skill.name
									? 'linear-gradient(to bottom, #0f2027, #203a43, #2c5364)'
									: 'linear-gradient(to bottom, #485563, #29323c)'
							}
							border='2px solid rgba(192, 192, 192, 0.2)'
							borderRadius='10px'
							cursor='pointer'
						>
							<Image
								src={skill.icon}
								alt={skill.name}
								height='100%'
								width='85%'
								objectFit='contain'
								marginLeft='3px'
							/>
						</Box>
					);
				})}
			</Grid>
			<Text as='h2' textAlign='center' margin='5px'>
				{leagueList[league].name}: {stringCapitalize(skillToShow)} {stringCapitalize(hiscoreMode)}
			</Text>
			<IdlescapeContainer>
				<TableContainer>
					<Table
						color='white'
						border='2px solid rgba(102, 102, 102)'
						background='rgba(0, 0, 0, 0.5)'
						width='100%'
					>
						<Thead borderBottom='2px solid rgba(192, 192, 192, 0.2)'>
							<Tr>
								<Th width='75px' fontSize='22px' padding='8px 10px'>
									Rank
								</Th>
								<Th width='40%' fontSize='22px' padding='8px 10px'>
									Player
								</Th>
								<Th fontSize='22px' padding='8px 10px'>
									Experience
								</Th>
								<Th fontSize='22px' padding='8px 10px'>
									Level
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{hiscoreData.map((player, index) => {
								return renderHiscoreRow(player, index + 1);
							})}
							<Tr border='2px solid rgba(102, 102, 102)'></Tr>
							{personalData.map((player) => {
								if (player.rank <= 20) return;
								return renderHiscoreRow(player, player.rank);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</IdlescapeContainer>
			<Text textAlign='center'>Click on a players name to open their profile</Text>
		</Box>
	);
}
