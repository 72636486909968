import React, { useState } from 'react';
import './CombatBar.css';
import { socket } from '../../../services/socket.service';
import CombatPlayerStats from './CombatPlayerStats';
import CombatBarGear from './CombatBarComponents/CombatBarGear';
import CombatGearInventory from './CombatGearInventory';
import _ from 'lodash';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import {
	Box,
	Grid,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { CombatInstanceStats } from './CombatInstanceStats';

const fleeTexts = [
	'Flee Combat',
	'Run Away',
	'Get Me Outta Here!',
	'I want my mommy!',
	'I just wanna refill my food!',
	'Forgot my lunch, gotta go!',
	'Bwak bwak bwak!',
	'Chicken!',
];
const fleeImages = [
	'/images/combat/monsters/chicken.png',
	'/images/combat/monsters/elite/chicken_elite.png',
	'/images/combat/monsters/elite/flame_chicken_1.png',
	'/images/combat/monsters/elite/flame_chicken_2.png',
	'/images/combat/monsters/elite/flame_chicken_3.png',
	'/images/combat/monsters/elite/flame_chicken_4.png',
];
function CombatBar() {
	const [render, setRender] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [fleeImage, setFleeImage] = useState('/images/combat/monsters/chicken.png');
	const [fleeText, setFleeText] = useState('Flee Combat');
	const dungeonRequeue = usePlayerField('settings').combat.dungeonRequeue;
	const subscription = usePlayerField('subscription');

	function handleOpenModal() {
		if (Math.random() <= 0.01) {
			setFleeText(_.sample(fleeTexts) as string);
		} else {
			setFleeText(fleeTexts[0]);
		}
		if (Math.random() <= 0.001) {
			setFleeImage(_.sample(fleeImages) as string);
		} else {
			setFleeImage(fleeImages[0]);
		}
		onOpen();
	}

	function setActiveContent(content: string) {
		if (content === render) {
			content = '';
		}
		setRender(content);
	}

	function stopDungeonRequeue() {
		socket.emit('settings:update', {
			value: false,
			path: 'combat.dungeonRequeue',
		});
		onClose();
	}

	function runAwayFromCombat() {
		socket.emit('combat:end');
		handleOpenModal();
	}

	function renderContent() {
		if (render === 'inventory') return <CombatGearInventory />;
		return (
			<IdlescapeContainer type='thin' className='combat-bar-content'>
				{render === 'stats' && <CombatPlayerStats />}
				{render === 'equipment' && <CombatBarGear />}
				{render === 'instance-stats' && <CombatInstanceStats />}
			</IdlescapeContainer>
		);
	}

	function subscribed() {
		return subscription.active;
	}

	return (
		<div className='combat-bar-container'>
			<Modal isOpen={isOpen} onClose={onClose} size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Flee Combat</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Are you sure you want to run away?
						<Image src={fleeImage} alt='flee' />
					</ModalBody>
					<ModalFooter flexDirection='column'>
						<IdlescapeButton variant='green' size='large' onClick={onClose}>
							Keep fighting
						</IdlescapeButton>
						{dungeonRequeue && (
							<IdlescapeButton variant='blue' size='large' onClick={stopDungeonRequeue}>
								Disable dungeon queue
							</IdlescapeButton>
						)}
						<IdlescapeButton variant='red' size='large' onClick={runAwayFromCombat}>
							{fleeText}
						</IdlescapeButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{render !== '' && renderContent()}

			<Grid
				gridTemplateColumns={`repeat(${subscribed() ? '5' : '4'}, minmax(60px, 80px))`}
				justifyContent='center'
			>
				<Box
					className={'combat-bar-button'}
					onClick={handleOpenModal}
					backgroundImage='url(/images/ui/frame_box_red.png) !important'
					border-radius='20px 0 0 0'
				>
					<Image src={fleeImage} alt='flee' filter='brightness(1.5)' />
				</Box>
				<Box
					className={'combat-bar-button ' + (render === 'stats' ? 'selected' : '')}
					onClick={() => setActiveContent('stats')}
				>
					<img src='/images/total_level.png' alt='Player Stats' />
				</Box>
				<Box
					className={'combat-bar-button ' + (render === 'equipment' ? 'selected' : '')}
					onClick={() => setActiveContent('equipment')}
				>
					<img src='/images/ui/equipment_icon.png' alt='Equipment' />
				</Box>
				<Box
					className={'combat-bar-button ' + (render === 'inventory' ? 'selected' : '')}
					onClick={() => setActiveContent('inventory')}
					border-radius='0 20px 0 0'
				>
					<img src='/images/ui/inventory_icon.png' alt='Inventory' />
				</Box>
				{subscribed() && (
					<Box
						className={'combat-bar-button ' + (render === 'instance-stats' ? 'selected' : '')}
						onClick={() => setActiveContent('instance-stats')}
						border-radius='0 20px 0 0'
					>
						<img src='/images/misc/notepad_icon.png' alt='Combat Stats' />
					</Box>
				)}
			</Grid>
		</div>
	);
}

export default CombatBar;
