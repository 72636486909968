import { AbsoluteCenter, Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import Tooltip from '../../layout/UI/Tooltip';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { ICombatBuff } from '../../../../../game-server/src/modules/combat/Combat.interface';
import { EnchantmentData } from '../EnchantmentData';

export default function CombatBuffs({
	enchantments,
	type,
}: {
	enchantments: ICombatBuff[];
	type: 'buffs' | 'debuffs';
}) {
	return (
		<Flex gap='2px' flexDirection='row-reverse'>
			{enchantments?.map((enchantment, index) => {
				const enchantmentData = enchantmentsList[enchantment.enchantmentId];
				if (!enchantmentData || !EnchantmentData.isCombatEnchantment(enchantment.enchantmentId)) return;
				if ((type === 'debuffs' && !enchantmentData.isDebuff) || (type === 'buffs' && enchantmentData.isDebuff))
					return;
				const backgroundColor = enchantmentData.isDebuff ? '#962828' : '#238318';
				const borderColor = enchantmentData.isDebuff ? '#ff0000' : '#00ff00';
				const border = `2px solid ${borderColor}`;
				return (
					<Box
						key={index}
						borderRadius='5px'
						cursor='pointer'
						position='relative'
						padding='2px'
						width='40px'
						height='40px'
						border={border}
						backgroundColor={backgroundColor}
					>
						<Image src={enchantmentData.buffIcon} height='100%' width='100%' />
						<AbsoluteCenter
							fontSize='16px'
							fontWeight='bold'
							bottom='20%'
							textShadow='-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
						>
							{enchantment.stacks?.toLocaleString('en-us', { notation: 'compact' })}
						</AbsoluteCenter>
						<Tooltip>
							<VStack textAlign='center'>
								<Text color='rgb(201, 30, 207)' fontSize='1.5rem' fontWeight='bold' margin='0'>
									{enchantmentData.name} {enchantment.enchantmentStrength}
								</Text>
								{enchantment.stacks && (
									<Text>{enchantment.stacks.toLocaleString('en-us')} actions remaining</Text>
								)}
								<Text>
									{enchantmentData.getTooltip(
										enchantment.enchantmentStrength,
										enchantmentData.strengthPerLevel
									)}
								</Text>
							</VStack>
						</Tooltip>
					</Box>
				);
			})}
		</Flex>
	);
}
