import { useLocalStorage } from 'usehooks-ts';
import useIsMobile from './useIsMobile';

const useNavbarPinned = () => {
	const [navbarPinned, setNavbarPinned] = useLocalStorage('navbarPinned', false);
	const isMobile = useIsMobile();

	if (isMobile) return { navbarPinned: false, setNavbarPinned } as const;
	return { navbarPinned, setNavbarPinned } as const;
};

export default useNavbarPinned;
