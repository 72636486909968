import React, { useEffect, useState } from 'react';
import { socket } from '../../../../services/socket.service';
import { IChannelSettings, IChatMessage } from '../../../../../../game-server/src/modules/chat/Chat.interface';
import { PlayerData } from '../../../../../../game-server/src/modules/player/PlayerData';
import { Box } from '@chakra-ui/react';
import ChatMessage from '../../../game/Chat/ChatMessage';

function ChatChannelHistory(props: {
	playerId: PlayerData['id'];
	channelID: number;
	maxMessages: number;
	onlyDeleted: boolean;
	reverse?: boolean;
}) {
	const [chatHistory, setChatHistory] = useState<IChatMessage[]>([]);
	if (props.reverse) {
		chatHistory.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
	}

	useEffect(() => {
		// get maxMessages most recent messages from channel with channelID
		socket.on('chat:message:history:player:send', (data) => {
			setChatHistory(data);
		});
		socket.emit('chat:messages:player:get', {
			playerId: props.playerId,
			channelId: props.channelID,
			maxMessages: props.maxMessages,
			onlyDeleted: props.onlyDeleted,
		});

		socket.on('chat:message:delete', handleDeleteMessage);
		return () => {
			socket.off('chat:message:history:player:send');
			socket.off('chat:message:delete', handleDeleteMessage);
		};
	}, [props.playerId, props.maxMessages, props.onlyDeleted]);

	const channelSettings: IChannelSettings = {
		channelId: props.channelID,
		channelName: '',
		type: 'settings',
	};

	function handleDeleteMessage(data: { messageId: number; channelId: number; deleted: boolean }) {
		if (data.channelId !== props.channelID) return;
		setChatHistory((prev) =>
			prev.map((message) => {
				if (message.messageId === data.messageId) {
					message.deleted = data.deleted;
				}
				return message;
			})
		);
	}

	return (
		<Box>
			{chatHistory.map((message) => {
				return (
					<ChatMessage
						key={message.messageId}
						message={message}
						settings={channelSettings}
						showTimestamp={true}
					/>
				);
			})}
			{chatHistory.length === 0 && <Box>No messages in this channel.</Box>}
		</Box>
	);
}

export default ChatChannelHistory;
