import React from 'react';
import { useRecoilState } from 'recoil';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingIntensitySelector } from '../../../../atoms/smithingIntensityAtom';
import { forges } from '../../../../utils/forgeList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { maxIntensity } from '../../../../utils/smithingFunctions';

export default function SmithingFurnaceList() {
	const [activeForge, setActiveForge] = useRecoilState(smithingActiveForgeAtom);
	const [intensity, setIntensity] = useRecoilState(smithingIntensitySelector);

	const skills = usePlayerField('skills');
	const smithingLevel = skills.smithing.level;
	const metallurgyStrength = usePlayerEnchantmentStrength(enchantmentsIds.metallurgy, 'smithing');
	const moltenMetalStrength = usePlayerEnchantmentStrength(enchantmentsIds.molten_metal, 'smithing');
	return (
		<div className='smithing-furnace-list'>
			{Object.values(forges).map((forge) => {
				const isUnlocked = forge.forgeLevelRequired <= smithingLevel;
				return (
					<div
						key={forge.forgeID}
						onClick={() => {
							if (isUnlocked) {
								setActiveForge(forge.forgeID);
								const maxForgeIntensity = maxIntensity(forge, moltenMetalStrength, metallurgyStrength);
								if (maxForgeIntensity < intensity) {
									setIntensity(maxForgeIntensity);
								}
							}
						}}
						className={`smithing-furnace-list-item ${
							activeForge === forge.forgeID ? 'smithing-furnace-list-item-active' : ''
						} ${isUnlocked ? '' : 'smithing-furnace-locked'}`}
					>
						{!isUnlocked && (
							<div className='smithing-furnace-locked-cover'>
								<img
									src='images/smithing/smithing_icon.png'
									className='icon60'
									alt='Required Smithing Level'
								/>
								<div className='smithing-furnace-locked-cover-level'>{forge.forgeLevelRequired}</div>
							</div>
						)}
						<img src={forge.image} alt={forge.forgeName} />
					</div>
				);
			})}
		</div>
	);
}
