import React, { MouseEvent } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { ProgressBar } from '../UI/ProgressBar';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { useSetRecoilState } from 'recoil';
import { questAtom } from '../../../atoms/questAtom';
import { IQuestData, IQuestStep } from '../../../../../game-server/src/modules/quest/Quest.interface';
import QuestTooltip from '../../game/Achievements/QuestTooltip';
import { shareQuestSelector } from '../../../atoms/chat/chatHistoryAtom';

export default function PinnedQuestStep({ quest, step }: { quest: IQuestData; step: IQuestStep }) {
	const questProgress = usePlayerField('questProgress');
	const questsCompleted = usePlayerField('questsCompleted');
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setCurrentQuest = useSetRecoilState(questAtom);
	const shareQuest = useSetRecoilState(shareQuestSelector);

	const stepIndex = quest.questSteps.findIndex((qs) => qs.stepID === step.stepID);

	const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);
	let requirementMet = true;

	if (stepIndex > 0) {
		const previousStep = quest.questSteps[stepIndex - 1];
		const previousProgress =
			inProgress.find(
				(questProgress) => questProgress.id === quest.id && previousStep.stepID === questProgress.stepID
			)?.current ?? 0;
		if (previousProgress < previousStep.targetAmount) {
			requirementMet = false;
		}
	} else {
		if (inProgress.length === 0) {
			requirementMet = false;
		}
	}

	const currentProgress =
		inProgress.find((questProgress) => questProgress.id === quest.id && step.stepID === questProgress.stepID)
			?.current ?? 0;

	let progressString = currentProgress.toLocaleString('en-us') + '/' + step.targetAmount.toLocaleString('en-us');
	if (
		currentProgress === step.targetAmount ||
		questsCompleted.find((completedQuest) => completedQuest.id === quest.id)
	) {
		progressString = 'Complete';
	}

	let extraCSS = '';
	if (progressString === 'Complete') {
		extraCSS = ' quest-complete';
	} else if (!requirementMet) {
		extraCSS = ' quest-listing-requirement-unmet';
	}

	function clickPinned(e: MouseEvent) {
		if (e.ctrlKey || e.metaKey) {
			shareQuest(quest.id.toString());
			e.stopPropagation();
			return;
		}
		quest.achievement ? setActiveTab('achievements') : setActiveTab('quests');
		setCurrentQuest({ quest: quest.id, step: step.stepID });
	}

	return (
		<Flex
			position='relative'
			flexDirection='column'
			className={'quest-step quest-header' + extraCSS}
			cursor='pointer'
			padding='5px 15px'
			justifyContent='center'
			alignItems='center'
			width='100%'
			height='60px'
			minWidth='0'
			minHeight='0'
			margin='-5px 0 -10px'
			onClick={clickPinned}
		>
			<Box position='relative' lineHeight='1em' maxHeight='30px' overflow='hidden'>
				{step.name}
			</Box>
			<Flex
				position='relative'
				justifyContent='center'
				alignItems='center'
				height='20px'
				width='100%'
				marginTop='2px'
			>
				<ProgressBar
					position='absolute'
					top='0'
					theme='quest'
					value={currentProgress + step.targetAmount / 100} // This is a hack to make the progress bar not be full :)
					max={step.targetAmount + step.targetAmount / 100} // Nick UI go?!?
					height='100%'
					maxWidth='300px'
					width='100%'
				/>
				<Box position='relative'>{progressString}</Box>
			</Flex>
			<QuestTooltip quest={quest} step={step} />
		</Flex>
	);
}
