import React, { useState } from 'react';
import { platinumPacks } from '../../../../utils/platinumPackList';
import { IPlatinumPack } from '../../../../../../game-server/src/modules/monetization/PlatinumPackData';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { PlatinumShopPurchaseReview } from '../PlatinumShopPurchaseReview';
import ChatItem from '../../Chat/Links/ChatItem';
import LocaleCurrency from 'locale-currency';

export const PlatinumShopPlatinumContainer = () => {
	const [selectedPack, setSelectedPack] = useState(0);

	const locale = navigator.language;
	const localeCurrency = LocaleCurrency.getCurrency(locale) === 'EUR' ? '€' : '$';

	const PlatinumPackList = () => {
		const packs = Object.values(platinumPacks).map((platinumPackData) => {
			return (
				<PlatinumPack
					key={'platinum-pack-' + platinumPackData.id}
					platinumData={platinumPackData}
				></PlatinumPack>
			);
		});

		return <div className='platinum-pack-list'>{packs}</div>;
	};

	const PlatinumPack = (props: { platinumData: IPlatinumPack }) => {
		const discount = props.platinumData.discount ? (
			<div className='platinum-pack-discount'>+{props.platinumData.discount}%</div>
		) : null;
		return (
			<div
				onClick={() => setSelectedPack(props.platinumData.id)}
				className='platinum-pack idlescape-container idlescape-container-platinum'
			>
				{discount}
				<div className='platinum-pack-titles'>
					<div className='platinum-pack-main-title'>
						{props.platinumData.platinumAmount.toLocaleString('en-us')}
					</div>
					<div className='platinum-pack-sub-title'>
						<ChatItem item={{ itemID: 20, name: 'Platinum' }}></ChatItem>
					</div>
				</div>
				<img src={props.platinumData.image} alt={'Platinum'} />
				<div className='platinum-pack-price'>
					{localeCurrency}
					{props.platinumData.price}
				</div>
			</div>
		);
	};

	return (
		<div className='platinum-shop-platinum-container'>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={selectedPack}
					timeout={300}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames='fade'
				>
					{selectedPack > 0 ? (
						<PlatinumShopPurchaseReview platinumPackId={selectedPack} setSelectedPack={setSelectedPack} />
					) : (
						<PlatinumPackList />
					)}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};
