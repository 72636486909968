import {
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { PiTreasureChestBold } from 'react-icons/pi';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IChestData } from '../../../../../game-server/src/modules/client/ClientManager';
import { shareChestSelector } from '../../../atoms/chat/chatHistoryAtom';
import { openedChestAtom } from '../../../atoms/chat/openedChestAtom';
import useIsMobile from '../../../hooks/useIsMobile';
import { socket } from '../../../services/socket.service';
import FakeItem from '../Inventory/FakeItem';

export default function ChatChestShortcut() {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [chests, setChests] = useRecoilState(openedChestAtom);
	const shareChest = useSetRecoilState(shareChestSelector);
	const isMobile = useIsMobile();

	useEffect(() => {
		socket.on('chest', handleChest);

		return () => {
			socket.off('chest', handleChest);
		};
	}, []);

	function handleChest(data: IChestData) {
		setChests((chests) => {
			const newChests = chests.filter((chest) => chest.itemID !== data.chestID);
			newChests.push({ itemID: data.chestID, stackSize: data.amount });
			return newChests;
		});
	}

	return (
		<>
			{chests.length > 0 && (
				<div className='chat-channel-function' onClick={onOpen}>
					<PiTreasureChestBold />
				</div>
			)}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className='sell-item-dialog'>
					<ModalHeader>Share opened chests</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Click on any chest to share your latest loot.</Text>
						<Grid
							gridTemplateColumns={isMobile ? 'repeat(auto-fit, 50px)' : 'repeat(auto-fit, 64px)'}
							maxWidth='300px'
							justifyContent='center'
						>
							{chests.map((chest) => (
								<FakeItem
									key={chest.itemID}
									item={chest}
									onClick={() => {
										shareChest(chest.itemID.toString());
										onClose();
									}}
								/>
							))}
						</Grid>
						<Text>Only the most recent loot can be shared.</Text>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
