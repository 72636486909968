import { socket } from '../../services/socket.service';
import { Box, Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';
import {
	REFERRAL_TIERS,
	REFERRAL_TOTAL_LEVEL_NGIM_REQUIREMENTS,
	REFERRAL_TOTAL_LEVEL_REQUIREMENTS,
} from '../../utils/constantsCollection';
import './FriendsTab.css';
import { leaguesIds } from '../../utils/lookup-dictionaries/lookupLeagueList';
import { addNotification } from '../../services/NotificationManager';
import { NotificationType } from '../../../../game-server/src/modules/notifications/Notification';
import { HiClipboardCopy } from 'react-icons/hi';

export default function ReferralTab() {
	const myReferralCode = usePlayerField('myReferralCode');
	const targetReferralCode = usePlayerField('targetReferralCode');
	const claimedTiers = usePlayerField('claimedTiers') ?? [];
	const claimedReferralPoints = usePlayerField('claimedReferralPoints') ?? [];
	const league = usePlayerField('league');
	const [value, setValue] = useState('');

	const allClaimed = claimedTiers.filter((tier) => tier !== 0).length === REFERRAL_TIERS.length;

	function setReferralCode(event: React.MouseEvent | React.KeyboardEvent) {
		if (event.type !== 'click' && 'key' in event && event.key !== 'Enter') return;
		if (value === '') return;
		socket.emit('referral:setCode', {
			code: value,
		});
		setValue('');
	}

	function claimReferralRewards(event: React.MouseEvent | React.KeyboardEvent) {
		if (event.type !== 'click' && 'key' in event && event.key !== 'Enter') return;
		socket.emit('referral:claimRewards');
	}

	function renderTiers() {
		return REFERRAL_TIERS.map((tier, index) => {
			const requiredTotalLevel =
				league === leaguesIds.no_gather_ironman
					? REFERRAL_TOTAL_LEVEL_NGIM_REQUIREMENTS[index]
					: REFERRAL_TOTAL_LEVEL_REQUIREMENTS[index];
			return (
				<div
					key={`${tier}-${index}-referraltier`}
					style={{
						padding: '10px',
						backgroundColor: 'rgba(5, 5, 5, 0.1)',
						borderRadius: '5px',
						marginBottom: '10px',
						textAlign: 'center',
					}}
				>
					<h3 style={{ margin: '0' }}>
						Tier {tier} - Total Level Required {requiredTotalLevel}
					</h3>
					<p style={{ margin: '10px 0' }}>{getRewardByTier(tier)}</p>
					<p style={{ fontWeight: 'bold', color: claimedTiers[index] ? 'green' : 'red' }}>
						{claimedTiers[index] ? 'Claimed' : 'Unclaimed'}
					</p>
					<p style={{ margin: '10px 0' }}>
						{claimedReferralPoints[index]
							? `Others have claimed this tier ${claimedReferralPoints[index]} times with your code.`
							: 'No one has claimed this tier with your code yet.'}
					</p>
				</div>
			);
		});
	}

	function getRewardByTier(tier: number) {
		switch (tier) {
			case 1:
				return <>Global 30 Minutes of +50% XP</>;
			case 2:
				return (
					<>
						Global Hour of +50% XP and +20% Non-Combat Speed<br></br>200 Platinum for both you and your
						friend
					</>
				);
			case 3:
				return (
					<>
						Two Global Hours of +50% XP and +20% Non-Combat Speed<br></br>400 Platinum for both you and your
						friend<br></br>The Friendly Chat Icon<br></br>On 5 Tier 3 Referrals, the Socialite Chat Icon
					</>
				);
			default:
				return 'Invalid Tier';
		}
	}

	return (
		<div className='friendlist-container'>
			{allClaimed ? (
				''
			) : (
				<Flex gap='15px' alignItems='center' maxWidth='450px' margin='auto'>
					<IdlescapeButton
						variant='green'
						alignItems='center'
						onClick={claimReferralRewards}
						maxWidth='450px'
						width='100%'
					>
						Claim Rewards
					</IdlescapeButton>
				</Flex>
			)}
			{!targetReferralCode || targetReferralCode === '' ? (
				<Flex gap='15px' alignItems='center' maxWidth='450px' margin='auto'>
					<IdlescapeInput
						value={value}
						placeholder='Enter Your Friend Referral Code Here...'
						variant='simple'
						setValue={setValue}
						onKeyUp={setReferralCode}
						flex='2'
					/>
					<IdlescapeButton variant='green' onClick={setReferralCode} flex='1'>
						Set Referral Code
					</IdlescapeButton>
				</Flex>
			) : (
				''
			)}
			<IdlescapeContainer margin='auto' maxWidth='500px' textAlign='center'>
				<h2>Your Referral Code:</h2>
				<Flex justifyContent='center' cursor='pointer'>
					<Box
						className='referral-code'
						background='#0004'
						textAlign='center'
						padding='5px 10px'
						borderRadius='5px'
						userSelect='all'
					>
						{myReferralCode}
					</Box>
					<HiClipboardCopy
						fontSize='24px'
						onClick={() => {
							navigator.clipboard.writeText(myReferralCode);
							addNotification(
								'Referral',
								'Your referral code has been copied to the clipboard.',
								'success' as NotificationType
							);
						}}
					/>
				</Flex>
				<h2 />
				<h2>Used Referral Code:</h2>
				<p>{targetReferralCode ?? 'None'}</p>
				<hr />
				<h2>Rewards:</h2>
				{renderTiers()}
			</IdlescapeContainer>
		</div>
	);
}
