import './Achievements.css';
import { usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import { Box } from '@chakra-ui/react';
import AchievementList from './AchievementList';
import QuestSummary from './QuestSummary';

/**
 * This is used for both Quests AND Achievements
 */
export default function Achievements() {
	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	if (!leagueData?.rules?.quests) {
		return <>You cannot do achievements in this league or season.</>;
	}

	return (
		<Box className='achievement-container' height='calc(100% - 30px)'>
			<QuestSummary isAchievements={true} questsCompleted={questsCompleted} questProgress={questProgress} />
			<AchievementList league={league} />
		</Box>
	);
}
