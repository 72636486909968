import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../../hooks/useIsMobile';
import { itemList } from '../../../../utils/itemList';
import ItemTooltipDialog from '../../Inventory/ItemDialogs/ItemTooltipDialog';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import './ChatItem.css';

export default function ChatItem(props: { item: TPartialItemWithItemID<IItem>; hideName?: boolean }) {
	const item = itemList[props.item.itemID];
	const isMobile = useIsMobile();
	const { isOpen, onOpen, onClose } = useDisclosure();
	if (!item) return <div>Invalid Item</div>;

	return (
		<div className={`chat-item ${item.class}`} data-itemid={props.item.itemID} onClick={onOpen}>
			<img className='chat-skill-icon' src={item.itemIcon || item.itemImage} alt={item.name} />
			{!props.hideName && item.name}
			{(props.item.augmentations ?? 0) >= 1 && ' +' + props.item.augmentations}
			{isMobile ? (
				isOpen && <ItemTooltipDialog item={props.item} onClose={onClose} />
			) : (
				<ItemTooltip item={props.item} />
			)}
		</div>
	);
}
