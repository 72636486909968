import { useAppSelector } from '../../../hooks/hooks';
import { CombatAvatar } from './CombatAvatar2';
import { useRecoilValue } from 'recoil';
import { monsterAtom } from '../../../atoms/monsterAtom';
import { TEquipmentPartials, TEquipmentSlots } from '../../../../../game-server/src/modules/player/PlayerTypes';

export const CombatGroupAvatarArea = () => {
	const group = useAppSelector((state) => state.group);
	const monsters = useRecoilValue(monsterAtom);
	const summons = monsters.filter((monster) => monster.faction === 'playerSummon');
	const playerSummonCount = (group?.groupData?.groupMemberData?.length ?? 0) + summons.length;
	const raidMode = playerSummonCount > 5;
	return (
		<div className='combat-group-avatars'>
			{group?.groupData?.groupMemberData?.map((elem) => {
				return (
					<CombatAvatar
						equipment={elem.equipment ?? {}}
						cosmetics={elem.selectedCosmetics ?? {}}
						className={`combat-group-avatar-${elem.id} combat-group-avatar ${
							raidMode ? 'combat-raid-scaling' : ''
						}`}
						id={elem.id}
						showSplashes={true}
						key={`combat-avatar-idx-${elem.id}`}
						dead={elem.currentHealth ? elem.currentHealth <= 0 : false}
					/>
				);
			})}
			{summons?.map((elem) => {
				const summonEquipment: TEquipmentPartials = {};
				if (elem.playerEquipment) {
					for (const [slot, item] of Object.entries(elem.playerEquipment)) {
						if (item) {
							summonEquipment[slot as TEquipmentSlots] = item;
						}
					}
				}
				return (
					<CombatAvatar
						equipment={summonEquipment}
						cosmetics={{}}
						className={`combat-group-avatar-${elem.id} combat-group-avatar ${
							raidMode ? 'combat-raid-scaling' : ''
						}`}
						id={elem.id}
						showSplashes={true}
						key={`combat-avatar-idx-${elem.id}`}
						dead={elem.monsterHealth <= 0}
					/>
				);
			})}
		</div>
	);
};
