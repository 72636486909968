import React from 'react';
import { IQuestData } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { Box, Flex, Image } from '@chakra-ui/react';
import { ProgressBar } from '../../layout/UI/ProgressBar';
import { usePlayerField } from '../../../hooks/hooks';
import { checkRequirements } from './QuestFunctions';
import { AiOutlinePushpin, AiFillLock } from 'react-icons/ai';
import { socket } from '../../../services/socket.service';
import { formatNumberToString } from '../../../helper/helperFunctions';
import QuestTooltip from './QuestTooltip';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import { useSetRecoilState } from 'recoil';
import { shareQuestSelector } from '../../../atoms/chat/chatHistoryAtom';

export default function AchievementEntry({ quest }: { quest: IQuestData }) {
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const metRequirements = checkRequirements(quest, skills, questIdsCompleted);
	const step = quest.questSteps[0];
	const shareQuest = useSetRecoilState(shareQuestSelector);

	const pinnedQuest = settings.quest.pin;

	const completed = questIdsCompleted.find((completedQuest) => completedQuest === quest.id);
	const inProgress = questProgress.find((questProgress) => questProgress.id === quest.id);

	function pinQuest(quest: number, e?: React.MouseEvent) {
		if (e) e.stopPropagation();
		socket.emit('settings:update', {
			value: settings.quest.pin === quest ? 0 : quest,
			path: 'quest.pin',
		});
	}

	const currentProgress = inProgress?.current ?? 0;

	let progressString = formatNumberToString(currentProgress) + '/' + formatNumberToString(step.targetAmount);
	if (
		currentProgress === step.targetAmount ||
		questsCompleted.find((completedQuest) => completedQuest.id === quest.id)
	) {
		progressString = 'Complete';
	}

	function renderPin() {
		const pinned = quest.id === pinnedQuest;
		return (
			<Box
				position='absolute'
				fontSize={20}
				right='10px'
				top='10px'
				cursor='pointer'
				onClick={(e) => pinQuest(quest.id, e)}
				color={pinned ? 'white' : 'gray'}
				_hover={{ color: 'white' }}
				zIndex='1000'
			>
				<AiOutlinePushpin />
			</Box>
		);
	}

	let extraCSS = '';
	if (completed) {
		extraCSS = 'quest-listing-completed';
	} else if (!metRequirements) {
		extraCSS = 'quest-listing-requirement-unmet';
	} else if (inProgress) {
		extraCSS = 'quest-listing-in-progress';
	}

	return (
		<IdlescapeWrappingTooltip content={<QuestTooltip quest={quest} step={step} isAchievement={true} />}>
			<Flex
				position='relative'
				className={`quest-step ${extraCSS} achievement-highlight`}
				fontSize='14px'
				padding='10px'
				justifyContent='center'
			>
				<Flex height='60px' alignItems='center'>
					{quest.questIcon ? (
						<Box className={`achievement-icon ${quest.name}`} width='80px' height='60px' overflow='hidden'>
							<Image
								position='relative'
								width='56px'
								height='56px'
								margin='5px 12px'
								src={quest.questIcon}
							/>
						</Box>
					) : (
						<span>{quest.name}</span>
					)}
				</Flex>
				<Flex
					position='absolute'
					justifyContent='center'
					alignItems='center'
					height='20px'
					width='80%'
					marginTop='2px'
					bottom='10px'
				>
					<ProgressBar
						position='absolute'
						top='0'
						theme='quest'
						value={currentProgress}
						max={step.targetAmount}
						height='100%'
						maxWidth='300px'
						width='100%'
					/>
					<Box position='relative'>{progressString}</Box>
				</Flex>
				<Flex height='66%' width='66%' alignItems='center' justifyContent='center' position='absolute'>
					{extraCSS === 'quest-listing-requirement-unmet' ? <AiFillLock size='100%'></AiFillLock> : ''}
				</Flex>
				{extraCSS !== 'quest-listing-requirement-unmet' &&
					extraCSS !== 'quest-listing-completed' &&
					renderPin()}
				<IdlescapeWrappingTooltip content='Share your achievement progress!'>
					<Box
						className='share-achievement-button'
						display='none'
						onClick={() => shareQuest(quest.id.toString())}
						filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
						float='right'
						cursor='pointer'
						position='absolute'
						top='10px'
						left='10px'
						zIndex='11'
						_hover={{ filter: 'brightness(2)' }}
					>
						<Image src='/images/chaticons/chat-icon-global.png' width='25px' height='25px' />
					</Box>
				</IdlescapeWrappingTooltip>
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}
