import authReducer from './authReducer';
import errorReducer from './errorReducer';
import gameReducer from './gameReducer';
import groupReducer from './groupReducer';

export default {
	auth: authReducer,
	errors: errorReducer,
	game: gameReducer,
	group: groupReducer,
};
