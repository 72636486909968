import React, { Component } from 'react';
import './EmailRegistered.css';
import axios from 'axios';

export default class EmailRegistered extends Component {
	constructor(props) {
		super(props);

		this.state = { message: '' };
	}

	componentDidMount() {
		axios
			.get('/verification')
			.then((res) => {
				this.setState({
					message: res.message,
				});
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.get('/verification-completed')
			.then((res) => {
				this.setState({
					message: res.message,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		return (
			<div
				className='email-registered-body page-header'
				style={{
					backgroundImage: 'url(/images/landing_page.png)',
				}}
			>
				<div className='motto text-center'>
					<img className='img-fluid logo mb-5' alt='Idlescape Logo' src={'/images/landing/logo_2.0.png'} />
					<div className='email-registered-box'>
						<p>Email registered successfully.</p>
						<br></br>
						<p>You may now use the Marketplace.</p>
						<p>{this.state.message}</p>
					</div>
				</div>
			</div>
		);
	}
}
