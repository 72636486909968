import { Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { socket } from '../../../services/socket.service';

export const GroupInvitePlayerInput = () => {
	const [inputValue, setInputValue] = useState('');

	function handleGroupInvite(event: React.KeyboardEvent | React.MouseEvent) {
		if (event.type !== 'click' && 'key' in event && event.key !== 'Enter') return;
		if (inputValue === '') return;
		socket.emit('group:invite', {
			username: inputValue,
		});
		setInputValue('');
	}

	return (
		<Flex gap='15px' alignItems='center' maxWidth='450px' margin='0 auto' width='100%'>
			<IdlescapeInput
				placeholder='Type a username...'
				variant='simple'
				value={inputValue}
				setValue={setInputValue}
				onKeyUp={handleGroupInvite}
				flex='2'
			/>
			<IdlescapeButton variant='green' onClick={handleGroupInvite} flex='1'>
				Invite Player
			</IdlescapeButton>
		</Flex>
	);
};
