import { Heading, Text } from '@chakra-ui/react';

export const PaymentFailed = () => {
	const heading = 'Payment failed';
	const text =
		'You were not charged for this transaction. You can try again or contact support. You can now close this page.';

	return (
		<div
			className='email-registered-body page-header'
			style={{
				backgroundImage: 'url(/images/landing_page.png)',
			}}
		>
			<div className='motto text-center'>
				<img className='img-fluid logo mb-5' alt='Idlescape Logo' src={'/images/landing/logo_2.0.png'} />
				<div className='email-registered-box'>
					<Heading>{heading}</Heading>
					<br></br>
					<Text>{text}</Text>
				</div>
			</div>
		</div>
	);
};
