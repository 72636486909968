import React, { useEffect, useState } from 'react';
import { usePlayerField, usePlayerQuestCompleted, usePlayerQuestProgress } from '../../../../../hooks/hooks';
import { leagueList } from '../../../../../utils/leagueList';
import { affixList } from '../../../../../utils/affixList';
import { Box } from '@chakra-ui/react';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { TAffixesPaths } from '../../../../../../../game-server/src/modules/affixes/affixes.interface';
import RunecraftingAffixItemSelection from './RunecraftingAffixItemSelection';
import AffixList from './AffixList';
import AffixSelection from './AffixSelection';
import { getAffixesSlotsForItem } from '../../../../../utils/affixFunctions';
import { questsIds } from '../../../../../utils/lookup-dictionaries/lookupQuestList';
import { IdlescapeContainer } from '@idlescape/ui';
import { itemList } from '../../../../../utils/itemList';
import AffixRerollPicker from './AffixRerollPicker';

export default function RunecraftingAffix() {
	const league = usePlayerField('league');
	const affixRerollSelected = usePlayerField('affixRerollSelected');
	const affixRerollOption = usePlayerField('affixRerollOption');

	const [selectedItemId, setSelectedItem] = useState(-1);
	const [selectedAffixes, setSelectedAffixes] = useState<TAffixesPaths[]>([]);

	const tutorialCurrentStep = usePlayerQuestProgress(questsIds.affixing_tutorial);
	const tutorialCompleted = usePlayerQuestCompleted(questsIds.affixing_tutorial);
	const stockpile = usePlayerField('stockpile');
	let selectedItem = stockpile.find((item) => item.id === selectedItemId);
	const isRerolling = selectedItem?.affixes !== undefined;

	// Clear selected item and affixes if the item is removed
	useEffect(() => {
		if (selectedItemId !== -1 && !stockpile.find((item) => item.id === selectedItemId)) {
			setSelectedItem(-1);
			setSelectedAffixes([]);
		}
	}, [stockpile]);
	function canAffixLeague() {
		const leagueData = leagueList[league];
		if (!leagueData) {
			return false;
		}
		return leagueData.rules.affixes;
	}

	function tutorialCheck() {
		return tutorialCompleted || tutorialCurrentStep > 0;
	}

	function handleSelectItem(item: IItem) {
		if (selectedItem?.id === item.id) {
			setSelectedItem(-1);
			setSelectedAffixes([]);
		} else {
			setSelectedItem(item.id);

			// This is janky af, but it works!
			selectedItem = stockpile.find((i) => item.id === i.id);
			if (isRerolling || selectedItem?.affixes) {
				if (selectedItem?.affixes) {
					const affixes = selectedItem.affixes.map((affix) => affix.path);
					if (selectedAffixes.length !== affixes.length) {
						setSelectedAffixes(affixes as TAffixesPaths[]);
					}
				}
			}
		}
	}

	function handleAffixSelect(affix: TAffixesPaths) {
		// If we're rerolling, they can't add any paths that aren't already on the item
		if (isRerolling) {
			if (!selectedItem) {
				return;
			}
			const affixData = affixList[affix];
			if (!affixData) return;
			if (!selectedItem.affixes) {
				return;
			}
			const affixOnItem = selectedItem.affixes.find((a) => a.path === affix);
			if (!affixOnItem) {
				return;
			}
		}
		if (selectedAffixes.includes(affix)) {
			setSelectedAffixes(selectedAffixes.filter((a) => a !== affix));
		} else {
			// Check if we have too many selected
			if (selectedAffixes.length >= (selectedItem ? getAffixesSlotsForItem(itemList[selectedItem.itemID]) : 0)) {
				return;
			}
			setSelectedAffixes([...selectedAffixes, affix]);
		}
	}

	if (!canAffixLeague()) {
		const now = new Date();
		const activeAffixingLeagues = Object.values(leagueList).filter((leagueData) => {
			if (!leagueData) {
				return false;
			}
			if (leagueData.rules.endDate && new Date(leagueData.rules.endDate) < now) {
				return false;
			}
			return leagueData.rules.affixes && leagueData.active;
		});
		const leagueNames = activeAffixingLeagues.map((league) => leagueList[league.id].name);
		const leagueString = leagueNames.join(', ');

		return (
			<div className='enchanting-main'>
				<div className='enchanting-items-container'>
					<h1 className='enchanting-title'>Affixing is not available in your league.</h1>
					<h3 className='enchanting-title'>
						Active Affixing Leagues/Seasons: {leagueString.length > 0 ? leagueString : 'Currently none.'}
					</h3>
				</div>
			</div>
		);
	}
	if (!tutorialCheck()) {
		return (
			<IdlescapeContainer>
				<h1 className='enchanting-title'>
					You must start or complete the Affixing Tutorial to use this feature.
				</h1>
			</IdlescapeContainer>
		);
	}

	const shouldRenderEquipment = !selectedItem;
	const shouldRenderAffixes = selectedItem !== undefined && !isRerolling;
	const shouldRenderSelected = selectedItem || selectedAffixes.length > 0;
	const nothingRendered = !shouldRenderEquipment && !shouldRenderAffixes && !shouldRenderSelected;
	if (nothingRendered) {
		return (
			<div className='enchanting-main'>
				<h1 className='enchanting-title'>Well this is not supposed to happen.</h1>
			</div>
		);
	}

	if (affixRerollSelected !== null && affixRerollOption !== null) {
		return <AffixRerollPicker affixRerollSelected={affixRerollSelected} affixRerollOption={affixRerollOption} />;
	}

	return (
		<Box className='enchanting-main' height='auto'>
			{shouldRenderEquipment && <RunecraftingAffixItemSelection onSelect={handleSelectItem} />}
			{shouldRenderSelected && (
				<AffixSelection
					selectedItem={selectedItem}
					selectedAffixes={selectedAffixes}
					setSelectedItem={setSelectedItem}
					setSelectedAffixes={setSelectedAffixes}
					handleAffixSelect={handleAffixSelect}
				/>
			)}
			{shouldRenderAffixes && (
				<AffixList
					selectedItem={selectedItem}
					handleAffixSelect={handleAffixSelect}
					selectedAffixes={selectedAffixes}
				/>
			)}
		</Box>
	);
}
