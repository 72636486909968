import React from 'react';
import './PlatinumShop.css';
import PlatinumShopGlobalBuffs from './Buffs/PlatinumShopGlobalBuffs';
import { PlatinumShopPlatinumContainer } from './Platinum/PlatinumShopPlatinumContainer';
import { ShopContainer } from './Shop/ShopContainer';
import { PlatinumShopIdlescapePlus } from './IdlescapePlus/PlatinumShopIdlescapePlus';
import { Box, VStack } from '@chakra-ui/react';
import { IdlescapePill } from '@idlescape/ui';
import { usePlayerField } from '../../../hooks/hooks';
import { chatIconList } from '../../../utils/chatIconList';
import Tooltip from '../../layout/UI/Tooltip';
import { IChatIcon } from '../../../../../game-server/src/modules/chat/Chat.interface';
import { useRecoilState } from 'recoil';
import { platinumTabAtom } from '../../../atoms/platinumTabAtom';

export const platinumTabs = ['Idlescape Plus', 'Platinum', 'Shop', 'Buffs'] as const;

const PlatinumShopContainer = () => {
	const [activeTab, setActiveTab] = useRecoilState(platinumTabAtom);

	const platinumSpent = usePlayerField('platinumSpent');

	function handleTabSwitch(tabName: (typeof platinumTabs)[number]) {
		setActiveTab(tabName);
	}

	function renderContent() {
		switch (activeTab) {
			case 'Idlescape Plus':
				return <PlatinumShopIdlescapePlus />;
			case 'Platinum':
				return <PlatinumShopPlatinumContainer />;
			case 'Shop':
				return <ShopContainer />;
			case 'Buffs':
				return <PlatinumShopGlobalBuffs />;
			default:
				return <div>lol have a cookie</div>;
		}
	}

	function nextIconUnlockData(): IChatIcon | undefined {
		for (const iconData of Object.values(chatIconList)) {
			if (iconData.platinumUnlock && platinumSpent < iconData.platinumUnlock) {
				return iconData;
			}
		}

		return undefined;
	}

	const iconData = nextIconUnlockData();
	const platToNextIcon = (iconData?.platinumUnlock ?? 0) - platinumSpent;

	const IconsTooltipContent = () => {
		return (
			<Box>
				<Box textAlign={'center'}>
					Spending Platinum in the shop will give you progress towards unlocking chat icons.{' '}
					{iconData ? (
						<>
							Your next unlock is{' '}
							<img src={iconData.source} style={{ height: '20px', width: '20px' }} alt={iconData.name} />
							{iconData?.name}, which requires {platToNextIcon.toLocaleString('en-us')} more Platinum to
							be spent.
						</>
					) : (
						'You have unlocked all icons.'
					)}
				</Box>
				<VStack>
					All icons:
					{Object.values(chatIconList).map((iconData) => {
						if (!iconData.platinumUnlock) {
							return null;
						}

						return (
							<IdlescapePill
								key={'platinum-shop-unlock-icon-' + iconData.id}
								mt={'0 !important'}
								padding={'0 !important'}
								height={'20px'}
								width={'115px'}
								lineHeight={'20px'}
								fontSize={'12px'}
								variant={'progress'}
								progress={Math.min(1, platinumSpent / iconData.platinumUnlock) * 100}
								fontWeight={'bold'}
								textAlign={'left'}
								image={iconData.source}
							>
								{iconData.platinumUnlock.toLocaleString('en-us')}
							</IdlescapePill>
						);
					})}
				</VStack>
			</Box>
		);
	};

	return (
		<div className='platinum-shop-container'>
			<div className='platinum-shop-container-buttons'>
				{platinumTabs.map((elem) => {
					return (
						<div
							key={'platinum-shop-key-button-' + elem.replaceAll(' ', '')}
							onClick={() => handleTabSwitch(elem)}
							data-active={activeTab === elem}
							className={
								'platinum-shop-container-button idlescape-frame-panel idlescape-frame-panel-interactive idlescape-frame-panel-dark'
							}
						>
							{elem}
						</div>
					);
				})}
			</div>
			<div className='platinum-shop-container-content'>
				{/* 				<Center color={'red'} backgroundColor={'black'}>
					<Box>
						NOTE: This is still a development version of the premium store. You can't spend real money on
						this. Please go to this link
						<a href='https://stripe.com/docs/testing' target={'_blank'} rel='noreferrer'>
							https://stripe.com/docs/testing
						</a>{' '}
						to find the Test Cards you can use to help test the functionality.
					</Box>
				</Center> */}
				<IdlescapePill
					marginLeft={'auto'}
					height={'26px'}
					lineHeight={'16px'}
					fontSize={'16px'}
					image={iconData?.source || '/images/chaticons/donate-5000.png'}
					width={'fit-content'}
				>
					{platinumSpent.toLocaleString('en-us')} / {iconData?.platinumUnlock?.toLocaleString('en-us')}
					<Tooltip>
						<IconsTooltipContent></IconsTooltipContent>
					</Tooltip>
				</IdlescapePill>

				{renderContent()}
			</div>
		</div>
	);
};

export default PlatinumShopContainer;
