import React from 'react';
import { itemList } from '../../../../utils/itemList';
import { itemImage } from '../../../../helper/itemHelperFunctions';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { Grid, Box, Flex, Image, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { cookingRecipeAtom } from '../../../../atoms/cookingRecipeAtom';
import { cookingList } from '../../../../utils/cookingList';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { IdlescapeTooltip } from '@idlescape/ui';
import { usePlayerField } from '../../../../hooks/hooks';
import { getCookingTags } from '../../../../utils/cookingFunctions';

export default function RecipeList({ isAlchemy = false }: { isAlchemy?: boolean }) {
	const [cookingRecipe, setCookingRecipe] = useRecoilState(cookingRecipeAtom);
	const stockpile = usePlayerField('stockpile');
	const recipes: IItemData[] = [];

	const ownedTags: string[] = [];
	const tags = getCookingTags();
	// Find all prepared stuff in inventory
	for (const item of stockpile) {
		const cookingData = cookingList[item.itemID];
		const tag = cookingData?.ingredientTags?.[0];
		if (!tag) continue;
		if (tags[tag] === item.itemID) {
			ownedTags.push(tag);
		}
	}
	let totalRecipes = 0;
	// Find all recipes that are valid with the currently prepared stuff
	for (const itemID in cookingList) {
		const item = itemList[itemID];
		const cookingData = cookingList[itemID];
		if (!cookingData?.recipeTags) continue;
		let hasStock = true;
		for (const tag in cookingData.recipeTags) {
			if (!ownedTags.includes(tag)) {
				hasStock = false;
				break;
			}
		}
		if ((!isAlchemy && !cookingData.alchemyEnchantment) || (isAlchemy && cookingData.alchemyEnchantment)) {
			totalRecipes++;
			if (!hasStock) continue;
			recipes.push(item);
		}
	}

	if (!isAlchemy) {
		recipes.sort((a, b) => {
			const hpA = (a.healing?.hp ?? 0) + (a.healing?.perTick ?? 0) * (a.healing?.totalTicks ?? 0);
			const hpB = (b.healing?.hp ?? 0) + (b.healing?.perTick ?? 0) * (b.healing?.totalTicks ?? 0);
			return hpB - hpA;
		});
	}

	const recipeList = recipes.map((itemData) => {
		const cookingData = cookingList[itemData.id];
		const boxShadow = itemData.id === cookingRecipe ? 'inset 0 0 2px 5px rgb(250, 150, 0)' : undefined;
		const enchantment = enchantmentsList[cookingData.alchemyEnchantment ?? 0];
		return (
			<Flex
				position={'relative'}
				onClick={() => setCookingRecipe(itemData.id)}
				key={itemData.id}
				alignItems='center'
				gap='10px'
				borderRadius='10px'
				cursor='pointer'
			>
				<Box
					position='relative'
					boxShadow={boxShadow}
					flex='0 0 60px'
					className={`item ${!isAlchemy ? itemData.class : ''}`}
				>
					{isAlchemy ? (
						<Image
							className='item-icon'
							src={enchantmentsList[cookingData?.alchemyEnchantment ?? 0].buffIcon}
						/>
					) : (
						itemImage(itemData)
					)}
				</Box>
				{isAlchemy ? (
					<IdlescapeTooltip>
						{enchantment.name}
						<br />
						{enchantment.getTooltip(enchantment.strengthPerLevel, 1)}
					</IdlescapeTooltip>
				) : (
					<ItemTooltip item={{ itemID: itemData.id }} />
				)}
			</Flex>
		);
	});

	return (
		<Box overflowY='auto'>
			<Grid
				overflowY='auto'
				templateRows='repeat(auto-fill, 60px)'
				templateColumns='repeat(auto-fill, 60px)'
				justifyContent='center'
				gap='5px'
			>
				{recipeList}
			</Grid>
			{recipeList.length === 0 ? (
				<Text fontSize='xl'>
					{isAlchemy ? 'Find and prepare some alchemy ingredients' : 'Prepare some ingredients'}
				</Text>
			) : totalRecipes > recipeList.length ? (
				<Text>More recipes may be found by preparing and obtaining new ingredients.</Text>
			) : (
				''
			)}
		</Box>
	);
}
