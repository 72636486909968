import { Flex, Grid, Image, ImageProps } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { IQuestData } from '../../../../../../game-server/src/modules/quest/Quest.interface';
import { usePlayerField } from '../../../../hooks/hooks';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { questList } from '../../../../utils/questList';

export function AchievementDisplay({
	achievementData,
	date,
	allowRemove,
	...props
}: { achievementData: IQuestData; date?: Date; allowRemove?: boolean } & ImageProps) {
	if (!achievementData.achievement) return null;
	function removeAchievement(achievementID: number | null) {
		if (achievementID === null) return;
		socket.emit('profile:achievement:remove', achievementID);
	}

	return (
		<IdlescapeWrappingTooltip
			content={`${achievementData.name} (${achievementData.questRewards?.achievementPoints ?? 0})`}
		>
			<Flex flexDirection='column' position='relative'>
				<Image
					className={'inspect-achievement-icon'}
					src={achievementData.questIcon}
					alt={achievementData.name}
					objectFit='contain'
					{...props}
				/>
				{date !== undefined && new Date(date).toLocaleDateString()}
				{allowRemove && (
					<Flex
						onClick={() => removeAchievement(achievementData.id)}
						position='absolute'
						top='0'
						right='0'
						fontSize='20px'
						color='red.500'
						cursor='pointer'
						zIndex='1000'
						_hover={{ transform: 'scale(1.1)' }}
					>
						<MdOutlineRemoveCircle />
					</Flex>
				)}
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}

export function AchievementEditor({ activeAchievements }: { activeAchievements: IInspectData['achievements'] }) {
	const questsCompleted = usePlayerField('questsCompleted');

	const isMobile = useIsMobile();

	const achievements = questsCompleted.filter((quest) => {
		const questData = questList[quest.id];
		return questData.achievement && !activeAchievements.map((a) => a.id).includes(quest.id);
	});
	if (achievements.length === 0) return null;

	function addAchievement(achievementID: number) {
		socket.emit('profile:achievement:add', achievementID);
	}

	function achievementPicker() {
		return (
			<Grid gridTemplateColumns={`repeat(auto-fit, ${isMobile ? '30px' : '40px'})`} gap='5px' maxWidth='300px'>
				{achievements.map((achievement) => {
					const achievementData = questList[achievement.id];
					if (!achievementData) return null;
					return (
						<AchievementDisplay
							key={achievement.id}
							achievementData={achievementData}
							onClick={() => addAchievement(achievement.id)}
							height={isMobile ? '30px' : '40px'}
							width={isMobile ? '30px' : '40px'}
							style={{ cursor: 'pointer' }}
						/>
					);
				})}
			</Grid>
		);
	}

	return (
		<IdlescapeWrappingTooltip content={achievementPicker()} activateOnClick>
			<Flex
				height={isMobile ? '50px' : '60px'}
				width={isMobile ? '50px' : '60px'}
				border='3px solid white'
				borderRadius='15px'
				borderStyle='dashed'
				justifyContent='center'
				alignItems='center'
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
			>
				<FaPlus />
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}
