import React from 'react';
import { locations } from '../../../utils/locationList';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import GatheringResource from './GatheringResource';

export default function Mining() {
	const locs = [
		locations[locationsIds.clay_pit],
		locations[locationsIds.city_outskirts_mine],
		locations[locationsIds.village_quarry],
		locations[locationsIds.desert_mine],
		locations[locationsIds.underground_mine],
		locations[locationsIds.slate_spires],
		locations[locationsIds.hidden_mine],
		locations[locationsIds.volcano_mine],
		locations[locationsIds.deep_pit],
	];
	return (
		<div className='resource-list mining-container'>
			{locs.map((loc) => (
				<GatheringResource location={loc} key={loc.locID} />
			))}
		</div>
	);
}
