import { Box, Grid } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useState } from 'react';
import AbilityEditor from './Abilities/AbilityEditor';
import CombatAmmoInventory from './CombatAmmoInventory';
import { CombatCharacterEquipment } from './CombatCharacterEquipment';
import CombatFoodInventory from './CombatFoodInventory';
import CombatPlayerStats from './CombatPlayerStats';
import Loadouts from './Loadout/Loadouts';

/**
 * This is its own file because trying to put it in CombatGear led to a bunch of weird hooks issues
 */
export default function CombatGearRightPanel() {
	const [containerView, setContainerView] = useState<'equipment' | 'cosmetics' | 'ammo' | 'food' | 'loadout'>(
		'equipment'
	);

	function renderView() {
		switch (containerView) {
			case 'equipment':
			case 'cosmetics':
				return <CombatCharacterEquipment view={containerView} />;
			case 'ammo':
				return <CombatAmmoInventory />;
			case 'food':
				return <CombatFoodInventory />;
			case 'loadout':
				return <Loadouts />;
			default:
				return null;
		}
	}

	return (
		<div className='combat-gear-container-wrapper'>
			<div className='combat-gear-container'>
				<Box gridRow={'span 2'}>
					<Grid
						gridTemplateColumns={'repeat(auto-fit, minmax(50px, 1fr))'}
						textAlign={'center'}
						columnGap={'2px'}
						margin='0 5px'
					>
						<IdlescapeButton
							onClick={() => setContainerView('equipment')}
							variant='green'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'end'}
						>
							Gear
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('cosmetics')}
							variant='purple'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'start'}
						>
							Cosmetics
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('loadout')}
							variant='orange'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'center'}
						>
							Loadout
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('food')}
							variant='blue'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'end'}
						>
							Food
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('ammo')}
							variant='red'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'start'}
						>
							Ammo
						</IdlescapeButton>
					</Grid>
					{renderView()}
				</Box>
				{containerView === 'equipment' && (
					<>
						<CombatPlayerStats />
						<AbilityEditor />
					</>
				)}
			</div>
		</div>
	);
}
