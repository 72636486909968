import {
	Box,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { ILoadout } from '../../../../../game-server/src/modules/loadout/loadout.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { getLoadoutIcon } from '../../../helper/helperFunctions';
import FakeItem from '../../game/Inventory/FakeItem';

export default function FailedMerc() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [missingItems, setMissingItems] = useState<TPartialItemWithItemID<IItem>[]>([]);
	const [failedMercs, setFailedMercs] = useState<ILoadout['id'][]>([]);
	const loadouts = usePlayerField('loadouts');

	useEffect(() => {
		socket.on('mercenary:failed', (data) => {
			setFailedMercs(data.failedMercs);
			setMissingItems(data.missingItems);
			onOpen();
		});

		return () => {
			socket.off('mercenary:failed');
		};
	}, []);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Failed Mercenaries</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>At least one of your mercenaries couldn&apos;t fully load.</Text>
					<Text>Incomplete merc loadouts:</Text>
					{failedMercs.map((merc) => {
						const mercData = loadouts.find((l) => l.id === merc);
						if (!mercData) return null;
						const icon = getLoadoutIcon(mercData);
						return (
							<Box justifySelf='center' key={merc}>
								<Image
									src={icon.icon}
									alt={icon.alt}
									width='30px'
									height='30px'
									objectFit='contain'
									filter='drop-shadow(2px 2px 2px black)'
									paddingRight='5px'
								/>
								{mercData.name}
							</Box>
						);
					})}
					<Text>Missing items:</Text>
					{missingItems.map((item, index) => (
						<FakeItem key={index} item={item} />
					))}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
