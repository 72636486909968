// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { IEquipmentStats, TItemList } from '../../../game-server/src/modules/items/items.interface';
import { TEquipmentPartials } from '../../../game-server/src/modules/player/PlayerTypes';
import { abilitiesIds } from '../utils/lookup-dictionaries/lookupAbilityList';

export function getEquipmentAbilities(
	equipment: TEquipmentPartials,
	learnedAbilities: number[],
	itemList: TItemList
): number[] {
	const newKnownAbilities: number[] = structuredClone(learnedAbilities);
	// Array of all equipment items for easier helper usage
	if (equipment) {
		for (const item of Object.values(equipment)) {
			if (!item) continue;
			const foundItem = itemList[item.itemID];
			if (foundItem?.equipmentStats) {
				// Ability
				processEquipmentAbilities(foundItem.equipmentStats, newKnownAbilities);
			}
		}
	}

	// if (useTalents) {
	// 	newKnownAbilities.push(...talents.abilities);
	// }

	return newKnownAbilities;
}

export function processEquipmentAbilities(equipmentStats: IEquipmentStats, newKnownAbilities: number[]) {
	if (equipmentStats.grantedAbility) {
		for (const abilityID of equipmentStats.grantedAbility) {
			if (newKnownAbilities.indexOf(abilityID) === -1) {
				newKnownAbilities.push(abilityID);
			}
		}
	}
	// Handle automatic/default Abilities based on equipment stats
	if (equipmentStats.slot === 'weapon' || equipmentStats.slot === 'shield') {
		if (equipmentStats.offensiveDamageAffinity?.Melee ?? 0 >= 1) {
			// Melee abilities
			if ((equipmentStats.offensiveDamageAffinity?.Piercing ?? 0) >= 1) {
				// Add stab
				const abilityID = abilitiesIds.stab;
				if (newKnownAbilities.find((a) => a === abilityID) === undefined) {
					newKnownAbilities.push(abilityID);
				}
				const bleedID = abilitiesIds.bleed;
				if (newKnownAbilities.find((a) => a === bleedID) === undefined) {
					newKnownAbilities.push(bleedID);
				}
			}
			if ((equipmentStats.offensiveDamageAffinity?.Slashing ?? 0) >= 1) {
				// Add slash
				const abilityID = abilitiesIds.slash;
				if (newKnownAbilities.find((a) => a === abilityID) === undefined) {
					newKnownAbilities.push(abilityID);
				}
				const cleaveID = abilitiesIds.cleave;
				if (newKnownAbilities.find((a) => a === cleaveID) === undefined) {
					newKnownAbilities.push(cleaveID);
				}
			}
			if ((equipmentStats.offensiveDamageAffinity?.Blunt ?? 0) >= 1) {
				// Add crush
				const abilityID = abilitiesIds.crush;
				if (newKnownAbilities.find((a) => a === abilityID) === undefined) {
					newKnownAbilities.push(abilityID);
				}
				const dazeID = abilitiesIds.daze;
				if (newKnownAbilities.find((a) => a === dazeID) === undefined) {
					newKnownAbilities.push(dazeID);
				}
			}
		}
		if ((equipmentStats.offensiveDamageAffinity?.Magic ?? 0) >= 1) {
			// Magic abilities
			const abilityIDs = [
				abilitiesIds.lesser_fire_bolt,
				abilitiesIds.lesser_ice_bolt,
				abilitiesIds.lesser_nature_bolt,
				abilitiesIds.lesser_earth_bolt,
				abilitiesIds.lesser_air_bolt,
			];
			for (const abilityID of abilityIDs) {
				if (newKnownAbilities.find((a) => a === abilityID) === undefined) {
					newKnownAbilities.push(abilityID);
				}
			}
		}
		if ((equipmentStats.offensiveDamageAffinity?.Range ?? 0) >= 1) {
			// Range abilities
			const abilityIDs = [
				abilitiesIds.piercing_ammo,
				abilitiesIds.heavy_ammo,
				abilitiesIds.serrated_ammo,
				abilitiesIds.skirmisher_stance,
				abilitiesIds.sniper_stance,
			];
			for (const abilityID of abilityIDs) {
				if (newKnownAbilities.find((a) => a === abilityID) === undefined) {
					newKnownAbilities.push(abilityID);
				}
			}
		}
	}
}
