import { Flex, Heading } from '@chakra-ui/react';
import { IdlescapeContainer } from '@idlescape/ui';
import { capitalize } from 'lodash';
import React from 'react';
import { TShrineAnyBucket, IShrineBuffBucket } from '../../../../../game-server/src/modules/shrine/Shrine.interface';
import { BucketIcon, BucketProgress, getBucketInfo } from './ShrineUtils';

export default function ShrineBucketPreview(props: { bucket: TShrineAnyBucket; onSelect: () => void }) {
	function getBucketLabel() {
		switch (props.bucket.type) {
			case 'time':
				return 'Time';
			case 'buff': {
				const theme = (props.bucket as IShrineBuffBucket).theme;
				return capitalize(theme);
			}
			case 'madnessStrength':
				return 'Madness Strength';
			case 'madnessBuff':
				return 'Madness Buff';
		}
	}

	return (
		<IdlescapeContainer
			as={Flex}
			onClick={props.onSelect}
			cursor='pointer'
			width='300px'
			height='160px'
			flexDirection='column'
			justifyContent='space-between'
		>
			<Flex alignItems='center' justifyContent='space-between'>
				<Heading size='md' margin='0' float='left'>
					{getBucketLabel()}
				</Heading>
				<BucketIcon bucket={props.bucket} size={30} />
			</Flex>
			{getBucketInfo(props.bucket, true)}
			<BucketProgress bucket={props.bucket} height='15px' margin='5px' />
		</IdlescapeContainer>
	);
}
