import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import NavFeedback from '../layout/NavFeedback';

// Lifted almost exactly from https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/

interface Props {
	children?: React.ReactNode;
}

interface State {
	hasError: boolean;
	isOpen: boolean;
	lastError: { error: string; info: string } | undefined;
}

export class ErrorBoundary extends React.Component<Props, State> {
	public state: State = {
		hasError: false,
		isOpen: false,
		lastError: undefined,
	};

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// You can also log the error to an error reporting service
		// eslint-disable-next-line no-console
		console.log(error, errorInfo);
		const err = error.toString();
		const stack = errorInfo.componentStack;
		this.setState({
			lastError: {
				error: err.length > 500 ? err.slice(0, 497) + '...' : err,
				info: stack.length > 1000 ? errorInfo.componentStack.slice(0, 997) + '...' : stack,
			},
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div>
					ERROR RENDERING UI
					<br />
					<IdlescapeButton size='large' variant='green' onClick={() => this.setState({ hasError: false })}>
						Reset
					</IdlescapeButton>
					<IdlescapeButton size='large' variant='orange' onClick={() => this.setState({ isOpen: true })}>
						Bug Report
					</IdlescapeButton>
					<NavFeedback
						open={this.state.isOpen}
						category='Bug'
						error={this.state.lastError}
						close={() => this.setState({ isOpen: false })}
					/>
				</div>
			);
		}

		return this.props.children;
	}
}
