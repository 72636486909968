import { Grid } from '@chakra-ui/react';
import {
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { TTrueInventoryType } from '../../../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../../hooks/hooks';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { socket } from '../../../../../services/socket.service';
import { itemList } from '../../../../../utils/itemList';
import { hasAugmentationCost } from '../../../../../utils/augmentingFunctions';
import { Item } from '../../../Inventory/Item';
import AugmentingItemInfo from './AugmentingItemInfo';
import AugmentingQueue from './AugmentingQueue';
import AugmentingStockpile from './AugmentingStockpile';

export function toggleAugmentingQueue(
	itemInventoryId: number,
	inventoryName: TTrueInventoryType,
	itemAmount: number,
	newIndex?: number
) {
	if (inventoryName !== 'stockpile' && inventoryName !== 'augmentingItemSlot') {
		return;
	}
	socket.emit('inventory:augmentationToggle', {
		inventoryItemID: itemInventoryId,
		sourceInventory: inventoryName,
		itemAmount: itemAmount,
		newIndex: newIndex,
	});
}

function Augmenting() {
	const originalAugmentingQueue = usePlayerField('augmentingItemSlot');
	const [augmentingQueue, setAugmentingQueue] = useState(originalAugmentingQueue);
	const augmentingQueueIDs = augmentingQueue.map((item) => item.id.toString());
	const originalStockpile = usePlayerField('stockpile');
	const [stockpile, setStockpile] = useState(originalStockpile.filter((item) => hasAugmentationCost(item, itemList)));
	const stockpileIDs = stockpile.map((item) => item.id.toString());

	const [activeItem, setActiveItem] = useState<IItem | null>(null);
	const [previewItem, setPreviewItem] = useState<IItem | null>(null);
	const [itemOrigin, setItemOrigin] = useState<null | 'stockpile' | 'augmentingItemSlot'>(null);

	const isMobile = useIsMobile();

	const sensors = useSensors(
		useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
		useSensor(TouchSensor, { activationConstraint: { delay: 100, tolerance: 5 } })
	);

	useEffect(() => {
		setAugmentingQueue((queue) => {
			if (!activeItem) return originalAugmentingQueue;
			const activeInQueue = queue.some((item) => item.id === activeItem.id);
			const activeInOriginalQueue = originalAugmentingQueue.some((item) => item.id === activeItem.id);
			if (activeInQueue && !activeInOriginalQueue) {
				return [...originalAugmentingQueue, activeItem];
			} else if (!activeInQueue && activeInOriginalQueue) {
				return originalAugmentingQueue.filter((item) => item.id !== activeItem?.id);
			}
			return originalAugmentingQueue;
		});
	}, [activeItem, originalAugmentingQueue]);

	useEffect(() => {
		setStockpile((stockpile) => {
			const filteredOriginalStockpile = originalStockpile.filter((item) => hasAugmentationCost(item, itemList));
			if (!activeItem) return filteredOriginalStockpile;
			const activeInStockpile = stockpile.some((item) => item.id === activeItem.id);
			const activeInOriginalStockpile = filteredOriginalStockpile.some((item) => item.id === activeItem.id);
			if (activeInStockpile && !activeInOriginalStockpile) {
				return [...filteredOriginalStockpile, activeItem];
			} else if (!activeInStockpile && activeInOriginalStockpile) {
				return filteredOriginalStockpile.filter((item) => item.id !== activeItem?.id);
			}
			return filteredOriginalStockpile;
		});
	}, [activeItem, originalStockpile]);

	function handleOnClick(event: React.MouseEvent, item: IItem, inventoryName: TTrueInventoryType) {
		if (event.shiftKey) {
			toggleAugmentingQueue(item.id, inventoryName, 1);
			return true;
		}
		return false;
	}

	function handleDragStart({ active }: DragStartEvent) {
		let activeItem = stockpile.find((item) => item.id.toString() === active.id);
		if (activeItem) {
			setItemOrigin('stockpile');
			setActiveItem(activeItem);
			if (previewItem?.id === activeItem.id) {
				setPreviewItem(null);
			}
		}
		activeItem = augmentingQueue.find((item) => item.id.toString() === active.id);
		if (activeItem) {
			setItemOrigin('augmentingItemSlot');
			setActiveItem(activeItem);
			if (previewItem?.id === activeItem.id) {
				setPreviewItem(null);
			}
		}
	}

	function handleDragOver({ active, over }: DragOverEvent) {
		// We need to move the item in/out of the queue, when switching between the two lists
		// while still dragging, for the preview to work
		const activeId = String(active.id);
		const overId = String(over?.id);
		if (!overId) return;

		if (
			overId !== 'augmentingQueue' &&
			augmentingQueueIDs.includes(activeId) &&
			(overId === 'augmentingStockpile' || stockpileIDs.includes(overId))
		) {
			removeItem(activeId);
		} else if (
			overId !== 'augmentingStockpile' &&
			!augmentingQueueIDs.includes(activeId) &&
			(overId === 'augmentingQueue' || augmentingQueueIDs.includes(overId))
		) {
			addItem(activeId);
		}
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		setActiveItem(null);
		const activeId = String(active.id);
		const overId = String(over?.id);
		if (!activeId || !overId || !activeItem) {
			return;
		}
		if (overId === 'augmentingQueue' || augmentingQueueIDs.includes(overId)) {
			const oldIndex = augmentingQueueIDs.indexOf(activeId);
			let newIndex = augmentingQueueIDs.indexOf(overId);
			if (newIndex === -1) {
				newIndex = augmentingQueueIDs.length - 1;
			}
			if (itemOrigin === 'stockpile') {
				toggleAugmentingQueue(activeItem.id, 'stockpile', activeItem.stackSize, newIndex);
			} else if (itemOrigin === 'augmentingItemSlot') {
				if (newIndex !== oldIndex) {
					const correctedIndex = oldIndex < newIndex ? newIndex + 1 : newIndex;
					socket.emit('inventory:augmentation:move', {
						inventoryItemID: Number(activeId),
						newIndex: correctedIndex,
					});
				}
			}
			setAugmentingQueue((queue) => arrayMove(queue, oldIndex, newIndex));
		} else if (overId === 'augmentingStockpile' || stockpileIDs.includes(overId)) {
			if (itemOrigin === 'augmentingItemSlot') {
				toggleAugmentingQueue(activeItem.id, 'augmentingItemSlot', activeItem.stackSize);
			}
		}
	}

	function addItem(itemId: string) {
		const numberId = Number(itemId);
		const item = stockpile.find((item) => item.id === numberId);
		if (!item) return;
		setAugmentingQueue((queue) => [...queue, item]);
		setStockpile((stockpile) => stockpile.filter((item) => item.id !== numberId));
	}

	function removeItem(itemId: string) {
		const numberId = Number(itemId);
		const item = augmentingQueue.find((item) => item.id === numberId);
		if (!item) return;
		setAugmentingQueue((queue) => queue.filter((item) => item.id !== numberId));
		setStockpile((stockpile) => [...stockpile, item]);
	}

	return (
		<Grid gridTemplateColumns={isMobile ? '' : 'repeat(3,1fr)'} minHeight='calc(100% - 35px)'>
			<DndContext
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				<SortableContext items={augmentingQueueIDs}>
					<AugmentingQueue
						items={augmentingQueue}
						onClick={(e, item) => handleOnClick(e, item, 'augmentingItemSlot')}
						previewState={[previewItem, setPreviewItem]}
						isDragging={activeItem !== null}
					/>
				</SortableContext>
				<SortableContext items={stockpileIDs}>
					<AugmentingStockpile
						items={stockpile}
						onClick={(e, item) => handleOnClick(e, item, 'stockpile')}
						isDragging={activeItem !== null}
					/>
				</SortableContext>
				{createPortal(
					<DragOverlay
						dropAnimation={{
							sideEffects: defaultDropAnimationSideEffects({
								styles: {
									active: {
										opacity: '0.5',
									},
								},
							}),
						}}
					>
						{activeItem ? <Item item={activeItem} cursor='grabbing' showTooltip={false} /> : null}
					</DragOverlay>,
					document.getElementsByClassName('game-container')[0] as HTMLElement
				)}
			</DndContext>
			<AugmentingItemInfo previewItem={previewItem} />
		</Grid>
	);
}

export default Augmenting;
