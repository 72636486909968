import { Box, Flex } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { AiFillEdit, AiFillSave, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { usePlayerSetting } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';

export default function TabEditor(props: { tab: number; allowEdit: boolean; allowUp: boolean; allowDown: boolean }) {
	const [edit, setEdit] = useState(false);
	const inventoryTabNames = usePlayerSetting('miscellaneous.inventoryTabNames');
	const tabName = inventoryTabNames[Number(props.tab)] ?? `Tab ${props.tab}`;
	const [name, setName] = useState(tabName);
	const allowEdit = props.allowEdit || edit; // Keep editing even if closing the settings

	function handleSetName(name: string) {
		setName(name.replace(/[^a-zA-Z0-9 ]/g, ''));
	}

	function handleChangeMode() {
		if (edit && name.length > 0 && name !== inventoryTabNames[props.tab]) {
			socket.emit('inventory:changeTabName', { tab: props.tab, name });
		}
		setEdit(!edit);
	}

	function handleMoveTab(down: boolean) {
		socket.emit('inventory:moveTab', { tab: props.tab, down });
		setEdit(false);
	}

	return (
		<Flex className='inventory-tab-title' paddingBottom='17px' alignItems='center' justifyContent='space-between'>
			{edit ? <IdlescapeInput value={name} setValue={handleSetName} /> : tabName}
			{edit && (
				<>
					<IdlescapeButton
						variant={props.allowUp ? 'blue' : 'disabled'}
						size='icon'
						onClick={() => handleMoveTab(false)}
					>
						<AiOutlineArrowUp />
					</IdlescapeButton>
					<IdlescapeButton
						variant={props.allowDown ? 'blue' : 'disabled'}
						size='icon'
						onClick={() => handleMoveTab(true)}
					>
						<AiOutlineArrowDown />
					</IdlescapeButton>
				</>
			)}
			{allowEdit && (
				<Box cursor='pointer' onClick={handleChangeMode}>
					{edit ? <AiFillSave /> : <AiFillEdit />}
				</Box>
			)}
		</Flex>
	);
}
