/* eslint-disable react/no-unescaped-entities */
import { Text } from '@chakra-ui/react';
import React from 'react';
import { itemIcon, SubHeadingWithIcons } from '../../../../helper/NavigationTabsList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

function FarmingGuide() {
	return (
		<>
			Farming is a passive skill where you can plant seeds obtained from Foraging into a field and they'll
			automatically harvest and replant till you either stop it or run out of seeds. Each seed has a farming level
			requirement as well as a size requirement in order for it to plant. Starting out, your field will be small
			and you won't be able to plant much, but every 10 farming levels, your farm will expand alternating by 1 row
			vertically and horizontally up to level 90, afterwards it'll expand one row horizontally at the bottom every
			10 mastery levels. Your effective farming level will increase the minimal yield of seeds.
			<br />
			Start by selecting a seed and then clicking on the plot to plant it. You can drag to plant multiple seeds at
			once. This also allows you to select multiple plants at once to change their settings.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.fertilizer)]}>Fertilizer</SubHeadingWithIcons>
			Fertilizer is an optional item that will increase the minimal yield of your crops as well as increase the
			experience earned from them. It costs one fertilizer per tile the seed uses, so a 2x2 seed will consume four
			Fertilizer per cycle.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.farming_shard)]}>Farming Shards</SubHeadingWithIcons>
			With a full plot, you'll gain a farming shard every five minutes, no matter what is planted. This time is
			delayed with every empty plot spot you have, an example being if you have half of your field planted, you'll
			obtain a shard every ten minutes. The farming shards can be used to craft the legendary elven hoe.
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.mysterious_seed)]}>Mysterious Seeds</SubHeadingWithIcons>
			Mysterious Seeds are special seeds that can be obtained via other plants and combat from time to time, can
			be planted up to 4x4 size, and give a variety of items from a special loot table. You will need one seed per
			tile. Bigger plants will yield more loot, but the experience per invested seed decreases as every size gives
			the same experience.
			<SubHeadingWithIcons
				icons={[
					itemIcon(itemsIds.water_totem),
					itemIcon(itemsIds.nature_totem),
					itemIcon(itemsIds.earth_totem),
					itemIcon(itemsIds.coalescing_totem),
				]}
			>
				Totems
			</SubHeadingWithIcons>
			There are four special totems you can use to enhance your idle farming experience. Each one lasts a full
			week, growing stronger the farther longer they are in their growth.
			<ul>
				<li>Water Totems increase growth stages, meaning faster crop growth</li>
				<li>Nature Totems have a chance to give you a free replant of a crop.</li>
				<li>Earth Totems increase crop Yields up to 50% depending on the growth stage.</li>
				<li>
					Coalescing Totems do what the other totems do (Stacking with them as well), as well as reduce
					fertilizer costs. But to get their full effect, you need to plant different seeds on your plot
					(Different mysterious seeds count as different seeds for this).
				</li>
			</ul>
			<SubHeadingWithIcons icons={[itemIcon(itemsIds.box_trap)]}>Traps</SubHeadingWithIcons> You can craft
			different kinds of traps using logs from Foraging. These traps can be used to collect some useful items used
			in crafting Ranged Equipment, such as Scales, Chiten, and furs/hides to make leather. Different traps are
			better for different loot.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</>
	);
}

export default FarmingGuide;
