import React, { useEffect, useRef, useState } from 'react';
import { ICombatSplotch } from '../../../../../game-server/src/modules/combat/CombatEmitHelper';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import CombatSplash from './CombatSplash';

const maxSplashesAmount = 6;

export default function CombatSplashesContainer({ id, compact }: { id: number; compact?: boolean }) {
	const [splashes, setSplashes] = useState<(ICombatSplotch | undefined)[]>([
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	]);
	const nextIndex = useRef(0);
	const [delays, setDelays] = useState<(NodeJS.Timeout | undefined)[]>([
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	]);
	const settings = usePlayerField('settings');
	const highPerformance = settings.miscellaneous.highPerformance;

	useEffect(() => {
		socket.on('combat:splotch', addSplash);

		return () => {
			socket.off('combat:splotch', addSplash);
		};
	}, []);

	if (highPerformance) {
		return null;
	}

	function addSplash(data: ICombatSplotch) {
		if (id !== data.id) return;
		let index: number;
		setSplashes((s) => {
			index = nextFreeIndex(s);
			const splashesCopy = [...s];
			splashesCopy[index] = data;
			nextIndex.current = (index + 1) % maxSplashesAmount;
			return splashesCopy;
		});
		setDelays((d) => {
			const delaysCopy = [...d];
			if (delaysCopy[index]) {
				// we overwrite an existing splash, so we need to clear the timeout
				clearTimeout(delaysCopy[index]);
			}
			const delay = setTimeout(() => {
				removeSplash(index);
			}, 3000);
			delaysCopy[index] = delay;
			return delaysCopy;
		});
	}

	function nextFreeIndex(array: (ICombatSplotch | undefined)[]) {
		const index = array.findIndex((elem) => !elem);
		if (index === -1) return nextIndex.current;
		return index;
	}

	function removeSplash(index: number) {
		setSplashes((s) => {
			const splashesCopy = [...s];
			splashesCopy[index] = undefined;
			return splashesCopy;
		});
	}

	return (
		<div className={`combat-splashes-container ${compact ? 'compact' : ''}`}>
			{splashes.map((elem, index) => {
				if (!elem) return null;
				return <CombatSplash data={elem} index={index} key={index}></CombatSplash>;
			})}
		</div>
	);
}
