import React from 'react';
import { CombatZone } from './CombatZone';
import { CombatLocationInfo } from './CombatLocationInfo';
import { locations } from '../../../utils/locationList';
import { itemList } from '../../../utils/itemList';
import { usePlayerField } from '../../../hooks/hooks';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { playAreaAtom } from '../../../atoms/playAreaAtom';
import { combatZoneAtom } from '../../../atoms/combatZoneAtom';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';

export const baseLocations = [
	locationsIds.farm,
	locationsIds.caves,
	locationsIds.city,
	locationsIds.fallen_academy,
	locationsIds.lava_maze,
	locationsIds.corrupted_lands,
	locationsIds.valley_of_giants,
	locationsIds.overgrown_woods,
	locationsIds.enchanted_armory,
	locationsIds.elven_encampment,
	locationsIds.chaos_wastes,
	locationsIds.training_ground,
];

export const CombatZones = () => {
	const eliteChallengeData = usePlayerField('eliteChallenges');
	const stockpile = usePlayerField('stockpile');
	const [combatZone, setCombatZone] = useRecoilState(combatZoneAtom);
	const infoLocation = typeof combatZone === 'number' ? combatZone : 0;
	const playAreaRef = useRecoilValue(playAreaAtom);

	function openLocationInfo(locationID: number) {
		playAreaRef?.scrollTo({
			top: 0,
		});
		setCombatZone(locationID);
	}
	const dungeons: number[] = [];
	const eliteChallenges: number[] = [];
	for (const item of stockpile) {
		const itemInList = itemList[item.itemID];
		if (itemInList.champEncounter) {
			if (itemInList.tags?.includes('elite') && !eliteChallenges.includes(itemInList.champEncounter)) {
				eliteChallenges.push(itemInList.champEncounter);
			}
			if (itemInList.tags?.includes('dungeon') && !dungeons.includes(itemInList.champEncounter)) {
				dungeons.push(itemInList.champEncounter);
			}
		}
	}

	// Sort by base location
	eliteChallenges.sort((a, b) => {
		const locA = locations[a]?.bestiaryBaseLocationID ?? 0;
		const locB = locations[b]?.bestiaryBaseLocationID ?? 0;
		const indexA = baseLocations.findIndex((i) => i === locA);
		const indexB = baseLocations.findIndex((i) => i === locB);
		if (indexA === -1) return 1;
		if (indexB === -1) return -1;
		return indexA - indexB;
	});

	// Sort by recommended level
	dungeons.sort((a, b) => {
		const levelA = Number(locations[a]?.extraTooltipInfo?.split(/[-+]/)[0]);
		const levelB = Number(locations[b]?.extraTooltipInfo?.split(/[-+]/)[0]);
		if (levelA !== levelB) return levelA - levelB;
		const locA = locations[a]?.bestiaryBaseLocationID ?? 0;
		const locB = locations[b]?.bestiaryBaseLocationID ?? 0;
		const indexA = baseLocations.findIndex((i) => i === locA);
		const indexB = baseLocations.findIndex((i) => i === locB);
		return indexA - indexB;
	});

	function renderLocationList() {
		return (
			<Accordion defaultIndex={[0]} allowMultiple>
				{renderCombatZones('Combat Zones', baseLocations)}
				{eliteChallenges.length > 0 && renderCombatZones('Elite Challenges', eliteChallenges)}
				{dungeons.length > 0 && renderCombatZones('Dungeons', dungeons)}
			</Accordion>
		);
	}

	function renderCombatZones(header: string, locationIds: number[]) {
		return (
			<AccordionItem>
				<AccordionButton as='h2'>
					{header}
					<AccordionIcon />
				</AccordionButton>
				<AccordionPanel>
					<div className='combat-zones-list'>
						{locationIds.map((location) => {
							const locationData = locations[location];
							return (
								<CombatZone
									key={locationData.locID}
									zoneID={location}
									eliteChallenge={eliteChallengeData[location]}
									zoneName={locationData.name}
									openLocationInfo={openLocationInfo}
								></CombatZone>
							);
						})}
					</div>
				</AccordionPanel>
			</AccordionItem>
		);
	}

	function renderLocationInfo() {
		// Figure out which list they infoLocation is in
		let list: number[];
		if (baseLocations.includes(infoLocation)) {
			list = baseLocations;
		} else if (eliteChallenges.includes(infoLocation)) {
			list = eliteChallenges;
		} else if (dungeons.includes(infoLocation)) {
			list = dungeons;
		} else {
			list = [];
		}
		// Find the previous and next valid locations, if they don't exist then set them to -1
		const idx = list.indexOf(infoLocation);
		const prevIdx = list[idx - 1] || -1;
		const nextIdx = list[idx + 1] || -1;
		return (
			<CombatLocationInfo
				key={infoLocation}
				locationId={infoLocation}
				eliteChallenge={eliteChallengeData[infoLocation] || 0}
				back={() => openLocationInfo(0)}
				prev={() => setCombatZone(prevIdx)}
				next={() => setCombatZone(nextIdx)}
				allowPrev={prevIdx !== -1}
				allowNext={nextIdx !== -1}
			/>
		);
	}

	let content: React.ReactElement;
	if (locations[infoLocation]) {
		content = renderLocationInfo();
	} else {
		content = renderLocationList();
	}

	return <div className='combat-zones-container'>{content}</div>;
};
