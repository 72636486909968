import { itemList } from '../../../../utils/itemList';
import { chatIconList } from '../../../../utils/chatIconList';
import { platinumShopList } from '../../../../utils/platinumShopList';
import ChatItem from '../../Chat/Links/ChatItem';
import { IdlescapeFrameBox } from '@idlescape/ui';
import { Image, Text, Box } from '@chakra-ui/react';

interface IShopItemProps {
	platinumShopListID: number;
	setActiveItem: (value: number) => void;
}

export const ShopItem = (props: IShopItemProps) => {
	const shopData = platinumShopList[props.platinumShopListID];
	const itemData = shopData.itemID && shopData.itemID.length === 1 ? itemList[shopData.itemID[0]] : null;
	const iconData = shopData.iconID && shopData.iconID.length === 1 ? chatIconList[shopData.iconID[0]] : null;
	return (
		<IdlescapeFrameBox
			onClick={() => props.setActiveItem(props.platinumShopListID)}
			variant='primary'
			paddingBottom={'30px'}
			filter={'drop-shadow(2px 2px 3px rgba(0, 225, 255, 0.7))'}
			cursor={'pointer'}
		>
			<Text
				background={
					'linear-gradient(to right, rgb(0, 0, 0) 0, rgba(0, 40, 60, 0.8) 80%, rgba(0, 225, 255, 0) 100%);'
				}
				maxWidth={'80%'}
				borderTopLeftRadius={'10px'}
				filter={'drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'}
				margin={'10px 0 0 10px'}
				textAlign={'left'}
				paddingLeft={'10px'}
				fontWeight={'bold'}
			>
				{shopData.name || itemData?.name || iconData?.name}
			</Text>
			<Image
				filter={'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.9))'}
				marginTop={'auto'}
				marginBottom={'auto'}
				src={shopData.images[0]}
				maxHeight={'150px'}
				maxWidth={'150px'}
			></Image>

			<Box position={'absolute'} bottom={'15px'} width={'100%'}>
				{shopData.price.toLocaleString('en-us')}
				<ChatItem item={{ itemID: 20, name: 'Platinum' }}></ChatItem>
			</Box>
		</IdlescapeFrameBox>
	);
};
