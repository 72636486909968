import { Heading, Image } from '@chakra-ui/react';
import { IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { IItem, TItemRarity } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../../hooks/hooks';
import { AFFIX_BLACKLISTED_SLOTS, AFFIX_DUST_PER_RARITY } from '../../../../../utils/constantsCollection';
import { filterItem } from '../../../../../helper/helperFunctions';
import { itemList } from '../../../../../utils/itemList';
import { stringCapitalize } from '../../../../../helper/StringFormatter';
import ItemSelector from '../../../Inventory/ItemSelector';

const rarityOrder: TItemRarity[] = ['common', 'uncommon', 'rare', 'epic', 'legendary'];

export default function RunecraftingAffixItemSelection(props: { onSelect: (item: IItem) => void }) {
	const [sort, setSort] = useState('');
	const stockpile = usePlayerField('stockpile');
	const filteredStockpile = stockpile
		.filter((item) => {
			const itemData = itemList[item.itemID];
			if (!itemData.equipmentStats) {
				return false;
			}
			if (AFFIX_BLACKLISTED_SLOTS.includes(itemData.equipmentStats.slot)) {
				return false;
			}
			if (itemData.equipmentStats.isTransmog && !itemData.enchantmentTier) {
				return false;
			}
			return true;
		})
		.filter((item) => filterItem(item, sort));

	const categorizedStockpile = filteredStockpile.reduce((acc, item) => {
		const itemData = itemList[item.itemID];
		const rarity = itemData.rarity ?? 'common';
		if (!acc[rarity]) {
			acc[rarity] = [];
		}
		acc[rarity].push(item);
		return acc;
	}, {} as Record<TItemRarity, IItem[]>);

	return (
		<div className='enchanting-items-container'>
			<h4 className='enchanting-title'>Items</h4>
			<div className='enchanting-title-border'></div>
			<IdlescapeInput
				variant='search'
				setValue={setSort}
				value={sort}
				type='search'
				className={'anchor-enchanting-inventory-filter'}
				placeholder='Inventory Filter'
			/>
			{rarityOrder.map((rarity) => {
				if (!categorizedStockpile[rarity]) {
					return null;
				}
				return (
					<React.Fragment key={rarity}>
						<Heading fontSize='lg'>
							<Image
								src={itemList[AFFIX_DUST_PER_RARITY[rarity]].itemImage}
								alt={rarity}
								width='20px'
								height='20px'
								marginRight='5px'
							/>
							{stringCapitalize(rarity)}
						</Heading>
						<ItemSelector
							items={categorizedStockpile[rarity]}
							selectedId={-1}
							select={props.onSelect}
							multiSelection={false}
							className='enchanting-items'
						/>
					</React.Fragment>
				);
			})}
			{filteredStockpile.length === 0 && <p className='enchanting-no-items'>No items found</p>}
		</div>
	);
}
