/* eslint-disable react/no-unescaped-entities */
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React from 'react';
import { abilityIcon, SubHeading } from '../../../helper/NavigationTabsList';
import { abilitiesIds } from '../../../utils/lookup-dictionaries/lookupAbilityList';

function CombatGuide() {
	return (
		<>
			<Tabs>
				<TabList>
					<Tab>Basic</Tab>
					<Tab>Advanced</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<SubHeading>Combat Basics</SubHeading>
						In Idlescape there are three main ways to fight various enemies; Melee, Magic, and Ranged. You
						can earn experience in these different styles of combat by hitting enemies with their related
						abilities. To get more abilities to use, you can get them from either skill books or equipping
						certain items.
						<SubHeading>Consumables</SubHeading>
						In combat you can use food, ammo, and runes as consumables to help you fight. Food will increase
						survivability, while ammo will increase damage. Runes are used mostly in magic for certain
						abilities, without them the skills won&apos;t be as potent.
						<SubHeading>Abilities and Rotations</SubHeading>
						When you obtain abilities, you can slot them into a rotation, which will automatically execute
						in combat related activities. You can do this by either dragging and dropping them into place,
						or clicking the abilities. Abilities in the first slot will be prioritized when they are off
						cooldown compared to abilities in the last slot. You can change your auto attack to be more
						specalized depending on your equipment. These abilities have no cooldown and are placed at the
						end of your rotation.
						<SubHeading>Waves and Elites</SubHeading>
						When you defeat all enemies on the screen, it will start a new "wave". After defeating 50 of
						these, you will go up in difficulty and the stats of the enemies will increase, but so will the
						loot rewards. Around difficulty 4, you will start to see more Elite variants of enemies that are
						stronger and probably a different color. The higher the difficulty, the more elite enemies you
						will see.
						<SubHeading>Death and Respawning</SubHeading>
						When your HP hits zero, you will be "knocked out" and respawn after a minute in most areas. When
						this happens, waves will reset and combat will start again. When "knocked out" in elite scrolls,
						you will not respawn and will fail the scroll, so plan and prepare carefully. In dungeons you
						will fail when everyone in your party is "knocked out". To prevent this, you can bring good food
						or use certain abilities and enchantments to keep yourself healthy.
						<SubHeading>Loadouts</SubHeading>
						You can assign equipment to a loadout and then assign that loadout to a specific zone. When
						loadouts are assigned to a zone, it will automatically equip that loadout before entering the
						zone. If it is unable to do so, it will give you a popup and tell you what went wrong.
						<SubHeading>Mercenaries</SubHeading>
						Mercenaries is a feature that can be used to create a "fake player" for either duoing zones or
						completing dungeons. However, they will require you to provide gear and food for them to use and
						it can't be gear you are using yourself, or have assigned to another merc. These can be provided
						by using a loadout and setting it as an "active" mercenary. Mercenaries will consume all of the
						provide food in a loadout at once and add it to their HP pool. Using Mercenaries has the
						downside of losing some of your junk drops and crippling loneliness, which increases with every
						merc you hire.
					</TabPanel>
					<TabPanel>
						There are four styles of combat that you can choose to do: melee, ranged, magic, or hybrid.
						Melee makes use of melee weapons (such as scimitars and daggers). Ranged makes use of ranged
						weapons (such as longbows and crossbows). Magic makes use of magic weapons (such as staffs and
						wands). Hybrid utilizes 2 or more of the above skills with weapons that provide hybrid
						statistics (such as Shapeless Scythe)
						<SubHeading size='lg'>Player, Equipment Stats, and Skills</SubHeading>
						Both your equipment and your skill levels decide how effective you are in combat. You must know
						how they work in order to make effective loadouts.Combat is broken into six skills, each with
						their own experience and how to earn it.
						<br />
						Constitution: Earned when other Combat experience is earned.
						<br />
						Attack: Landing hits in Combat.
						<br />
						Defense: Taking, mitigating and dodging damage in Combat.
						<br />
						Strength: Dealing damage with Melee related skills.
						<br />
						Magic: Dealing damage with Magic related skills.
						<br />
						Ranged: Dealing damage with Ranged related skills.
						<br />
						Experience for each combat skill is rewarded on monster kill, so dying before defeating a mob
						will reward no experience.
						<SubHeading>The Offensive Stats</SubHeading>
						<b>Strength</b> (Melee), <b>Dexterity</b> (Ranged), and <b>Intelligence</b> (Magic) are the main
						offensive stats and modify your overall damage for their respective fields. They are also
						affected by Combat Skill Levels (<b>Strength</b>, <b>Ranged</b>, and <b>Magic</b>). <br />
						<b>Crit Chance</b> is your chance to hit an enemy for Critical damage, when you do hit a crit,
						it'll add damage based off your <b>Crit Multiplier</b>. Having a Crit Chance of 30% and a Crit
						Mult of 1.5x means that you have a 30% chance on hit to do 1.5x damage. <br />
						<b>Accuracy</b> is what decides your hit chance against the enemy. There are several different
						types of accuracy, where the types of abilities you use will be affected by those. For example
						the ability 'Fireball' will make use of Magic Accuracy and Fire Accuracy.
						<SubHeading>The Defensive Stats</SubHeading>
						<b>Protection</b> is your physical damage reduction. Any melee or ranged damage that an enemy
						hits you with will be reduced using this value. <br />
						<b>Resistance</b> is your magic damage reduction. Any magic damage that an enemy hits you with
						will be reduced using this value. <br />
						<b>Agility</b> is used to calculate your dodge chance. The higher this value is, the less likely
						it is that an enemy will be able to hit you. <br />
						<b>Stamina</b> determines the value of your total health, in addition to your{' '}
						<b>Constitution</b> level
						<SubHeading>Affinities</SubHeading>
						Affinities are used in calculating your effectiveness for each subclass of combat, both for
						offense and defense. A good example of this are Breastplates, which give a high amount of
						negative offensive affinities, but lots of positive defensive affinities in return. This makes
						them very effective at tanking, but not good at outputting damage. Affinities are added together
						from item to item (ex. a fire amulet provides +15% fire affinity, which would be added with the
						+35% fire affinity given by the Mantle of Flame to give a total fire affinity of +50%).
						Affinities can increase or decrease your total effectiveness in combat, but not as drastically
						as it first seems. Each ability used takes an average of all styles used in an ability.
						<br />
						Player Stats: +50% magic affinity,, +115% fire affinity
						<br />
						Ability Being Used: Fireball (1x Magic, 1.33x Fire) {abilityIcon(abilitiesIds.fire_ball)}
						<br />
						(Magicaffinity x Magicmultiplier1 + Fireaffinity x Firemultiplier2) / 2
						<br />
						↓↓
						<br />
						(1.00 x 1.50 + 1.33 x 2.15) / 2
						<br />
						↓↓
						<br />
						Effectiveness = 2.179
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Text color='gray' fontStyle='oblique'>
				The basic guide was written by Feylos. The advanced guide was written by HeckYea with some edits and
				formatting from Feylos. Thx HeckYea and Feylos {'<3'}
			</Text>
		</>
	);
}

export default CombatGuide;
