import {
	Flex,
	Grid,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { FaImage } from 'react-icons/fa';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';

export default function BackgroundEditor({ activeBackground }: { activeBackground: ILocationData['locID'] }) {
	const availableBackgrounds = [
		locationsIds.caves,
		locationsIds.city,
		locationsIds.dark_vault,
		locationsIds.chaotic_rift,
	];
	const { isOpen, onOpen, onClose } = useDisclosure();

	function setBackground(location: ILocationData['locID']) {
		socket.emit('profile:background:set', location);
	}

	function renderLocations(location: ILocationData['locID']) {
		const locData = locations[location];
		return (
			<Flex
				key={location}
				flexDirection='column'
				onClick={() => setBackground(location)}
				cursor='pointer'
				overflow='hidden'
				borderRadius='10px'
				border={activeBackground === location ? '2px solid #9b9cb0' : '3px solid transparent'}
				_hover={{ border: '3px solid white' }}
			>
				<Image src={locData.locationImage} width='100%' height='100%' objectFit='cover' />
				<Text textAlign='center'>{locData.name}</Text>
			</Flex>
		);
	}

	return (
		<>
			<IdlescapeButton variant={'blue'} size='icon' onClick={onOpen}>
				<FaImage />
			</IdlescapeButton>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Choose Background</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Grid gridTemplateColumns='1fr 1fr' gap='5px'>
							{availableBackgrounds.map((location) => renderLocations(location))}
						</Grid>
					</ModalBody>
					<ModalFooter>
						<IdlescapeButton variant='red' onClick={onClose}>
							Close
						</IdlescapeButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
