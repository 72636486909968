import React from 'react';
import { socket } from '../../../../services/socket.service';
import { itemList } from '../../../../utils/itemList';
import { IDialogProps } from './MainItemDialog';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import { affixList } from '../../../../utils/affixList';
import { getAffixCost } from '../../../../utils/affixFunctions';

function ClearAffixDialog(props: IDialogProps) {
	const itemData = itemList[props.item.itemID];
	const affixCost = getAffixCost(itemData, props.item?.affixes?.map((affix) => affixList[affix.path]) ?? [], 'clear');
	function handleClearAffix() {
		if (itemData.equipmentStats) {
			socket.emit('runecrafting:clearAffix', {
				inventoryItemID: props.item.id,
			});
			props.onClose();
		}
	}

	function getGoldCost() {
		return affixCost.gold.amount.toLocaleString('en-us');
	}

	function getDustCost() {
		const dustAmount = affixCost.dust.amount;
		const dustData = itemList[affixCost.dust.id];
		return `${dustAmount.toLocaleString('en-US')} ${dustData?.name}`;
	}

	function getScrapCost() {
		const scrapAmount = affixCost.scrap.amount;
		const scrapData = itemList[affixCost.scrap.id];
		return `${scrapAmount.toLocaleString('en-US')} ${scrapData?.name}`;
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Clear Affixes</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<>
						Are you sure you want to clear affixes from {props.name}? This will cost {getDustCost()},{' '}
						{getScrapCost()}, and {getGoldCost()} Gold!
					</>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleClearAffix}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default ClearAffixDialog;
