import { IdlescapeButton, IdlescapeContainer, IdlescapeInput, IdlescapePill } from '@idlescape/ui';
import { Box, Center, Grid, Heading, Text } from '@chakra-ui/react';
import Axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { leagueList } from '../../utils/leagueList';
import { openGameUrl } from '../../GameUrls';

export const CreateCharacterPanel = (props: {
	setView: (view: 'characterList' | 'createCharacter') => void;
	isCharacterSlotAvailable: (league: number) => boolean;
	activateCharacter: (characterId: number) => void;
}) => {
	const [characterName, setCharacterName] = useState('');
	const [errors, setErrors] = useState<Partial<AxiosError & { username: string }>>({});
	const isDefaultAvailable = props.isCharacterSlotAvailable(1);
	const [selectedLeague, setSelectedLeague] = useState(isDefaultAvailable ? 1 : 0);

	const navigate = useNavigate();

	function createCharacter() {
		setErrors({});
		Axios.post('/api/auth/create-character', {
			username: characterName,
			league: selectedLeague,
		})
			.then((res) => {
				console.log('RECEIVED CHARACTER ID', res);
				if (res.data.characterId === null) {
					props.setView('characterList');
					return;
				}
				if (res.data.inactive) {
					props.activateCharacter(res.data.characterId);
					props.setView('characterList');
					return;
				}

				sessionStorage.setItem('characterID', res.data.characterId);
				openGameUrl('game', { navigate });
			})
			.catch((err) => {
				setErrors(err.response.data);
			});
	}

	const showSeasons = Object.values(leagueList).find((league) => league.rules.endDate !== undefined);

	return (
		<IdlescapeContainer
			textAlign={'center'}
			maxWidth={'1000px'}
			minWidth={'370px'}
			marginLeft={'auto'}
			marginRight={'auto'}
		>
			<Heading marginBottom={'10px'}>Choose Your Username</Heading>
			<Center>
				<IdlescapeInput
					setValue={setCharacterName}
					defaultValue={characterName}
					placeholder={'Username'}
					width={'300px !important'}
					maxLength={24}
				/>
			</Center>
			<Text color={'red'}>{errors.username}</Text>
			<div className='decorative-line'></div>
			<Heading width={'100%'} marginBottom='0'>
				Choose League
			</Heading>
			<Box marginBottom='20px'>A default character is strongly recommended if this is your first character.</Box>
			<Center flexDirection='column'>
				<IdlescapePill
					key={'create-character-league-' + leagueList[1].id}
					image={leagueList[1].icon}
					tooltipText={leagueList[1].info}
					cursor={isDefaultAvailable ? 'pointer' : ''}
					fontSize={'1.2rem'}
					maxWidth={'400px'}
					width={'80%'}
					marginTop={'10px'}
					gridColumnStart={'span 3'}
					variant={isDefaultAvailable ? (selectedLeague === leagueList[1].id ? 'light' : 'dark') : 'disabled'}
					onClick={() =>
						isDefaultAvailable && leagueList[1].active ? setSelectedLeague(leagueList[1].id) : null
					}
				>
					{leagueList[1].name}
				</IdlescapePill>
				<Box>Only one main character can be active at any time</Box>
			</Center>
			<Center>
				<Grid
					gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}
					justifyContent={'space-around'}
					gap={'10px'}
					width={'60%'}
					marginTop={'10px'}
					marginBottom={'20px'}
				>
					{Object.values(leagueList).map((leagueInfo) => {
						if (!leagueInfo.active) return null;
						if (leagueInfo.id === 1) return null;
						if (leagueInfo?.rules?.endDate) return null;
						const isAvailable = props.isCharacterSlotAvailable(leagueInfo.id);

						return (
							<IdlescapePill
								key={'create-character-league-' + leagueInfo.id}
								image={leagueInfo.icon}
								tooltipText={leagueInfo.info}
								cursor={isAvailable ? 'pointer' : ''}
								fontSize={'1.2rem'}
								variant={
									isAvailable ? (selectedLeague === leagueInfo.id ? 'light' : 'dark') : 'disabled'
								}
								onClick={() =>
									isAvailable && leagueInfo.active ? setSelectedLeague(leagueInfo.id) : null
								}
							>
								{leagueInfo.name}
							</IdlescapePill>
						);
					})}
				</Grid>
			</Center>
			{showSeasons && (
				<>
					<Box className='decorative-line' overflow='initial' position='relative'>
						<Box
							position='absolute'
							color='coral'
							textAlign='center'
							width='100%'
							top='-0.5em'
							fontSize='5xl'
							fontWeight='bold'
							filter='drop-shadow(rgba(0, 0, 0, 0.9) 3px 3px 2px)'
						>
							OR
						</Box>
					</Box>
					<Heading width={'100%'} marginBottom='0'>
						Choose Season
					</Heading>
					<Box color='#F66' marginBottom='20px'>
						Season characters are disabled once the season ends!
					</Box>
					<Center>
						<Grid
							gridTemplateColumns={'repeat(auto-fit, minmax(200px, 1fr))'}
							justifyContent={'space-around'}
							gap={'10px'}
							width={'60%'}
							marginTop={'10px'}
							marginBottom={'20px'}
						>
							{Object.values(leagueList).map((leagueInfo) => {
								if (!leagueInfo.active) return null;
								if (leagueInfo.id === 1) return null;
								if (!leagueInfo?.rules?.endDate) return null;
								const date = new Date(leagueInfo.rules.endDate).toDateString();
								const info = leagueInfo.info + ' | Ends on ' + date + ' (server time).';
								const isAvailable = props.isCharacterSlotAvailable(leagueInfo.id);
								return (
									<Box key={'create-character-league-' + leagueInfo.id}>
										<IdlescapePill
											image={leagueInfo.icon}
											tooltipText={info}
											cursor={isAvailable ? 'pointer' : ''}
											fontSize={'1.2rem'}
											variant={
												isAvailable
													? selectedLeague === leagueInfo.id
														? 'light'
														: 'dark'
													: 'disabled'
											}
											onClick={() =>
												isAvailable && leagueInfo.active
													? setSelectedLeague(leagueInfo.id)
													: null
											}
										>
											{leagueInfo.name}
										</IdlescapePill>
										<Box>End: {date}</Box>
									</Box>
								);
							})}
						</Grid>
					</Center>
				</>
			)}
			<IdlescapeButton
				marginLeft={'auto'}
				marginRight={'auto'}
				width={'fit-content'}
				padding={'0 20px'}
				variant={selectedLeague ? 'green' : 'disabled'}
				onClick={createCharacter}
			>
				Create Character
			</IdlescapeButton>
		</IdlescapeContainer>
	);
};
