import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { FISHING_LOCATION_MULTIPLIER, getBaitConsumeChance } from '../../../../utils/fishingFunctions';
import { itemList } from '../../../../utils/itemList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { fishingLocations } from './Fishing';

export interface IFishingData {
	level: number;
	levelLoc: { [locID: ILocationData['locID']]: number };
	bait: number;
	reel: number;
	bonus: number;
	preservation: number;
}

export function FishingData(): IFishingData {
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const tacklebox = usePlayerField('tacklebox');
	const skills = usePlayerField('skills');
	const magnetismStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishing_magnetism, 'fishing');
	const pungentStrength = usePlayerEnchantmentStrength(enchantmentsIds.pungent_bait, 'fishing');
	const reinforcedStrength = usePlayerEnchantmentStrength(enchantmentsIds.reinforced_line, 'fishing');
	const deadliestCatchStrength = usePlayerEnchantmentStrength(enchantmentsIds.deadliest_catch, 'fishing');
	const baitPreservationStrength = usePlayerEnchantmentStrength(enchantmentsIds.bait_preservation, 'fishing');

	let level = skills.fishing.level + skills.fishing.masteryLevel + skillEquipmentStats.fishing;
	let bait = skillEquipmentStats.fishingBaitPower;
	let reel = skillEquipmentStats.fishingReelPower;
	let bonus = skillEquipmentStats.fishingRarityPower;

	bait += pungentStrength - magnetismStrength;
	reel += reinforcedStrength - magnetismStrength;
	bonus += magnetismStrength;

	const levelLoc = level;
	let multLevel = 1;
	const levelLocations: IFishingData['levelLoc'] = {};
	let baitID = 0;
	if (tacklebox[0]) {
		const baitData = itemList[tacklebox[0].itemID];
		if (baitData && baitData.fishingBait) {
			baitID = baitData.id;
			bait += (baitData.fishingBait.bait ?? 0) * (1 + deadliestCatchStrength);
			reel += (baitData.fishingBait.reel ?? 0) * (1 + deadliestCatchStrength);
			bonus += (baitData.fishingBait.bonus ?? 0) * (1 + deadliestCatchStrength);
			level += (baitData.fishingBait.level ?? 0) * (1 + deadliestCatchStrength);
			bait = Math.floor(bait * (baitData.fishingBait.multBait ?? 1));
			reel = Math.floor(reel * (baitData.fishingBait.multReel ?? 1));
			bonus = Math.floor(bonus * (baitData.fishingBait.multBonus ?? 1));
			level = Math.floor(level * (baitData.fishingBait.multLevel ?? 1));
			multLevel = baitData.fishingBait.multLevel ?? 1;
		}
	}
	for (const location of fishingLocations) {
		const requiredFishingLevel = location?.accessRequirements?.requiredSkills?.find(
			(e) => e.skill === 'fishing'
		)?.level;
		levelLocations[location.locID] = levelLoc - (requiredFishingLevel ?? 0);
		levelLocations[location.locID] *= FISHING_LOCATION_MULTIPLIER * multLevel;
	}
	const preservation = 1 - getBaitConsumeChance(level, baitPreservationStrength, deadliestCatchStrength, baitID);
	return { level, levelLoc: levelLocations, bait, reel, bonus, preservation };
}
