import { BoxProps, Flex, Grid, Image, Text } from '@chakra-ui/react';
import React from 'react';

export default function SubSkillTabs<T extends React.Key>(props: {
	tabs: { id: T; name: string; image: string; imageAlt: string }[];
	setTab: (valOrUpdater: T | ((old: T) => T)) => void;
	selected: string;
	baseStyle: BoxProps;
	selectedStyle: BoxProps;
}) {
	return (
		<Grid
			marginTop='-5px'
			zIndex='100'
			justifyContent='center'
			gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
			textShadow='2px 2px 2px rgba(0, 0, 0, 0.9)'
		>
			{props.tabs.map((tab) => (
				<Flex
					key={tab.id}
					onClick={() => props.setTab(tab.id)}
					textAlign='center'
					justifyContent='center'
					alignItems='center'
					gap='3px'
					height='35px'
					lineHeight='30px'
					textOverflow='ellipsis'
					whiteSpace='nowrap'
					cursor='pointer'
					{...props.baseStyle}
					_first={{
						borderRadius: '20px 0 0 20px',
						// boxShadow: 'inset -11px 0 8px -10px rgb(0, 98, 128)',
						// borderRight: 'none',
					}}
					_last={{
						borderRadius: '0 20px 20px 0',
						filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.9))',
					}}
					{...(props.selected === tab.id ? props.selectedStyle : undefined)}
					className={props.selected === tab.id ? 'anchor-selected-skill-tab' : undefined}
				>
					<Image
						src={tab.image}
						alt={tab.imageAlt}
						width='26px'
						height='26px'
						filter='drop-shadow(0 0 4px rgba(0, 0, 0, 0.9))'
					/>
					<Text as='span' fontSize='16px'>
						{tab.name}
					</Text>
				</Flex>
			))}
		</Grid>
	);
}
