import { Heading } from '@chakra-ui/react';
import React from 'react';
import { locations } from '../../../../utils/locationList';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import AllItems from '../../AllItems2';
import GatheringResource from '../GatheringResource';
import './Fishing.css';
import { FishingInfo } from './FishingInfo';
import { FishingData } from './FishingData';

export const fishingLocations = [
	locations[locationsIds.shallow_pond],
	locations[locationsIds.lazy_river],
	locations[locationsIds.still_lake],
	locations[locationsIds.open_ocean],
	locations[locationsIds.stormy_seas],
];

export default function Fishing() {
	const fishingData = FishingData();
	return (
		<div className='resource-list fishing-container'>
			<FishingInfo {...fishingData} />
			<div className='fishing-tacklebox idlescape-container idlescape-container-blue'>
				<div className='fishing-tacklebox-items'>
					<Heading size={'md'}>Tacklebox</Heading>
					<div className='fishing-info-title-border'></div>
					<AllItems hideSorting hideCurrency inventoryName='tacklebox' />
				</div>
			</div>
			{fishingLocations.map((loc) => {
				return <GatheringResource location={loc} fishingData={fishingData} key={loc.locID} />;
			})}
		</div>
	);
}
