import React from 'react';
import GroupInvites from './GroupInvites';

export const GroupNonGroupContent = () => {
	const [response, setResponse] = React.useState({});

	return (
		// TODO Fix the response part below
		<div className='group-panel-container'>
			<GroupInvites />
		</div>
	);
};
