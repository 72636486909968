import { Box, Image } from '@chakra-ui/react';

interface LoadingWheelProps {
	statusText?: string;
	subStatusText?: string;
}

export const LoadingWheel = (props: LoadingWheelProps) => {
	return (
		<Box textAlign={'center'}>
			<Image
				className='loading-animation'
				src='/images/loading_animation.svg'
				alt='Loading...'
				width='min(100vw, 400px)'
			/>
			{props.statusText && <h3 className='loading-text'>{props.statusText}</h3>}
			{props.subStatusText && <h4 className='loading-text'>{props.subStatusText}</h4>}
		</Box>
	);
};
